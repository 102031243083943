/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import WarningIcon from "@material-ui/icons/WarningRounded";
import { TableCell, Tooltip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { FormatColorResetSharp } from "@material-ui/icons";
import { Fragment } from "react";
import ViewRejectedDocument from "./ViewRejectedDocument";

const useStyles = makeStyles({
  tooltip: {
    width: 280,
    height: "max-content",
    boxShadow: "0 5px 15px 0 #b5b6b4",
    backgroundColor: "#fff",
    color: "#354052",
    padding: 8,
  },
});

export default function RejectedWarning({ data }) {
  const [viewDocument, setViewDocument] = React.useState(null);
  const classes = useStyles();

  return (
    <Fragment>
      <TableCell>
        {data.rejectedDocuments.length > 0 && (
          <Tooltip
            interactive
            classes={classes}
            title={data.rejectedDocuments.map((rejectedDocument, i) => (
              <div css={{ paddingTop: 2, paddingBottom: 2,  ':not(:last-child)': {
                borderBottom: '1px solid #E1E3E7',
              } }}>
                <span>
                  {`A document uploaded as the ${
                    rejectedDocument.documentType
                  } on ${new Date(
                    rejectedDocument.uploadDate
                  ).toLocaleDateString()} for this loan`}
                </span>
                <span>
                  {rejectedDocument.hasRejectedMatch
                    ? ` was rejected${
                        rejectedDocument.rejectedReason
                          ? " because: " + rejectedDocument.rejectedReason
                          : ""
                      }`
                    : rejectedDocument.irrelevantType
                    ? ` was deleted because it was found to be ${rejectedDocument.irrelevantType}`
                    : rejectedDocument.documentTypeChanged &&
                      ` was found to be a different document type`}
                </span>
                {rejectedDocument.active && (
                  <span>
                    <br />
                    <button
                    css ={{
                      color: '#069',
                      textDecoration: 'underline',
                    }}
                      onClick={() =>
                        viewDocument
                          ? setViewDocument(null)
                          : setViewDocument(rejectedDocument.uploadId.toString())
                      }
                    >
                      Click here to view document
                    </button>
                  </span>
                )}
              </div>
            ))}
          >
            <WarningIcon style={{ color: "#354052" }} />
          </Tooltip>
        )}
      </TableCell>
      {viewDocument && <ViewRejectedDocument uploadId={viewDocument} />}
    </Fragment>
  );
}
