export const robust = {
  fontSize: 27,
  fontWeight: 500,
  color: 'white',
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    maxWidth: 300,
    fontSize: 37,
    fontWeight: 300,
    textAlign: 'left',
  },
};
export const using = {
  color: 'white',
  fontWeight: 300,
  textAlign: 'center',
  lineHeight: 1.9,
  marginTop: 13,
  '@media(min-width: 768px)': {
    maxWidth: 500,
    margin: '13px auto 0',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 345,
    textAlign: 'left',
    lineHeight: 1.8,
    fontSize: 16,
  },
};
export const innerWrap = {
  '@media(min-width: 1260px)': {
    display: 'flex',
    alignItems: 'center',
    margin: 'auto',
    maxWidth: 1100,
  },
};
export const outerWrap = {
  backgroundColor: '#3b3f43',
  padding: '28px 35px 74px 35px',
  '@media(min-width: 1260px)': {
    minHeight: 850,
    maxHeight: 1000,
  },
};
export const imagesStyles = {
  position: 'relative',
  margin: '28px auto 0',
  maxWidth: 342,
  maxHeight: 422,
  img: {
    width: '100%',
  },
  '@media(min-width: 768px)': {
    marginTop: 45,
    maxWidth: 450,
    maxHeight: 477,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 530,
    maxHeight: 564,
  },
};
export const image = {
  position: 'absolute',
  top: 0,
  bottom: 0,
};
