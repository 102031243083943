export const wrapS = {
  '@media(min-width: 1260px)': {
    height: 520,
  },
};

export const imageOverrides = {
  maxWidth: 280,
  bottom: -280,
  right: 60,
  '@media(min-width: 768px)': {
    maxWidth: 390,
    bottom: -100,
    right: 20,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 560,
    bottom: -150,
    right: 80,
  },
};

export const headerS = {
  paddingTop: 31,
  maxWidth: 326,
  '@media(min-width: 768px)': {
    paddingTop: 56,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 100,
  },
  '@media(min-width: 1440px)': {
    paddingLeft: 50,
    maxWidth: 450,
  },
};

export const headerWrapS = {
  '> div': {
    padding: '212px 0 51px',
    '@media(min-width: 768px)': {
      padding: '58px 0 57px',
    },
    '@media(min-width: 1260px)': {
      padding: '49px 0 149px',
      maxWidth: 1032,
    },
  },
  '[data-text]': {
    margin: 0,
    paddingLeft: 55,
    maxWidth: 660,
    '@media(min-width: 768px)': {
     paddingLeft: 95,
    },
    '@media(min-width: 1260px)': {
      maxWidth: 720,
    },
  },
  '[data-line]': {
    height: 'calc(100% + 120px)',
    borderColor: '#426d61',
    '&:after': {
      borderColor: '#426d61',
    },
    '@media(min-width: 1260px)': {
      left: 0,
    },
  },
};
