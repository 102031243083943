/** @jsx jsx */
import { jsx } from '@emotion/core';
import { TextField, makeStyles } from "@material-ui/core";
import {Fragment, useMemo, useState} from "react";
import { DocprobeLogo } from '../content/home/homeIcons';
import { bgImage, button, container, disabled, text, logo, returnButton, success, wrapper } from './PortalLoginStyles';
import axios from "axios";
import { validEmail } from '../Utils';

const LoginModalState = {
  InitialLogin: "INITIAL_LOGIN",
  LoginSuccess: "LOGIN_SUCCESS",
  LoginError: "LOGIN_ERROR",
};

const useStyles = makeStyles({
  input: {
    fontSize: 16, 
    lineHeight: 1.56, 
    color: '#023459',
    borderBottom: 'solid 2px rgba(152, 152, 152, 0.15)',
  },
})

export default function PortalLogin() {
  const classes = useStyles();
  const [loginModalState, setLoginModalState] = useState(LoginModalState.InitialLogin);   
  const [email, setEmail] = useState("");
  const [helperText, setHelperText] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isInvalid = useMemo(() => !validEmail(email), [email]);
  const isDisabled = useMemo(() => !email || isSubmitting, [email, isSubmitting]);
  
  const sendEmail = async () => {
    if(isDisabled) return;
    if(isInvalid) {
      setHelperText('Invalid email address.');
      return;
    }
    setIsSubmitting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/SendTitlePortalMagicLink?email=${email}`
      );
      setLoginModalState(LoginModalState.LoginSuccess);
    } catch (err) {
      console.error(err.error);
      setLoginModalState(LoginModalState.LoginError);
    }
    setIsSubmitting(false);
  };
    
  return(
    <div css={bgImage}>
      <div css={container}> 
        <div css={wrapper}>
          <div css={logo}><DocprobeLogo/></div>
          {(loginModalState === LoginModalState.InitialLogin || loginModalState === LoginModalState.LoginError) && (
            <Fragment>
              <div css={text}>Please enter your email address to login</div>
              <TextField 
                error
                fullWidth
                helperText={helperText}
                label="Email Address"
                value={email}
                InputLabelProps={{style:{color:'#707070'}}}
                InputProps={{ disableUnderline: true, className: classes.input }}
                onChange={(e) => {
                  setHelperText('');
                  setEmail(e.target.value);
                }}
              />
              <button css={[button, helperText && {margin: '33px 0 10px'}, isDisabled && disabled]} onClick={sendEmail}>Send</button>
              {loginModalState === LoginModalState.LoginError && (
                <div css={{color: '#e33e3a', fontSize: 12}}>
                  Something went wrong. Please try again later.
                </div>
              )}
            </Fragment>
          )}
          {loginModalState === LoginModalState.LoginSuccess && (
            <Fragment>
              <div css={success}>Success!</div>
              <div css={text}>We sent you an email with your access link to the
                DocProbe Portal. If you need assistance, please contact&nbsp; 
                <a
                  href="mailto:portalsupport@docprobe.net"
                  css={{fontWeight: 900, color: '#023459', ':hover': {textDecoration: 'underline'}}}
                >
                  PortalSupport@DocProbe.net
                </a>
              </div>
              <a css={[button, returnButton]} href="/">Return to DocProbe</a>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  );
}