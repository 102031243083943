export const blackBox = {
  backgroundColor: '#3b3f43',
  padding: '20px 20px 118px',
  position: 'relative',
  // zIndex: '100',
  '@media(min-width: 768px)': {
    padding: '50px 20px 196px',
  },
  '@media(min-width: 1260px)': {
    padding: '70px 20px 187px',
  },
};

export const whiteLine = {
  borderLeft: '1px solid white',
  height: 20,
  position: 'absolute',
  left: '50%',
  top: 0,
  zIndex: '25',
  '@media(min-width: 768px)': {
    height: 50,
  },
  '@media(min-width: 1260px)': {
    height: 100,
  },
};

export const halfBlack = {
  borderRight: '1px solid #3b3f43',
  content: '""',
  height: 100,
  left: '50%',
  position: 'absolute',
  top: '-50%',
  // top: 'calc(-50% - -6px)',
  // width: 1,
  '@media(min-width: 768px)': {
    height: 170,
  },
  zIndex: '20',
  '&:before': {
    content: '""',
    borderTop: '1px solid #3b3f43',
    width: 7,
    top: 0,
    left: -3,
    position: 'absolute',
  },
  '@media(min-width: 1260px)': {
    height: 250,
  },
};

export const header = {
  fontSize: 32,
  fontWeight: 100,
  padding: '0 20px',
  color: '#3b3f43',
  display: 'inline-block',
  '@media(min-width: 768px)': {
    fontSize: 57,
    fontWeight: 300,
  },
  '@media(min-width: 1260px)': {
    fontSize: 67,
  },
};

export const subHeader = {
  color: '#57616b',
  fontSize: 16,
  fontWeight: 300,
  lineHeight: '1.7',
  padding: '30px 30px 0',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    fontSize: 18,
  },
};
