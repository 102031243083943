import { makeStyles, alpha } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  pageWrap: {
    margin: '20px auto 10px',
    maxWidth: '93%',
    backgroundColor: '#fff',
    borderRadius: 6,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
    position: 'relative',
  },
  contentWrap: {
    alignItems: 'center',
    display: 'flex',
    flexWrap: 'wrap',
    margin: '0px auto',
    padding: '40px 32px 32px',
    alignContent: 'flex-start',
  },
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    padding: '0 16px',
  },
  headerTop: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 32,
  },
  headerText: {
    fontWeight: 'bold',
    fontSize: 24,
    color: '#1a173b',
    minWidth: 225,
  },
  headerBottomFull: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  uploadGroup: {
    display: 'flex',
    '@media(min-width: 715px)': {
      flexDirection: 'column',
      maxWidth: 80,
    },
    '@media(min-width: 831px)': {
      flexDirection: 'column',
      maxWidth: 100,
    },
    '@media(min-width: 1115px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      maxWidth: 260,
    },
  },
  uploadText: {
    fontSize: 12,
    color: '#023459',
    fontWeight: 600,
    textAlign: 'center',
    '@media(min-width: 715px)': {
      padding: 5,
    },
    '@media(min-width: 831px)': {
      padding: 10,
    },
    '@media(min-width: 1115px)': {
      padding: 10,
    },
  },
  uploadTextButton: {
    color: '#fff',
    backgroundColor: '#f7a01b',
    border: 'solid 0.5px #dcddd9',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    height: '35px',
    borderRadius: '4px',
    '@media(min-width: 715px)': {
      padding: 5,
    },
    '@media(min-width: 831px)': {
      padding: 5,
    },
    '@media(min-width: 1115px)': {
      padding: 10,
    },
  },
  root: {
    flexGrow: 1,
    backgroundColor: '#fff',
  },
  rowHeader: {
    '& th': {
      backgroundColor: '#fff',
      letterSpacing: 1.71,
      fontWeight: 'normal',
      fontSize: 12,
      textTransform: 'uppercase',
      color: '#b4bac6',
    },
  },
  search: {
    width: 433,
    height: 40,
    display: 'flex',
    padding: '8px 15px 8px 0',
    borderRadius: 12,
    border: 'solid 1px #b4bac6',
    marginLeft: 24,
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    '& ::placeholder': { color: 'rgba(0, 0, 0, 0.90)', fontSize: 16 },
    '@media(min-width: 715px)': {
      maxWidth: 233,
    },
    '@media(min-width: 831px)': {
      maxWidth: 333,
    },
    '@media(min-width: 1115px)': {
      maxWidth: 433,
    },
  },
  searchIcon: {
    width: 30,
    height: 30,
    border: 'solid 0.8px #fff',
    color: '#b4bac6',
    '& svg': { marginTop: -5, fontSize: 30 },
  },
  inputRoot: {
    width: '100%',
    color: '#023459', //inherit
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 4),
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  headerBottom: {
    display: 'flex',
    width: '100%',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '@media(min-width: 715px)': {
      flexWrap: 'nowrap',
      alignItems: 'center',
      maxWidth: 515,
    },
    '@media(min-width: 831px)': {
      flexWrap: 'nowrap',
      alignItems: 'center',
      maxWidth: 550,
    },
    '@media(min-width: 1115px)': {
      alignItems: 'flex-end',
      maxWidth: 700,
    },
  },
  buttonGroup: {
    display: 'flex',
    marginTop: 16,
    '& > :first-child': { margin: '0 9px 0 0' },
    '@media(min-width: 715px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: 0,
      '& > :last-child': { width: 103 },
      '& > :first-child': { margin: '0 0 9px 0' },
    },
    '@media(min-width: 1115px)': {
      flexDirection: 'row',
      alignItems: 'flex-end',
      '& > :last-child': { width: 86 },
      '& > :first-child': { margin: '0 9px 0 40px' },
    },
  },
  filterGroup: {
    display: 'flex',
    '& > :nth-child(n)': { width: 128 },
    '@media(min-width: 715px)': {
      '& > :nth-child(1)': { marginRight: 5 },
      '& > :nth-child(2)': { marginRight: 5 },
    },
    '@media(min-width: 831px)': {
      '& > :nth-child(1)': { marginRight: 15 },
      '& > :nth-child(2)': { marginRight: 15 },
    },
    '@media(min-width: 1115px)': {
      //"& > :nth-child(n)": { width: "auto" },
      '& > :nth-child(1)': { marginRight: 24 },
      '& > :nth-child(2)': { marginRight: 24 },
    },
  },
  titleCell: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    position: 'relative',
    marginRight: 10,
    padding: 4,
    justifyContent: 'space-between',
    '& svg': { height: 21 },
  },
  dateFunded: {
    maxWidth: 65,
    '@media(min-width: 1600px)': {
      maxWidth: 'none',
    },
  },
  arrowIcon: {
    position: 'absolute',
    left: 60,
    '@media(min-width: 1553px)': {
      left: 105,
    },
    color: '#b4bac6',
    display: 'inline',
  },
  checkIcon: {
    color: '#64AE45',
  },
  gridContainer: {
    backgroundColor: '#fafafa',
    outline: '2px solid #9E9E9E',
  },
  divider: {
    color: '1px solid #9E9E9E',
  },
  filterButton: {
    width: '103px',
    height: '35px',
    color: '#fff',
    backgroundColor: '#f7a01b',
    border: 'solid 0.5px #dcddd9',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',

    fontSize: '12px',
    fontWeight: 'bold',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f7a01b',
      filter: 'brightness(0.75)',
    },
    '&:disabled': {
      backgroundColor: '#c6c7c4',
      border: 'solid 0.5px #b1b2b0',
      color: '#b1b2b0',
    },
  },
  clearButton: {
    width: '86px',
    height: '35px',
    color: '#354052',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
    border: 'solid 0.5px #dcddd9',
    backgroundColor: '#f5f7f2',
    fontSize: '12px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f5f7f2',
      filter: 'brightness(0.75)',
    },
    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: '#c6c7c4',
      border: 'solid 0.5px #b1b2b0',
      color: '#b1b2b0',
    },
  },
  filterBreadcrumbs: {
    background: '#fff',
    padding: '16px 16px 0 16px',
    color: 'rgba(113, 113, 113, 0.65)',
    fontSize: 13,
  },
  modalHeader: {
    border: '1px solid #e5e5e5',
    padding: 8,
    borderRadius: 5,
    '& span': {
      marginTop: 8,
      display: 'block',
      lineHeight: 1.4,
    },
    '& p': {
      fontWeight: 600,
    },
  },
}));
