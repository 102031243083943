export const navWrap = {
  backgroundColor: '#7CC99E',
  bottom: 0,
  height: 0,
  left: 0,
  overflow: 'auto',
  position: 'fixed',
  right: 0,
  top: 0,
  transition: 'all .2s',
  width: '100%',
  zIndex: '5040',
};

export const opened = {
  height: 'auto',
  transition: 'all .2s',
  padding: 'env(safe-area-inset-bottom)',
};

export const listWrap = {
  marginTop: 36,
  marginLeft: 44,
  '@media(min-width: 768px)': {
    marginTop: 141,
    marginLeft: 120,
  },
};

export const navItem = {
  listStyleType: 'none',
  marginBottom: 28,
  textTransform: 'capitalize',
  a: {
    color: '#fff',
  },
};

export const categoryHeader = {
  alignItems: 'center',
  color: '#fff',
  display: 'flex',
  fontSize: 40,
  fontWeight: 500,
  lineHeight: 1.13,
  textDecoration: 'none',
  svg: {
    marginLeft: 18,
    marginTop: 5,
  },
  '@media(min-width: 768px)': {
    fontSize: 60,
    svg: {
      marginLeft: 27,
    },
  },
};

export const subListWrap = {
  height: 0,
  overflow: 'hidden',
  transition: 'all .2s',
  ul: { marginTop: 10 },
  li: {
    listStyleType: 'none',
    color: '#fff',
    fontSize: 15,
    lineHeight: 2.67,
    fontWeight: 300,
    letterSpacing: 2.5,
    textTransform: 'uppercase',
  },
  '@media(min-width: 768px)': {
    li: { fontSize: 22 },
  },
};

export const sublistExpandedS = {
  height: 126,
  transition: 'all .2s',
  '@media(min-width: 768px)': {
    height: 180,
  },
};

export const userWrap = {
  alignItems: 'center',
  backgroundColor: '#6AAD88',
  color: 'white',
  display: 'flex',
  justifyContent: 'space-between',
  // opacity: '0.19',
  padding: '30px 40px',
  textTransform: 'uppercase',
  width: '100%',
  position: 'absolute',
  bottom: 0,
  '@media(min-width: 768px)': {
    display: 'none',
  },
};

export const contactButton = {
  '&&': {
    '@media(max-width: 767px)': {
      border: '1px solid white',
      color: 'white',
      backgroundColor: 'transparent',
      minWidth: 216,
    },
    minWidth: 210,
  },
};
