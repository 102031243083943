/** @jsx jsx */
import { jsx } from '@emotion/core';

import { outerWrap, textStyles, innerWrap, buttonStyles } from './contactUsStyles';
import Button from '../../components/ui/Button';

export default function ContactUs() {
  return (
    <div css={outerWrap}>
      <div css={innerWrap}>
        <div css={textStyles}>
          <span
            css={{
              fontWeight: 500,
              '@media(min-width: 1260px)': { fontWeight: 300 },
            }}
          >
            Find out how DocProbe
          </span>{' '}
          <span css={{ '@media(min-width: 1260px)': { fontWeight: 500 } }}>helps businesses like yours.</span>
        </div>
        <Button styles={buttonStyles} to='/contact-us' text='Contact Us' />
      </div>
    </div>
  );
}
