/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { bar } from '../content/home/benefits-lp.js';

const Wrap = styled.div`
  margin-bottom: 100px;
  * {
    font-family: sans-serif !important;
  }
  <![endif]-- > <!--[if (gte mso 9)|(IE)] > table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    padding: 0;
  }

  body {
    width: 100% !important;
  }

  .ReadMsgBody {
    width: 100%;
  }

  a {
    word-wrap: break-word;
  }

  .ExternalClass {
    width: 100%;
  }

  .ExternalClass,
  .ExternalClass p,
  .ExternalClass td,
  .ExternalClass div,
  .ExternalClass span,
  .ExternalClass font {
    line-height: 100%;
  }

  body,
  table,
  td,
  p,
  a,
  li,
  blockquote {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    mso-line-height-rule: exactly;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100% !important;
    width: 100% !important;
    -webkit-font-smoothing: antialiased;
  }

  .yshortcuts a {
    border-bottom: none !important;
  }

  table td {
    border-collapse: collapse;
  }

  #backgroundTable {
    height: 100% !important;
    margin: 0;
    padding: 0;
    width: 100% !important;
  }

  table {
    mso-table-lspace: 0;
    mso-table-rspace: 0;
  }

  a[x-apple-data-detectors] {
    color: inherit !important;
    text-decoration: none !important;
    font-size: inherit !important;
    font-family: inherit !important;
    font-weight: inherit !important;
    line-height: inherit !important;
  }

  .appleLinksWhite a {
    color: #ffffff;
    text-decoration: none;
  }

  .ExternalClass div {
    mso-line-height-rule: exactly;
    line-height: 100%;
  }

  img {
    -ms-interpolation-mode: bicubic;
    mso-line-height-rule: exactly;
    border: 0;
    line-height: 100%;
    outline: none;
    text-decoration: none;
    height: auto;
  }

  #MessageViewBody a {
    color: inherit;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
  }

  @media screen and (max-width: 600px) {
    .c1 {
      text-align: center !important;
    }
  }

  @media screen and (max-width: 600px) {
    .f32 {
      font-size: 32px !important;
      line-height: 125% !important;
    }
  }

  @media screen and (max-width: 600px) {
    .f19 {
      font-size: 19px !important;
      line-height: 125% !important;
    }
  }

  tr[class='displaymobile'] {
    display: none !important;
  }

  table[class='displaymobile'] {
    display: none !important;
  }

  @media screen and (max-width: 600px) {
    table[class='hidemobile'] {
      display: none !important;
    }
  }

  @media screen and (max-width: 600px) {
    tr[class='hidemobile'] {
      display: none !important;
    }
  }

  @media screen and (max-width: 600px) {
    table[class='displaymobile'] {
      display: table !important;
    }
  }

  @media screen and (max-width: 1024px) {
    .no-radius {
      border-top-left-radius: 0px !important;
      border-top-right-radius: 0px !important;
      border-radius: 0px !important;
    }
  }

  @media screen and (max-width: 600px) {
    tr[class='displaymobile'] {
      display: table-row !important;
    }

    .chbg {
      background-image: url(https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/5c7153ba-6004-4422-be80-4105de7c2b28.jpeg) !important;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
    }
  }
`;

export default function BenefitsLandingPage() {
  return (
    <Wrap>
      <div dangerouslySetInnerHTML={{ __html: bar }}></div>
    </Wrap>
  );
}
