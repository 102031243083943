export const headerSticky = {
  height: 75,
  zIndex: 6000,
  position: 'fixed',
  top: 0,
  backgroundColor: 'rgba(255,255,255, 0.9)',
  width: '100%',
  '@media(min-width: 768px)': {
    height: 85,
  },
  '@media(min-width: 1260px)': {
    height: 120,
  },
};

export const headerWrap = {
  position: 'relative',
  height: 75,
  zIndex: '1',
  '@media(min-width: 768px)': {
    height: 85,
  },
  '@media(min-width: 1260px)': {
    height: 120,
  },
};

export const imageWrap = {
  display: 'inline-block',
  maxWidth: 199,
  svg: { width: '100%', height: 'auto' },
  '@media(min-width: 1260px)': {
    marginRight: 54,
  },
};

export const desktopNavWrap = {
  display: 'none',
  '@media(min-width: 1260px)': {
    display: 'inline-flex',
    justifyContent: 'space-between',
    marginLeft: 8,
    position: 'relative',
    width: 430,
  },
};

export const navItem = {
  color: '#3b3f43',
  fontSize: 12,
  letterSpacing: 3,
  textDecoration: 'none',
  textTransform: 'uppercase',
  overflow: 'visible',
  height: 13,
  '> a': { color: 'inherit' },
  '&:hover': { color: '#3b3f43', transition: 'all .2s' },
};

export const handleDropDown = {
  width: 81,
  '&:hover': {
    '> div': {
      display: 'block',
      height: 217,
      transition: 'all .2s',
    },
  },
};

export const dropDownWrap = {
  height: 0,
  overflow: 'hidden',
  width: 180,
  transition: 'all .2s',
  ul: {
    backgroundColor: ' #44484c',
    borderRadius: 4,
    boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
    marginTop: 23,
    'li:first-of-type': { paddingTop: 15 },
    'li:last-of-type': { paddingBottom: 15 },
  },
  a: {
    alignItems: 'center',
    color: '#fbfbfb',
    display: 'flex',
    fontWeight: 500,
    height: 38,
    padding: '11px 16px 10px 28px',
    '&:hover': { backgroundColor: '#5f6872', transition: 'all .2s' },
  },
};

export const loginWrap = {
  width: 190,
  marginLeft: -132,
  '@media(min-width: 768px)': {
    marginLeft: 0,
  },
};

export const rightSide = {
  color: '#7CC99E',
  display: 'inline-flex',
  alignItems: 'center',
};

export const contactButton = {
  '&&': {
    alignItems: 'center',
    backgroundColor: '#64ae3e',
    borderColor: '#64ae3e',
    display: 'none',
    fontSize: 12,
    fontWeight: 400,
    height: 42,
    justifyContent: 'center',
    letterSpacing: 3,
    marginRight: 24,
    minWidth: 164,
    padding: '0px',
    verticalAlign: 'top',
    width: 164,
    '@media(min-width: 768px)': {
      display: 'inline-flex',
    },
    '@media(min-width: 1260px)': {
      marginRight: 0,
    },
    '&:hover': {
      backgroundColor: '#426d61',
      borderColor: 'currentColor',
      color: '#fff',
      transition: 'all .2s',
      a: {
        color: 'white',
      },
    },
  },
};

export const loginButton = {
  color: '#3b3f43',
  display: 'none',
  fontSize: 12,
  fontWeight: 500,
  justifyContent: 'center',
  letterSpacing: 2.5,
  marginRight: 24,
  textTransform: 'uppercase',
  '@media(min-width: 768px)': {
    display: 'inline-flex',
  },
  '@media(min-width: 1260px)': {
    marginRight: 48,
  },
};

export const burgerMenu = {
  display: 'inline-flex',
  cursor: 'pointer',
  width: 24,
  '@media(min-width: 1260px)': {
    display: 'none',
  },
};

export const callButton = {
  alignItems: 'center',
  color: '#64AE3E',
  display: 'flex',
  fontWeight: 500,
  marginRight: 16,
  marginTop: 2,
  '&:hover': { color: '#426D61' },
  '.desktop-phone': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    marginRight: 24,
    marginTop: 0,
    '.desktop-phone': {
      display: 'inline-block',
    },
    '.mobile-phone': {
      display: 'none',
    },
  },
  '@media(min-width: 1260px)': {
    marginRight: 38,
  },
  span: {
    display: 'none',
    '@media(min-width: 1024px)': {
      display: 'block',
      marginLeft: 4,
      fontSize: 12,
      letterSpacing: 3,
    },
    '@media(min-width: 1260px)': {
      display: 'inline-block',
    },
  },
};
