/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import { isIE, getUA } from 'react-device-detect';

import { mainWrap, mainStuffs, textWrap } from './stepStyles';
import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';

const stepWrap = {
  marginTop: 200,
  '@media(min-width: 768px)': {
    marginTop: 100,
  },
  '@media(min-width: 1260px)': {
    marginTop: 165,
  },
};
const topLine = {
  borderLeft: '1px solid #3b3f43',
  position: 'absolute',
  left: '50%',
  top: -200,
  height: 200,
  '@media(min-width: 768px)': {
    height: 271,
    top: -120,
  },
  '@media(min-width: 1260px)': {
    height: 453,
    top: -200,
  },
};

const contentS = {
  '@media(min-width: 768px)': {
    paddingTop: 20,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 0,
    marginLeft: 128,
  },
};

export default function StepOne() {
  return (
    <section css={[mainWrap, stepWrap]} className='container step-one'>
      <div
        css={{
          display: 'flex',
          justifyContent: 'center',
          left: 0,
          position: 'absolute',
          right: 0,
          top: -240,
          zIndex: 50,
          '@media(min-width: 768px)': { top: -155 },
          '@media(min-width: 1260px)': { top: -240, left: -25 },
        }}
      >
        <Image
          image='home/group-5_3x_naxwqo.png'
          styles={{
            height: '220px',
            width: 'auto',
            '@media(min-width: 1260px)': { height: 360 },
          }}
        />
      </div>
      <div css={topLine} />
      <div css={[mainStuffs, contentS]} data-content>
        <Text />
        <Images />
      </div>
    </section>
  );
}

const imageStyles = {
  width: '100%',
  opacity: 0,
  '@media(min-width: 768px)': {
    maxWidth: 650,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 770,
  },
};

function Images() {
  return (
    <div className='relative mt4 bg-white'>
      <Image image='c_scale,w_800/home/steps/data-import-1_m7wz2o.png' alt='' styles={imageStyles} />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ?(
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='c_scale,w_800/home/steps/data-import-1_m7wz2o.png' alt='Data import' />
            <Image image='c_scale,w_800/home/steps/data-import-2_stqlco.png' alt='Data import' />
            <Image image='c_scale,w_800/home/steps/data-import-3_obwrzq.png' alt='Data import' />
          </div>
        ) : (
          <Fragment>
            <Parallax y={[0, 0]}>
              <Image image='c_scale,w_800/home/steps/data-import-1_m7wz2o.png' alt='Data import' />
            </Parallax>
            <Parallax y={[9, -9]}>
              <Image image='c_scale,w_800/home/steps/data-import-2_stqlco.png' alt='Data import' />
            </Parallax>
            <Parallax y={[15, -15]}>
              <Image image='c_scale,w_800/home/steps/data-import-3_obwrzq.png' alt='Data import' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const textMod = {
  '@media(min-width: 1260px)': {
    marginRight: -70,
  },
};

function Text() {
  return (
    <div css={[textWrap, textMod]}>
      <h3>
        Data <span className='semi-bold'>Import</span>
      </h3>
      <p>
        Your post-closing process begins with scheduled imports of your closed loan data into our system, the start of
        your headache free and streamlined trailing docs process. You only pay per loan processed so your costs adjust
        along with your volume.
      </p>
      <NavLink to='/efficiency' className='data-import'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}
