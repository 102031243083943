export const circleWrap = {
  alignItems: 'center',
  border: '1px solid white',
  borderRadius: '50%',
  color: 'white',
  display: 'flex',
  flexDirection: 'column',
  height: 328,
  justifyContent: 'center',
  margin: 'auto',
  padding: 20,
  position: 'relative',
  textAlign: 'center',
  width: 328,
  zIndex: '30',
  backgroundColor: '#3b3f43',
  '@media(min-width: 768px)': {
    width: 416,
    height: 416,
  },
  '@media(min-width: 1260px)': {
    height: 532,
    width: 532,
  },
};

export const threeSixty = {
  fontSize: 28,
  margin: '8px auto 12px',
  fontWeight: 500,
  '@media(min-width: 768px)': {
    fontSize: 40,
    margin: '12px auto 16px',
  },
};

export const circleMessage = {
  fontSize: 18,
  lineHeight: '1.5',
  '@media(min-width: 768px)': {
    maxWidth: 297,
    fontSize: 21,
  },
};

const circleStyles = {
  content: "''",
  border: '1px solid white',
  height: 13,
  width: 13,
  borderRadius: '50%',
  position: 'absolute',
  top: -5,
  right: -37,
  zIndex: '30',
  backgroundColor: '#3b3f43',
};

export const tabs = {
  position: 'absolute',
  color: '#8f8f8f',
  lineHeight: 1.33,
  fontSize: 18,
  cursor: 'pointer',
  '&:hover': {
    transition: 'all .2s',
    color: '#fff',
    span: {
      transition: 'transform .2s ease',
      display: 'block',
      transform: 'scale(1.1)',
      transformOrigin: 'right',
    },
  },
  '&:after': { ...circleStyles },
  '@media(max-width: 767px)': {
    display: 'none',
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
  },
};

export const rightTab = {
  position: 'absolute',
  color: '#8f8f8f',
  lineHeight: 1.33,
  fontSize: 18,
  cursor: 'pointer',
  transition: 'all .2s',
  '&:hover': {
    transition: 'all .2s',
    color: '#fff',
    span: {
      transition: 'transform .2s ease',
      display: 'block',
      transform: 'scale(1.1)',
      transformOrigin: 'left',
    },
  },
  '&:before': {
    ...circleStyles,
    left: -24,
  },
  '@media(max-width: 767px)': {
    display: 'none',
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
  },
};

export const dataImp = {
  top: 50,
  right: -50,
  '&:before': { left: -32 },
  '@media(min-width: 1260px)': {
    top: 44,
    right: -56,
    '&:before': { left: -45, top: 9 },
  },
};

export const docsRet = {
  top: '50%',
  transform: 'translateY(-50%)',
  right: -102,
  textAlign: 'left',
  '&:before': { top: 16, left: -25 },
  '@media(min-width: 1260px)': {
    right: -128,
    '&:before': { left: -31, top: 22 },
  },
};

export const sortingS = {
  bottom: 33,
  right: -17,
  '&:before': { top: -5, left: -32 },
  '@media(min-width: 1260px)': {
    bottom: 30,
    right: -7,
    '&:before': { left: -40 },
  },
};

export const auditCorrect = {
  bottom: 33,
  left: -101,
  '&:after': { top: -5, right: -33 },
  '@media(min-width: 1260px)': {
    bottom: 30,
    left: -107,
    '&:after': { right: -41 },
  },
};

export const processesingIndex = {
  top: '50%',
  transform: 'translateY(-50%)',
  left: -145,
  textAlign: 'right',
  '&:after': { top: 18, right: -28 },
  '@media(min-width: 1260px)': {
    left: -180,
    '&:after': { top: 22, right: -35 },
  },
};

export const shippingS = {
  top: 50,
  left: -30,
  '@media(min-width: 1260px)': {
    left: -20,
    top: 44,
    '&:after': { right: -38, top: 9 },
  },
};
