export const outerWrap = {
  backgroundColor: '#3b3f43',
  marginBottom: 40,
  marginTop: 140,
  padding: '55px 0px 29px 0px',
  position: 'relative',
  '@media(min-width: 768px)': { marginTop: 82 },
  '@media(min-width: 1260px)': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    marginBottom: 114,
    marginTop: 92,
    padding: '105px 101px',
  },
  '&:before': {
    borderLeft: '1px solid black',
    content: '""',
    height: 65,
    position: 'absolute',
    right: '50%',
    top: -65,
    '@media(min-width: 768px)': {
      height: 82,
      top: -82,
    },
  },
};
export const quote = {
  height: 57,
  left: 20,
  position: 'absolute',
  top: -27,
  width: 59,
  '@media(min-width: 768px)': {
    height: 84,
    top: -40,
    width: 87,
  },
};
export const quoteStyles = {
  color: '#7cc99e',
  fontSize: 17,
  fontWeight: 500,
  margin: '0 auto',
  maxWidth: 262,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    maxWidth: 424,
  },
  '@media(min-width: 1260px)': {
    fontSize: 17,
    lineHeight: 1.3,
    maxWidth: 415,
    textAlign: 'left',
  },
};
export const nameStyles = {
  color: 'white',
  fontSize: 18,
  fontWeight: 500,
  marginTop: 20,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    maxWidth: 415,
    textAlign: 'left',
  },
};
export const titleStyles = {
  color: 'white',
  fontWeight: 300,
  marginTop: 10,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    lineHeight: 1.6,
    maxWidth: 415,
    textAlign: 'left',
  },
};
export const imageOuterStyles = {
  position: 'relative',
};
export const imageStyles = {
  // height: 162,
  margin: '46px auto 0',
  position: 'relative',
  width: '100%',
  maxWidth: '80%',
  '@media(min-width: 768px)': {
    maxWidth: 600,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 680,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 540,
  },
  '@media(min-width: 1400px)': {
    maxWidth: 680,
  },
};
export const line = {
  borderLeft: '1px solid #3b3f43',
  position: 'absolute',
  left: '50%',
  bottom: -142,
  height: 142,
  '&:before': {
    content: '""',
    borderTop: '1px solid #3b3f43',
    position: 'absolute',
    left: -4,
    bottom: 0,
    width: 7,
  },
  '@media(min-width: 768px)': {
    height: 174,
    bottom: -175,
  },
  '@media(min-width: 1260px)': {
    height: 285,
    bottom: -285,
    left: '93%',
  },
};
