/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SecondaryHero from '../components/layout/SecondaryHero';

import SecondayHeader from '../components/layout/SecondaryHeader';
import SimplerTransitions from '../content/features/SimplerTransitions';
import TransparentTracking from '../content/features/TransparentTracking';
import SecuredCompliant from '../content/features/SecuredCompliant';
import VideoSection from '../content/features/VideoSection';
import BottomSection from '../components/ui/BottomSection';

const imageOverrides = {
  maxWidth: 390,
  bottom: -135,
  right: -80,
  '@media(min-width: 768px)': {
    maxWidth: 471,
    bottom: -156,
    right: -20,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 550,
    bottom: -189,
    right: 72,
  },
  '@media(min-width: 1440px)': {
    right: 22,
  },
};

const headerS = {
  lineHeight: 1.3,
  paddingTop: 31,
  '@media(min-width: 768px)': {
    paddingTop: 59,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 141,
    maxWidth: 597,
  },
  '@media(min-width: 1440px)': {
    paddingLeft: 50,
  },
};

const headerWrapS = {
  '> div': {
    padding: '84px 0 65px',
    '@media(min-width: 768px)': {
      padding: '87px 0 62px',
    },
    '@media(min-width: 1260px)': {
      padding: '107px 0 115px',
    },
  },
  '[data-line]': { height: 'calc(100% + 100px)' },
};

export default function Features() {
  return (
    <Fragment>
      <Helmet>
        <title>Features | DocProbe</title>
        <meta name='description' content='Document fulfillment that works for you — and with you' />
      </Helmet>
      <SecondaryHero image='secondary-pages/Features_fwqdhb.png' imageStyles={imageOverrides} headerStyles={headerS}>
        Document fulfillment that works for you— <span>and with you</span>
      </SecondaryHero>
      <SecondayHeader
        wrapStyles={headerWrapS}
        subText='Using proprietary LOS integrated software and processes, DocProbe is here to make trailing your post-closing documents and completing your transactions easier, simpler, and more secure — letting you focus on the sales and relationships that build your business.'
      >
        <span>
          DocProbe is not just a document fulfillment service. We partner with you to upgrade your document fulfillment
          operations -{' '}
        </span>
        by taking ownership of the entire trailing docs process from start to finish.
      </SecondayHeader>
      <SimplerTransitions />
      <TransparentTracking />
      <SecuredCompliant />
      <VideoSection />
      <BottomSection
        title='Explore Our Benefits'
        textOne='Reduced Costs'
        linkOne='/cost'
        linkTwo='/efficiency'
        textTwo='Streamlined Process'
        textThree='Improved Relationships'
        linkThree='/relationships'
      />
    </Fragment>
  );
}
