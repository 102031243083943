export const secondBlackBox = {
  backgroundColor: '#3b3f43',
  position: 'relative',
  paddingTop: 35,
  '@media(min-width: 1260px)': {
    width: '100%',
    padding: 0,
  },
};
export const outerContainer = {
  display: 'flex',
  justifyContent: 'center',
};
export const whiteLineStyles = {
  '@media(max-width: 375px)': {
    display: 'none',
  },
  borderLeft: '1px solid #fff',
  height: 1050,
  position: 'absolute',
  top: -15,
  left: 0,
  '@media(min-width: 768px)': {
    left: 30,
    height: 802,
    top: -267,
  },
  '@media(min-width: 1260px)': {
    left: 54,
    top: -305,
    height: 999,
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    borderLeft: '1px solid #3b3f43',
    left: -1,
    bottom: -102,
    // width: 1,
    height: 150,
    zIndex: 0,
  },
};
export const blackContent = {
  color: 'white',
  padding: '0 20px 32px 20px',
  '@media(min-width: 1260px)': {
    marginTop: 206,
    marginLeft: 50,
  },
};

export const duringEveryStage = {
  fontSize: 14,
  marginTop: 23,
  lineHeight: 1.9,
  paddingBottom: 32,
  '@media(min-width: 768px)': {
    fontSize: 16,
    width: 340,
  },
  '@media(min-width: 1260px)': {
    marginTop: 0,
    width: 422,
  },
};
export const faster = {
  color: '#7cc99e',
  fontSize: 20,
  marginTop: 10,
  fontWeight: 500,
  '@media(min-width: 768px)': {
    maxWidth: 310,
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
  },
};
export const moreImageStyles = {
  width: '100%',
  maxWidth: 400,
  '@media(min-width: 768px)': {
    width: 345,
    marginTop: -95,
    marginLeft: 31,
  },
  '@media(min-width: 1260px)': {
    maxHeight: 1000,
    width: 551,
    maxWidth: 551,
    marginTop: -206,
    position: 'relative',
    marginBottom: 62,
    marginLeft: 54,
  },
};
export const mediumLineStyles = {
  height: 102,
  right: 0,
  bottom: -150,
  position: 'absolute',

  '@media(min-width: 768px)': {
    right: 345,
  },
  '@media(min-width: 1260px)': {
    display: 'none',
  },
};
