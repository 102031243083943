/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import FormSection from '../content/contact/FormSection';
import ContactDetails from '../content/contact/ContactDetails';

const outerWrap = {
  '@media(min-width: 1260px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
};

const mapS = {
  position: 'relative',
  textAlign: 'right',
  width: '100%',
};

const mapInner = {
  background: 'none !important',
  height: 380,
  overflow: 'hidden',
  width: '100%',
  '@media(min-width: 768px)': {
    height: 660,
  },
};

export default function Home() {
  return (
    <Fragment>
      <Helmet>
        <title>Contact Us | DocProbe</title>
        <meta name='description' content='Contact us' />
      </Helmet>
      <div css={outerWrap}>
        <FormSection />
        <ContactDetails />
      </div>
      {/* <div css={mapS}>
        <div css={mapInner}>
          <iframe
            width='100%'
            height='100%'
            id='gmap_canvas'
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.6493626992306!2d-74.17109488424717!3d40.083232779405186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c183b51b82a7db%3A0xf633c1003861391c!2s1133%20Ocean%20Ave%2C%20Lakewood%2C%20NJ%2008701!5e0!3m2!1sen!2sus!4v1591377401542!5m2!1sen!2sus'
            title='docprobe map'
          />
        </div>
      </div> */}
    </Fragment>
  );
}
