export const outerWrap = {
  padding: '80px 32px',
  margin: '0 auto',
  maxWidth: 1440,
  '@media(min-width: 768px)': {
    padding: '100px 0 217px 177px',
  },
};
export const fourOhFour = {
  color: '#6aaa37',
  fontSize: 50,
  fontWeight: 500,
  '@media(min-width: 768px)': {
    fontSize: 67,
  },
};
export const statement = {
  fontSize: 18,
  fontWeight: 300,
  marginTop: 24,
  lineHeight: 1.4,
  '@media(min-width: 768px)': {
    fontSize: 25,
    width: 510,
  },
};

export const commentsStyles = {
  backgroundColor: '#f8f8f8',
  border: '1px solid #ececec',
  borderRadius: 4,
  display: 'block',
  fontSize: 13,
  height: 120,
  marginTop: 24,
  maxWidth: 500,
  resize: 'none',
  width: '100%',
};
