/** @jsx jsx */
import { jsx } from '@emotion/core';

import { greyBottom, bottomHeader, bottomText, buttonWrap, buttonStyles } from './bottomSectionStyles';
import Button from '../../components/ui/Button';
export default function BottomSection() {
  return (
    <div css={greyBottom}>
      <div css={bottomHeader}>
        <span css={{ fontWeight: 500 }}>Your Documents. Retrieved.</span> Delivered. On Time.
      </div>
      <div css={bottomText}>Easy. Efficient. Cost Effective.</div>
      <div css={buttonWrap}>
        <Button styles={buttonStyles} to='/contact-us' text='Schedule a demo' />
      </div>
    </div>
  );
}
