/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import Highlighter from "react-highlight-words";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import { TitlePortalContext } from "./TitlePortalContext";
import {
  disabledDocTypeButton,
  docTypeButton,
  uploadFileIcon,
} from "./uploadDocStyle";
import { UploadModal } from "./UploadModal";

export default function UploadDoc({
  data,
  disabled,
  requiresOriginal,
  digitalReceived,
  docType,
}) {
  const {
    uploadModalState,
    uploadInProgressState,
    uploadMissingDocState,
    searchValueState,
  } = React.useContext(TitlePortalContext);
  const [uploadModal, setUploadModal] = uploadModalState;
  const [uploadInProgress, setUploadInProgress] = uploadInProgressState;
  const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;
  const [searchValue, setSearchValue] = searchValueState;

  function HighlightedWords({ text }) {
    return <Highlighter searchWords={[searchValue]} textToHighlight={text} />;
  }

  return (
    <>
      {uploadModal === data.loanId && uploadMissingDoc === docType ? (
        <div>
          <UploadModal
            data={data}
            requiresOriginal={requiresOriginal}
            digitalReceived={digitalReceived}
            missingDoc={uploadMissingDoc}
          />
        </div>
      ) : (
        <button
          style={
            disabled
              ? { ...docTypeButton, ...disabledDocTypeButton }
              : docTypeButton
          }
          onClick={() => {
            setUploadModal(data.loanId);
            setUploadMissingDoc(docType);
          }}
          disabled={disabled || uploadInProgress}
        >
          <span>
            <HighlightedWords text={docType} />
            &nbsp;
            {docType.length > 12 ? "" : "Upload"} &nbsp;
          </span>
          <span css={uploadFileIcon}>
            <Icon
              icon={
                disabled
                  ? IconType.DisabledUploadFileIcon
                  : IconType.UploadFileIcon
              }
            />
          </span>
        </button>
      )}
    </>
  );
}
