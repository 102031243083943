/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import { Events, scroller } from 'react-scroll';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Helmet } from 'react-helmet';

import TopSection from '../content/home/TopSection';
import StepOne from '../content/home/StepOne';
import StepTwo from '../content/home/StepTwo';
import StepThree from '../content/home/StepThree';
import StepFour from '../content/home/StepFour';
import StepFive from '../content/home/StepFive';
import StepSix from '../content/home/StepSix';
import ThreeSection from '../content/home/ThreeSection';
import { heroWrap, imageWrap } from './homeStyles';
import ContactUs from '../content/home/ContactUs';
import TopLenders from '../content/home/TopLenders';
import VideoSection from '../content/home/VideoSection';
import BottomSection from '../content/home/BottomSection';
import { DeeForty } from '../content/home/homeIcons';
import FloatingContact from '../content/home/FloatingContact';

export default class Home extends React.Component {
  componentDidMount() {
    Events.scrollEvent.register('begin');
    Events.scrollEvent.register('end');
  }
  onSectionClick(section) {
    scroller.scrollTo(section, {
      duration: 3000,
      delay: 0,
      smooth: 'easeInOutQuart',
    });
  }

  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  render() {
    function deleteAllCookies() {
      const cookies = document.cookie.split(';');

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
      }
    }
    return (
      <ParallaxProvider>
        <Helmet>
          <title>DocProbe</title>
          <meta name='description' content='Your trailing / final documents made easy, cost-effective and efficient' />
        </Helmet>
        <div css={heroWrap}>
          <div css={imageWrap}>
            <DeeForty />
          </div>
          <TopSection onSectionClick={this.onSectionClick} />
          {/* <FloatingContact /> */}
        </div>
        <div className='bg-white'>
          <StepOne />
          <StepTwo />
          <StepThree />
          <StepFour />
          <StepFive />
          <StepSix />
          <ThreeSection />
          <ContactUs />
          <TopLenders />
          <VideoSection />
          <BottomSection />
        </div>
      </ParallaxProvider>
    );
  }
}
