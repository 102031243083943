export const pageWrap = {
  margin: '0 auto',
  // paddingTop: 34,
  position: 'relative',
  '@media(min-width: 768px)': {
    padding: '0 15px 0 40px',
    maxWidth: 768,
    position: 'relative',
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1260,
  },
};
export const titleWrap = {
  paddingLeft: 20,
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 0,
  },
};

export const title = {
  borderLeft: '1px solid #426d61',
  color: '#426d61',
  fontSize: 40,
  fontWeight: 300,
  paddingBottom: 32,
  paddingLeft: 20,
  paddingTop: 40,
  '@media(max-width: 375px)': {
    paddingLeft: 24,
  },
  '@media(min-width: 768px)': {
    paddingLeft: 55,
    paddingTop: 66,
    width: 'calc(50% - 1px)',
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    paddingBottom: 75,
    paddingTop: 100,
    paddingLeft: 93,
    width: 569,
  },
};
export const outerContainer = {
  '@media(min-width: 768px)': {
    paddingTop: 55,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1260,
  },
};
export const outerGrey = {
  '&:before': {
    content: '""',
    top: 0,
    height: 636,
    right: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#e5e5e5',
    width: '100%',
    position: 'absolute',
    zIndex: '-1',
  },
  position: 'relative',
  '@media(min-width: 768px)': {
    '&:before': {
      height: 589,
    },
  },
  '@media(min-width: 1260px)': {
    '&:before': {
      height: 700,
    },
  },
};
export const secondLineStyles = {
  borderLeft: '1px solid #3b3f43',
  height: 'calc(100% + 33px)',
  left: 20,
  position: 'absolute',
  zIndex: '50',
  '@media(max-width: 325px)': {
    height: 995,
  },
  '@media(min-width: 768px)': {
    height: 663,
    left: 'calc(50% + 11px)',
    position: 'absolute',
    top: -60,
  },
  '@media(min-width: 1260px)': {
    height: 824,
    left: 636,
    top: -65,
    '&:after': {
      borderBottom: '1px solid #3b3f43',
      bottom: 0,
      content: '""',
      left: -4,
      position: 'absolute',
      width: 7,
    },
  },
};

export const textBox = {
  // paddingTop: 34,
  overflow: 'visible',
  '@media(min-width: 768px)': {
    height: 589,
    width: '50%',
    paddingLeft: 20,
  },
  '@media(min-width: 1260px)': {
    height: 700,
    width: 675,
    paddingLeft: 0,
  },
};

export const innergreyContent = {
  padding: '40px 15px 0 40px',
  '@media(min-width: 768px)': {
    paddingTop: 55,
    lineHeight: 1.5,
    paddingLeft: 0,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 100,
    paddingLeft: 190,
    paddingRight: 20,
  },
};
export const threeSixty = {
  fontSize: 27,
  color: '#57616b',
  marginBottom: 15,
  '@media(min-width: 1260px)': { fontSize: 37 },
};
export const documentServices = {
  '@media(min-width: 768px)': {
    display: 'flex',
    position: 'relative',
  },
};

export const endToEnd = {
  color: '#7cc99e',
  fontSize: 20,
  fontWeight: 500,
  maxWidth: 328,
  width: '100%',
  // margin: '0 auto',
  '@media(max-width: 325px)': {
    width: '100%',
  },
  '@media(min-width: 768px)': {
    maxWidth: 325,
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
    maxWidth: 400,
    width: '100%',
  },
};
export const greyPar = {
  paddingTop: 10,
  color: '#57616b',
  lineHeight: 1.9,
  '@media(min-width: 1024px)': {
    fontSize: 16,
    maxWidth: 393,
  },
};
export const imageStyles = {
  '@media(min-width: 768px)': {
    backgroundColor: '#e5e5e5',
    paddingTop: 34,
    position: 'relative',
    width: '50%',
    img: {
      position: 'absolute',
      left: 12,
    },
  },
  '@media(min-width: 1260px)': {
    maxHeight: 700,
    // maxWidth: '48%',
    img: {
      position: 'absolute',
      left: 0,
    },
  },
};

export const thinkingImageStyles = {
  height: 498,
  paddingTop: 50,
  padding: '0 20px',
  width: 'auto',
  '@media(min-width: 768px)': {
    height: 549,
    paddingTop: 0,
    maxWidth: 1000,
    minWidth: 372,
    overflow: 'visible',
    padding: 0,
    top: -47,
  },
  '@media(min-width: 1260px)': {
    height: 673,
    maxWidth: 1000,
    minWidth: 456,
  },
};
