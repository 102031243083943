/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { TitlePortalContext } from "./TitlePortalContext";
import axios from "axios";
import { Modal, Box, Typography } from "@material-ui/core";
import { SmallXIcon, CircleCheckIcon, HourGlassIcon, ErrorCircleIcon } from '../../components/ui/icons'

import { IconType } from '../../components/ui/icons/Icon';
import NoData from '../../components/ui/NoData';


export default function ViewRejectedDocument({ uploadId }) {
  const { token } = React.useContext(TitlePortalContext);
  const [document, setDocument] = React.useState("");
  const [error, setError] = React.useState(false);
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/api/GetTitlePortalRejectedMatchDocument?token=${encodeURIComponent(
            token
          )}&rejectedDocument=${Buffer.from(uploadId).toString("base64")}`
        );
        setDocument(data);
      } catch (error) {
        setError(true);
      }
    };

    fetchData();
  }, [token, uploadId]);

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setError(false);
      }}
    >
      <div
        css={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "600px",
          height: "600px",
          overflow: "auto",
          backgroundColor: "#fff",
          padding: 40,
          borderRadius: 12,
          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
          color: "#686868",
        }}
      >
        {document && (
          <embed
            style={{ width: "100%", height: "100%" }}
            src={`data:application/pdf;base64,${document}`}
          />
        )}
        {error &&  <NoData
                icon={IconType.ErrorFaceIcon}
                message='Something went wrong'
                subMessage={'We could not find your document'}
              />}
      </div>
    </Modal>
  );
}
