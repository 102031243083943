import React from "react";
import { Checkbox, Paper, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { FilterOptionChip } from "../../components/ui/Filter/Filter";
import { Tzvi, useStyles } from "./LocationFilterStyles";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const CustomPaper = (props) => {
  return <Paper elevation={0} {...props} />;
};

const CustomPopper = function (props) {
  return <div {...props} style={{ zIndex: 10000 }} />;
};

export function FilterSelection({ handleChange, optionsList, selectedList }) {
  const classes = useStyles();
    
  return (
    <Tzvi>
      <Autocomplete
        disableCloseOnSelect 
        multiple
        size="small"
        onChange={handleChange}
        value={selectedList}
        options={optionsList}
        open={true}
        PaperComponent={CustomPaper}
        PopperComponent={CustomPopper}
        popupIcon={<KeyboardArrowDownIcon className="icon"/>}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              style={{ marginRight: 8 }}
              checked={selected}
              color="secondary"
            />
            {option}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            autoFocus
            InputProps={{
              ...params.InputProps,
            }}
            className={classes.root}
            type="search"
            variant="outlined"
            color="secondary"
            label="Type To Search"
          />
        )}
        getLimitTagsText={moreText => <span className={classes.tagText}>+{moreText}</span>}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <FilterOptionChip
              getTagProps={getTagProps}
              index={index}
              label={option}
            />
          ))
        }
      />
    </Tzvi>
  );
}
