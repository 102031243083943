/** @jsx jsx */
import { jsx } from "@emotion/core";

import Button from "../Button";
import IconCircle from "./IconCircle";
import { IconType } from "../icons/Icon";

export const StatusTypes = {
  initial: "INITIAL",
  loading: "LOADING",
  success: "SUCCESS",
  error: "ERROR"
};

const circleStyles = {
  backgroundColor: "#426d61"
};
const getIcon = (status, initialIcon = IconType.RightArrowIcon) => {
  switch (status) {
    case StatusTypes.initial:
      return <IconCircle icon={initialIcon} styleOverrides={circleStyles} />;
    case StatusTypes.loading:
      return (
        <IconCircle
          icon={IconType.ProcessingIcon}
          styleOverrides={circleStyles}
        />
      );
    case StatusTypes.success:
      return (
        <IconCircle
          icon={IconType.CheckIcon}
          styleOverrides={{
            ...circleStyles,
            backgroundColor: "#4A6865"
          }}
        />
      );
    case StatusTypes.error:
      return (
        <IconCircle
          icon={IconType.XIcon}
          styleOverrides={{
            ...circleStyles,
            backgroundColor: "#FF0000 !important"
          }}
        />
      );
    default:
      return null;
  }
};

const iconStyles = {
  marginRight: "8px",
  width: "40px"
};

const textStyles = {
  textAlign: "center",
  display: "inline-block",
  border: "1px solid red"
};

const secondaryStyles = {
  backgroundColor: "#FFFFFF",
  border: `1px solid #426d61`,
  color: "#000000",
  div: {
    color: "#FFFFFF"
  }
};

const StatusButton = ({
  status,
  disabled,
  onClick,
  text,
  initialIcon,
  width,
  secondary,
  styleOverrides,
  children,
  ...props
}) => {
  const buttonStyles = !disabled &&
    status && [
      {
        alignItems: "center",
        backgroundColor: "#426d61",
        display: "inline-flex",
        justifyContent: "space-between",
        padding: 0,
        paddingLeft: "20px",
        width,
        ":hover": {
          backgroundColor: "#4A6865",
          color: "#FFFFFF",
          // border: "none",
          " div": {
            backgroundColor: "#FFFFFF",
            borderRadius: "50%",
            color: "#426d61"
          }
        }
      },
      secondary && secondaryStyles,
      styleOverrides,
      status === StatusTypes.loading && { pointerEvents: "none" }
    ];
  return (
    <Button
      disabled={disabled}
      styles={buttonStyles}
      onClick={
        status !== StatusTypes.loading && status !== StatusTypes.success
          ? onClick
          : null
      }
      text={text}
      {...props}
    >
      {/* <span
        css={[
          textStyles,
          !disabled && status && { width: "calc(100% - 48px)", marginRight: 16 }
        ]}
      >
        {children}
      </span> */}
      {!disabled && <div css={iconStyles}>{getIcon(status, initialIcon)}</div>}
    </Button>
  );
};

export default StatusButton;

export const getButtonStatus = (loading, error, success) => {
  if (loading) return StatusTypes.loading;
  if (error) return StatusTypes.error;
  if (success) return StatusTypes.success;
  return StatusTypes.initial;
};
