export const root = {
  '@media(min-width: 1260px)': {
    ':nth-of-type(even)': {
      '[data-content]': {
        justifyContent: 'center',
        flexDirection: 'row-reverse',
      },
    },
  },
};
export const mainWrap = {
  marginTop: 180,
  padding: 16,
  paddingTop: 30,
  position: 'relative',
  '@media(min-width: 768px)': {
    paddingTop: 150,
  },
  '@media(min-width: 1260px)': {
    alignItems: 'center',
    display: 'flex',
    height: '100vh',
    marginTop: 0,
    minHeight: 850,
    paddingTop: 0,
    maxHeight: 850,
  },
};

export const mainStuffs = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  zIndex: '1',
  position: 'relative',
  '@media(min-width: 1260px)': {
    flexDirection: 'row',
    width: '100%',
  },
};

export const textWrap = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  h3: {
    fontSize: 50,
    textAlign: 'center',
  },
  p: {
    color: '#57616b',
    fontSize: 16,
    lineHeight: '1.8',
    marginBottom: 28,
    marginTop: 14,
    textAlign: 'center',
    maxWidth: 528,
  },
  a: {
    alignItems: 'center',
    color: '#426d61',
    display: 'flex',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 3,
    paddingBottom: 1,
    textTransform: 'uppercase',
  },
  '@media(min-width: 768px)': {
    p: {
      fontSize: 16,
      marginTop: 35,
      maxWidth: 528,
    },
  },
  '@media(min-width: 1260px)': {
    alignItems: 'flex-start',
    marginTop: 20,
    zIndex: '50',
    p: {
      marginBottom: 20,
      maxWidth: 358,
      textAlign: 'left',
    },
    h3: { textAlign: 'left' },
  },
};

export const imagesWrap = {
  maxWidth: 600,
  position: 'relative',
  width: '100%',
  border: '1px solid green',
};


export const topBlackLine = {
  borderLeft: '1px solid #426d61',
  height: 160,
  left: '50%',
  position: 'absolute',
  top: -144,
  '&:before': {
    borderTop: '1px solid #426d61',
    content: '""',
    left: -4,
    position: 'absolute',
    top: 0,
    width: 7,
  },
  '@media(min-width: 768px)': {
    height: 280,
    top: -150,
  },
  '@media(min-width: 1260px)': {
    height: 450,
    top: -100,
  },
};

export const horizontalLine = {
  display: 'none',
  '@media(min-width: 768px)': {
    borderTop: '1px solid #426d61',
    display: 'block',
    left: '-50%',
    position: 'absolute',
    top: 77,
    width: '100%',
    '@media(min-width: 1260px)': {
      top: 150,
    },
  },
};

export const horizontalRightLine = {
  display: 'none',
  '@media(min-width: 768px)': {
    borderTop: '1px solid #426d61',
    display: 'block',
    right: '-50%',
    position: 'absolute',
    top: 77,
    width: '100%',
    '@media(min-width: 1260px)': {
      top: 150,
    },
  },
};
