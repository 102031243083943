export const horizontalLine = {
    borderTop: '1px solid black',
    position: 'absolute',
    content: '""',
    right: 0,
    top: 0,
    left: 0,
    width: '100%'
  };
  export const outerWrap = {
  padding: '72px 18px 0 18px',
  position: 'relative',
  '@media(min-width: 1260px)': {
    maxWidth: 1033,
    padding:'89px 0 37px 27px'
  },
};
export const title = {
  fontWeight: 500,
  fontSize: 40,
  textAlign: 'center',
  paddingBottom: 50,
  '@media(min-width: 768px)': {
    textAlign: 'left',
    paddingBottom: 72
  },
  '@media(min-width: 1260px)': {
   fontSize: 50,
  },
};
export const imageParSection ={
    '@media(min-width: 1260px)': {
     display: 'flex',
    },
}
export const image = {
  paddingBottom: 20,
  height: 111,
  maxWidth:237,
  '@media(min-width: 768px)': {
    marginBottom: 20
  }
};
export const paragraph = {
  lineHeight: 1.9,
  width: '100%',
  paddingBottom: 63,
  '@media(min-width: 768px)': {
    maxWidth: 620,
    paddingBottom: 79
  },
  '@media(min-width: 1260px)': {
    marginLeft: 52,
    maxWidth: 646,
    fontSize: 16,
    lineHeight: 1.8
  }
};
export const californiaImageStyles = {
  paddingBottom: 20,
  height: 87,
  maxWidth:237,
  '@media(min-width: 768px)': {
    height: 74,
    marginBottom: 9
  }
};
export const paragraphTwo = {
  lineHeight: 1.9,
  width: '100%',
  paddingBottom: 63,
  '@media(min-width: 768px)': {
    maxWidth: 620,
    paddingBottom: 70
  },
  '@media(min-width: 1260px)': {
    marginLeft: 52,
    maxWidth: 646,
    fontSize: 16,
    lineHeight: 1.8
  }
};
export const tmbaImage = {
  paddingBottom: 20,
  height: 100,
  maxWidth:237,
  '@media(min-width: 768px)': {
    marginBottom: 20
  }
};
export const paragraphThree = {
    lineHeight: 1.9,
    width: '100%',
    paddingBottom: 63,
    '@media(min-width: 768px)': {
      maxWidth: 620,
      paddingBottom: 155
    },
    '@media(min-width: 1260px)': {
        marginLeft: 52,
        maxWidth: 646,
        fontSize: 16,
        lineHeight: 1.8
      }
};
export const lineStyles = {
  '@media(max-width: 380px)': {
    display: 'none'
  },
  '@media(min-width: 768px)': {
    borderLeft: '1px solid #3b3f43',
    height: 1345,
    position: 'absolute',
    bottom: 0,
    right: 27
  },
  '@media(min-width: 1260px)': {
   right:-95,
   height:1172
  },
};
