export const headerWrap = {
  backgroundImage: 'linear-gradient(101deg, #02555b, #006e74)',
  border: 'solid 1px #707070',
  borderBottom: 'none',
  padding: '40px 81px 32px 96px',
  position: 'relative',
  '&:before': {
    backgroundImage: 'linear-gradient(101deg, #02555b, #006e74)',
    border: 'solid 1px #707070',
    borderTop: 'none',
    content: '""',
    height: 209,
    left: '-1px',
    position: 'absolute',
    top: 116,
    width: '100vw',
  },
};

export const logoWrap = {
  fontSize: 40,
  color: '#f5f7f2',
};

export const docprobe = {
  fontWeight: 600,
  color: '#f7a01b',
};

export const initialS = {
  alignItems: 'center',
  backgroundColor: '#64AE45',
  borderRadius: '50%',
  color: 'white',
  display: 'inline-flex',
  fontSize: 18,
  fontWeight: 'bold',
  height: 40,
  justifyContent: 'center',
  textTransform: 'uppercase',
  width: 40,
};
