export const blackBox = {
  backgroundColor: '#3b3f43',
  position: 'relative',
  marginTop: 56,
  marginBottom: 15,
  '&:before': {
    content: '""',
    position: 'absolute',
    borderLeft: '1px solid #f5f5f5',
    left: 'calc(50% + 12px)',
    top: -100,
    // width: 1,
    height: 102,
    zIndex: 0,
  },
  
  '@media(min-width: 768px)': {
    marginTop: 98,
    marginBottom: 30,
  },
  '@media(min-width: 1260px)': {
    marginTop: 147,
    marginBottom: 100,
    '&:before': {
      left: '50%',
      height: 150,
      top: -150,
    },
  },
};
export const whiteLineStyles = {
  borderLeft: '1px solid #f5f5f5',
  height: 1060,
  position: 'absolute',
  top: -20,
  left: 15,
  '@media(min-width: 768px)': {
    left: 368,
    height: 930,
    top: 0,
  },
  '@media(min-width: 1260px)': {
    left: '50%',
    top: 0,
    height: 1130,
    borderLeft: '1px solid #f5f5f5',
  },
};
export const outerContainer = {
  '@media(min-width: 768px)': {
    position: 'relative',
    display: 'flex',
    margin: 'auto',
    flexDirection: 'row-reverse',
  },
  '@media(min-width: 1260px)': {
    backgroundColor: 'white',
    height: 700,
  },
};
export const innerWhiteBox = {
  backgroundColor: 'white',
  padding: '30px 5px 0 22px',
  '@media(min-width: 768px)': {
    padding: '30px 0 0 28px',
    height: 543,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    width: '50%',
  },
};
export const innerWhiteContent = {
  color: '#57616b',
  paddingBottom: 50,
  '@media(min-width: 768px)': {
    paddingTop: 13,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 100,
    paddingLeft: 105,
  },
};
export const highSpeed = {
  fontSize: 27,
  maxWidth: 290,
  lineHeight: 1.3,
  width: '100%',
  '@media(min-width: 1260px)': {
    fontSize: 37,
    width: '100%',
    maxWidth: 430,
  },
};
export const faster = {
  color: '#7cc99e',
  fontSize: 20,
  marginTop: 10,
  fontWeight: 500,
  '@media(min-width: 768px)': {
    maxWidth: 310,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 407,
    fontSize: 22,
    marginTop: 20,
  },
};
export const withDocProbe = {
  fontSize: 14,
  marginTop: 17,
  lineHeight: 1.9,
  '@media(min-width: 768px)': {
    maxWidth: 290,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 456,
    fontSize: 16,
  },
};
export const imageStyles = {
  paddingBottom: 30,
  '@media(min-width: 1260px)': {
    // maxHeight: 700,
    width: 'calc(50% + 1px)',
    img: {
      position: 'absolute',
      right: '50%',
      top: 50,
    },
  },
};
export const phoneImageStyles = {
  maxWidth: 338,
  '@media(min-width: 768px)': {
    maxWidth: 368,
    backgroundColor: 'white',
    overflow: 'visible',
    paddingTop: 40,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 508,
    padding: 0,
  },
};
