/** @jsx jsx */
import { jsx } from '@emotion/core';
import NoData from '../components/ui/NoData';
import { IconType } from '../components/ui/icons/Icon';

export default function Testpage() {
  return (
    <div>
      <NoData isLoader={true} icon={IconType.loadingData} message='Loading data...' />
    </div>
  );
}
