export const secondLineStyles = {
  borderLeft: '1px solid #3b3f43',
  height: 920,
  position: 'absolute',
  top: -40,
  '@media(max-width: 325px)': {
    height: 995,
  },
  '@media(min-width: 768px)': {
    height: 930,
    position: 'absolute',
    top: -50,
    left: 362,
  },
  '@media(min-width: 1024px)': {
    left: 495,
  },
  '@media(min-width: 1260px)': {
    top: 0,
    left: 700,
    height: 850,
    '&:after': {
      borderBottom: '1px solid #3b3f43',
      width: 7,
      left: -4,
      content: '""',
      position: 'absolute',
      bottom: 0,
    },
  },
};
export const headerWrapS = {
  borderBottom: 'none',
  '> div': {
    padding: '84px 0 65px',
    '@media(min-width: 768px)': {
      padding: '87px 0 62px',
    },
    '@media(min-width: 1260px)': {
      padding: '107px 0 115px',
    },
  },
  '[data-line]': {
    borderColor: '#426d61',
    '&:after': {
      borderColor: '#426d61',
    },
    '@media(min-width: 1260px)': {
      left: 30,
      top: -150,
      height: 'calc(100% + 180px)',
    },
  },
};

export const horizontalLineStyles = {
  borderTop: '1px solid #3b3f43',
  width: '100%',
  marginTop: 94,
  '@media(max-width: 325px)': {
    marginTop: 170,
  },
  '@media(min-width: 768px)': {
    display: 'none',
  },
  '@media(min-width: 1260px)': {
    display: 'block',
    position: 'absolute',
    bottom: -74,
  },
};
export const mediumLineStyles = {
  borderLeft: '1px solid #3b3f43',
  height: 133,
  marginLeft: '50%',
  '@media(min-width: 768px)': {
    display: 'none',
  },
};
export const bottomLineStyles = {
  borderLeft: '1px solid #3b3f43',
  position: 'absolute',
  bottom: -100,
  left: '50%',
  height: 110,
  '&:after': {
    borderTop: '1px solid #3b3f43',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    bottom: 0,
  },
  '@media(min-width: 1260px)': {
    height: 164,
    bottom: -160,
  },
};
export const whiteLineStyles = {
  borderLeft: '1px solid white',
  height: 300,
  position: 'absolute',
  top: 0,
  left: 20,
  '@media(min-width: 768px)': {
    left: 363,
    height: 930,
    top: -267,
  },
  '@media(min-width: 1024px)': {
    left: 450,
    height: 1120,
  },
  '@media(min-width: 1260px)': {
    left: 658,
    top: -300,
    height: 1120,
  },
};
export const smallLineStyles = {
  borderLeft: '1px solid #3b3f43',
  height: 60,
  position: 'absolute',
  bottom: -55,
  left: 20,
  '&:after': {
    borderBottom: '1px solid #3b3f43',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    bottom: 0,
  },
  '@media(min-width: 768px)': {
    left: 363,
    bottom: -60,
  },
  '@media(min-width: 1024px)': {
    left: 450,
  },
  '@media(min-width: 1260px)': {
    left: 658,
  },
};

export const thinkingImageStyles = {
  width: '100%',
  marginBottom: 47,
  maxWidth: 350,
  '@media(min-width: 768px)': {
    display: 'inline-block',
    verticalAlign: 'top',
    maxWidth: 372,
    marginBottom: 115,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 475,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 500,
    width: '100%',
    marginBottom: 72,
  },
};
export const innovativeImageStyles = {
  margin: '65px auto 0',
  width: 96,
  '@media(min-width: 768px)': {
    marginTop: 137,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
export const highEfficiency = {
  color: '#7cc99e',
  fontSize: 22,
  textAlign: 'center',
  marginTop: 16,
  fontWeight: 500,
  '@media(min-width: 1260px)': {
    fontWeight: 500,
    color: '#8ee5b4',
  },
};
export const ourUnique = {
  textAlign: 'center',
  marginTop: 11,
  color: '#3b3f43',
  lineHeight: 1.9,
  '@media(min-width: 768px)': {
    margin: '31px auto 0',
    maxWidth: 590,
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    lineHeight: 1.8,
    maxWidth: 740,
  },
};

export const plpInnerWrap = {
  maxWidth: 1160,
  '@media(min-width: 1260px)': {
    marginLeft: 37,
  },
};
export const blackBox = {
  marginTop: 65,
  backgroundColor: '#3b3f43',
};
export const title = {
  color: 'white',
  fontSize: 40,
  fontWeight: 300,
  paddingTop: 40,
  width: 350,
  position: 'relative',
  marginLeft: 24,
  '@media(max-width: 375px)': {
  },
  '@media(min-width: 768px)': {
    width: 372,
    paddingLeft: 50,
    paddingTop: 67,
  },
  '@media(min-width: 1024px)': {
    paddingLeft: 20,
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    width: 500,
  },
};
export const whiteBoxWrapper = {
  '@media(min-width: 768px)': {
    position: 'relative',
    display: 'inline-flex',
    marginTop: 65,
  },
};
export const whiteBox = {
  backgroundColor: 'white',
  padding: 30,
  marginTop: 50,
  '@media(min-width: 768px)': {
    height: 500,
    width: 367,
    margin: '0 auto',
  },
  '@media(min-width: 1024px)': {
    width: 450,
    height: 600,
  },
  '@media(min-width: 1260px)': {
    width: 659,
    height: 659,
  },
};
export const innerWhiteContent = {
  '@media(min-width: 768px)': {
    margin: '110px 0 0 13px',
    lineHeight: 1.5,
    width: 290,
  },
  '@media(min-width: 1024px)': {
    margin: '140px auto 0',
  },
  '@media(min-width: 1260px)': {
    margin: '150px auto 0',
    width: 393,
  },
};
export const payPerLoan = {
  color: '#7cc99e',
  fontSize: 20,
  fontWeight: 500,
  marginTop: 10,
  width: '100%',
  '@media(min-width: 1260px)': {
    fontSize: 22,
  },
};
export const containerShow = {
  '@media(min-width: 768px)': {
    margin: '0 28px',
  },
  '@media(min-width: 1260px)': {
    margin: '0 auto',
    position: 'relative',
  },
};
export const greyBox = {
  backgroundColor: '#f5f5f5',
  position: 'relative',
  padding: '44px 20px 0 20px',
  marginTop: 131,
  height: 785,
  overflow: 'visible',
  '@media(min-width: 768px)': {
    height: 565,
    maxWidth: 712,
    width: '100%',
    padding: 0,
    margin: '145px auto 178px',
  },
  '@media(min-width: 1024px)': {
    maxWidth: 1000,
  },
  '@media(min-width: 1260px)': {
    height: 749,
    maxWidth: 1380,
    width: '100%',
  },
};
export const innerGreyBox = {
  '@media(min-width: 768px)': {
    display: 'inline-flex',
  },
  '@media(min-width: 1024px)': {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 100,
    paddingRight: 80,
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 200,
    display: 'inline-flex',
  },
};
export const innerGreyContent = {
  marginLeft: 27,
  '@media(min-width: 768px)': {
    paddingTop: 158,
    marginRight: 45,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 200,
    marginRight: 114,
  },
};
export const noShipping = {
  color: '#7cc99e',
  fontSize: 20,
  marginTop: 10,
  width: '100%',
  maxWidth: 290,
  fontWeight: 500,
  '@media(min-width: 1260px)': {
    maxWidth: 320,
    fontWeight: 500,
    fontSize: 22,
  },
};
export const weveSidelined = {
  fontSize: 14,
  margin: '17px 0',
  lineHeight: 1.9,
  '@media(min-width: 768px)': {
    maxWidth: 290,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    maxWidth: 360,
  },
};
export const phoneImageStyles = {
  width: '100%',
  maxWidth: 350,
  '@media(min-width: 768px)': {
    margin: '0 auto',
    maxWidth: 304,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 360,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 456,
  },
};
export const quote = {
  position: 'absolute',
  top: -30,
  left: 10,
  height: 57,
  width: 59,
  '@media(min-width: 768px)': {
    position: 'absolute',
    left: 43,
    top: -40,
    height: 84,
    width: 87,
  },
};
export const videoWrapper = {
  backgroundColor: '#3b3f43',
  padding: 25,
  marginBottom: 39,
  position: 'relative',
  '@media(min-width: 1260px)': {
    marginBottom: 114,
  },
};
export const innerBlackContent = {
  '@media(min-width: 1260px)': {
    display: 'inline-flex',
    flexDirection: 'row-reverse',
    padding: 80,
    alignItems: 'center',
  },
};
export const quoteWrap = {
  '@media(min-width: 1260px)': {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 88,
  },
};
export const quoteStyles = {
  color: '#7cc99e',
  fontSize: 22,
  fontWeight: 500,
  textAlign: 'center',
  marginTop: 35,
  '@media(min-width: 768px)': {
    maxWidth: 424,
    margin: '45px auto 0',
  },
  '@media(min-width: 1260px)': {
    marginTop: 0,
    textAlign: 'left',
    fontSize: 22,
  },
};
export const nameSection = {
  width: 173,
  margin: '0 auto',
  '@media(min-width: 768px)': {
    width: 385,
  },
  '@media(min-width: 1260px)': {
    margin: 0,
  },
};
export const nameStyles = {
  textAlign: 'center',
  fontWeight: 500,
  fontSize: 18,
  marginTop: 20,
  color: '#fff',
  '@media(min-width: 1260px)': {
    textAlign: 'left',
  },
};
export const nameDescription = {
  textAlign: 'center',
  color: '#fff',
  marginTop: 8,
  '@media(min-width: 1260px)': {
    textAlign: 'left',
  },
};
export const videoImageStyles = {
  height: 162,
  width: 289,
  marginTop: 24,
  margin: '24px auto 0',
  '@media(min-width: 768px)': {
    width: 626,
    height: 351,
    marginTop: 29,
  },
  '@media(min-width: 1260px)': {
    height: 336,
    width: 598,
    marginTop: 0,
  },
};
