export const outerWrap = {
  paddingTop: 63,
  '@media(min-width: 768px)': {
    paddingTop: 157
  }
};
export const imageStyles = {
  height: 96,
  width: 96,
  margin: '0 auto'
};
export const innerWrap = {
  margin: '0 auto',
  textAlign: 'center'
};
export const informed = {
  paddingTop: 27,
  fontSize: 27,
  margin: '0 auto',
  lineHeight: 1.3,
  maxWidth: 248,
  '@media(min-width: 768px)': {
    width: '100%',
    maxWidth: 682
  },
  '@media(min-width: 1260px)': {
    fontSize: 37
  }
};
export const noMore = {
  color: '#7cc99e',
  fontWeight: 500,
  textAlign: 'center',
  fontSize: 20,
  maxWidth: 316,
  margin: '0 auto',
  paddingTop: 12,
  lineHeight: 1.3,
  '@media(min-width: 768px)': {
    width: '100%',
    maxWidth: 574
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
    color: '#8ee5b4',
    paddingTop: 17
  }
};
export const paragraph = {
  lineHeight: 1.9,
  width: 316,
  margin: '0 auto',
  paddingTop: 20,
  paddingBottom: 90,
  '@media(min-width: 768px)': {
    width: '100%',
    maxWidth: 544,
    paddingBottom: 190
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    lineHeight: 1.8,
    paddingTop: 32,
    maxWidth:740
  }
};
