export const outerWrap = {
  textAlign: 'center',
  padding: '25px 20px 0',
  '@media(min-width: 1260px)': {
    width: '60%',
    padding: '124px 0 151px',
    textAlign: 'left',
    verticalAlign: 'top',
    margin: 0,
  },
};

export const contentWrap = {
  maxWidth: 458,
  margin: 'auto',
  '@media(min-width: 1260px)': {
    marginLeft: 60,
  },
};

export const title = {
  fontSize: 27,
  fontWeight: 500,
  marginBottom: 20,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    textAlign: 'left',
    marginBottom: 34,
  },
  '@media(min-width: 1260px)': {
    fontSize: 37,
    marginBottom: 50,
  },
};
export const detailsStyles = {
  backgroundColor: '#f8f8f8',
  border: '1px solid #ececec',
  borderRadius: 4,
  display: 'block',
  fontSize: 13,
  height: 42,
  marginTop: 14,
  width: '100%',
};

export const commentsStyles = {
  backgroundColor: '#f8f8f8',
  border: '1px solid #ececec',
  borderRadius: 4,
  display: 'block',
  fontSize: 13,
  height: 120,
  marginTop: 14,
  resize: 'none',
  width: '100%',
};
export const buttonWrap = {
  margin: '14px auto 0',
  maxWidth: 268,
  width: '100%',
  '@media(min-width: 768px)': {
    margin: '34px 0px 0px',
    width: 251,
  },
  '@media(min-width: 1260px)': {
    marginTop: 14,
  },
};
