export const grayBoxTop = {
  position: 'relative',
  backgroundColor: '#efefef',
  '&:before': {
    backgroundColor: '#efefef',
    content: '""',
    display: 'block',
    height: 75,
    left: 0,
    position: 'absolute',
    right: 0,
    top: -75,
    width: '100%',
  },
  '@media(min-width: 768px)': {
    '&:before': {
      height: 85,
      top: -85,
    },
  },
  '@media(min-width: 1260px)': {
    '&:before': {
      height: 120,
      top: -120,
    },
  },
};

export const headerWrap = {
  position: 'relative',
  height: 280,
  maxWidth: 1010,
  '@media(min-width: 768px)': {
    height: 395,
    maxWidth: 832,
  },
  '@media(min-width: 1260px)': {
    height: 360,
    maxWidth: 1100,
  },
};

export const header = {
  fontSize: 37,
  lineHeight: 1.3,
  maxWidth: 270,
  zIndex: '10',
  position: 'relative',
  span: {
    fontWeight: 500,
  },
  '@media(min-width: 768px)': {
    fontSize: 57,
    lineHeight: 1.11,
    maxWidth: 405,
  },
  '@media(min-width: 1260)': {
    fontSize: 67,
  },
};

export const imageStyle = {
  position: 'absolute',
  right: 3,
  bottom: '-100px',
  width: '100%',
  maxWidth: 250,
  '@media(min-width: 768px)': {
    bottom: -125,
    right: 50,
    maxWidth: 420,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 600,
    bottom: -150,
    right: 0,
  },
};
