/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  textBoxWrap,
  contentWrap,
  imageStyles,
  innerTextBox,
  headerWrap,
  simpler,
  changing,
  transitioning,
  secondPar,
  whiteLine,
} from './simplerTransitionsStyles';
import Image from '../../components/ui/Image';

export default function SimplerTransitions() {
  return (
    <div>
      <div css={headerWrap}>
        <h2>
          <span css={{ fontWeight: 500 }}>
            Switching to
            <br /> DocProbe
          </span>{' '}
          is easy
        </h2>
      </div>
      <div css={{ backgroundColor: '#3b3f43', position: 'relative' }}>
        <div css={contentWrap}>
          <div css={textBoxWrap}>
            <div css={innerTextBox}>
              <div css={simpler}>
                <span css={{ fontWeight: 500 }}>
                  Simpler transitions,
                  <br /> operations,
                </span>{' '}
                and pricing
              </div>
              <div css={changing}>Changing to a better document fulfillment service shouldn’t be complicated.</div>
              <div css={transitioning}>
                At DocProbe, transitioning your fulfillment operations is fast and easy. With our hands-on account
                representatives and operations specialists, we help you make the switch without costly delays or
                complications—so you can start smarter fulfillment right away.
                <div css={secondPar}>
                  Our file processing systems and LOS-bridged software are set up and integrated smoothly and quickly by
                  the DocProbe team, so it’s easy to get going without missing a beat. With DocProbe as your document
                  recipient,{' '}
                  <span css={{ fontWeight: 500 }}>
                    you’ll never have to process another post-closing document again.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Image image='bitmap_3x_xc8fyb.jpg' styles={imageStyles} alt='' />
        </div>
        <div css={whiteLine} />
      </div>
    </div>
  );
}
