/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SecondaryHero from '../components/layout/SecondaryHero';
import SecondayHeader from '../components/layout/SecondaryHeader';
import { wrapS, imageOverrides, headerS, headerWrapS } from './aboutUsStyles';
import TrustSection from '../content/about/TrustSection';
import CultureSection from '../content/about/CultureSection';
import CommitmentSection from '../content/about/CommitmentSection';
import Spotlight from '../content/about/Spotlight';

import FoundingStory from '../content/about/FoundingStory';
import VisionForFuture from '../content/about/VisionForFuture';
import BottomSection from '../components/ui/BottomSection';

const titleText = (
  <div>
    See the <span css={{ fontWeight: 500 }}>DocProbe Solution</span>
  </div>
);

export default function AboutUs(props) {
  return (
    <Fragment>
      <Helmet>
        <title>About Us | DocProbe</title>
        <meta name='description' content='Headache free trailing doc retrieval. Your dream and our mission.' />
      </Helmet>
      <SecondaryHero
        image='secondary-pages/about-us_f2lqwn.png'
        imageStyles={imageOverrides}
        headerStyles={headerS}
        wrapStyles={wrapS}
      >
        Headache free trailing doc retrieval. <span>Your dream and our mission.</span>
      </SecondaryHero>
      <SecondayHeader
        wrapStyles={headerWrapS}
        subText='The in-between stage of trailing doc retrieval, processing, revising and shipping creates a necessary hurdle to the smooth and economical running of your operation and to your peace of mind. DocProbe was founded to allow you to focus on what matters most- closing and selling loans. We’ll take care of the rest.'
      >
        As a lender, you want to close loans and move them along to your investors. <span>That’s it.</span>
      </SecondayHeader>
      <TrustSection />
      <CultureSection />
      <CommitmentSection />
      <Spotlight />
      <FoundingStory />
      <VisionForFuture />
      <BottomSection
        linkOne='/cost'
        textOne='Reduced costs'
        linkTwo='/efficiency'
        textTwo='Streamlined Process'
        textThree='Improved Relationships'
        linkThree='/relationships'
        styles={{ marginTop: 32, '> div:first-of-type': { fontWeight: 300, maxWidth: 1000, padding: '0 20px' } }}
        title={titleText}
      />
    </Fragment>
  );
}
