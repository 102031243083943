/** @jsx jsx */
import { jsx } from '@emotion/core';

export default function Image({ image, styles, alt }) {
  return (
    <img
      sizes='(max-width: 1400px) 100vw, 1400px'
      srcSet={`
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_500/${image} 500w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_767/${image} 767w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1023/${image} 1023w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1259/${image} 1259w,
        https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1439/${image} 1439w,
      `}
      src={`https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/${image}`}
      alt={alt}
      css={styles}
    />
  );
}
