/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SecondaryHero from '../components/layout/SecondaryHero';
import SecondayHeader from '../components/layout/SecondaryHeader';
import AccessOnDemand from '../content/platform/AccessOnDemand';
import FollowUpTracking from '../content/platform/FollowUpTracking';
import RobustReporting from '../content/platform/RobustReporting';
import RequestDemo from '../content/platform/RequestDemo';

const wrapS = {
  borderColor: '#426d61',
  '@media(min-width: 768px)': { height: 225 },
  '@media(min-width: 1260px)': { height: 360 },
};

const imageOverrides = {
  bottom: -85,
  maxWidth: 285,
  right: 45,
  '@media(min-width: 768px)': {
    bottom: -130,
    maxWidth: 450,
    right: -82,
  },
  '@media(min-width: 1260px)': {
    bottom: -130,
    maxWidth: 570,
    right: 0,
  },
};

const headerS = {
  paddingTop: 31,
  '@media(min-width: 768px)': { paddingTop: 96 },
  '@media(min-width: 1260px)': { paddingTop: 125 },
  '@media(min-width: 1440px)': { maxWidth: 500, fontSize: 67 },
};

const headerWrapS = {
  '> div': {
    padding: '14px 0 47px',
    '@media(min-width: 768px)': { padding: '71px 0 56px' },
    '@media(min-width: 1260px)': { padding: '137px 0 148px' },
  },
  '[data-line]': {
    borderColor: '#426d61',
    '&:after': {
      borderColor: '#426d61',
    },
    '@media(min-width: 768px)': {
      height: 'calc(100% + 110px)',
    },
    '@media(min-width: 1440px)': { left: 30 },
  },
};

export default function Platform() {
  return (
    <Fragment>
      <Helmet>
        <title>Platform | DocProbe</title>
        <meta
          name='description'
          content='Our mission is to create a simple, all-encompassing fulfillment process for you'
        />
      </Helmet>
      <SecondaryHero
        image='secondary-pages/platform_yj5l9j.png'
        imageStyles={imageOverrides}
        headerStyles={headerS}
        wrapStyles={wrapS}
      >
        The DocProbe <span>Platform</span>
      </SecondaryHero>
      <SecondayHeader wrapStyles={headerWrapS}>
        <span>Our mission is to create a simple, all-encompassing fulfillment process for you.</span> DocProbe’s
        proprietary platform plays a central role in accomplishing that goal.
      </SecondayHeader>
      <div css={{ maxWidth: 1440, margin: 'auto', img: { width: '100%' } }}>
        <AccessOnDemand />
        <div
          css={{
            marginTop: 205,
            '@media(min-width: 768px)': { marginTop: 274 },
            '@media(min-width: 1260px)': { marginTop: 0 },
          }}
        />
        <FollowUpTracking />
        <div
          css={{
            marginTop: 225,
            '@media(min-width: 768px)': {
              marginTop: 292,
            },
            '@media(min-width: 1260px)': {
              marginTop: 0,
            },
          }}
        />
      </div>
      <RobustReporting />
      <RequestDemo />
    </Fragment>
  );
}
