/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {  useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import { validPoBox } from "../../Utils";
import { addressBox, modalIcon, modalText, copyBox, copiedText } from "./requiresOriginalStyle";

export default function RequiresOriginalModal({
  data: { clientName, poBox, clientAddress, clientAddressLine2 },
}) {
  const [copied, setCopied] = useState(false);

  const fullAddressToCopy = `${clientName} C/O Docprobe ${
    validPoBox(poBox) ? `${clientAddress.includes('1133')?'Mail Code':'PO Box'} ${poBox} ` : ``
  }${clientAddress} ${clientAddressLine2}`;

  return (
    <div css={{marginBottom: 16}}>
      <span css={modalText}>
        Send <b>Original</b> to the Following Address:{" "}
      </span>
      <div css={addressBox}>
        <span>
          {clientName}
          <br />
          C/O Docprobe
          <br />
          {validPoBox(poBox) && (
            <span> 
              {clientAddress.includes('1133')?'Mail Code ':'PO Box '}{poBox}
              <br />
            </span>
          )}
          {clientAddress}
          <br />
          {clientAddressLine2}
        </span>
        <CopyToClipboard
          text={fullAddressToCopy}
          onCopy={() => setCopied(true)}
        >
          <div>
            <span
              css={
                !copied
                  ? modalIcon
                  : { ...modalIcon, color: "#3ea848", "&:hover": {} }
              }
            >
              <Icon icon={IconType.ContentCopyIcon} />
            </span>
            {copied && <div css={copiedText}>Copied</div>}
          </div>
        </CopyToClipboard>
      </div>
    </div>
  );
}
