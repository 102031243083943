/** @jsx jsx */
import { jsx } from '@emotion/core';

import { robust, using, outerWrap, imagesStyles, image, innerWrap } from './robustReportingStyles';
import Image from '../../components/ui/Image';

export default function RobustReporting() {
  return (
    <div>
      <div css={outerWrap}>
        <div css={innerWrap}>
          <div>
            <div css={robust}>
              Robust{' '}
              <span
                css={{
                  '@media(min-width: 1260px)': {
                    fontWeight: 500,
                  },
                }}
              >
                Reporting
              </span>
            </div>
            <div css={using}>
              Using our LOS integrated software, you can access your document status and generate reports from your own
              portal, keeping your pending documents just one click away, anytime and anywhere.
            </div>
          </div>
          <div css={imagesStyles}>
            <Image image='secondary-pages/1_Robust_Reporting_ytaiid.png' alt='' />
            <div css={image}>
              <Image image='secondary-pages/2_Robust_Reporting_hfotsg.png' alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
