/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import { mainWrap, textTwo, textWrap, fisrtSection, secondSection, imageOne, imageTwo } from './commitmentStyles';
import Image from '../../components/ui/Image';

export default function CommitmentSection() {
  return (
    <Fragment>
      <div css={{ backgroundColor: '#3b3f43' }}>
        <div css={[fisrtSection, mainWrap]}>
          <div css={textWrap}>
            <h3>
              <span> A Commitment</span> to Partnership
            </h3>
            <p>
              We know the best solutions are built together. At DocProbe, we partner with every client to create
              targeted, customized services that work together with your team, business, and bottom line.
              <br />
              <br />
              And we believe that real solutions go beyond the software. That’s why every DocProbe client works
              hand-in-hand with a dedicated account expert and an innovative, streamlined document fulfillment system—to
              address the challenges and provide the benefits that matter most.
            </p>
          </div>
          <Image image='bitmap_3x_t0raka' alt='' styles={imageOne} />
        </div>
      </div>
      <div
        css={{
          backgroundColor: '#3b3f43',
          '@media(min-width: 768px)': { backgroundColor: '#f5f5f5' },
        }}
      >
        <div css={[secondSection, mainWrap]}>
          <div css={[textWrap, textTwo]}>
            <h3>
            <span>A Confidence</span> in Innovation</h3>
            <p>
              In a fast moving world, and a constantly changing industry, keeping pace requires staying informed of, and
              utilizing, the latest technology available. DocProbe is a market leader in the creation and usage of the
              most advanced software and systems.
              <br />
              <br /> Always listening, and always improving, we move forward to develop and create the solutions that
              our clients need as their business pivots with the times. In tandem with market changes, DocProbe
              continues to expand our capacities for e-loans, e-mortgages, and the technology standards of the future.
            </p>
          </div>
          <Image image='bitmap_3x_lbzerl' alt='' styles={imageTwo} />
        </div>
      </div>
    </Fragment>
  );
}
