/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';

import { root, mainWrap, textWrap, topBlackLine, horizontalRightLine, mainStuffs } from './stepStyles';
import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';
import { isIE, getUA } from 'react-device-detect';

export default function StepSix({ onSectionClick }) {
  return (
    <section css={root} className='relative step-six'>
      <div css={horizontalRightLine} data-line />
      <div className='container' css={mainWrap}>
        <div css={topBlackLine} />
        {/* <SectionCounter styles={{ ' div:nth-of-type(6)': { opacity: '1' } }} onSectionClick={onSectionClick} /> */}
        <div css={mainStuffs} data-content>
          <Text />
          <Images />
        </div>
      </div>
    </section>
  );
}

const imageStyles = {
  width: '100%',
  opacity: 0,
  '@media(min-width: 768px)': {
    maxWidth: 550,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 770,
  },
};

function Images() {
  return (
    <div className='relative'>
      <Image image='home/steps/shipping-1_hdj2xf.png' alt='' styles={imageStyles} />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: -60, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ?(
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='home/steps/shipping-1_hdj2xf.png' alt='shipping images' />
            <Image image='home/steps/shipping-2_cyhxnl.png' alt='shipping images' />
            <Image image='home/steps/shipping-3_keafaz.png' alt='shipping images' />
            <Image image='home/steps/shipping-4_apgqst.png' alt='shipping images' />
          </div>
        ) : (
          <Fragment>
            <Parallax x={[0, 0]}>
              <Image image='home/steps/shipping-1_hdj2xf.png' alt='shipping images' />
            </Parallax>
            <Parallax x={[-10, 10]}>
              <Image image='home/steps/shipping-2_cyhxnl.png' alt='shipping images' />
            </Parallax>
            <Parallax x={[-15, 15]}>
              <Image image='home/steps/shipping-3_keafaz.png' alt='shipping images' />
            </Parallax>
            <Parallax x={[-20, 20]}>
              <Image image='home/steps/shipping-4_apgqst.png' alt='shipping images' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}

function Text() {
  return (
    <div css={textWrap}>
      <h3>Shipping</h3>
      <p>
        We ship all your documents to your investors and custodians. Every document arrives on time, every time so you
        can build better secondary market relationships. With no added shipping fees, and our No-Penalty guarantee, your
        costs remain stable and without surprises.
      </p>
      <NavLink to='/relationships' className='shipping'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}
