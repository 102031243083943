/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import Slider from "react-slick";

import {
  outerWrap,
  quoteStyles,
  nameStyles,
  titleStyles,
  imageOuterStyles,
  imageStyles,
  quote,
  innerWrap,
  line,
  textWrap,
} from './videoSectionStyles';
import Image from '../../components/ui/Image';
import { QuoteGreenIcon } from '../../components/ui/icons';

export default function VideoSection() {
  return (
    <div className='container'>
      <QuoteGreenIcon css={quote} alt='' />
      <div css={outerWrap}>
        <SimpleSlider />
      </div>
    </div>
  );
}

const testamonialPeeps = [
  {
    name: 'Chip Glover',
    image: 'home/chip-glover-2_3x_mqbfup',
    position: 'Senior Executive Vice President (SEVP) – Chief Operating Officer',
    company: 'Towne Bank Mortgage',
    testamonial: `“DocProbe has been a partner with us for about a year and it has been proven time and again what a good
    decision it was to outsource our trailing docs process to them”`,
  },
  {
    name: 'Mathew Nyman',
    image: 'home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_kg5axv.jpg',
    position: 'SVP, Director Secondary Markets',
    company: 'Synergy One Lending a Mutual Bank of Omaha Company',
    testamonial: `“DocProbe understands what our investors require, and they deliver
    those documents to our investors in a timely fashion”`,
  },
  {
    name: 'Patty Gong',
    image: 'home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_ldjn4l.jpg',
    position: 'EVP, Secondary Marketing',
    company: 'Parkside Lending',
    testamonial: `“DocProbe has been a part of our team since 2014. They work the
    Final Docs on both our business channels and ensure that every
    trailing document is handled through best practices. The DocProbe
    team, process, and software coordinate flawlessly with ours”`,
  },
  {
    name: 'Adam Mason',
    image: 'home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_va21le.jpg',
    position: 'Executive Vice President, Coo ',
    company: 'Gershman Mortgage',
    testamonial: `"Great company, great service, great team.”`,
    styles: {
      '> div:first-of-type': { marginBottom: 50, '@media(min-width: 1260px)': { marginBottom: 0 }, },
      '[data-quote]': {
        fontSize: 40,
        '@media(min-width: 768px)': { fontSize: 50 },
      }
    }
  },
]

function PersonSlide({ info }) {
  const { name, image, position, company, testamonial, styles } = info;
  return (
    <div css={[innerWrap, styles]}>
      <div css={textWrap}>
        <div css={quoteStyles} data-quote>
          {testamonial}
        </div>
        <div css={nameStyles}>{name}</div>
        <div css={titleStyles}>
          {position}
          <br /> {company}
        </div>
      </div>
      <div css={imageOuterStyles}>
        <Image image={image} styles={imageStyles} alt='' />
        <div css={line} />
      </div>
    </div>
  )
}

class SimpleSlider extends Component {
  render() {
    var settings = {
      arrows: false,
      infinite: true,
      pauseOnHover: false,
      dots: true,
      slidesToScroll: 1,
      slidesToShow: 1,
      speed: 500,
      autoplaySpeed: 3000,
    };
    return (
      <Slider {...settings}>
        {testamonialPeeps.map(i => <PersonSlide info={i} />)}
      </Slider>
    );
  }
}