/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useState } from 'react';
import axios from 'axios';

import {
  formWrap,
  headerS,
  fieldStyles,
  commentBox,
  submitButton,
  closeButton,
  mobileContactUs,
  successMessage,
} from './floatingContactStyles';
import { PlaneIcon, LargeXIcon, LongRightArrow } from './homeIcons';
import { NavLink } from 'react-router-dom';
import { validEmail } from '../../Utils';
import { getTrackingStringForEmail } from '../../seo-tracking';

export default function FloatingContact() {
  const [formOpen, setFormOpen] = useState(true);
  const [submitted, setSubmit] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    comments: '',
  });

  const submitForm = async () => {
    await axios.post(
      'https://prod-34.eastus.logic.azure.com:443/workflows/f83d235c97354150b0d20dfcfccbd08e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uxOBJUfdqcKxb8S-4jZB7gT5Ao-5qAz6R2n3qsv33bI',
      {
        ...formData,
        comments: `${formData.comment}${getTrackingStringForEmail()}`,
      },
    );
    setSubmit(true);
  };

  return (
    <Fragment>
      <div
        css={[
          formWrap,
          formOpen && {
            borderRadius: 6,
            bottom: 100,
            height: '362px',
            marginTop: 0,
            opacity: 1,
            right: 46,
            transform: 'scale(1)',
            transformOrigin: '100% 100%',
            transition: 'all .15s linear',
            width: 274,
          },
        ]}
      >
        <div css={headerS}>
          <PlaneIcon className='mr2' style={{ maxWidth: 21 }} /> Let’s Get Started
        </div>
        {submitted ? (
          <div css={successMessage}>
            <div>Thank you</div>
            <p className='floating-contact-form-tracking'>We’ll be in touch shortly.</p>
            <span>The DocProbe Team</span>
          </div>
        ) : (
          <div css={{ padding: '24px 28px' }}>
            <input
              type='text'
              name='name'
              placeholder='Name'
              css={[fieldStyles, { marginTop: 0 }]}
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            />
            <input
              type='email'
              name='email'
              placeholder='Email'
              css={fieldStyles}
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            />
            <textarea
              name='comment'
              placeholder='Comment'
              cols='30'
              rows='10'
              css={commentBox}
              value={formData.comment}
              onChange={(e) => setFormData({ ...formData, comment: e.target.value })}
            ></textarea>
            <div
              css={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: 18,
              }}
            >
              <button
                type='submit'
                css={submitButton}
                onClick={submitForm}
                disabled={!formData.name || !validEmail(formData.email)}
              >
                Submit
              </button>
            </div>
          </div>
        )}
      </div>
      <button
        css={[closeButton, !formOpen && { boxShadow: '3px 3px 7px 0px rgba(0, 0, 0, 0.22)' }]}
        onClick={() => setFormOpen(!formOpen)}
      >
        {formOpen ? <LargeXIcon /> : <PlaneIcon style={{ maxWidth: 16, position: 'relative', left: -1 }} />}
      </button>
      <NavLink to='/contact-us' css={mobileContactUs}>
        <PlaneIcon style={{ maxWidth: 15 }} /> <span>CONTACT US</span> <LongRightArrow />
      </NavLink>
    </Fragment>
  );
}
