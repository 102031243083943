/** @jsx jsx */
import { jsx } from '@emotion/core';
import Typist from 'react-typist';
import React from 'react';

import CircleNav from './CirlceNav';
import Papers from './Papers';
import { blackBox, header, subHeader, whiteLine, halfBlack } from './topSectionStyle';
import triangle from '../../images/home/rectangle@3x.png';
import Button from '../../components/ui/Button';

const mainWrap = {
  paddingTop: 27,
  position: 'relative',
  zIndex: '1',
  '@media(min-width: 768px)': { paddingTop: 80 },
  '@media(min-width: 1260px)': { paddingTop: 137 },
};

const sectionWrap = {
  marginTop: 48,
  position: 'relative',
  '@media(min-width: 768px)': { marginTop: 120 },
  '@media(min-width: 1260px)': { marginTop: 130 },
  '@media(min-width: 1440px)': {
    marginTop: 157,
  },
};

const triangleS = {
  position: 'relative',
  top: 1,
  display: 'block',
};

const triangleWrap = {
  position: 'relative',
  '&:after': {
    borderRight: '1px solid white',
    top: '50%',
    content: '""',
    height: 100,
    left: '50%',
    maxHeight: '50%',
    position: 'absolute',
    width: 1,
    zIndex: '50',
  },
  '@media(min-width: 1260px)': {
    '&:after': {
      height: 500,
    },
  },
};

const whiteBottomLine = {
  borderLeft: '1px solid #fff',
  position: 'absolute',
  left: '50%',
  bottom: 0,
  height: 70,
  '@media(min-width: 768px)': {
    height: 128,
  },
  '&:before': {
    borderTop: '1px solid white',
    content: '""',
    width: 7,
    top: 0,
    left: -4,
    position: 'absolute',
  },
};

const demoButton = {
  '&&': {
    display: 'inline-flex',
    margin: '32px auto 0',
    letterSpacing: 3,
    justifyContent: 'center',
    backgroundColor: 'transparent',
    color: '#64AE3E',
    borderColor: '#64AE3E',
    '&:hover': {
      color: '#426D61',
      borderColor: '#426D61',
    },
    '@media(min-width: 768px)': {
      marginTop: 24,
    },
  },
};

export default function TopSection({ onSectionClick }) {
  return (
    <section css={mainWrap}>
      <Papers />
      <div css={{ textAlign: 'center' }}>
        <h1 css={header}>
          Your trailing documents{' '}
          <span className='semi-bold db'>
            made{' '}
            <div css={{ display: 'inline-block' }}>
              <TextTicker />
            </div>
          </span>
        </h1>
      </div>
      <p css={subHeader}>End-to-end document services for mortgage lenders and investors</p>
      <div className='tac'>
        <Button secondary styles={demoButton} to='/contact-us' text='SCHEDULE A DEMO' />
      </div>
      <div css={sectionWrap}>
        <div css={halfBlack} />
        <div css={triangleWrap}>
          <img src={triangle} alt='' css={triangleS} />
        </div>
      </div>
      <div css={blackBox}>
        <div css={whiteLine} />
        <CircleNav onSectionClick={onSectionClick} />
        <div css={whiteBottomLine} />
      </div>
    </section>
  );
}

class TextTicker extends React.Component {
  state = {
    showTypist: true,
  };

  onTypingDone = () => {
    this.setState({ showTypist: false }, () => {
      setTimeout(() => {
        this.setState({ showTypist: true });
      }, 0);
    });
  };

  render() {
    const { showTypist } = this.state;

    return (
      <div>
        {showTypist && (
          <Typist onTypingDone={this.onTypingDone} avgTypingDelay={75} cursor={{ hideWhenDone: true }}>
            easy
            <Typist.Backspace count={4} delay={600} />
            cost-effective
            <Typist.Backspace count={14} delay={600} />
            efficient
            <Typist.Backspace count={14} delay={600} />
          </Typist>
        )}
      </div>
    );
  }
}
