/** @jsx jsx */
import { jsx } from "@emotion/core";
import React from "react";
import { Tooltip, Icon, Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import OutsideClickHandler from "react-outside-click-handler";

const useStyles = makeStyles({
  arrow: {
    color: "#fff",
    shadow: "0 5px 15px 0 #b5b6b4",
  },
  tooltip: {
    width: 280,
    height: "max-content",
    boxShadow: "0 5px 15px 0 #b5b6b4",
    backgroundColor: "#fff",
    color: "#686868",
    padding: 24,
  },
});

const wrapper = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "flex-start",
};

const labelStyles = {
  marginRight: 8,
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "right",
  color: "#686868",
  lineHeight: "24px",
};

const filter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0px 8px 0 15px",
  width: "128px",
  height: "31.4px",
  borderRadius: "15.7px",
  backgroundColor: "#fbfbfd",
  cursor: "pointer",
};

const select = {
  fontSize: "14px",
  color: "#707070",
};

export function Filter({ label, hasSelections, handleClear, tooltip, styles }) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <div css={[wrapper, styles]}>
      <span style={labelStyles}>{label}:</span>
      <OutsideClickHandler
        onOutsideClick={(e) => {
          if (e.target.getAttribute("data-id") === "chip") {
            return;
          }
          setOpen(false);
        }}
      >
        <div
          css={
            !open
              ? {
                  ...filter,
                  border: "1px solid #d4d7db",
                  "&:hover": { border: "1px solid #707070" },
                }
              : { ...filter, border: "solid 1px #29a3aa" }
          }
          onClick={() => setOpen((prevValue) => !prevValue)}
        >
          {hasSelections && <div style={select}>Selected </div>}
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
            }}
          >
            {hasSelections && (
              <ClearIcon
                onClick={handleClear}
                className={classes.clearIcon}
                fontSize="small"
              />
            )}
            <Tooltip
              open={open}
              placement="bottom-start"
              interactive
              classes={classes}
              title={<>{tooltip}</>}
              arrow
            >
              <Icon>
                {open ? (
                  <KeyboardArrowUpIcon style={{ color: "#29a3aa" }} />
                ) : (
                  <KeyboardArrowDownIcon style={{ color: "#707070" }} />
                )}
              </Icon>
            </Tooltip>
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
}

//extracting the chip component from material-ui so we can add a "data-id" of "chip"
export function FilterOptionChip({ getTagProps, index, label }) {
  return (
    <Chip
      variant="outlined"
      label={<div style={{ color:'#707070', padding: '6px 9.8px 5.3px 7px', fontSize: 8 }}>{label}</div>}
      {...getTagProps({ index })}
      style={{ backgroundColor:'#fbfbfd', height: 21.3, borderRadius: 10.6, border: 'solid 1px #d4d7db' }}
      className="MuiChip-root MuiAutocomplete-tag MuiAutocomplete-tagSizeSmall MuiChip-sizeSmall MuiChip-deletable"
      deleteIcon={
        // CancelIcon - but need the data-id attribute on the delete icon
        <svg
          className="MuiSvgIcon-root MuiChip-deleteIcon"
          focusable="false"
          viewBox="0 0 24 24"
          aria-hidden="true"
          style={{ maxWidth: 11, maxHeight: 11, marginTop: 0 }}
          data-id="chip"
        >
          <path
            data-id="chip"
            stroke="#707070"
            d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
          />
        </svg>
      }
    />
  );
}
