export const mainWrap = {
  color: '#fff',
  position: 'relative',
  h3: {
    fontSize: 27,
    lineHeight: 1.3,
  },
  p: {
    fontSize: 14,
    lineHeight: 1.93,
    marginTop: 26,
    maxWidth: 430,
  },
  span: { fontWeight: 500 },
  '@media(min-width: 768px)': {
    maxWidth: 768,
    margin: '0 auto',
    p: {
      fontSize: 14,
      lineHeight: 1.93,
    },
  },
  '@media(min-width: 1024px)': {
    maxWidth: 1024,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1260,
    h3: { fontSize: 37 },
    p: { fontSize: 18, marginTop: 31 },
  },
  '@media(min-width: 1440px)': {
    maxWidth: 1440,
  },
};

export const fisrtSection = {
  padding: '32px 20px 0',
  '@media(min-width: 768px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '69px 29px 29px',
    div: {
      paddingTop: 20,
      paddingLeft: 44,
    },
  },
  '@media(min-width: 1260px)': {
    height: 870,
  },
};

export const textWrap = {
  '@media(min-width: 768px)': {
    display: 'inline-block',
    width: '100%',
    maxWidth: '50%',
    verticalAlign: 'top',
    marginTop: 25,
  },
  // '@media(min-width: 1024px)': {
  //   paddingLeft: 80,
  //   marginLeft: 0,
  //   paddingRight: 16,
  // },
  // '@media(min-width: 1260px)': {
  //   paddingLeft: 181,
  // },
};

export const imageOne = {
  marginTop: 32,
  '@media(min-width: 768px)': {
    display: 'inline-block',
    width: 333,
    height: 490,
    verticalAlign: 'top',
  },
  '@media(min-width: 1024px)': {
    width: 400,
    height: 'auto',
    // maxHeight: 676,
  },
  '@media(min-width: 1260px)': {
    width: 492,
  },
};

// 222222222222

export const secondSection = {
  padding: '47px 20px 77px',
  '@media(min-width: 768px)': {
    color: '#57616b',
    fontSize: 14,
    padding: '81px 0px 0px 64px',
  },
  '@media(min-width: 1024px)': {
    paddingBottom: 100,
  },
  '@media(min-width: 1260px)': {
    height: 825,
  },
};

export const textTwo = {
  '@media(min-width: 768px)': {
    paddingRight: 45,
  },
};

export const imageTwo = {
  marginTop: 32,
  '@media(min-width: 768px)': {
    display: 'inline-block',
    width: 352,
    marginTop: -118,
    height: 676,
  },
  '@media(min-width: 1024px)': {
    width: 400,
    height: 'auto',
    position: 'absolute',
    right: 0,
    marginTop: 0,
    top: -100,
  },
  '@media(min-width: 1260px)': {
    width: 500,
  },
  '@media(min-width: 1440px)': {
    width: 671,
    top: -170,
  },
};
