/** @jsx jsx */
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import React, { Component } from 'react';
import Slider from 'react-slick';

import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';
import { lendersList } from './lendersList';
import {
  outerWrap,
  clients,
  innerWrap,
  topLenders,
  imagesContainer,
  horizontalLine,
  lineStyles,
} from './topLendersStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const carouselItems = lendersList.map((i) => (
  <div key={i}>
    <Image image={`home/${i.img}`} alt={i.name} />
  </div>
));

export default function TopLenders() {
  return (
    <Root>
      <div css={outerWrap}>
        <div css={innerWrap}>
          <div>
            <div css={clients}>Our clients</div>
            <div css={topLenders}>
              Top lenders <span css={{ fontWeight: 500 }}>trust DocProbe</span>
            </div>
          </div>
          <div css={imagesContainer}>
            <SimpleSlider />
          </div>
        </div>
      </div>
      <div css={horizontalLine} />
      <div css={lineStyles} />
    </Root>
  );
}

class SimpleSlider extends Component {
  render() {
    var settings = {
      arrows: true,
      autoplay: false,
      infinite: true,
      pauseOnHover: false,
      slidesToScroll: 4,
      slidesToShow: 4,
      speed: 1500,
      autoplaySpeed: 3000,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 767,
          settings: { slidesToShow: 2, slidesToScroll: 2 },
        },
      ],
    };
    return <Slider {...settings}>{carouselItems}</Slider>;
  }
}

function NextArrow(props) {
  const { className, style, onClick } = props;
  return <RightCaretIcon className={className} style={{ ...style, color: '#426D61' }} onClick={onClick} />;
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <RightCaretIcon
      className={className}
      style={{ ...style, color: '#426D61' }}
      css={{ path: { transform: 'rotate(-180deg)', transformOrigin: '50% 50%' } }}
      onClick={onClick}
    />
  );
}

const Root = styled.div`
  position: relative;
  [data-index='11'] {
    img {
      max-width: 120px;
      margin: 0 auto;
      @media (min-width: 768px) {
        max-width: 96px;
      }
      @media (min-width: 1260px) {
        max-width: 158px;
      }
    }
  }
`;
