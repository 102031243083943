/** @jsx jsx */
import { jsx, Global } from '@emotion/core';

import {
  modalWrap,
  imageS,
  textWrap,
  nameS,
  positionS,
  closeIcon,
  bioStyles,
  connectStyles,
  emailDetails,
  phoneStyles,
} from './profileModalStyles';

import Image from '../../components/ui/Image';
import { XIcon, EmailIcon, PhoneIcon } from '../../components/ui/icons';

export default function ProfileModal({ img, name, position, company, bio, closeModal, email, telNum }) {
  var firstName = name.split(' ').slice(0, -1).join(' ');
  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    }
    return null;
  }
  return (
    <div css={modalWrap}>
      <Global styles={{ body: { overflow: 'hidden' }, html: { overflow: 'hidden' } }} />
      <div onClick={closeModal} css={closeIcon}>
        <XIcon />
      </div>
      <Image image={img} styles={imageS} />
      <div css={textWrap}>
        <div css={nameS}>{name}</div>
        <div css={positionS}>
          {position}, {company}
        </div>
        <div css={bioStyles}>{bio}</div>
        {(email || telNum) && <div css={connectStyles}>Connect with {firstName}</div>}
        {email && (
          <div css={{ display: 'flex', alignItems: 'center', paddingTop: 8 }}>
            <EmailIcon />
            <a href={`mailto:${email}`} css={emailDetails}>
              {email}
            </a>
          </div>
        )}
        {telNum && (
          <div css={{ display: 'flex', alignItems: 'center', paddingTop: 8 }}>
            <PhoneIcon />
            <a href={`tel:${telNum}`} css={phoneStyles}>
              {formatPhoneNumber(telNum)}
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
