export const bgImage = {
  backgroundImage: `url(https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/home/component-3-2_2x_delznu.png)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat', 
  backgroundSize: 'cover',
  minHeight: '100vh',
  display: 'flex',
  justifyContent:'center',
  alignItems:'center',
  position: 'relative',
  padding: '86px 112px 85px',
};

export const container = {
  display:'flex',
  width: 576,
  height: 467,
  padding: '0 50px',
  borderRadius: 12,
  boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.04)',
  backgroundColor: '#fff'
};

export const wrapper = {
  maxWidth:352, 
  height:296, 
  margin:'auto',
  display: 'flex',
  flexDirection:'column',
  alignItems:'center',
};

export const logo = {
  display: 'inline-block', 
  maxWidth: 287,
  svg: {
    width: '100%',
    height: 'auto',
  },
};

export const text = {
  margin: '28px 0 48px',
  opacity: 0.74, 
  color: '#707070',
  lineHeight: 1.56,
  textAlign:'center',
  fontSize: 16,
};

export const success = {
  fontSize: 34, 
  color: '#3b3f43',
  fontWeight: 600,
  fontFamily: 'system-ui',
  margin: '40px 0 -10px',
};

export const button ={
  width: 240, 
  height: 40,
  borderRadius: 4,
  padding: 13,
  margin: '54px 0 10px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
  border: 'solid 0.5px #dcddd9',
  color: '#fff',
  backgroundColor: '#f7a01b',
  cursor: 'pointer',
};

export const disabled = {
  border: 'solid 0.5px #b1b2b0',
  color: '#b1b2b0',
  backgroundColor: '#c6c7c4',
  boxShadow: 'none',
  cursor: 'not-allowed',
};

export const returnButton = {
  display: 'flex',
  justifyContent:'center',
  alignItems:'center',
  marginTop: 8, 
  fontSize: 16, 
  width: 255, 
  fontWeight: 'bold',
};