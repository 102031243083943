/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment, useState } from "react";
import {
  button,
  container,
  containerWrap,
  innerWrapper,
  outerWrapper,
  submitButton,
  overlay,
} from "./GeneralUploadStyles";
import GeneralUpload from "./GeneralUpload";

export default function GeneralUploadModal({setGeneralUpload}) {
  const [uploadSuccessful, setUploadSuccessful] = useState(false);
  const [continueUploading, setContinueUploading] = useState(false);

  return (
    <Fragment>
      <div css={overlay}/>
      <div css={containerWrap}>
        {uploadSuccessful ? (
          <Fragment>
            <div css={container}>
              <div css={outerWrapper}>
                <div css={innerWrapper}>
                  <div css={{fontSize: 20, color: '#1a173b'}}>
                    Your document was uploaded <span css={{fontWeight: 700}}>successfully!</span>
                  </div>
                  <div css={{fontSize: 15, fontWeight: 300, color: '#354052'}}>
                    Do you want to continue uploading documents?
                  </div>
                </div>
                <div css={{marginLeft: -30}}>
                  <button
                    onClick={() => setGeneralUpload(false)}
                    css={[button, {color: '#354052'}]}
                  >
                    Close
                  </button>
                  <button
                    onClick={() => {
                      setContinueUploading(true);
                      setUploadSuccessful(false);
                    }}
                    css={[button, submitButton]}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <GeneralUpload 
            setUploadSuccessful={setUploadSuccessful}
            setGeneralUpload={setGeneralUpload}
            continueUploading={continueUploading}
          />
        )}
      </div>
    </Fragment>
  );
}
