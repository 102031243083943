/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import queryString from 'query-string';
import { NavLink } from 'react-router-dom';

import { SmallXIcon, CircleCheckIcon, HourGlassIcon, ErrorCircleIcon } from '../components/ui/icons';
import Image from '../components/ui/Image';
import Spinner from '../components/ui/Spinner';

const query = queryString.parse(window.location.search);
let tcid = '';
try {
  tcid = atob(query.tcid);
} catch (e) {}

const uploadFiles = async files => {
  const formData = new FormData();
  files.forEach(file => formData.append('documents', file));
  await axios.post(`https://external-document-uploads.azurewebsites.net/api/ReceiveDocument?tcid=${tcid}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const header = {
  alignItems: 'center',
  backgroundColor: '#fff',
  boxShadow: '0px 1px 0px #E0E0E0',
  display: 'flex',
  height: 64,
  marginBottom: 1,
  paddingLeft: 24,
  h1: { fontSize: 20, textTransform: 'uppercase', fontWeight: 400 },
  '> img': { width: 37, marginRight: 16 },
};

const subHeader = {
  fontSize: 34,
  letterSpacing: 0.24,
  lineHeight: '42px',
  fontWeight: 400,
  padding: '56px 0 32px',
};

const dropFieldStyles = {
  backgroundColor: '#fff',
  border: '1px dashed #C4C4C4',
  margin: 'auto',
  maxWidth: 640,
  minHeight: 500,
  padding: '36px 42px',
  borderRadius: 4,
};

const submitButtonStyles = {
  alignItems: 'center',
  backgroundColor: '#64AE45',
  borderRadius: 4,
  color: '#fff',
  display: 'flex',
  height: 42,
  justifyContent: 'center',
  textTransform: 'uppercase',
  width: 144,
  margin: '32px auto 0',
  '&:hover': { backgroundColor: '#609A45' },
  ':disabled': { backgroundColor: 'gray' },
};

const footerStyles = {
  position: 'absolute',
  bottom: 0,
  paddingBottom: 14,
  textAlign: 'center',
  width: '100%',
  letterSpacing: 0.15,
  color: '#9E9E9E',
  display: 'flex',
  justifyContent: 'center',
  a: { color: '#9E9E9E', ':hover': { color: '#444' } },
};

export default function DocumentUploader() {
  const [files, setFiles] = useState([]);
  // Possible states: INITIAL, LOADING, SUCCESS, ERROR
  const [uploadState, setUploadState] = useState('INITIAL');


  const onDrop = acceptedFiles => setFiles(files.concat(acceptedFiles));

  const handleUploadFiles = useCallback(async () => {
    try {
      setUploadState('LOADING');
      await uploadFiles(files);
      setFiles([]);
      setUploadState('SUCCESS');
    } catch (error) {
      setUploadState('ERROR');
    }
  }, [files]);

  const removeFile = fileToRemove => {
    setFiles(files.filter(file => file !== fileToRemove));
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: 'application/pdf' });
  return (
    <div css={{ backgroundColor: '#fafafa', minHeight: '100vh', paddingBottom: 44 }}>
      <Helmet>
        <title>Document Uploads | DocProbe</title>
        <meta name='description' content='Document Uploads' />
      </Helmet>
      <div className='tac'>
        <div css={header}>
          <Image image='logos-d_iaftkc.png' />
          <h1>Docprobe</h1>
        </div>
        <div css={{ fontWeight: 400 }}>
          <h3 css={subHeader}>Document Upload Portal</h3>
          {
            {
              INITIAL: (
                <div css={dropFieldStyles} {...getRootProps()}>
                  <svg
                    width='24'
                    height='16'
                    viewBox='0 0 24 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    css={{ cursor: 'pointer', '&:hover': { path: { fill: '#bbb' } } }}
                  >
                    <path
                      d='M19.35 6.04C18.67 2.59 15.64 0 12 0C9.11 0 6.6 1.64 5.35 4.04C2.34 4.36 0 6.91 0 10C0 13.31 2.69 16 6 16H19C21.76 16 24 13.76 24 11C24 8.36 21.95 6.22 19.35 6.04ZM14 9V13H10V9H7L12 4L17 9H14Z'
                      fill='black'
                    />
                  </svg>
                  {/* --- DROPZONE --- */}
                  <div>
                    <input {...getInputProps()} />
                    <p css={{ fontSize: 24, marginTop: 28, marginBottom: 32 }}>
                      {isDragActive ? 'Drop the files here ...' : 'Click or drop doc here to upload'}
                    </p>
                    <div>
                      {files.map(file => (
                        <div key={file.name} css={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                          <SmallXIcon
                            onClick={e => {
                              e.stopPropagation();
                              removeFile(file);
                            }}
                            css={{ marginRight: 14, cursor: 'pointer', '&:hover': { path: { fill: '#000' } } }}
                          />
                          {file.name}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ),
              SUCCESS: <UploadSuccess handleUploadMoreClick={() => setUploadState('INITIAL')} />,
              LOADING: <LoadingTile />,
              ERROR: <ErrorTile />,
            }[uploadState]
          }
          {uploadState === 'INITIAL' && (
            <button disabled={!files.length} onClick={handleUploadFiles} css={submitButtonStyles}>
              Submit
            </button>
          )}
        </div>
      </div>
      <div css={footerStyles}>
        <span className='mr2'>© 2020 DocProbe. All Rights Reserved.</span>
        <span>
          <NavLink to='/privacy'>Privacy policy</NavLink>
        </span>
      </div>
    </div>
  );
}

function UploadSuccess({ handleUploadMoreClick }) {
  return (
    <div css={[dropFieldStyles, { borderStyle: 'solid' }]}>
      <div
        css={{
          width: 58,
          height: 58,
          borderRadius: '50%',
          border: '2px solid #64AE45',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '16px auto 0',
        }}
      >
        <CircleCheckIcon />
      </div>
      <p css={{ fontSize: 24, margin: '51px auto 74px', maxWidth: 421 }}>
        All docs have been uploaded successfully. Have a great day!
      </p>
      <button css={submitButtonStyles} onClick={handleUploadMoreClick}>
        Upload More
      </button>
    </div>
  );
}

function LoadingTile() {
  return (
    <div css={[dropFieldStyles, { borderStyle: 'solid' }]}>
      <div className='mt2 mb6'>
        <Spinner speed='3'>
          <HourGlassIcon />
        </Spinner>
      </div>
      <p css={{ fontSize: 24 }}>Hold tight while we crunch this stuff</p>
    </div>
  );
}

function ErrorTile() {
  return (
    <div css={[dropFieldStyles, { borderStyle: 'solid' }]}>
      <div className='mt2 mb6'>
        <ErrorCircleIcon />
      </div>
      <p css={{ fontSize: 24, maxWidth: 421, margin: '0 auto 72px' }}>
        Umm... something went wrong. Please contact us.
      </p>
      <button css={submitButtonStyles}>
          <a href="mailto:info@docprobe.net?cc=bgreenes@madisoncres.com&subject=Error%20Uploading%20Documents">Contact Us</a>
      </button>
    </div>
  );
}
