/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SecondaryHero from '../components/layout/SecondaryHero';
import SecondayHeader from '../components/layout/SecondaryHeader';
import Coops from '../content/partners/Coops';

import TechPartners from '../content/partners/TechPartners';
import MbaMemberships from '../content/partners/MbaMemberships';
import GetInTouch from '../content/partners/GetInTouch';

const imageOverrides = {
  maxWidth: 315,
  bottom: -220,
  right: 25,
  '@media(min-width: 768px)': {
    maxWidth: 471,
    bottom: -175,
    right: 20,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 597,
    bottom: -204,
  },
};

const headerS = {
  lineHeight: 1.1,
  paddingTop: 31,
  fontSize: 57,
  '@media(min-width: 768px)': {
    paddingTop: 136,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 100,
    fontSize: 67,
  },
};

const headerWrapS = {
  '> div': {
    padding: '185px 0 45px',
    '@media(min-width: 768px)': {
      padding: '87px 0 72px',
    },
    '@media(min-width: 1260px)': {
      padding: '67px 0 154px',
      maxWidth: 1032,
    },
  },
  '[data-text]': {
    margin: 0,
    paddingLeft: 55,
    maxWidth: 660,
    '@media(min-width: 768px)': {
      paddingLeft: 105,
    },
    '@media(min-width: 1260px)': {
      maxWidth: 720,
    },
  },
  '[data-line]': {
    borderColor: '#426d61',
    '&:after': {
      borderColor: '#426d61',
    },
    '@media(min-width: 1260px)': {
      left: 0,
      height: 'calc(100% + 180px)',
    },
  },
};

export default function Partners() {
  return (
    <Fragment>
      <Helmet>
        <title>Partners | DocProbe</title>
        <meta name='description' content='DocProbe Partners' />
      </Helmet>
      <SecondaryHero
        image='secondary-pages/geometric-dee_uxfwnt.png'
        imageStyles={imageOverrides}
        headerStyles={headerS}
      >
        DocProbe <span>Partners</span>
      </SecondaryHero>
      <SecondayHeader
        wrapStyles={headerWrapS}
        subText='These affiliations create a network of efficiency, productivity and technological advancement to allow us to serve you better. '
      >
        DocProbe partners with numerous Associations, CO-OPs, Trade Organizations, and other strategic Alliances{' '}
        <span>to build a powerful platform of collaboration.</span>
      </SecondayHeader>
      <Coops />
      <TechPartners />
      <MbaMemberships />
      <GetInTouch />
    </Fragment>
  );
}
