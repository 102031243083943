import { Checkbox, TextField} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState }from "react";
import styled from '@emotion/styled';
import { CustomTooltip, CustomPopper, CustomPaper, label, wrapper, Tzvi, useStyles } from "./LocationFilterStyles";
import { FilterOptionChip } from "../../components/ui/Filter/Filter";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

export function LocationFilter({
  handleStateChange,
  handleCountyChange,
  locationList,
  selectedStates,
  selectedCounties,
}) {
  const stateSelected = selectedStates.length > 0;
  const classes = useStyles();

  return (
    <div style={wrapper}>
      <Tzvi>
        <span style={label}>State:</span>
        <Autocomplete
          disableCloseOnSelect
          multiple
          size="small"
          onChange={handleStateChange}
          value={selectedStates}
          options={[...new Set(locationList.map((l) => l.state))].sort()}
          limitTags={2}
          PopperComponent={CustomPopper}
          PaperComponent={CustomPaper}
          popupIcon={<KeyboardArrowDownIcon className="icon"/>}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                style={{ marginRight: 8 }}
                color="secondary"
                checked={selected}
              />
              {option}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
              }}
              className={classes.root}
              style={{ marginTop: "8px" }}
              label="Select State(s)"
              variant="outlined"
              color="secondary"
            />
          )}
          getLimitTagsText={moreText => <span className={classes.tagText}>+{moreText}</span>}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <FilterOptionChip
                getTagProps={getTagProps}
                index={index}
                label={option}
              />
            ))
          }
        />
      </Tzvi>

      <Tzvi>
        <span style={label}>County:</span>
        <Autocomplete
          disabled={!stateSelected}
          disableCloseOnSelect
          multiple
          size="small"
          onChange={handleCountyChange}
          value={locationList.filter((l) =>
            selectedCounties?.includes(l.county)
          )}
          groupBy={(option) => option.state}
          options={locationList
            .filter((l) => selectedStates?.includes(l.state))
            .sort((a, b) => b.state.localeCompare(a.state))}
          limitTags={2}
          PopperComponent={CustomPopper}
          PaperComponent={CustomPaper}
          popupIcon={<KeyboardArrowDownIcon className="icon"/>}
          getOptionLabel={(option) => option.county}
          renderOption={(option, { selected }) => (
            <React.Fragment>
              <Checkbox
                style={{ marginRight: 8 }}
                color="secondary"
                checked={selected}
              />
              {option.county}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <CustomTooltip
              arrow
              title={
                stateSelected ? (
                  ""
                ) : (
                  <div>Please select state first</div>
                )
              }
            >
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                }}
                className={stateSelected ? classes.root : classes.disabled}
                InputLabelProps={!stateSelected && { style: { color:'#b1b2b0' }}}
                style={stateSelected ? { marginTop: "8px" } : { borderRadius: 4, backgroundColor: '#c6c7c4', marginTop: "8px" }}
                label="Select County(s)"
                variant="outlined"
                color="secondary"
              />
            </CustomTooltip>
          )}
          getLimitTagsText={moreText => <span className={classes.tagText}>+{moreText}</span>}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <FilterOptionChip
                getTagProps={getTagProps}
                index={index}
                label={option.county}
              />
            ))
          }
        />
      </Tzvi>
    </div>
  );
}
