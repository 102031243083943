export const horizontalLine = {
  borderTop: '1px solid black',
  position: 'absolute',
  content: '""',
  right: 0,
  top: 0,
  left: 0,
  width: '100%'
};
export const outerWrap = {
  padding: '65px 18px 0 18px',
  position: 'relative',
  '@media(min-width: 768px)': {
    padding: '76px 0 0 120px'
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1033,
    padding: '89px 0 37px 27px'
  }
};
export const title = {
  fontWeight: 500,
  fontSize: 40,
  textAlign: 'center',
  paddingBottom: 50,
  '@media(min-width: 768px)': {
    textAlign: 'left',
    paddingBottom: 70
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    paddingBottom: 61
  }
};
export const imageParStyles = {
  '@media(min-width: 1260px)': {
    display: 'flex'
  }
};
export const imageStyles = {
  paddingBottom: 10,
  height: 72,
  maxWidth: 237,
  '@media(min-width: 768px)': {
    paddingBottom: 22
  }
};
export const paragraph = {
  lineHeight: 1.9,
  width: '100%',
  paddingBottom: 63,
  '@media(min-width: 768px)': {
    maxWidth: 622,
    paddingBottom: 92
  },
  '@media(min-width: 1260px)': {
    marginLeft: 52,
    maxWidth: 646,
    fontSize: 16,
    lineHeight: 1.8
  }
};
export const lineStyles = {
  '@media(max-width: 380px)': {
    display: 'none'
  },
  '@media(min-width: 768px)': {
    borderLeft: '1px solid #3b3f43',
    height: 484,
    position: 'absolute',
    bottom: 0,
    left: 26
  },
  '@media(min-width: 1260px)': {
    left: -92,
    height: 502
  }
};
