import React from 'react';
import * as icons from './index';

export const IconType = {
  BackwardsDeeIcon: 'BackwardsDeeIcon',
  BoxIcon: 'BoxIcon',
  BurgerMenuIcon: 'BurgerMenuIcon',
  CellPhoneIcon: 'CellPhoneIcon',
  CheckIcon: 'CheckIcon',
  ContentCopyIcon: 'ContentCopyIcon',
  DisabledUploadFileIcon: 'DisabledUploadFileIcon',
  EmailIcon: 'EmailIcon',
  ErrorFaceIcon: 'ErrorFaceIcon',
  ForwardDeeIcon: 'ForwardDeeIcon',
  GotItFaceIcon: 'GotItFaceIcon',
  HoverFaceIcon: 'HoverFaceIcon',
  InfoIcon: 'InfoIcon',
  MinusIcon: 'MinusIcon',
  ModalUploadFileIcon: 'ModalUploadFileIcon',
  ModalUploadFileIconBig: 'ModalUploadFileIconBig',
  NoFileFaceIcon: 'NoFileFaceIcon',
  NoResultsIcon: 'NoResultsIcon',
  PhoneIcon: 'PhoneIcon',
  PlusIcon: 'PlusIcon',
  ProcessingIcon: 'ProcessingIcon',
  QuoteGreenIcon: 'QuoteGreenIcon',
  QuoteGreyIcon: 'QuoteGreyIcon',
  RightArrowIcon: 'RightArrowIcon',
  RightCaretIcon: 'RightCaretIcon',
  SpotlightIcon: 'SpotlightIcon',
  submitSpinner: 'submitSpinner',
  SuccessFaceIcon: 'SuccessFaceIcon',
  TimeIcon: 'TimeIcon',
  UploadFileIcon: 'UploadFileIcon',
  XIcon: 'XIcon',
};

export default function Icon({ icon, ...props }) {
  const IconImage = icons[icon];
  return <IconImage {...props} />;
}
