export const mainWrap = {
  backgroundColor: '#f5f5f5',
  padding: '31px 19px 0',
  height: 636,
  marginBottom: 250,
  h3: {
    fontSize: 27,
    lineHeight: 1.3,
  },
  p: {
    fontSize: 14,
    lineHeight: 1.93,
    marginTop: 26,
    maxWidth: 430,
  },
  span: { fontWeight: 500 },
  '@media(min-width: 768px)': {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 56px)',
    margin: '0 auto 27px',
    p: {
      fontSize: 16,
      lineHeight: 1.81,
      marginRight: 25,
    },
  },
  '@media(min-width: 1024px)': {
    marginBottom: 100,
  },
  '@media(min-width: 1260px)': {
    height: 700,
    width: 'calc(100% - 108px)',
    maxWidth: 1327,
    h3: { fontSize: 37 },
    p: { fontSize: 18, marginTop: 31 },
  },
};

export const textWrap = {
  margin: '0 25px',
  '@media(min-width: 768px)': {
    display: 'inline-block',
    width: '100%',
    maxWidth: '50%',
    margin: '0 0 0 15px',
  },
  '@media(min-width: 1024px)': {
    paddingLeft: 80,
    marginLeft: 0,
    paddingRight: 16,
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 181,
  },
};

export const imageStyle = {
  width: '100%',
  maxWidth: 372,
  margin: '32px auto 0',
  '@media(min-width: 768px)': {
    margin: 0,
    position: 'absolute',
    right: -0,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 458,
    marginTop: 86,
    position: 'static',
  },
};
