/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';
import styled from '@emotion/styled';

import React from 'react';
import Icon, { IconType } from './icons/Icon';

const errorMsgOuter = {
  alignItems: 'center',
  color: '#8e8e93',
  display: 'flex',
  flexDirection: 'column',
  fontSize: 16,
  height: 1500,
  justifyContent: 'center',
  margin: '0 auto',
  maxHeight: 'calc(100vh - 418px)',
  overflow: 'auto',
  textAlign: 'center',
  width: '100%',
};

const iconCircle = {
  alignItems: 'center',
  border: '1px solid #e5e5e5',
  borderRadius: '50%',
  display: 'flex',
  height: 224,
  justifyContent: 'center',
  margin: '0px auto',
  width: 224,
};

const errorMsgHeader = {
  fontSize: 24,
  fontWeight: 'bold',
  marginTop: 40,
};

const NoData = ({ isLoader, message, subMessage, icon = IconType.NoResultsIcon, children }) => {
  return (
    <div css={errorMsgOuter}>
      {isLoader ? (
        <LoadingCircle>
          <Loader>
            <Icon icon={icon} />
          </Loader>
        </LoadingCircle>
      ) : (
        <div css={iconCircle}>
          <Icon icon={icon} />
        </div>
      )}
      <div css={errorMsgHeader}>{message}</div>
      <div>{subMessage}</div>
      {children}
    </div>
  );
};

export default NoData;

const LoadingCircle = styled.div`
  position: relative;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
`;

const spin = keyframes`
    0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  align-items: center;
  background-color: transparent;
  display: flex;
  height: 224px;
  justify-content: center;
  position: relative;
  text-indent: -9999em;
  width: 224px;
  svg {
    height: 100px;
    width: auto;
  }
  ::after {
    border-radius: 50%;
    width: 224px;
    border-bottom: 2px solid #e5e5e5;
    border-left: 2px solid #64ae45;
    transform: translateZ(0);
    animation: ${spin} 1.1s infinite linear;
    border-radius: 50%;
    border-right: 2px solid #e5e5e5;
    border-top: 2px solid #e5e5e5;
    height: 224px;
    content: '';
    position: absolute;
  }
`;
