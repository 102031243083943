/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { useState, Fragment } from "react";
import queryString from "query-string";
import StatusButton, { getButtonStatus } from "../components/ui/StatusButton";
import { usePostData } from "../hooks";
import { IconType } from "../components/ui/icons/Icon";
import HashIds from "hashids";
import { Helmet } from "react-helmet";

import {
  outerWrap,
  fourOhFour,
  statement,
  commentsStyles,
} from "./manageNotificationsStyles";

const getButtonText = (loading, error, success) => {
  if (loading) return "Submitting";
  if (error) return "Error Submitting";
  if (success) return "Submitted";
  return "Submit";
};

export default function ManageNotifications({ location: { search } }) {
  const {
    tc: titleCompanyHash,
    tce: encodedEmail,
    messageId,
  } = queryString.parse(search);
  const [text, setText] = useState("");
  const { doPost: submit, isLoading, isSuccess, error } = usePostData();
  return (
    <Fragment>
      <Helmet>
        <title>Manage Notifications | DocProbe</title>
        <meta name="description" content="Manage Notifications" />
      </Helmet>
      <div css={outerWrap}>
        <h2 css={fourOhFour}>Manage Notifications</h2>
        <div css={statement}>
          We understand that you believe some files have been sent to you in
          error. Please advise on the errors so we can do better next time.
          Thank you.
        </div>
        <textarea
          css={commentsStyles}
          value={text}
          placeholder="Comments - please include loan numbers and borrower names if applicable"
          onChange={(e) => setText(e.target.value)}
        />
        <div css={{ marginTop: 32 }}>
          <StatusButton
            onClick={() =>
              submit(
                "https://prod-50.eastus.logic.azure.com:443/workflows/f567a7248ec44a95bc60afd362c46129/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=bXJDwy3Ilv-oFfP7Xjp3QQ6NczB4klfIQJaoApLEJz8",
                {
                  // check if base64 encoded
                  titleCompanyEmail: encodedEmail
                    ? window.atob(encodedEmail)
                    : new HashIds("DocProbe")._decode(titleCompanyHash)[0],
                  text,
                  messageId: messageId ? +window.atob(messageId) : "",
                }
              )
            }
            status={getButtonStatus(isLoading, !!error, isSuccess)}
            text={getButtonText(isLoading, !!error, isSuccess)}
            disabled={!text}
            initialIcon={IconType.RightArrowIcon}
          />
        </div>
      </div>
    </Fragment>
  );
}
