export const whiteWrap = {
  paddingTop: 48,
  textAlign: 'center',
  fontSize: 40,
  lineHeigt: 1.13,
  '@media(min-width: 768px)': {
    margin: '0 auto',
    maxWidth: 566,
    paddingTop: 78,
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    paddingTop: 142,
  },
};
export const outerWrap = {
  '@media(min-width: 1260px)': {
    display: 'flex',
    margin: '100px auto 0',
    maxWidth: 1440,
  },
};
export const brownWrap = {
  backgroundColor: '#ede1cd',
  marginTop: 47,
  // maxHeight: 442,
  paddingTop: 110,
  '@media(min-width: 768px)': {
    marginTop: 66,
  },
  '@media(min-width: 1260px)': {
    width: 720,
    minHeight: 849,
    maxHeight: 850,
    marginTop: 0,
    paddingTop: 211,
  },
};
export const imageStyles = {
  // height: 333,
  width: 315,
  '@media(min-width: 768px)': {
    width: 500,
  },
  '@media(min-width: 1260px)': {
    width: 604,
    // height: 639,
  },
};
export const innerWrap = {
  padding: '30px 20px 50px 20px',
  '@media(min-width: 768px)': {
    padding: '35px 20px 50px 30px',
  },
  '@media(min-width: 1260px)': {
    paddingTop: 0,
    marginLeft: 104,
  },
};
export const pillar = {
  fontSize: 27,
  '@media(min-width: 1260px)': {
    fontSize: 37,
    lineHeight: 1.22,
    maxWidth: 300,
  },
};
export const paragraph = {
  lineHeight: 1.9,
  marginTop: 10,
  '@media(min-width: 768px)': {
    maxWidth: 526,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 361,
    fontSize: 16,
  },
};
export const show = {
  '@media(max-width: 1260px)': {
    display: 'none',
  },

  '@media(min-width: 1261px)': {
    display: 'visible',
  },
};
