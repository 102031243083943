export const modalWrap = {
  backgroundColor: 'rgba(59, 63, 67, .9)',
  bottom: 0,
  color: '#fff',
  height: '100%',
  left: 0,
  maxHeight: '100vh',
  overflow: 'auto',
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
  zIndex: 6000,
};

export const imageS = {
  backgroundColor: '#000',
  opacity: 1,
  width: '100%',
  '@media(min-width: 1024px)': {
    display: 'inline-block',
    maxHeight: '100vh',
    maxWidth: '50%',
    verticalAlign: 'top',
    width: 'auto',
  },
};

export const textWrap = {
  padding: 20,
  overflow: 'auto',
  maxHeight: '100vh',
  WebkitScrollbar: {
    width: '0 !important',
    display: 'none',
  },
  msOverflowStyle: 'none',
  MsOverflowStyle: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
  '-ms-overflow-style': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    display: 'inline-block',
    fontSize: 16,
    lineHeight: 1.81,
    paddingLeft: 72,
    paddingTop: 80,
    verticalAlign: 'top',
    width: '50%',
    '> div': {
      maxWidth: 456,
    },
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 114,
    paddingTop: 100,
    width: '58%',
  },
};

export const nameS = {
  color: '#fff',
  fontSize: 37,
  fontWeight: 500,
  marginTop: 13,
};

export const positionS = {
  color: '#fff',
  fontSize: 20,
  letterSpacing: 1.55,
  marginTop: 6,
};

export const company = {
  color: '#64ae3e',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 2.5,
  marginTop: 4,
  textTransform: 'uppercase',
};

export const closeIcon = {
  cursor: 'pointer',
  display: 'inline-block',
  position: 'absolute',
  right: 17,
  top: 17,
  '@media(min-width: 768px)': {
    top: 28,
    right: 28,
  },
};

export const bioStyles = {
  fontSize: 14,
  lineHeight: 1.93,
  marginTop: 16,
};
export const connectStyles = {
  color: '#64ae3e',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 3,
  marginTop: 40,
  textTransform: 'uppercase',
};
export const emailDetails = {
  color: '#fff',
  fontSize: 16,
  fontWeight: 500,
  paddingLeft: 8,
};
export const phoneStyles = {
  color: '#fff',
  fontSize: 16,
  fontWeight: 500,
  paddingLeft: 8,
};
