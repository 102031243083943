/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  blackBox,
  whiteLineStyles,
  innerWhiteBox,
  innerWhiteContent,
  faster,
  withDocProbe,
  phoneImageStyles,
  outerContainer,
  highSpeed,
  imageStyles,
} from './highSpeedStyles';
import Image from '../../components/ui/Image';

export default function HighSpeedSystems() {
  return (
    <div>
      <div css={blackBox}>
        <div css={outerContainer}>
          <div css={innerWhiteBox}>
            <div css={whiteLineStyles} />
            <div css={innerWhiteContent}>
              <div css={highSpeed}>
                <span css={{ fontWeight: 500 }}>High speed systems </span>for on-schedule delivery
              </div>
              <div css={faster}>Faster turnaround for the people who matter, delay-free.</div>
              <div css={withDocProbe}>
                With DocProbe, your documentation fulfillment is faster and more accurate than ever—getting your
                documents to your investors with rapid turnaround and impeccable accuracy. As soon as you complete your
                sale, DocProbe retrieves, audits, manages corrections when necessary, and uploads all documentation to
                our secure software. With easy tracking and powerful recording, your documents are shipped to their
                designated destination, on schedule and on target.
              </div>
            </div>
          </div>
          <div css={imageStyles}>
            <Image image='bitmap_3x_lbzerl.jpg' styles={phoneImageStyles} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
