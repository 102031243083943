/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  outerWrap,
  quoteStyles,
  nameStyles,
  titleStyles,
  imageOuterStyles,
  imageStyles,
  quote,
  line
} from './videoSectionStyles';
import Image from '../../components/ui/Image';
import { QuoteGreenIcon } from '../../components/ui/icons';

export default function VideoSection() {
  return (
    <div className='container'>
      <div css={outerWrap}>
        <div>
          <QuoteGreenIcon css={quote} alt='' />
          <div css={quoteStyles}>
            “DocProbe has been a part of our team since 2014. They work the
            Final Docs on both our business channels and ensure that every
            trailing document is handled through best practices. The DocProbe
            team, process, and software coordinate flawlessly with ours”
          </div>
          <div css={nameStyles}>Patty Gong</div>
          <div css={titleStyles}>
            EVP, Secondary Marketing at Parkside Lending
          </div>
        </div>
        <div css={imageOuterStyles}>
          <Image
            image='home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_ldjn4l.jpg'
            styles={imageStyles}
            alt=''
          />
          <div css={line} />
        </div>
      </div>
    </div>
  );
}
