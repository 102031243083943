export const outerWrap = {
  backgroundColor: '#426d61',
  marginTop: 79,
  padding: '43px 19px 70px 19px',
  '@media(min-width: 768px)': {
    paddingBottom: 58,
    paddingTop: 50,
  },
  '@media(min-width: 1260px)': {
    marginTop: 0,
    padding: '87px 100px 93px 180px',
  },
  '@media(min-width: 1440px)': {
    padding: '87px 250px 93px 200px',
  },
};
export const innerWrap = {
  maxWidth: 1300,
  margin: 'auto',
  '@media(min-width: 1260px)': {
    display: 'flex',
    alignItems: 'center',
  },
};
export const textStyles = {
  color: 'white',
  textAlign: 'center',
  lineHeight: 1.13,
  fontSize: 40,
  '@media(min-width: 768px)': {
    maxWidth: 500,
    margin: 'auto',
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    maxWidth: 770,
    textAlign: 'left',
    margin: '0 auto',
  },
};
export const buttonStyles = {
  backgroundColor: 'white',
  color: '#15284b',
  margin: '66px auto 0',
  marginTop: 66,
  width: 291,
  '@media(min-width: 768px)': {
    marginTop: 34,
  },
  '@media(min-width: 1260px)': {
    marginTop: 0,
  },
};
