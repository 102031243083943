import { makeStyles } from "@material-ui/core/styles";

export const useAddressStyles = makeStyles({
  arrow: {
    color: "#fff",
    shadow: "0 5px 15px 0 #b5b6b4",
  },
  tooltip: {
    width: 163,
    height: 210,
    padding: "19px 10px 11px 10px",
    boxShadow: "0 5px 15px 0 #b5b6b4",
    backgroundColor: "#fff",
    textAlign: "left",
    color: "#686868",
    fontSize: "14px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.29",
    letterSpacing: "normal",
  },
});

export const wrapper = {
  width: "160px",
  textAlign: "center",
  marginTop: 0,
};

export const text = {
  textTransform: "uppercase",
  fontSize: "9px",
  fontWeight: 600,
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "1.94px",
  color: "#f7a01b",
};

export const icon = {
  width: "16px",
  height: "16px",
  margin: "1px 0 0 7px",
  verticalAlign: "middle",
};

export const modalText = {
  fontSize: "14px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#012938",
};

export const addressBox = {
  width: "144px",
  minHeight: "144px",
  marginTop: "9px",
  padding: "16px 21px 16px 16px",
  opacity: "0.57",
  borderRadius: "4px",
  border: "dashed 1px #e2e2e1",
  backgroundColor: "#fcfcfb",
  fontSize: "12px",
  fontWeight: "normal",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "1.33",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#707070",
  position: "relative",
};

export const modalIcon = {
  float: "right",
  position: "absolute",
  right: 15,
  bottom: 16,
  width: 13.8,
  height: 16,
  color: "#023459",
  "&:hover": { color: "#f7a01b" },
};

export const copiedText = {
  position: "absolute",
  bottom: -7,
  right: -8,
  fontSize: 8,
  textTransform: 'uppercase',
  fontWeight: 600,
  letterSpacing: 1.6,
  backgroundColor: "#fcfcfb",
  zIndex: 50,
  color: '#3ea848',
  width: 43,
  height: 16,
};
