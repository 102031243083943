export const outerWrap = {
  backgroundColor: '#3b3f43',
  maxWidth: 1314,
  padding: '55px 0px 30px 0px',
  position: 'relative',
  width: '100%',
  '@media(min-width: 768px)': {
    paddingBottom: 50,
    paddingTop: 70,
  },
  '@media(min-width: 1260px)': {
    marginBottom: 70,
    marginTop: 92,
    padding: '105px 71px',
  },
};

export const innerWrap = {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  '@media(min-width: 1260px)': {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    height: 'auto',
  },
}

export const quote = {
  height: 57,
  left: 40,
  position: 'absolute',
  top: -27,
  width: 59,
  zIndex: '1',
  '@media(min-width: 768px)': {
    height: 84,
    top: -40,
    width: 87,
  },
};
export const quoteStyles = {
  color: '#7cc99e',
  fontSize: 25,
  fontWeight: 500,
  margin: '0 auto',
  maxWidth: 350,
  padding: '0 10px',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    maxWidth: 520,
    padding: 0,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 640,
  },
  '@media(min-width: 1260px)': {
    fontSize: 25,
    maxWidth: 415,
    textAlign: 'left',
  },
};

export const textWrap = {
  '@media(min-width: 1260px)': {
    marginLeft: 50,
  },
}
export const nameStyles = {
  color: 'white',
  fontSize: 18,
  fontWeight: 500,
  marginTop: 20,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    textAlign: 'left',
  },
};
export const titleStyles = {
  color: 'white',
  fontWeight: 300,
  lineHeight: 1.5,
  marginTop: 10,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    textAlign: 'left',
  },
};
export const imageStyles = {
  margin: '46px auto 0',
  padding: '0 10px',
  position: 'relative',
  '@media(min-width: 768px)': {
    maxWidth: 598,
  },
  '@media(min-width: 1260px)': {
    margin: 0,
  },
};
export const imageOuterStyles = {
  position: 'relative',
  '&:before': {
    borderTop: '2px solid #3b3f43',
    content: '""',
    position: 'absolute',
    top: 0,
    width: '100%',
    zIndex: 5,
  },
  '&:after': {
    borderTop: '1px solid #3b3f43',
    bottom: 0,
    content: '""',
    position: 'absolute',
    width: '100%',
    zIndex: 5,
  },
};
export const line = {
  borderLeft: '1px solid #3b3f43',
  bottom: -142,
  height: 142,
  left: '50%',
  position: 'absolute',
  '&:before': {
    borderTop: '1px solid #3b3f43',
    bottom: 0,
    content: '""',
    left: -4,
    position: 'absolute',
    width: 7,
  },
  '@media(min-width: 768px)': {
    height: 174,
    bottom: -175,
  },
  '@media(min-width: 1260px)': {
    height: 285,
    bottom: -285,
    left: '93%',
  },
};
