/** @jsx jsx */
// import { keyframes } from '@emotion/core';

// const floaterOne = keyframes`
//     from { transform: translate(0px, 0px) transform: 'rotate(30deg)',; }
//     to  { transform: translate(50px, 350px) rotateX(-.5turn); }
// `;

// const floaterTwo = keyframes`
//     from { transform: translate(0,  0px); }
//     to  { transform: translate(-40px, 500px) rotateX(.5turn); }
// `;

// const floaterThree = keyframes`
//     from { transform: translate(0,  0px); }
//     to  { transform: translate(-70px, 150px) rotateX(.5turn); }
// `;

// const floaterFour = keyframes`
//     from { transform: translate(0,  0px); }
//     to  { transform: translate(50px, 200px) rotateX(.2turn); }
// `;

// const floaterFive = keyframes`
//     from { transform: translate(0,  0px); }
//     to  { transform: translate(50px, 200px) rotateX(.2turn); }
// `;

export const paperStyles = {
  animationDuration: '15s',
  animationIterationCount: '1',
  animationTimingFunction: 'ease-in-out',
  maxWidth: 241,
  position: 'absolute',
  width: '100%',
  zIndex: 100,
  animationFillMode: 'forwards',
};

export const paperOneS = {
  left: -16,
  transform: 'rotate(20deg)',
  width: 75,
  top: 260,
  animationDuration: '3s',
  // animationName: `${floaterOne}`,
  '@media(min-width: 768px)': {
    width: 140,
    left: 20,
    top: 309,
    transform: 'rotate(30deg)',
  },
  '@media(min-width: 1260px)': {
    width: 241,
    transform: 'rotate(10deg)',
  },
};

// const horizontal = keyframes`
//     from { transform: translate(0,  0px); }
//     to  { transform: translate(500px, 20px) rotateX(.5turn); }
// `;

// export const fooOuter = {
//   left: -16,
//   border: '1px solid red',
//   transform: 'rotate(0deg)',
//   animationDirection: 'alternate',
//   width: 75,
//   top: 300,
//   animationDuration: '3s',
//   animationName: `${horizontal}`,
//   '@media(min-width: 768px)': {
//     width: 140,
//     left: 20,
//     top: 350,
//     transform: 'rotate(30deg)',
//   },
// };

export const paperTwoS = {
  right: -10,
  width: 60,
  // animationName: `${floaterTwo}`,
  animationDuration: '30s',
  top: 90,
  '@media(min-width: 768px)': {
    width: 130,
    right: -60,
  },
  '@media(min-width: 1260px)': {
    width: 160,
    right: -20,
  },
};

export const paperThreeS = {
  width: 18,
  transform: 'rotate(20deg)',
  // animationName: `${floaterThree}`,
  animationDuration: '15s',
  right: 50,
  top: 340,
  '@media(min-width: 768px)': {
    right: 220,
    width: 28,
    height: 34,
    top: 350,
  },
  '@media(min-width: 1260px)': {
    width: 47,
    height: 58,
    right: 406,
    top: 438,
  },
};

export const paperFourS = {
  width: 80,
  left: '43%',
  top: 300,
  // animationName: `${floaterFour}`,
  '@media(min-width: 768px)': {
    width: 124,
    top: 413,
    left: '41%',
    zIndex: '-1',
  },
  '@media(min-width: 1260px)': {
    width: 214,
    zIndex: '50',
    top: 504,
    left: '38%',
  },
};

export const whiteOneS = {
  width: 20,
  right: 0,
  top: 380,
  animationDuration: '20s',
  transform: 'rotate(20deg)',
  // animationName: `${floaterFive}`,
  '@media(min-width: 768px)': {
    top: 450,
    width: 48,
    right: 76,
    transform: 'rotate(0deg)',
  },
  '@media(min-width: 1260px)': {
    top: 625,
  },
};

export const whiteTwoS = {
  width: 50,
  right: -8,
  bottom: 350,
  animationDuration: '20s',
  transform: 'rotate(60deg)',
  // animationName: `${floaterFive}`,
  '@media(min-width: 768px)': {
    bottom: 600,
    width: 137,
    right: -50,
  },
};

export const whiteThreeS = {
  width: 35,
  transform: 'rotate(-53deg)',
  left: -15,
  bottom: 310,
  animationDuration: '20s',
  // animationName: `${floaterFive}`,
  '@media(min-width: 768px)': {
    bottom: 500,
    width: 60,
  },
};

export const whiteFourS = {
  width: 44,
  left: 44,
  top: 329,
  '@media(min-width: 768px)': {
    width: 70,
    top: 550,
    left: 97,
  },
  '@media(min-width: 1260px)': {
    top: 800,
  },
};
