export const lendersList = [
  {
    img: 'AFR_rdvimp',
    name: 'AFR, American Financial Resources',
  },
  {
    img: 'APM_xvplyu',
    name: 'American Pacific Mortgage',
  },
  {
    img: 'Cornerstone_tk7lro',
    name: 'Cornerstone Home Lending',
  },
  {
    img: 'EnvoyMortgage_FullColor_logo_yu1iuz',
    name: 'Envoy Mortgage',
  },
  {
    img: 'Gershman_v0r5wd',
    name: 'Gershman Mortgage',
  },
  {
    img: 'Parkside_ikpyos',
    name: 'Parkside Lending',
  },
  {
    img: 'SWF_logo_standard_lajart',
    name: 'Southwest Funding',
  },
  {
    img: 'MountainWest_q5oufa',
    name: 'Mountain West Financial Inc',
  },
  {
    img: 'AMCAP_Logo_apg4h8',
    name: 'AmCap Home Loans',
  },
  {
    img: 'FINAL_Sente_logo_Color_m5ifmt',
    name: 'Sente Mortgage',
  },
  {
    img: 'iServeLogo_v48j3y',
    name: 'iServe Residential Lending',
  },
  {
    img: 'Guild-Logo_gi7wby.png',
    name: 'Guild Mortgage',
  },
  {
    img: 'Unknown_smhjkp',
    name: 'Towne Bank Mortgage',
  },
  {
    img: 'NRL_Logo_-_Full_Color_lfiwff',
    name: 'NRL Mortgage',
  },
  {
    img: 'PFN_kr9qpp',
    name: 'PFN, Priority Financial Network',
  },
  {
    img: 'CFMC_Focused_logo_lprsrs',
    name: 'C & F Mortgage Corporation',
  },
];
