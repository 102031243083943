/** @jsx jsx */
import { jsx } from "@emotion/core";
import { NavLink } from "react-router-dom";

const buttonWrap = {
  borderRadius: "26px",
  border: "1px solid #426d61",
  backgroundColor: "#426d61",
  color: "#fff",
  cursor: "pointer",
  display: "block",
  fontSize: 12,
  fontWeight: 500,
  height: 52,
  letterSpacing: 1,
  lineHeight: "52px",
  padding: "0 40px",
  textAlign: "center",
  textTransform: "uppercase",
  transition: "all .2s",
  minWidth: 251,
  "&:hover": {
    backgroundColor: "#4A6865",
    color: "#fff",
    borderColor: "#4A6865",
    transition: "all .2s"
  },
  "&:active": {
    backgroundColor: "#3C514E",
    borderColor: "#3C514E"
  }
};

const secondaryStyles = {
  "&&": {
    color: "#426d61",
    backgroundColor: "#fff"
  }
};

export default function Button({
  text,
  secondary,
  styles,
  className,
  to,
  onClick,
  children,
  ...props
}) {
  if (to) {
    return (
      <NavLink
        to={to}
        css={[buttonWrap, secondary && secondaryStyles, styles]}
        className={className}
        {...props}
      >
        {text}
      </NavLink>
    );
  }
  return (
    <button
      onClick={onClick}
      css={[buttonWrap, secondary && secondaryStyles, styles]}
      className={className}
      {...props}
    >
      {text}
      {children}
    </button>
  );
}
