/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import TopPart from '../content/process/TopPart';
import StreamlineSection from '../content/process/StreamlineSection';
import SmarterProcesses from '../content/process/SmarterProcesses';
import HighSpeedSystems from '../content/process/HighSpeedSystems';
import WebPortal from '../content/process/WebPortal';
import VideoSection from '../content/process/VideoSection';
import BottomSection from '../components/ui/BottomSection';

export default function Efficiency() {
  return (
    <Fragment>
      <Helmet>
        <title>Efficiency | DocProbe</title>
        <meta name='description' content='Simply Powerful. Powerfully Simple.' />
      </Helmet>
      <TopPart />
      <StreamlineSection />
      <SmarterProcesses />
      <div css={{ backgroundColor: '#3b3f43' }}>
        <div
          css={{
            margin: 'auto',
            paddingTop: 1,
            '@media(min-width: 768px)': { maxWidth: 712 },
            '@media(min-width: 1260px)': { maxWidth: 1320 },
          }}
        >
          <HighSpeedSystems />
          <WebPortal />
        </div>
      </div>
      <VideoSection />
      <BottomSection
        linkOne='/cost'
        textOne='Reduced Costs'
        linkTwo='/relationships'
        textTwo='Improved Relationships'
      />
    </Fragment>
  );
}
