/** @jsx jsx */
import { jsx } from "@emotion/core";
import ClearIcon from "@material-ui/icons/Clear";
import React from "react";
import { TitlePortalContext } from "./TitlePortalContext";
import {
  failedUploadDocWrapper,
  failedUploadDocText,
  retryText,
} from "./uploadDocStyle";
import { UploadModal } from "./UploadModal";
import {Tooltip, withStyles} from "@material-ui/core";

const CustomTooltip = withStyles({
  tooltip: {
    width: 144,
    height: 36,
    padding: "7px 30px",
    fontSize: 14,
    fontWeight: "normal",
    backgroundColor: '#01666c',
    color: '#fff',
  },
  arrow: {
    color: '#01666c',
  },
})(Tooltip);

export default function UploadedDoc({ data, docType, requiresOriginal }) {
  const { uploadModalState, uploadInProgressState, uploadMissingDocState } =
    React.useContext(TitlePortalContext);
  const [uploadModal, setUploadModal] = uploadModalState;
  const [uploadInProgress, setUploadInProgress] = uploadInProgressState;
  const [uploadMissingDoc, setUploadMissingDoc] = uploadMissingDocState;

  return (
    <>
      {uploadModal === data.loanId && uploadMissingDoc === docType ? (
        <div>
          <UploadModal
            data={data}
            requiresOriginal={requiresOriginal}
            missingDoc={uploadMissingDoc}
          />
        </div>
      ) : (
        <div css={failedUploadDocWrapper}>
          <ClearIcon fontSize="medium" css={{ float: "left" }} />
          <span css={failedUploadDocText}>Upload Failed </span>
          <CustomTooltip
              arrow
              title={<div>Click to Retry</div>}
          >
            <div
              css={
                !uploadInProgress
                    ? retryText
                    : { ...retryText, pointerEvents: "none" }
              }
              onClick={() => {
                setUploadModal(data.loanId);
                setUploadMissingDoc(docType);
              }}
            >
              Retry
            </div>
          </CustomTooltip>
        </div>
      )}
    </>
  );
}
