/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';

import Image from '../../components/ui/Image';

const cardWrap = {
  display: 'inline-block',
  maxWidth: '50%',
  position: 'relative',
  verticalAlign: 'bottom',
  width: '100%',
  p: {
    opacity: 0,
    transition: 'all .2s',
  },
  '&:before': {
    backgroundColor: 'rgba(255, 255, 255, 0.0)',
    transition: 'all .2s',
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    zIndex: '10',
  },
  '&:hover': {
    p: {
      opacity: 1,
    },
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
  },
  '@media(min-width: 768px)': {
    maxWidth: '33.3333333%',
    height: '100%',
    maxHeight: 374,
  },
};
const positionStyles = {
  fontSize: 16,
  color: '#8ee5b4',
  display: 'block',
  fontWeight: 500,
  letterSpacing: 1.12,
  lineHeight: 1.3,
  textAlign: 'center',
  textTransform: 'capitalize',
  '@media(min-width: 768px)': {
    fontSize: 19,
  },
};
const nameStyles = {
  fontSize: 25,
  color: '#fff',
  display: 'block',
  fontWeight: 500,
  letterSpacing: 1.12,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    fontSize: 37,
  },
};

const text = {
  left: '50%',
  zIndex: '50',
  width: '100%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '0 10px'
}

export default class TeamCard extends Component {
  state = {
    displayInfo: false,
  };
  render() {
    const { img, position, name } = this.props;
    return (
      <div onClick={() => this.setState({ displayInfo: true })} css={cardWrap}>
        <Image image={`team/${img}`} />
        <div css={text}>
          <p css={nameStyles}>{name}</p>
          <p css={positionStyles}>{position}</p>
        </div>
      </div>
    );
  }
}
