import React, { useState } from "react";

export const TitlePortalContext = React.createContext();

function TitlePortalProvider(props) {
  const [showAllDocs, setShowAllDocs] = useState(0);
  const [uploadModal, setUploadModal] = useState(false);
  const [uploadInProgress, setUploadInProgress] = useState(false);
  const [uploadMissingDoc, setUploadMissingDoc] = useState("");
  const [uploadedDocs, setUploadedDocs] = useState(new Set());
  const [failedUploadDocs, setFailedUploadDocs] = useState(new Set());
  const [searchValue, setSearchValue] = useState("");
  const token = props.token;
  const emailId = props.emailId;

  return (
    <TitlePortalContext.Provider
      value={{
        showAllDocsState: [showAllDocs, setShowAllDocs],
        uploadModalState: [uploadModal, setUploadModal],
        uploadInProgressState: [uploadInProgress, setUploadInProgress],
        uploadMissingDocState: [uploadMissingDoc, setUploadMissingDoc],
        uploadedDocsState: [uploadedDocs, setUploadedDocs],
        failedUploadDocsState: [failedUploadDocs, setFailedUploadDocs],
        searchValueState: [searchValue, setSearchValue],
        token: token,
        emailId: emailId,
      }}
    >
      {props.children}
    </TitlePortalContext.Provider>
  );
}
const TitlePortalConsumer = TitlePortalContext.Consumer;

export { TitlePortalProvider, TitlePortalConsumer };
