/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  secondBlackBox,
  whiteLineStyles,
  blackContent,
  duringEveryStage,
  faster,
  moreImageStyles,
  outerContainer,
} from './webPortalStyles';
import Image from '../../components/ui/Image';

export default function WebPortal() {
  return (
    <div css={{ position: 'relative' }}>
      <div css={secondBlackBox}>
        <div css={outerContainer}>
          <div css={blackContent}>
            <div css={{ '@media(min-width: 768px)': { display: 'flex' } }}>
              <div>
                <div
                  css={{
                    fontSize: 27,
                    marginBottom: 23,
                    lineHeight: 1.3,
                    width: '100%',
                    '@media(min-width: 1260px)': {
                      fontSize: 37,
                    },
                  }}
                >
                  <span css={{ fontWeight: 500 }}>LOS-integrated </span>web portal
                </div>
                <div css={faster}>In-the-know tracking, viewing, and automated reporting.</div>
                <div css={duringEveryStage}>
                  During every stage of your document fulfillment, you and your investors stay informed using our
                  proprietary, LOS-integrated software. Follow-up on your documents’ processing and shipping, generate
                  reports of completed and pending fulfillments, and view your documents’ real time status from your
                  personal account. With our user-friendly dashboard, you can track, review, download, and keep your
                  documents right at your fingertips—from anywhere, anytime.
                </div>
              </div>
              <div css={{ position: 'relative' }}>
                <Image image='bitmap_3x_uoc1vn.jpg' styles={moreImageStyles} alt='' />
                <div css={whiteLineStyles} />
                {/* <div css={mediumLineStyles} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
