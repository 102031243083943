export const outerWrap = {
  backgroundColor: '#3b3f43',
  padding: '0 18px 24px 20px',
  '@media(min-width: 768px)': {
    paddingBottom: 58
  },
  '@media(min-width: 1260px)': {
    paddingBottom: 86
  }
};
export const outer = {
  maxWidth: 1160,
  '@media(min-width: 1260px)': {
    margin: '0 auto'
  }
};
export const titleOne = {
  color: 'white',
  fontSize: 40,
  padding: '46px 0 34px 25px',
  borderLeft: '1px solid #e5e5e5',
  position: 'relative',
  '@media(min-width: 768px)': {
    maxWidth: 335,
    paddingBottom: 52,
    marginRight: 24,
    '&:after': {
      borderTop: '1px solid #e5e5e5',
      width: 7,
      left: -4,
      content: '""',
      position: 'absolute',
      top: 0
    },
    '@media(min-width: 1024px)': {
    maxWidth:'47.6%'
    },
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 90,
    maxWidth: 545,
    fontSize: 50,
  }
};
export const wrapper = {
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'center',
    height: 590
  },
  '@media(min-width: 1260px)': {
    height: 764
  }
};
export const innerWrap = {
  backgroundColor: 'white',
  padding: '27px 8px 24px 27px',
  '@media(min-width: 768px)': {
    padding: '121px 40px 0 33px'
  },
  '@media(min-width: 1024px)': {
    width:'50%',
    padding:'100px 30px 0 120px'
   },
  '@media(min-width: 1260px)': {
      minWidth:660,
    width: '100%',
    height: 700,
    padding: '183px 50px 0 149px'
  }
};
export const faster = {
  fontSize: 27,
  maxWidth: 262,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 37,
    maxWidth: 397
  }
};
export const processing = {
  color: '#7cc99e',
  fontWeight: 500,
  paddingTop: 6,
  fontSize: 20,
  maxWidth: 235,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 22,
    maxWidth: 440
  }
};
export const paragraph = {
  lineHeight: 1.9,
  width: '100%',
  '@media(min-width: 768px)': {
    paddingTop: 9,
    maxWidth: 290
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    lineheight: 1.8,
    maxWidth: 393
  }
};
export const imageStyles = {
  borderLeft: '1px solid #e5e5e5',
  '@media(min-width: 768px)': {
    width: '48%'
  },
  '@media(min-width: 1024px)': {
   width:'50%'
  },
  '@media(min-width: 1260px)': {
    minWidth: 641,
    width: '100%',
  }
};
export const whiteLine = {
  height: 24,
  borderLeft: '1px solid #e5e5e5',
  position: 'absolute',
  '@media(min-width: 768px)': {
    left: '51%',
    height: 59
  },
  '@media(min-width: 1024px)': {
   left:'50%'
  },
  '@media(min-width: 1260px)': {
   left:'50.7%',
   height:85,
  },
};
export const blackLine = {
  '@media(min-width: 768px)': {
    height: 56,
    borderLeft: '1px solid black',
    position: 'absolute',
    left: '51%',
    '&:after': {
      borderBottom: '1px solid black',
      width: 7,
      left: -4,
      content: '""',
      position: 'absolute',
      bottom: 0
    }
  },
  '@media(min-width: 1024px)': {
    left:'50%'
   },
  '@media(min-width: 1260px)': {
   left:'50.7%'
  },
};
