/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import SecondaryHero from '../../components/layout/SecondaryHero';
import SecondayHeader from '../../components/layout/SecondaryHeader';

const imageOverrides = {
  '@media(min-width: 1260px)': {
    bottom: -160,
    right: 16,
  },
};

const headerOverrides = {
  paddingTop: 31,
  '@media(min-width: 768px)': {
    paddingTop: 135,
  },
  '@media(min-width: 1260px)': {
    paddingTop: 141,
  },
  '@media(min-width: 1440px)': {
    paddingLeft: 50,
    maxWidth: 460,
  },
};

const headerWrapS = {
  '> div': {
    padding: '29px 0 34px',
    '@media(min-width: 768px)': {
      padding: '87px 0 52px',
    },
    '@media(min-width: 1260px)': {
      padding: '87px 0 142px',
    },
  },
};

export default function LenderInvestor() {
  return (
    <Fragment>
      <SecondaryHero
        image='secondary-pages/Relationship_c220cj.png'
        headerStyles={headerOverrides}
        imageStyles={imageOverrides}
      >
        Lender-investor relationships <span>built to last</span>
      </SecondaryHero>
      <SecondayHeader
        wrapStyles={headerWrapS}
        subText='Our streamlined document fulfillment system and transparent communications ensures on-time delivery of
        your completed and corrected documents, strengthening the trust and confidence of your investors.'
      >
        <span>With DocProbe, you can start building better lender-investor relationships</span> by turning over the
        entire process to our efficient operations.
      </SecondayHeader>
    </Fragment>
  );
}
