/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import { isIE, getUA } from 'react-device-detect';
import { Fragment } from 'react';

import { root, mainWrap, textWrap, topBlackLine, horizontalRightLine, mainStuffs } from './stepStyles';
import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';
import mailboxImage from '../../images/home/doc-retrieval-mailbox-min.png';

const textStyles = {
  '@media(min-width: 1260px)': {
    marginLeft: -130,
    marginTop: -20,
  },
};

const backgroundDee = {
  display: 'none',
  '@media(min-width: 768px)': {
    position: 'absolute',
    display: 'block',
    right: -300,
    height: 831,
    top: 270,
    transform: 'scaleX(-1)',
    zIndex: 0,
  },
  '@media(min-width: 1260px)': {
    height: 1800,
    top: 180,
    right: -600,
  },
};

const imageStyles = {
  opacity: 0,
  maxWidth: 550,
  '@media(min-width: 768px)': {
    maxWidth: 1000,
  },
};

export default function StepTwo({ onSectionClick }) {
  return (
    <section css={root} className='relative step-two'>
      <div css={horizontalRightLine} data-line />
      <div className='container' css={mainWrap}>
        <Image image='home/background-d_giemij' styles={backgroundDee} />
        <div css={topBlackLine} />
        <div css={mainStuffs} data-content>
          <Text />
          <Images />
        </div>
      </div>
    </section>
  );
}

function Text() {
  return (
    <div css={[textWrap, textStyles]}>
      <h3>
        Documents
        <span className='semi-bold'>
          <br />
          Retrieval
        </span>
      </h3>
      <p>
        DocProbe is the new address for all your trailing docs. Using a combination of automated follow ups and calls
        from your dedicated account representative to title companies, EVERY document is accounted for and retrieved.
      </p>
      <NavLink to='/efficiency' className='Documents-Retrieval'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}

function Images() {
  return (
    <div
      className='relative'
      css={{
        marginTop: 30,
        '@media(min-width: 1260px)': { marginLeft: -180 },
      }}
    >
      <Image image='home/steps/document-retrieval-1_ougx8a.png' alt='' styles={imageStyles} />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ? (
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='home/steps/document-retrieval-1_ougx8a.png' alt='Documents Retrieval' />
            <img src={mailboxImage} alt='' />
            <Image image='home/steps/document-retrieval-3_rps3ef.png' alt='Documents Retrieval' />
            <Image image='home/steps/document-retrieval-4_jas1yr.png' alt='Documents Retrieval' />
          </div>
        ) : (
          <Fragment>
            <Parallax y={[0, 0]}>
              <Image image='home/steps/document-retrieval-1_ougx8a.png' alt='Documents Retrieval' />
            </Parallax>
            <Parallax y={[5, -5]}>
              <img src={mailboxImage} alt='' />
            </Parallax>
            <Parallax y={[10, -10]}>
              <Image image='home/steps/document-retrieval-3_rps3ef.png' alt='Documents Retrieval' />
            </Parallax>
            <Parallax y={[15, -15]}>
              <Image image='home/steps/document-retrieval-4_jas1yr.png' alt='Documents Retrieval' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}
