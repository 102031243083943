export const mainWrap = {
  backgroundColor: '#f8f8f8',
  marginTop: 64,
  marginBottom: 47,
  maxWidth: 1122,
  position: 'relative',
  '&:before': {
    content: '""',
    borderLeft: '1px solid #426d61',
    position: 'absolute',
    left: '50%',
    top: -64,
    height: 64,
    '@media(min-width: 1260px)': {
      height: 125,
      top: -125,
    },
  },
  '&:after': {
    content: '""',
    borderLeft: '1px solid #426d61',
    position: 'absolute',
    left: '50%',
    bottom: -47,
    height: 47,
    '@media(min-width: 768px)': {
      bottom: -96,
      height: 96,
    },
    '@media(min-width: 1260px)': {
      height: 130,
      bottom: -130,
    },
  },
  '@media(min-width: 768px)': {
    margin: '64px 20px 96px',
    display: 'flex',
    height: 396,
  },
  '@media(min-width: 1164px)': {
    margin: '64px auto 96px',
  },
  '@media(min-width: 1260px)': {
    height: 544,
    margin: '125px auto 130px',
  },
};

export const banner = {
  backgroundColor: '#42accd',
  color: 'white',
  display: 'inline-block',
  height: 206,
  padding: '25px 0',
  paddingLeft: 16,
  verticalAlign: 'top',
  width: 69,
  img: { height: '100%' },
  '@media(min-width: 768px)': {
    width: 166,
    display: 'inline-flex',
    height: '100%',
    padding: '50px 0',
    paddingLeft: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  '@media(min-width: 1260px)': {
    width: 266,
    img: { height: 398 },
  },
};

export const textWrap = {
  display: 'inline-block',
  width: 'calc(100% - 69px)',
  padding: '28px 23px 40px',
  color: '#2f2f2f',
  h3: {
    fontSize: 27,
    lineHeight: 1.3,
  },
  p: {
    fontSize: 14,
    lineHeight: 1.93,
    marginTop: 26,
    maxWidth: 543,
  },
  span: { fontWeight: 500 },
  '@media(min-width: 768px)': {
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 90px 0 60px',
    width: 'calc(100% - 166px)',
  },
  '@media(min-width: 1260px)': {
    width: 'calc(100% - 266px)',
    paddingLeft: 115,
    h3: { fontSize: 37 },
    p: { fontSize: 18, marginTop: 31 },
  },
};
