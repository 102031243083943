/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core';
import { Children } from 'react';

const overlayStyles = {
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 72,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  zIndex: '10000',
};
const localOverlayStyles = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  zIndex: '5000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const floaterOne = keyframes`
    from { transform: rotate(0deg) }
    to  { transform: rotate(360deg) }
`;

export const spinnerS = {
  animation: `${floaterOne} 1s infinite linear`,
  position: 'absolute',
  width: '100%',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default function ({ children, overlay, speed, localOverlay }) {
  return (
    <div css={[overlay && overlayStyles, localOverlay && localOverlayStyles]}>
      <div className='relative' css={{ animation: `${floaterOne} ${speed || '1'}s infinite linear` }}>
        {children}
      </div>
    </div>
  );
}
