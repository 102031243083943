const axios = require("axios");

export async function submitDocument(token, loanId, docType, clientId, file) {
  const url = `${process.env.REACT_APP_API_URL}/api/UploadExternalDocuments?token=${token}&loanId=${loanId}&docType=${docType}&clientId=${clientId}`;

  let form = new FormData();

  form.append("documents", file);

  return await axios.post(url, form, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}
