export const mainWrap = {
  paddingBottom: 56,
  '@media(min-width: 768px)': { paddingBottom: 135 },
  '@media(min-width: 1260px)': { paddingBottom: 160 },
};
export const boxImageStyles = {
  margin: '78px auto 0',
  width: 96,
  '@media(min-width: 768px)': {
    marginTop: 88,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '@media(min-width: 1260px)': {
    marginTop: 158,
  },
};
export const retrieve = {
  marginTop: 20,
  textAlign: 'center',
  fontSize: 27,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
};
export const mobileBreak = {
  '@media(min-width: 1260px)': {
    display: 'none',
  },
};
export const smarterProcess = {
  color: '#7cc99e',
  fontSize: 20,
  textAlign: 'center',
  marginTop: 10,
  fontWeight: 500,
  lineHeight: 1.3,
  width: '100%',
  '@media(min-width: 768px)': {
    width: 350,
    margin: '10px auto 0',
  },
  '@media(min-width: 1260px)': {
    color: '#8ee5b4',
    fontSize: 22,
    width: '100%',
    marginTop: 17,
  },
};
export const paragraph = {
  textAlign: 'center',
  marginTop: 10,
  color: '#57616b',
  padding: '0 17px',
  lineHeight: 1.9,
  '@media(min-width: 768px)': {
    margin: '10px auto 0',
    width: 555,
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    color: '#3b3f43',
    marginTop: 30,
    fontWeight: 300,
    width: 740,
  },
};
