export const formWrap = {
  alignItems: 'flex-end',
  backgroundColor: '#f1f0f0',
  bottom: 32,
  borderRadius: '6px 6px 24px 24px',
  boxShadow: '15px 24px 41px 0px rgba(0, 0, 0, 0.20)',
  display: 'flex',
  flexDirection: 'column',
  height: 0,
  opacity: 0,
  marginTop: 362,
  overflow: 'hidden',
  position: 'fixed',
  right: 46,
  width: 52,
  zIndex: '5020',
  transition: 'all .15s linear',
  transform: 'scale(.1)',
  transformOrigin: '100% 100%',
  '@media(max-width: 767px)': {
    display: 'none',
  },
};

export const headerS = {
  alignItems: 'center',
  backgroundColor: '#64ae3e',
  color: '#fff',
  display: 'flex',
  fontSize: 20,
  fontWeight: 600,
  height: 64,
  justifyContent: 'center',
  letterSpacing: 0.45,
  width: '100%',
};

export const fieldStyles = {
  backgroundColor: '#fff',
  borderRadius: 6,
  border: '1px solid #f1f0f0',
  height: 44,
  marginTop: 14,
  padding: '0 18px',
  width: '100%',
  '&:focus': { border: '1px solid #64ae3e' },
  '::placeholder': { fontSize: 13, color: '#3b3f43', letterSpacing: 1, fontWeight: 300 },
};

export const commentBox = {
  ...fieldStyles,
  padding: 18,
  height: 84,
  resize: 'none',
};

export const submitButton = {
  backgroundColor: '#64ae3e',
  borderRadius: 26,
  color: '#fff',
  fontSize: 10,
  height: 29,
  letterSpacing: 2.5,
  textTransform: 'uppercase',
  transition: 'all .150s',
  width: 117,
  '&:hover': { backgroundColor: '#426d61', transition: 'all .150s' },
};

export const closeButton = {
  backgroundColor: '#64ae3e',
  borderRadius: '50%',
  bottom: 32,
  boxShadow: '10px 10px 27px 0px rgba(0, 0, 0, 0.22)',
  color: '#fff',
  height: 52,
  position: 'fixed',
  right: 46,
  transition: 'all .150s',
  width: 52,
  zIndex: '5020',
  '&:hover': { backgroundColor: '#426d61', transition: 'all .150s' },
  '@media(max-width: 767px)': {
    display: 'none',
  },
};

export const mobileContactUs = {
  alignItems: 'center',
  backgroundColor: '#ffffff',
  borderRadius: 26,
  bottom: 20,
  boxShadow: '-8px 8px 18px 0 rgba(0, 0, 0, 0.25)',
  color: '#64ae3e',
  display: 'flex',
  fontSize: 12,
  height: 52,
  justifyContent: 'center',
  left: '50%',
  letterSpacing: 3,
  maxWidth: 234,
  position: 'fixed',
  textTransform: 'uppercase',
  transform: 'translateX(-50%)',
  width: '100%',
  zIndex: '5020',
  fontWeight: 400,
  span: { margin: '0 19px' },
  '@media(min-width: 768px)': {
    display: 'none',
  },
};

export const successMessage = {
  alignItems: 'center',
  color: '#3b3f43',
  display: 'flex',
  flexDirection: 'column',
  height: 299,
  justifyContent: 'center',
  padding: '0 48px',
  textAlign: 'center',
  div: { fontSize: 48, textTransform: 'uppercase' },
  p: { marginTop: 32, fontSize: 16 },
  span: { fontWeight: 100, marginTop: 8 },
};
