/** @jsx jsx */
import { jsx } from '@emotion/core';

import { root, contentWrap, headerStyles, subTextS, lineStyles } from './secondaryHeaderStyles';

export default function SecondayHeader({ subText, children, wrapStyles }) {
  return (
    <div css={[root, wrapStyles]}>
      <div css={{ margin: 'auto', maxWidth: 1100, position: 'relative', paddingBottom: 56 }}>
        <div css={lineStyles} data-line />
        <div className='container' css={contentWrap} data-text>
          <h3 css={headerStyles}>{children}</h3>
          <div css={subTextS}>{subText}</div>
        </div>
      </div>
    </div>
  );
}
