export const accessOnDemand = {
  fontSize: 27,
  fontWeight: 500,
  color: 'white',
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    fontSize: 37,
    textAlign: 'left',
    fontWeight: 300,
  },
};
export const everyDocument = {
  color: 'white',
  fontWeight: 300,
  textAlign: 'center',
  lineHeight: 1.9,
  marginTop: 13,
  '@media(min-width: 768px)': {
    maxWidth: 520,
    margin: '13px auto 0',
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    textAlign: 'left',
    width: 266,
  },
};
export const outerWrap = {
  backgroundColor: '#7cc99e',
  maxHeight: 352,
  padding: '28px 35px',
  marginBottom: 50,
  marginTop: 35,
  '@media(min-width: 768px)': {
    maxWidth: 712,
    margin: '35px 28px 0',
    maxHeight: 432,
  },
  '@media(min-width: 1260px)': {
    display: 'flex',
    minWidth: 997,
    minHeight: 850,
    maxHeight: 1000,
    marginLeft: 46,
  },
};
export const innerWrap = {
  '@media(min-width: 1260px)': {
    padding: '300px 0 250px 200px',
  },
};
export const imagesStyles = {
  position: 'relative',
  margin: '50px auto 0',
  maxWidth: 342,
  maxHeight: 422,
  '@media(min-width: 768px)': {
    maxWidth: 440,
    maxHeight: 460,
  },
  '@media(min-width: 1260px)': {
    right: -80,
    top: 80,
    minWidth: 618,
    minHeight: 613,
  },
};

export const image = {
  position: 'absolute',
  right: 0,
  bottom: 0,
  width: '100%',
};
