/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import SecondaryHero from '../components/layout/SecondaryHero';
import BottomSection from '../components/ui/BottomSection';
import { QuoteGreenIcon } from '../components/ui/icons';
import SecondaryHeader from '../components/layout/SecondaryHeader';
import Image from '../components/ui/Image';

import {
  title,
  highEfficiency,
  payPerLoan,
  whiteBox,
  whiteBoxWrapper,
  innerWhiteContent,
  ourUnique,
  greyBox,
  innerGreyBox,
  weveSidelined,
  plpInnerWrap,
  noShipping,
  innerGreyContent,
  videoWrapper,
  quoteStyles,
  nameStyles,
  nameDescription,
  nameSection,
  innerBlackContent,
  quoteWrap,
  thinkingImageStyles,
  videoImageStyles,
  innovativeImageStyles,
  phoneImageStyles,
  quote,
  whiteLineStyles,
  smallLineStyles,
  secondLineStyles,
  containerShow,
  horizontalLineStyles,
  mediumLineStyles,
  bottomLineStyles,
  headerWrapS,
} from './costStyles';

const imageOverrides = {
  maxWidth: 225,
  bottom: -140,
  right: 20,
  '@media(min-width: 768px)': {
    maxWidth: 365,
    bottom: -101,
    right: 40,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 480,
    bottom: -148,
    right: 100,
  },
};

const headerS = {
  paddingTop: 31,
  maxWidth: 315,
  '@media(min-width: 768px)': {
    paddingTop: 136,
    maxWidth: 480,
  },
  '@media(min-width: 1260px)': {
    fontSize: 67,
    maxWidth: 555,
    paddingTop: 100,
    span: { display: 'block' },
  },
};

export default function Cost() {
  return (
    <Fragment>
      <Helmet>
        <title>Cost | DocProbe</title>
        <meta name='description' content='A pricing model that works for your bottom line' />
      </Helmet>
      <TopPart />
      <PerLoanPricing />
      <InnovativeProcesses />
      <PayForServices />
      <VideoSection />
      <BottomSection
        linkOne='/efficiency'
        textOne='Streamlined Process'
        linkTwo='/relationships'
        textTwo='Improved Relationships'
      />
    </Fragment>
  );
}

function TopPart() {
  return (
    <Fragment>
      <SecondaryHero image='secondary-pages/cost_uudsxp.png' imageStyles={imageOverrides} headerStyles={headerS}>
        A pricing model that works for your <span>bottom line</span>
      </SecondaryHero>
      <SecondaryHeader
        subText='Your fulfillment costs adjust in sync with your volume, reducing overhead during slow periods, and controlling the rush to ramp up as sales increase.'
        wrapStyles={headerWrapS}
      >
        <span>
          DocProbe's innovative, per-loan fee structure is tailored towards your budget
          <br />
        </span>
        delivering flexible costs, faster turnaround, and reduced, fixed overhead.
      </SecondaryHeader>
    </Fragment>
  );
}

function PerLoanPricing() {
  return (
    <div css={{ backgroundColor: '#3b3f43', position: 'relative' }}>
      <div className='container'>
        <div css={plpInnerWrap}>
          <div
            css={{
              '@media(min-width: 768px)': {
                display: 'flex',
                justifyContent: 'flex-end',
              },
            }}
          >
            <div css={title}>
              Here’s how we
              <br />
              lower your
              <span css={{ fontWeight: 600, display: 'block' }}>document costs</span>
            </div>
          </div>
          <div
            css={{
              '@media(min-width: 768px)': {
                display: 'flex',
                justifyContent: 'flex-end',
              },
            }}
          >
            <div css={whiteBoxWrapper}>
              <div css={whiteLineStyles} />
              <div css={whiteBox}>
                <div css={innerWhiteContent}>
                  <div
                    css={{
                      fontSize: 27,
                      color: '#57616b',
                      '@media(min-width: 1260px)': { fontSize: 37 },
                    }}
                  >
                    <span css={{ fontWeight: 600, marginRight: 6 }}>Per-Loan</span>
                    <span>Pricing</span>
                  </div>
                  <div css={payPerLoan}>Pay per loan - not per month.</div>
                  <div
                    css={{
                      marginTop: 10,
                      color: '#57616b',
                      lineHeight: 1.9,
                      '@media(min-width: 1260px)': {
                        fontSize: 16,
                        width: 393,
                        lineHeight: 1.8,
                      },
                    }}
                  >
                    With our per-loan fee structure, you take control of your document fulfillment costs. With DocProbe,
                    you pay only for the files you need, when you need them, based on your monthly loan reports. That
                    means no more fixed overhead—just variable ops costs that adjusts according to your volume.
                  </div>
                </div>
              </div>
              <div css={{ paddingBottom: 1 }}>
                <Image image='bitmap_3x_lxaqju.jpg' styles={thinkingImageStyles} alt='' />
              </div>
              <div css={smallLineStyles} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
function InnovativeProcesses() {
  return (
    <div className='container'>
      <Image image='secondary-pages/Costs_Page_yecmzs.png' styles={innovativeImageStyles} alt='' />
      <div css={{ marginTop: 25, textAlign: 'center', fontSize: 37 }}>
        <span css={{ fontWeight: 600 }}>Innovative processes</span> to lower your costs
      </div>
      <div css={highEfficiency}>High efficiency means lower costs per loan</div>
      <div css={ourUnique}>
        Our unique process streamlines your post-closing experience, trimming down the complications, delays, and
        workforce that drive up your costs. With DocProbe’s tried-and-true automated process, lenders benefit from lower
        operation costs, expedited fulfillment, and reliable results without the markup.
      </div>
    </div>
  );
}
function PayForServices() {
  return (
    <div css={containerShow}>
      <div css={greyBox}>
        <div css={secondLineStyles} />
        <div css={innerGreyBox}>
          <div css={innerGreyContent}>
            <div
              css={{
                fontSize: 27,
                width: 231,
                '@media(min-width: 1260px)': {
                  fontSize: 37,
                  width: 298,
                },
              }}
            >
              <span css={{ fontWeight: 600 }}>Pay for services </span>and nothing more
            </div>
            <div css={noShipping}>No shipping fees, no penalties, no long-term contracts.</div>
            <div css={weveSidelined}>
              We’ve sidelined the extra costs of doing business by providing powerful document fulfillment with your
              costs in mind. With reduced costs and our no investor penalty fee promise, you can maximize your budget
              for the document services you need, without any surprises.
            </div>
          </div>
          <div css={{ paddingBottom: 1 }}>
            <Image image='bitmap_3x_o5exv7.jpg' styles={phoneImageStyles} alt='' />
          </div>
        </div>
      </div>
      <div css={horizontalLineStyles} />
      <div css={mediumLineStyles} />
    </div>
  );
}

function VideoSection() {
  return (
    <div className='container'>
      <div css={videoWrapper}>
        <QuoteGreenIcon css={quote} alt='' />
        <div css={innerBlackContent}>
          <div css={quoteWrap}>
            <div css={quoteStyles}>
              “DocProbe allowed us to move our Trailing Docs function to a variable cost model while adding operational
              efficiency. This move allows us to focus on building our business.”
            </div>
            <div css={nameSection}>
              <div css={nameStyles}>Adam Mason</div>
              <div css={nameDescription}>President, Gershman Mortgage</div>
            </div>
          </div>
          <Image
            image='home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_va21le.jpg'
            styles={videoImageStyles}
            alt=''
          />
        </div>
      </div>
      <div css={bottomLineStyles} />
    </div>
  );
}
