/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  outerWrap,
  quoteStyles,
  nameStyles,
  titleStyles,
  imageOuterStyles,
  imageStyles,
  quote,
  line
} from './videoSectionStyles';
import Image from '../../components/ui/Image';
import { QuoteGreenIcon } from '../../components/ui/icons';

export default function VideoSection() {
  return (
    <div className='container'>
      <div css={outerWrap}>
        <div>
          <QuoteGreenIcon css={quote} alt='' />
          <div css={quoteStyles}>
            “DocProbe also gives us the ability to track documents via an easy
            to use web interface. The transition to working with DocProbe was
            seamless.”
          </div>
          <div css={nameStyles}>Matthew Nyman</div>
          <div css={titleStyles}>
            SVP, Director Secondary Markets at Synergy One Lending a Mutual Bank
            of Omaha Company
          </div>
        </div>
        <div css={imageOuterStyles}>
          <Image
            image='home/gershman-mortgage-adam-mason-dwp-19-4233-sm-1_3x_kg5axv.jpg'
            styles={imageStyles}
            alt=''
          />
          <div css={line} />
        </div>
      </div>
    </div>
  );
}
