/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import Image from './Image';
import ProfileModal from '../../content/employees/ProfileModal';

export const leadershipCard = {
  marginBottom: 55,
  width: '100%',
  ':nth-of-type(3n + 3)': {
    marginRight: 0,
  },
  img: { width: '100%' },
  '@media(min-width: 768px)': {
    display: 'inline-block',
    marginRight: 15,
    verticalAlign: 'top',
    width: 'calc(33.33% - 10px)',
  },
  '@media(min-width: 1024px)': {
    width: 'calc(33.33% - 27px)',
    marginRight: 40,
  },
};

export const bigImageWrap = {
  position: 'relative',
  cursor: 'pointer',
  '&:hover': {
    '&:before': {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: '10',
      transition: 'all .2s',
    },
  },
};
const noBio = {
  cursor: 'default',
  '&:hover': {
    '&:before': {
      display: 'none',
    },
  },
};

export const nameS = {
  color: '#57616b',
  fontSize: 37,
  fontWeight: 500,
  marginTop: 13,
};

export const positionS = {
  color: '#57616b',
  fontSize: 20,
  letterSpacing: 1.55,
  lineHeight: 1.5,
  marginTop: 6,
};

export const companyS = {
  color: '#64ae3e',
  fontSize: 12,
  fontWeight: 500,
  letterSpacing: 2.5,
  marginTop: 4,
  maxWidth: 222,
  lineHeight: 1.4,
  textTransform: 'uppercase',
};

export default class ProfileCard extends Component {
  state = { profileModal: false };

  render() {
    const { info } = this.props;
    const { bio, img, name, position, company, email, telNum } = info;
    const { profileModal } = this.state;
    return (
      <Fragment>
        <div css={leadershipCard} onClick={bio ? () => this.setState({ profileModal: !profileModal }) : null}>
          <div css={[bigImageWrap, !bio && noBio]}>
            <Image image={img} alt="" />
          </div>
          <div css={nameS}>{name}</div>
          <div css={positionS}>{position}</div>
        </div>
        {profileModal && (
          <ProfileModal
            img={img}
            name={name}
            position={position}
            company={company}
            bio={bio}
            email={email}
            telNum={telNum}
            closeModal={() => this.setState({ profileModal: !profileModal })}
          />
        )}
      </Fragment>
    );
  }
}
