export const contentWrap = {
  padding: '0px 19px 30px',
  '@media(min-width: 768px)': {
    display: 'flex',
    paddingBottom: 54,
    maxWidth: 768,
    margin: 'auto',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1440,
    paddingBottom: 62,
  },
};

export const headerWrap = {
  position: 'relative',
  h2: {
    fontSize: 40,
    padding: '53px 0 43px 24px',
    borderLeft: '1px solid black',
    marginLeft: 20,
    '@media(min-width: 768px)': {
      width: '50%',
      marginLeft: '50%',
    },
    '@media(min-width: 1260px)': {
      fontSize: 50,
      padding: '99px 0 100px 94px',
    },
  },
};
export const textBoxWrap = {
  color: 'white',
  padding: '30px 0 30px 20px',
  '@media(min-width: 768px)': {
    paddingTop: 100,
    width: '50%',
    position: 'relative',
  },
  '&:after': {
    borderLeft: '1px solid white',
    position: 'absolute',
    content: '""',
    height: '561px',
    right: -1,
    top: 0,
    '@media(min-width: 768px)': { height: 700 },
  },
};

export const innerTextBox = {
  '@media(min-width: 768px)': {
    maxWidth: 290,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 430,
  },
};
export const simpler = {
  fontSize: 27,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
};
export const changing = {
  fontSize: 20,
  fontWeight: 500,
  color: '#7cc99e',
  maxWidth: 290,
  width: '100%',
  marginTop: 10,
  lineHeight: 1.3,
  '@media(min-width: 768px)': {
    maxWidth: 400,
  },
  '@media(min-width: 1260px)': {
    fontSize: 22,
    maxWidth: 456,
  },
};
export const transitioning = {
  lineHeight: 1.9,
  fontWeight: 300,
  marginTop: 10,
  '@media(min-width: 768px)': {
    width: 330,
  },
  '@media(min-width: 1260px)': {
    marginTop: 33,
    width: 456,
    fontSize: 16,
  },
};
export const secondPar = {
  marginTop: 10,
  '@media(min-width: 1260px)': {
    marginTop: 30,
  },
};
export const imageStyles = {
  display: 'block',
  '@media(min-width: 768px)': {
    maxWidth: 344,
    maxHeight: 508,
  },
  '@media(min-width: 1260px)': {
    width: '100%',
    maxWidth: 532,
    maxHeight: 786,
  },
};
export const whiteLine = {
  height: 59,
  borderLeft: '1px solid #e5e5e5',
  position: 'absolute',
  left: '50%',
  '&:after': {
    borderBottom: '1px solid #e5e5e5',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    bottom: 0,
  },
};
