/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import React, { Fragment } from 'react';
import ouibounce from 'ouibounce';

import Button from '../../components/ui/Button';
import axios from 'axios';
import { getTrackingStringForEmail } from '../../seo-tracking';
import {
  outerWrap,
  header,
  imageWrap,
  imageStyles,
  title,
  innerTitle,
  stepWrap,
  triangleStyles,
  xStyles,
  bottomPart,
  showYou,
  topPart,
  inputStyles,
  buttonStyles,
} from './exitPopUpStyles';
import { XIcon } from '../../components/ui/icons';
import { validEmail } from '../../Utils';

const imageOne = 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/home/imageOne.2b747c8c_v6vf0h.png';
const imageTwo = 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/home/imageTwo.a4e735fa_ny4rrm.png';
const imageThree =
  'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/home/imageThree.eefef8af_bitilj.png';

const overlayStyles = {
  backgroundColor: 'rgba(3, 3, 3, 0.59)',
  bottom: 0,
  overflow: 'hidden',
  position: 'fixed',
  right: 0,
  top: 0,
  transition: 'all .2s',
  width: '100%',
  zIndex: 7000,
};

export default class ExitPopUp extends React.Component {
  state = {
    shouldDisplay: false,
    showReceived: false,
    closeModal: false,
    submitSuccess: false,
    email: '',
  };

  componentDidMount() {
    this.handlerRef = ouibounce(false, {
      sitewide: true,
      cookieExpire: 30,
      callback: () => {
        this.setState({
          shouldDisplay: true,
        });
      },
    });
  }

  componentWillUnmount() {
    if (this.handlerRef) {
      this.handlerRef.disable();
    }
  }

  handleDismiss = () => {
    if (this.handlerRef) {
      this.handlerRef.disable();
    }
    this.setState({
      shouldDisplay: false,
    });
  };

  handleSuccessfulSubmit = () => {
    this.setState({ showReceived: true });

    // auto close the exit modal 4 seconds after submitting
    setTimeout(() => {
      this.handleDismiss();
    }, 4000);
  };

  submitEmail = async () => {
    await axios.post(
      'https://prod-34.eastus.logic.azure.com:443/workflows/f83d235c97354150b0d20dfcfccbd08e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uxOBJUfdqcKxb8S-4jZB7gT5Ao-5qAz6R2n3qsv33bI',
      { email: this.state.email, comments: `exit modal: ${getTrackingStringForEmail()}` },
    );
    this.setState({ submitSuccess: true });
    this.handleSuccessfulSubmit();
  };

  render() {
    return (
      <div
        css={[
          { display: 'none' },
          this.state.shouldDisplay && { display: 'block' },
          this.state.closeModal && { display: 'none' },
        ]}
      >
        {this.state.shouldDisplay && (
          <Fragment>
            <Global
              styles={{
                body: { overflow: 'hidden' },
                html: { overflow: 'hidden' },
              }}
            />
            <div css={overlayStyles} />
            <div css={outerWrap}>
              <div css={{ overflow: 'auto', maxHeight: '99vh' }}>
                <div css={triangleStyles} onClick={() => this.setState({ closeModal: true })}>
                  <div
                    css={{
                      borderColor: 'transparent #63ae3e transparent transparent',
                      borderStyle: 'solid',
                      borderWidth: '0 96px 96px 0',
                      cursor: 'pointer',
                      height: 0,
                      width: 0,
                    }}
                  ></div>
                </div>
                <div css={xStyles}>
                  <XIcon />
                </div>
                <div css={topPart}>
                  <div css={header}>Do you want to</div>
                  <div css={imageWrap}>
                    <div css={stepWrap}>
                      <img src={imageOne} css={imageStyles} alt='' />
                      <div css={title}>Streamline</div>
                      <div css={innerTitle}>Your Trailing Documents</div>
                    </div>
                    <div css={stepWrap}>
                      <img src={imageTwo} css={imageStyles} alt='' />
                      <div css={title}>Reduce</div>
                      <div css={innerTitle}>Fixed Overhead Costs</div>
                    </div>
                    <div css={stepWrap}>
                      <img src={imageThree} css={imageStyles} alt='' />
                      <div css={title}>Improve</div>
                      <div css={innerTitle}>Your Secondary Market Relationships</div>
                    </div>
                  </div>
                </div>
                <div css={bottomPart}>
                  {this.state.submitSuccess ? (
                    <div
                      css={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <div css={showYou}>Thank you</div>
                      <p className='exit-modal-tracking' css={{ fontSize: 16, marginTop: 8 }}>
                        We’ll be in touch shortly. The DocProbe Team
                      </p>
                    </div>
                  ) : (
                    <Fragment>
                      <div css={showYou}>Let us show you how.</div>
                      <div css={{ display: 'flex', alignItems: 'center', marginTop: 13 }}>
                        <input
                          type='email'
                          name='email'
                          css={inputStyles}
                          placeholder='Email'
                          value={this.state.email}
                          onChange={(e) => this.setState({ email: e.target.value })}
                        />
                        <input type='hidden' name='comment' value='exit modal email' />
                        <Button
                          disabled={!validEmail(this.state.email)}
                          styles={buttonStyles}
                          text='Schedule a demo'
                          onClick={this.submitEmail}
                        />
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
