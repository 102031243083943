export const root = {
  position: 'relative',
  borderBottom: '1px solid #3b3f43',
  margin: 'auto',
};

export const contentWrap = {
  paddingLeft: 40,
  '@media(min-width: 768px)': {
    maxWidth: 575,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 780,
  },
};

export const headerStyles = {
  fontSize: 24,
  fontWeight: 300,
  lineHeight: 1.46,
  '@media(min-width: 768px)': {
    fontSize: 27,
  },
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
  span: {
    fontWeight: 500,
  },
};

export const subTextS = {
  marginTop: 20,
  fontSize: 18,
  lineHeight: 1.67,
  '@media(min-width: 768px)': {
    marginTop: 22,
  },
  '@media(min-width: 1260px)': {
    fontSize: 20,
    lineHeight: 1.55,
  },
};

// lines:

export const lineStyles = {
  borderLeft: '1px solid #3b3f43',
  // height: 450,
  height: 'calc(100% + 165px)',
  position: 'absolute',
  bottom: 0,
  left: 20,
  '&:after': {
    borderTop: '1px solid #3b3f43',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    top: 0,
  },
  '@media(min-width: 768px)': {
    left: 30,
    // height: 500,
    height: 'calc(100% + 150px)',
  },
  '@media(min-width: 1260px)': {
    height: 'calc(100% + 110px)',
  },
  '@media(min-width: 1440px)': {
    left: 80,
  },
};
