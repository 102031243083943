/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import { outerWrap, greyWrap, imageStyles, vision, innerWrap, paragraph, show } from './visionForFutureStyles';
import Image from '../../components/ui/Image';

export default function VisionForFuture() {
  return (
    <Fragment>
      <div css={outerWrap}>
        <div css={greyWrap}>
          <Image image='3-d-wall-logo-mock-up-2_dz01gd' alt='' styles={imageStyles} />
        </div>
        <div css={innerWrap}>
          <div css={vision}>
            DocProbe: <br css={show} />
            <span css={{ fontWeight: 500 }}>Vision for the future</span>
          </div>
          <div css={paragraph}>
            Realizing the extent of this industry-wide problem, Madison launched DocProbe---an immediate trailing
            document solution that adapts to the mortgage industry.
            <div css={{ marginTop: 38 }}>
              DocProbe was born as a company of efficiencies: helping lenders grow by taking ownership of their post
              closing-trailing documents process with streamlined operations and innovative technology that promised to
              revolutionize the industry.
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
