/** @jsx jsx */
import { jsx } from '@emotion/core';

import mbaImage from '../../images/partners/mbaImage.png';
import californiaImage from '../../images/partners/californiaImage.png';
import texasImage from '../../images/partners/texasImage.png';
import {
  title,
  outerWrap,
  image,
  paragraph,
  lineStyles,
  californiaImageStyles,
  paragraphTwo,
  tmbaImage,
  paragraphThree,
  horizontalLine,
  imageParSection,
} from './mbaMembershipsStyles';

export default function MbaMemberships() {
  return (
    <div css={{ position: 'relative' }}>
      <div css={horizontalLine} />
      <div css={outerWrap} className='container'>
        <div css={lineStyles} />
        <div css={title}>MBA memberships</div>
        <div css={imageParSection}>
          <img src={mbaImage} css={image} alt='' />
          <div css={paragraph}>
            The Mortgage Bankers Association (MBA) is the national association representing the real estate finance
            industry, an industry that employs more than 280,000 people in virtually every community in the country.
            Headquartered in Washington, D.C., the association works to ensure the continued strength of the nation's
            residential and commercial real estate markets; to expand homeownership and extend access to affordable
            housing to all Americans. MBA promotes fair and ethical lending practices and fosters professional
            excellence among real estate finance employees through a wide range of educational programs and a variety of
            publications. Its membership of over 2,200 companies includes all elements of real estate finance: mortgage
            companies, mortgage brokers, commercial banks, thrifts, Wall Street conduits, life insurance companies and
            others in the mortgage lending field. For additional information, visit MBA's website: www.mba.org.
          </div>
        </div>
        <div css={imageParSection}>
          <img src={californiaImage} css={californiaImageStyles} alt='' />
          <div css={paragraphTwo}>
            The California MBA's top priority is to represent the residential and commercial real estate before the
            State Legislature. Since the financial crisis, the number of bills targeting the mortgage industry has
            increased dramatically. Our role as the voice of California's real estate finance industry has never been
            more important, and our recent successes are a true sign of the strength and value of the California MBA.
          </div>
        </div>
        <div css={imageParSection}>
          <img src={texasImage} css={tmbaImage} alt='' />
          <div css={paragraphThree}>
            Founded in 1917, the Texas Mortgage Bankers Association has provided leadership, legislative advocacy, and
            education to the real estate finance industry. We promote fair and ethical lending practices among real
            estate finance professionals through a wide range of programs. As the oldest and largest statewide MBA, our
            mission is to advance Texas mortgage banking and serve the mutual interests of our members who support
            Texas' annual $100-plus billion mortgage market.
          </div>
        </div>
      </div>
    </div>
  );
}
