/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';

import { root, mainWrap, textWrap, topBlackLine, horizontalLine, mainStuffs } from './stepStyles';
import { RightCaretIcon } from '../../components/ui/icons';
import { isIE, getUA } from 'react-device-detect';
import Image from '../../components/ui/Image';

const contentS = {
  '@media(min-width: 1260px)': {
    marginLeft: 190,
    maxWidth: 1050,
  },
  '@media(min-width: 1440px)': {
    marginLeft: 258,
  },
};

const textStyles = {
  '@media(min-width: 1260px)': {
    maxWidth: 268,
    marginRight: 85,
  },
};

const imageStyles = {
  width: '100%',
  opacity: 0,
  '@media(min-width: 768px)': {
    maxWidth: 550,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 770,
  },
};

export default function StepThree({ onSectionClick }) {
  return (
    <section css={root} className='relative step-three'>
      <div css={horizontalLine} data-line />
      <div className='container' css={mainWrap}>
        <div css={topBlackLine} />
        {/* <SectionCounter styles={{ ' div:nth-of-type(3)': { opacity: '1' } }} onSectionClick={onSectionClick} /> */}
        <div css={topBlackLine} />
        <div css={[mainStuffs, contentS]} data-content>
          <Text />
          <Images />
        </div>
      </div>
    </section>
  );
}
function Images() {
  return (
    <div className='relative' css={{ margin: '50px auto 0' }}>
      <Image image='home/steps/sorting-2_adhbja.png' alt='' styles={imageStyles} />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ?(
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='home/steps/sorting-1_rtxrq1.png' alt='Sorting' />
            <Image image='home/steps/sorting-2_adhbja.png' alt='Sorting' />
          </div>
        ) : (
          <Fragment>
            <Parallax y={[0, 0]}>
              <Image image='home/steps/sorting-1_rtxrq1.png' alt='Sorting' />
            </Parallax>
            <Parallax y={[10, -10]}>
              <Image image='home/steps/sorting-2_adhbja.png' alt='Sorting' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}

function Text() {
  return (
    <div css={[textWrap, textStyles]}>
      <h3>Sorting</h3>
      <p>
        The DocProbe mailroom team opens and sorts through every incoming item, the first step in alleviating the
        expense from your pocket and burden from your staff.
      </p>
      <NavLink to='/cost' className='sorting'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}
