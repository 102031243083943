export const outerWrap = {
  marginBottom: 30,
  '@media(min-width: 1260px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
    maxWidth: 1440,
    margin: '0 auto 106px',
  },
};
export const greyWrap = {
  padding: 33,
  backgroundColor: '#f8f8f8',
  '@media(min-width: 768px)': {
    paddingRight: 0,
    paddingBottom: 0,
  },
  '@media(min-width: 1260px)': {
    padding: 65,
  },
};
export const imageStyles = {
  width: 307,
  '@media(min-width: 768px)': {
    width: 589,
    marginRight: 0,
    marginLeft: 'auto',
  },
  '@media(min-width: 1260px)': {
    width: 589,
  },
};
export const vision = {
  fontSize: 27,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
};
export const innerWrap = {
  padding: '23px 26px 95px 20px',
  '@media(min-width: 768px)': {
    paddingTop: '48px',
    paddingBottom: 0,
  },
  '@media(min-width: 1260px)': {
    marginRight: 105,
    paddingTop: 174,
  },
};
export const paragraph = {
  lineHeight: 1.9,
  marginTop: 20,
  '@media(min-width: 768px)': {
    maxWidth: 526,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 361,
    fontSize: 16,
  },
};
export const show = {
  '@media(min-width: 768px)': {
    display: 'none',
  },
  '@media(min-width: 1260px)': {
    display: 'block',
  },
};
