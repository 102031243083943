/** @jsx jsx */
import { jsx } from '@emotion/core';

import { imageStyles, transparent, innerWrap, deserve, paragraph } from './transparentTrackingStyles';
import Image from '../../components/ui/Image';

export default function TransparentTracking() {
  return (
    <div>
      <div>
        <Image image='secondary-pages/arrow-image_ornlll.png' styles={imageStyles} alt='' />
      </div>
      <div css={innerWrap}>
        <div css={transparent}>
          Transparent tracking, on-call reporting,
          <span css={{ fontWeight: 500 }}> and account representatives</span>
        </div>
        <div css={deserve}>You deserve full transparency about your most important documents.</div>
        <div css={paragraph}>
          With DocProbe, you always know where and when your document is processed—giving you the up-to-the-minute
          information you need for your business. Our commitment to open processes and transparent communication means
          you can be confident in how your documents are retrieved, stored, and forwarded to your investors.
          <div css={{ marginTop: 30 }}>
            Using our LOS integrated software, you can access your document status and generate reports from your own
            portal, keeping your pending documents just one click away, anytime and anywhere. With our dedicated account
            representatives ready to help, your documents—- and all the answers to your processing, shipping, or
            tracking questions—-are always available.
          </div>
        </div>
      </div>
    </div>
  );
}
