/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import Button from '../ui/Button';
import MobileNav from './MobileNav';
import { BurgerMenuIcon, XIcon } from '../ui/icons';
import { DocprobeLogo, CallingIcon, SmallPhone } from '../../content/home/homeIcons';

import {
  headerWrap,
  contactButton,
  imageWrap,
  navItem,
  desktopNavWrap,
  rightSide,
  burgerMenu,
  loginButton,
  loginWrap,
  dropDownWrap,
  handleDropDown,
  headerSticky,
  callButton,
} from './headerStyles';

const benefitsList = [
  { name: 'cost', link: '/cost' },
  { name: 'Efficiency', link: '/efficiency' },
  { name: 'relationships', link: '/relationships' },
];
const meetusList = [
  { name: 'about', link: '/about-us' },
  { name: 'team', link: 'team' },
  { name: 'contact', link: '/contact-us' },
  { name: 'partners', link: '/partners' },
];
const loginList = [
  { name: 'Client Login', link: 'https://app.docprobe.net' },
  { name: 'Portal Login', link: '/portal-login' },
];

const currentPage = { color: '#3b3f43', fontWeight: 600 };

const Header = () => {
  const location = useLocation();
  const [mobileMenuOpen, setMobileMenuOpen]=useState(false);
  const [benefitsOpen, setBenefitsOpen]=useState(false);
  const [meetUsOpen, setMeetUsOpen]=useState(false);
  const [loginOpen, setLoginOpen]=useState(false);
  const currentPath = location.pathname;
  const benefitsPage = currentPath === '/cost' || currentPath === '/efficiency' || currentPath === '/relationships';
  const meetUsPage = currentPath === '/about-us' || currentPath === '/team' || currentPath === '/contact-us' || currentPath ==='/partners';

  return (
    <div css={headerSticky}>
      <header
        css={[
          { position: 'relative', zIndex: 5050, opacity: 0.95 },
          mobileMenuOpen && { backgroundColor: '#f2f2f2' },
        ]}
      >
        <div css={headerWrap} className='container df jcsb aic'>
          <div className='dif aic'>
            <div css={imageWrap}>
              <NavLink
                to='/'
                onClick={() => {
                  mobileMenuOpen && setMobileMenuOpen(false);
                }}
              >
                <DocprobeLogo />
              </NavLink>
            </div>
            <ul css={desktopNavWrap}>
              <li css={[navItem, { width: 81 }]} onClick={() => setBenefitsOpen(!benefitsOpen)}>
                <div css={handleDropDown}>
                  <span css={[benefitsPage && currentPage, { cursor: 'default' }]}>Benefits</span>
                  <DropDown isOpen={benefitsOpen} navList={benefitsList} />
                </div>
              </li>
              <li css={[navItem, { width: 83 }]}>
                <NavLink to='/features' activeStyle={currentPage}>
                  Features
                </NavLink>
              </li>
              <li css={navItem}>
                <NavLink to='/platform' activeStyle={currentPage}>
                  Platform
                </NavLink>
              </li>
              <li css={[navItem, { width: 72 }]} onClick={() => setMeetUsOpen(!meetUsOpen)}>
                <div css={handleDropDown}>
                  <span css={[meetUsPage && currentPage, { cursor: 'default' }]}>Meet Us</span>
                  <DropDown isOpen={meetUsOpen} navList={meetusList} meetusOpen={true}/>
                </div>
              </li>
            </ul>
          </div>
          <div css={rightSide}>
            <a css={callButton} href='tel:8664860554'>
              <CallingIcon className='mobile-phone' />
              <SmallPhone className='desktop-phone' />
              <span>866.486.0554</span>
            </a>
            <a css={loginButton} href="https://app.docprobe.net">
              Log In
            </a>
            {/*<div css={[navItem, { width: 101 }]} onClick={() => setLoginOpen(!loginOpen)}>*/}
            {/*  <div css={handleDropDown}>*/}
            {/*    <span css={{ cursor: 'default' }}>Log In</span>*/}
            {/*    <DropDown isOpen={loginOpen} navList={loginList} loginOpen={true}/>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <Button styles={contactButton} to='/contact-us' text='Contact Us' />
            <div css={burgerMenu} onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
              {mobileMenuOpen ? <XIcon /> : <BurgerMenuIcon />}
            </div>
          </div>
        </div>
      </header>
      <MobileNav mobileMenuOpen={mobileMenuOpen} />
    </div>
  );
}
export default Header;

function DropDown({ navList, loginOpen, meetusOpen }) {
  return (
    <div css={[dropDownWrap, loginOpen && loginWrap, meetusOpen && { width: 145 }]}>
      <ul>
        {navList.map((item, i) => (
          <li key={i}>
            <NavLink to={item.link}>{item.name}</NavLink>
          </li>
        ))}
      </ul>
    </div>
  );
}
