/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, {Fragment, useEffect, useMemo, useState} from "react";
import {
  button,
  buttonGroup, 
  container,
  CustomTooltip,
  disabledButton,
  errorText,
  fileReceived,
  fileReceivedWrapper,
  headerText,
  descriptionText,
  infoText, 
  innerWrapper,
  inputGroup,
  label, 
  outerWrapper,
  submitButton,
} from "./GeneralUploadStyles";
import { Paper, Popper, TextField } from "@material-ui/core";
import Icon, { IconType } from "../../components/ui/icons/Icon";
import { UploadModal } from "./UploadModal";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Autocomplete } from "@material-ui/lab";
import { CustomPaper, Tzvi, useStyles } from "./LocationFilterStyles";
import { isValid } from "date-fns";
import { Check, Close } from "@material-ui/icons";
import OutsideClickHandler from "react-outside-click-handler";
import axios from "axios";
import { TitlePortalContext } from "./TitlePortalContext";

const mainDocumentTypeOptions = [
  "Mortgage",
  "Policy",
]
const otherDocumentTypeOptions = [
  "Affidavit Of Correction",
  "Affixation Affidavit",
  "Assignment",
  "CEMA",
  "Deed",
  "Endorsement",
  "HECM",
  "Modification Agreement",
  "Modification Endorsement",
  "POA",
  "Policy To Second Mortgage",
  "Second Mortgage",
  "Subordination",
  "UCC",
];

const LenderCustomPaper = ({children, ...props}) => {
  return (
    <Paper
      {...props}
      style={{
        borderRadius: 2,
        boxShadow: '0 5px 15px 0 rgba(181, 182, 180, 0.16)',
        border: 'solid 1px #e2e8f1',
      }}
    >
      <Fragment>
        <div
          css={{fontSize: 12, fontWeight:300, borderBottom: 'solid 1px #e2e8f1', padding: '18px 10px 8px'}}
          // defers blur event till after onClick, allowing the mailto tag to be clickable 
          onMouseDown={event => event.preventDefault()} 
        >
          For lenders not on this list, email 
          <br />
          <a 
            css={{fontWeight: 700, textDecoration: 'underline'}} 
            href={"mailto:PortalSupport@DocProbe.net"}
            target='_blank'
            rel='noopener noreferrer'
          >
            PortalSupport@DocProbe.net
          </a>
        </div>
        {children}
      </Fragment>
    </Paper>
  );
};

const CustomPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 200, marginTop: 4, position: "relative", zIndex: 10000 }}
    />
  );
};

const SubmitState = {
  Initial: "INITIAL",
  Loading: "LOADING",
  Success: "SUCCESS",
  Error: "ERROR",
};

export default function GeneralUpload({continueUploading, setGeneralUpload, setUploadSuccessful}) {
  const [attemptedExit, setAttemptedExit] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [lenderLabelText, setLenderLabelText] = useState("Select Lender");
  const [docTypeLabelText, setDocTypeLabelText] = useState("Select Document Type");
  const [loanNumberLabelText, setLoanNumberLabelText] = useState("Enter Loan Number");
  const [borrowerLabelText, setBorrowerLabelText] = useState("Enter Borrower Name");
  
  const [clientOptions, setClientOptions] = useState([]);
  const [submitState, setSubmitState] = useState(SubmitState.Initial);
  const [userInput, setUserInput] = useState({
    loanNumber: "",
    docType: "",
    client: {name: "", id: null},
    borrowerName: null,
    dateFunded: null,
    file: null,
  });

  const { token } = React.useContext(TitlePortalContext);

  const isDisabled = useMemo(() => 
    !userInput.client.id || !userInput.loanNumber || !userInput.docType || !userInput.file || isSubmitting,
    [userInput, isSubmitting],
  );
  
  const classes = useStyles();

  const submit = async () => {
    if(isDisabled) return;
    setSubmitState(SubmitState.Loading);
    setIsSubmitting(true);
    try {

      var borrowerClause = userInput.borrowerName ? `&borrowerName=${userInput.borrowerName}`  : '';
      var dateFundedClause =  userInput.dateFunded ? `&fundingDate=${userInput.dateFunded.toISOString()}` : ''
      const url = `${process.env.REACT_APP_API_URL}/api/UploadExternalDocuments?token=${token}&loanNumber=${userInput.loanNumber}&docType=${userInput.docType}&clientId=${userInput.client.id}${borrowerClause}${dateFundedClause}`
    
      let form = new FormData();
      form.append("documents", userInput.file);
      
      await axios.post(url, form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setSubmitState(SubmitState.Success);
    } 
    catch (err) {
      console.error(err);
      setSubmitState(SubmitState.Error);
    }
    setIsSubmitting(false);
    setUploadSuccessful(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/GetTitlePortalClients?token=${encodeURIComponent(token)}`
      );

      setClientOptions(
        data.map((d) => ({
            id: d.item1,
            name: d.item2
        }))
      );

    };

    fetchData();
  }, [token]);

  useEffect(() => {
    if(continueUploading) {
      setSubmitState(SubmitState.Initial);
    }    
  },[continueUploading]);
  
  return (
    <Fragment>
      {attemptedExit ? (
        <Fragment>
          {(userInput.loanNumber || userInput.docType || userInput.client.id || userInput.borrowerName || userInput.dateFunded || userInput.file) ? ( 
            <div css={container}>
              <div css={outerWrapper}>
                <div css={innerWrapper}>
                  <div css={{fontSize: 20, color: '#1a173b'}}>
                    Are you sure you want to <span css={{fontWeight: 700}}>leave</span> this form?
                  </div>
                  <div css={{fontSize: 15, fontWeight: 300, color: '#354052'}}>
                    Any data and documents not submitted will be lost
                  </div>
                </div>
                <div css={{marginLeft: -30}}>
                  <button
                    css={[button, {color: '#354052'}]}
                    onClick={() => setAttemptedExit(false)}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={() => setGeneralUpload(false)}
                    css={[button, submitButton, {width: 103}]}>Leave Form</button>
                </div>
              </div>
            </div>
          ) : (
            (() => setGeneralUpload(false))()
          )}
        </Fragment>
      ) : (
        <OutsideClickHandler
          onOutsideClick={(e) => {

            if(e.target.getAttribute("class") === "css-iue7uu-LenderCustomPaper"
                || e.target.getAttribute("class") === "MuiAutocomplete-option"
                || e.target.getAttribute("class") === "css-1xvy284-LenderCustomPaper"
                || e.target.getAttribute("class") === "MuiAutocomplete-listbox"
                || e.target.getAttribute("class") ===  null) {
              return;
            }
            setAttemptedExit(true);
          }}
        >
          <div css={container}>
            <span css={headerText}>Upload Anything</span>
            <div css={descriptionText}>If your loan isn't listed yet, use this form to upload your trailing documents.</div>
            <span className={classes.closeIcon} onClick={() => setAttemptedExit(true)}>
              <Close />
            </span>
            <div css={inputGroup}>
              <div>
                <div css={label}>Lender</div>
                <Tzvi>
                  <Autocomplete
                    autoComplete={true}
                    loading={!clientOptions.length}
                    size="small"
                    onChange={(event, value, reason) => {
                      setUserInput(reason === "clear" ?{...userInput, client: {name: "", id: null}}:{...userInput, client: value});
                    }}
                    style={{width: 200}}
                    value={userInput.client}
                    onFocus={() => setLenderLabelText("")}
                    onBlur={() => setLenderLabelText(userInput.client.id ? "" : "Select Lender")}
                    options={clientOptions}
                    getOptionLabel={(option) => option.name}
                    PaperComponent={LenderCustomPaper}
                    PopperComponent={CustomPopper}
                    popupIcon={<KeyboardArrowDownIcon className="icon"/>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{...params.InputProps}}
                        InputLabelProps={{shrink: false, style: {color: '#686868'}}}
                        className={classes.root}
                        variant="outlined"
                        label={lenderLabelText}
                     />
                    )}
                  />
                </Tzvi>
              </div>
              <div>
                <div css={label}>Document Type</div>
                <Tzvi>
                  <Autocomplete
                    autoComplete={true}
                    size="small"
                    onChange={(event, value, reason) => {
                      setUserInput(reason === "clear" ? {...userInput, docType: ""} : {...userInput, docType: value});
                    }}
                    style={{width: 200}}
                    value={userInput.docType}
                    onFocus={()=> setDocTypeLabelText("")}
                    onBlur={() => setDocTypeLabelText(userInput.docType ? "" : "Select Document Type")}
                    options={[...mainDocumentTypeOptions, ...otherDocumentTypeOptions]}
                    renderOption={(option) => (                 
                        <span style={{ fontWeight: mainDocumentTypeOptions.includes(option) ? 700 : 400}}>{option}</span>
                    )}
                    PaperComponent={CustomPaper}
                    PopperComponent={CustomPopper}
                    popupIcon={<KeyboardArrowDownIcon className="icon"/>}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{...params.InputProps}}
                        InputLabelProps={{shrink: false, style: {color: '#686868'}}}
                        className={classes.root}
                        variant="outlined"
                        label={docTypeLabelText}
                      />
                    )}
                  />
                </Tzvi>
              </div>
              <div>
                <div css={label}>Loan Number</div>
                <TextField
                  onChange={e => setUserInput({...userInput, loanNumber: e.target.value})}
                  onFocus={()=> setLoanNumberLabelText("")}
                  onBlur={() => setLoanNumberLabelText(userInput.loanNumber ? "" : "Enter Loan Number")}
                  style={{width: 200}}
                  value={userInput.loanNumber}
                  label={loanNumberLabelText}
                  className={classes.root}
                  InputProps={{style: {marginTop: 8}}}
                  InputLabelProps={{shrink: false, style: {top: -10.5, color: '#686868'}}}
                />
              </div>
              <div>
                <div css={label}>Borrower Name (Optional)</div>
                <TextField
                  label={borrowerLabelText}
                  size="small"
                  onChange={e => setUserInput({...userInput, borrowerName: e.target.value})}
                  onFocus={()=> setBorrowerLabelText("")}
                  onBlur={() => setBorrowerLabelText(userInput.borrowerName ? "" : "Enter Borrower Name")}
                  style={{width: 200}}
                  value={userInput.borrowerName}
                  className={classes.root}
                  InputProps={{style: {marginTop: 11}}}
                  InputLabelProps={{shrink: false, style: {top: -6.5, color: '#686868'}}}
                />
              </div>
              <div>
                <div css={label}>Funding Date (Optional)</div>
                <TextField
                  type="date"
                  size="small"
                  style={{width: 200}}
                  variant="outlined"
                  className={classes.root}
                  value={userInput.dateFunded ? userInput.dateFunded.toISOString().slice(0, 10) : ''}
                  onChange={e => {
                    if (isValid(new Date(e.target.value))) {
                      setUserInput({...userInput, dateFunded: new Date(e.target.value)});
                    }
                  }}
                />
              </div>
            </div>
            <div css={{height: 215}}>
              {fileUploaded ? (
                <div css={fileReceivedWrapper}>
                  <div css={fileReceived}>
                    <Check />
                    <span>We got your file!</span>
                  </div>
                </div>
              ):(
                <UploadModal
                  generalUpload={true}
                  uploadFile={(uploadFile) => {
                    setFileUploaded(true);
                    setUserInput({...userInput, file: uploadFile});
                  }}
                />
              )}
            </div>
            <div css={{height:32, display: 'flex', alignItems: 'flex-end', justifyContent: 'space-between'}}>
              <span css={infoText}>One document uploaded at a time</span>
              {submitState === SubmitState.Error && (
                <span css={errorText}>Error! Upload Failed</span>
              )}
            </div>
            <div css={buttonGroup}>
              <button 
                onClick={() => setAttemptedExit(true)}
                css={[button,{color: '#023459', fontSize: 15}]}
              >
                Close
              </button>
              <CustomTooltip
                arrow
                title={isDisabled && submitState === SubmitState.Initial ? 'Please attach a file and enter all required information' :''}
              >
                <button 
                  onClick={submit}
                  css={[button, {fontSize: 15}, (isDisabled && submitState === SubmitState.Initial) ? disabledButton : submitButton]}
                >
                  {submitState === SubmitState.Initial ? 'Submit' : <Icon icon={IconType.submitSpinner}/>}
                </button>
              </CustomTooltip>
            </div>
          </div>
        </OutsideClickHandler>
      )}
    </Fragment>
  );
}
