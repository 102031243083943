//#region imports
/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Button, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import { ArrowDownward, ArrowUpward, Search } from '@material-ui/icons';
import axios from 'axios';
import { format } from 'date-fns';
import orderBy from 'lodash/orderBy';
import pick from 'lodash/pick';
import queryString from 'query-string';
import React, { Fragment, useEffect, useState } from 'react';

import PageHeader from '../components/layout/PageHeader';
import { Filter } from '../components/ui/Filter/Filter';
import { IconType } from '../components/ui/icons/Icon';
import NoData from '../components/ui/NoData';
import { FilterBreadcrumbs } from '../content/titlePortal/Breadcrumb';
import { DataTableRow } from '../content/titlePortal/DataTableRow';
import { FilterSelection } from '../content/titlePortal/FilterSelection';
import { LocationFilter } from '../content/titlePortal/LocationFilter';
import { useStyles } from '../content/titlePortal/titleCompaniesStyle';
import { TitlePortalContext, TitlePortalProvider } from '../content/titlePortal/TitlePortalContext';
import { formatAddressSecondLine } from '../Utils';
import GeneralUploadModal from '../content/titlePortal/GeneralUploadModal';
import ViewRejectedDocument from '../content/titlePortal/ViewRejectedDocument';
//#endregion

const propertiesToSearch = ['borrower', 'loanNumber', 'address', 'addressLine2', 'county', 'dateFunded', 'titleNumber'];
const titlePortalDataFuncEndpointCode = `AXNY5L4/Rg3bo37xnip/jHwDGY6C3PyahSLE5K5MK/ymslWcSxeOKw==`;

const search = (data, searchValue, properties) =>
  data.filter((d) =>
    Object.values(pick(d, properties)).find((v) => v !== null && v.toLowerCase().includes(searchValue.toLowerCase())),
  );
const searchDocType = (data, searchValue) =>
  data.filter(({ missingDocs }) =>
    missingDocs.some(({ docType }) => docType.toLowerCase().includes(searchValue.toLowerCase())),
  );
const sort = (data, sortAsc) => {
  return orderBy(data, [(d) => Date.parse(d.dateFunded), (d) => d.titleNumber], [sortAsc ? 'asc' : 'desc', 'asc']);
};
const searchAndSort = (data, searchValue, sortAsc) =>
  new Set([
    ...sort(search(data, searchValue, propertiesToSearch), sortAsc),
    ...sort(searchDocType(data, searchValue), sortAsc),
  ]);

export default function TitleCompanyPortal({ location: { search } }) {
  const { token, emailId } = queryString.parse(search);
  return (
    <TitlePortalProvider token={token} emailId={emailId}>
      <TitleCompanyPortalFunc />
    </TitlePortalProvider>
  );
}

function TitleCompanyPortalFunc({ search }) {
  const classes = useStyles();
  const [generalUpload, setGeneralUpload] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [fetchDataError, setFetchDataError] = useState('');
  const [data, setData] = useState([]);
  const [sortAsc, setSortAsc] = useState(true);
  const [isDisabled, setIsDisabled] = useState(true);

  const [filter, setFilter] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedDocTypes, setSelectedDocTypes] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedCounty, setSelectedCounty] = useState([]);

  const [tempSelectedClients, setTempSelectedClients] = useState([]);
  const [tempSelectedDocTypes, setTempSelectedDocTypes] = useState([]);
  const [tempSelectedState, setTempSelectedState] = useState([]);
  const [tempSelectedCounty, setTempSelectedCounty] = useState([]);

  const { searchValueState, token, emailId } = React.useContext(TitlePortalContext);
  const [searchValue, setSearchValue] = searchValueState;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/GetTitlePortalData?token=${encodeURIComponent(token)}&code=${titlePortalDataFuncEndpointCode}`,
        );
        setData(
          data.map((d) => ({
            ...d,
            addressLine2: formatAddressSecondLine(d.city, d.state, d.zip),
            dateFunded: format(Date.parse(d.dateFunded.toString()), 'MM/dd/yy'),
          })),
        );
      } catch (error) {
        console.error(error);
        setFetchDataError(error);
      }
      setLoadingData(false);
    };

    fetchData();
  }, [token]);

  var locationList = [];
  data.map(
    (d) =>
      !locationList.some((location) => location.county === d.county) &&
      locationList.push({
        county: d.county,
        state: d.state,
      }),
  );

  var docTypeList = [
    ...new Set(data.map((d) => d.missingDocs.map((i) => i.docType)).reduce((a, b) => a.concat(b), [])),
  ].sort();
  var clientList = [...new Set(data.map((d) => d.clientName))].sort();

  const filterData = () => {
    var conditions = [];
    if (selectedState.length > 0) {
      conditions.push(function (item) {
        return selectedState.includes(item.state);
      });
    }
    if (selectedCounty.length > 0) {
      conditions.push(function (item) {
        return selectedCounty.includes(item.county);
      });
    }
    if (selectedDocTypes.length > 0) {
      conditions.push(function (item) {
        return item.missingDocs.some((r) => selectedDocTypes.includes(r.docType));
      });
    }
    if (selectedClients.length > 0) {
      conditions.push(function (item) {
        return selectedClients.includes(item.clientName);
      });
    }

    return data.filter((d) => conditions.every((c) => c(d)));
  };

  const hasFilters =
    selectedState.length > 0 || selectedCounty.length > 0 || selectedDocTypes.length > 0 || selectedClients.length > 0;

  const dataToRender = (() => {
    return searchValue
      ? searchAndSort(filter ? filterData() : data, searchValue, sortAsc)
      : sort(filter ? filterData() : data, sortAsc);
  })();

  const missingDocsCount = dataToRender.reduce((prev, curr) => prev + curr.missingDocs.length, 0);

  return (
    <div css={{ minHeight: '100vh' }}>
      {generalUpload && <GeneralUploadModal setGeneralUpload={setGeneralUpload} />}
      <Fragment>
        <PageHeader />
        <div className={classes.pageWrap}>
          <div className={classes.contentWrap}>
            <div className={classes.header}>
              <div className={classes.headerTop}>
                <div className={classes.headerText}>Missing Documents</div>
                <div>
                  <div className={classes.search}>
                    <InputBase
                      placeholder='Search'
                      classes={{
                        root: classes.inputRoot,
                        input: classes.inputInput,
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <div className={classes.searchIcon}>
                      <Search />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.headerBottomFull}>
                <div className={classes.headerBottom}>
                  <div className={classes.filterGroup}>
                    <Filter
                      label={'Location'}
                      hasSelections={tempSelectedState.length > 0}
                      styles={{ marginRight: 24 }}
                      handleClear={() => {
                        setTempSelectedState([]);
                        setTempSelectedCounty([]);
                        setIsDisabled(false);
                      }}
                      tooltip={
                        <LocationFilter
                          locationList={locationList}
                          handleStateChange={(event, value) => {
                            setTempSelectedState(value);
                            setTempSelectedCounty([
                              ...new Set(
                                locationList
                                  .filter((l) => value.includes(l.state) && tempSelectedCounty.includes(l.county))
                                  .map((v) => v.county),
                              ),
                            ]);
                            setIsDisabled(false);
                          }}
                          selectedStates={tempSelectedState}
                          handleCountyChange={(event, value) => {
                            setTempSelectedCounty([...new Set(value.map((v) => v.county))]);
                            setIsDisabled(false);
                          }}
                          selectedCounties={tempSelectedCounty}
                        />
                      }
                    />
                    <Filter
                      label={'Document Type'}
                      hasSelections={tempSelectedDocTypes.length > 0}
                      styles={{ marginRight: 24 }}
                      handleClear={() => {
                        setTempSelectedDocTypes([]);
                        setIsDisabled(false);
                      }}
                      tooltip={
                        <FilterSelection
                          handleChange={(event, value) => {
                            setTempSelectedDocTypes(value);
                            setIsDisabled(false);
                          }}
                          optionsList={docTypeList}
                          selectedList={tempSelectedDocTypes}
                        />
                      }
                    />
                    <Filter
                      label={'Lender'}
                      hasSelections={tempSelectedClients.length > 0}
                      handleClear={() => {
                        setTempSelectedClients([]);
                        setIsDisabled(false);
                      }}
                      tooltip={
                        <FilterSelection
                          handleChange={(event, value) => {
                            setTempSelectedClients(value);
                            setIsDisabled(false);
                          }}
                          optionsList={clientList}
                          selectedList={tempSelectedClients}
                        />
                      }
                    />
                  </div>
                  <div className={classes.buttonGroup}>
                    <Button
                      className={classes.filterButton}
                      disableRipple
                      onClick={() => {
                        setSelectedClients(tempSelectedClients);
                        setSelectedDocTypes(tempSelectedDocTypes);
                        setSelectedState(tempSelectedState);
                        setSelectedCounty(tempSelectedCounty);
                        setFilter(true);
                        setIsDisabled(true);
                      }}
                      disabled={isDisabled}
                    >
                      Apply Filter
                    </Button>
                    <Button
                      className={classes.clearButton}
                      disableRipple
                      onClick={() => {
                        setFilter(false);
                        setSelectedClients([]);
                        setSelectedDocTypes([]);
                        setSelectedState([]);
                        setSelectedCounty([]);
                        setTempSelectedClients([]);
                        setTempSelectedDocTypes([]);
                        setTempSelectedState([]);
                        setTempSelectedCounty([]);
                      }}
                      disabled={!hasFilters}
                    >
                      Clear All
                    </Button>
                  </div>
                </div>
                <div className={classes.uploadGroup}>
                  <div className={classes.uploadText}> Don't see your loan? </div>
                  <button className={classes.uploadTextButton} onClick={() => setGeneralUpload(true)}>
                    Upload Anything
                  </button>
                </div>
              </div>
            </div>
            {filter && (
              <div className={classes.filterBreadcrumbs}>
                <FilterBreadcrumbs
                  selectedStates={selectedState}
                  selectedLocations={locationList.filter(
                    (l) => selectedState.includes(l.state) && selectedCounty.includes(l.county),
                  )}
                  selectedDocTypes={selectedDocTypes}
                  selectedClients={selectedClients}
                />
              </div>
            )}
            {/* <NoData isLoader={true} icon={IconType.HoverFaceIcon} message='Loading data...' /> */}
            <TableContainer style={{ maxHeight: 'calc(100vh - 364px)', overflow: 'auto' }}>
              <Table stickyHeader className={classes.root}>
                <TableHead>
                  <TableRow className={classes.rowHeader}>
                    <TableCell></TableCell>
                    <TableCell>Title Id</TableCell>
                    <TableCell>Loan #</TableCell>
                    <TableCell onClick={() => setSortAsc(!sortAsc)}>
                      <div className={classes.titleCell}>
                        <div className={classes.dateFunded}>Date Funded </div>
                        <div className={classes.arrowIcon}>{sortAsc ? <ArrowUpward /> : <ArrowDownward />}</div>
                      </div>
                    </TableCell>
                    <TableCell>Borrower</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell>Lender</TableCell>
                    <TableCell>Documents Missing ({missingDocsCount})</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                  {dataToRender.map((data) => (
                    <DataTableRow key={data.loanId} data={data} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {(loadingData || token == 5151) && (
              <NoData isLoader={true} icon={IconType.HoverFaceIcon} message='Loading data...' />
            )}
            {fetchDataError && (
              <NoData
                icon={IconType.ErrorFaceIcon}
                message='Something went wrong'
                subMessage={fetchDataError.toString()}
              />
            )}
            {!loadingData && !fetchDataError && data.length === 0 && (
              <NoData
                icon={IconType.SuccessFaceIcon}
                message='Great Job! No Outstanding Documents'
                subMessage='Please check back soon'
              />
            )}
            {data.length > 0 && dataToRender.size === 0 && (
              <NoData
                message='No documents matching your filters'
                subMessage='Please clear your filters to see outstanding documents'
              />
            )}
          </div>
        </div>
      </Fragment>
    </div>
  );
}
