import * as React from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";

export function FilterBreadcrumbs({
  selectedStates,
  selectedLocations,
  selectedDocTypes,
  selectedClients,
}) {
  const getCounties = (state) => {
    return selectedLocations
      .filter((l) => l.state === state)
      .map((l) => l.county);
  };

  const getStateList = (state) => 
    getCounties(state).length > 0
      ? `${state} - ${getCounties(state).join("; ")}`
      : `${state}`;
  ;
  
  const joinSelection = (selection) => {
    return selection.map((c, i, arr) => (
    <span><b>{c}</b> {arr.length - 1 !== i ? 'and' : ''} </span>
  ))}

  return (
    <div role="presentation">
      <Breadcrumbs>
        {selectedStates.length > 0 && (
          <span>
            State: {joinSelection(selectedStates.map((s) => getStateList(s)))}
          </span>
        )}
        {selectedDocTypes.length > 0 && (
          <span>
            Document Type: {joinSelection(selectedDocTypes)}
          </span>
        )}
        {selectedClients.length > 0 && (
          <span>Lender: {joinSelection(selectedClients)}</span>
        )}
      </Breadcrumbs>
    </div>
  );
}
