/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import Button from '../components/ui/Button';

const outerWrap = {
  padding: '80px 32px',
  margin: '0 auto',
  maxWidth: 1440,
  '@media(min-width: 768px)': {
    padding: '100px 0 217px 177px',
  },
};
const fourOhFour = {
  color: '#6aaa37',
  fontSize: 50,
  fontWeight: 500,
  '@media(min-width: 768px)': {
    fontSize: 67,
  },
};
const statement = {
  fontSize: 18,
  fontWeight: 300,
  marginTop: 24,
  lineHeight: 1.4,
  '@media(min-width: 768px)': {
    fontSize: 25,
    width: 510,
  },
};
const buttonStyles = {
  marginTop: 38,
};
export default function Page404() {
  return (
    <Fragment>
      <Helmet>
        <title>404 Page | DocProbe</title>
        <meta
          name='description'
          content='404 page'
        />
      </Helmet>
      <div css={outerWrap}>
        <div css={fourOhFour}>404 Error</div>
        <div css={statement}>
          The page you were looking for does not exist. You may have mistyped the address or the page may have been
          moved.
        </div>
        <div css={buttonStyles}>
          <Button
            text='Go Back'
            styles={{
              backgroundColor: '#64ae3e',
              color: 'white',
              border: 'none',
            }}
          />
        </div>
      </div>
    </Fragment>
  );
}
