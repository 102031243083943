/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import axios from 'axios';

import { request, buttonStyles, outerWrap, email, innerWrap, emailButton } from './requestDemoStyles';

import Button from '../../components/ui/Button';

export default class RequestDemo extends Component {
  state = {
    email: '',
    submitted: false,
  };
  handleSubmit = async () => {
    await axios.post(
      'https://prod-07.westus.logic.azure.com:443/workflows/d157fe03324e4311b5c730e1e15f58fe/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OcNvr7Amuxz6khzQUDiPM7ov0fbwQzGgn2zsh0b2mwU',
      { email: this.state.email },
    );
    this.setState({ email: '', submitted: true });
  };
  render() {
    return (
      <div css={outerWrap}>
        {this.state.submitted ? (
          <div css={request}>We'll be in touch soon</div>
        ) : (
          <Fragment>
            <div css={request}>Request a demo</div>
            <div css={innerWrap}>
              <div css={emailButton}>
                <input
                  type='email'
                  required
                  css={email}
                  placeholder='Your email address'
                  value={this.state.email}
                  onChange={e => this.setState({ email: e.target.value })}
                />
                <Button styles={buttonStyles} text='Submit' onClick={this.handleSubmit} />
              </div>
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}
