export const mainWrap = {
  backgroundColor: '#393f43',
  backgroundImage: `url(https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1260/home/building-image_z3qkie.png)`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  height: '100%',
  position: 'relative',
  zIndex: 5001,
  '@media(min-width: 1024px)': {
    backgroundImage: `url(https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1260/home/building-image_z3qkie.png)`,
  },
  '@media(min-width: 1260px)': {
    backgroundImage: `url(https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto/home/building-image_z3qkie.png)`,
  },
};

export const footerWrap = {
  padding: '25px 16px 0',
  color: 'white',
  fontWeight: 600,
  maxWidth: '1172px',
  margin: '0 auto',
  a: { color: 'white' },
  '@media(min-width: 768px)': {
    padding: '42px 26px 0',
  },
  '@media(min-width: 1024px)': {
    paddingTop: 90,
  },
};

export const logoWrap = {
  fontSize: 50,
  fontWeight: 600,
  color: '#6baa28',
  lineHeight: 1,
  maxWidth: 85,
  '@media(min-width: 768px)': {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '16%',
    maxHeight: 86,
    maxWidth: 86,
  },
};
export const topHalf = {
  borderBottom: '1px solid #8c8f91',
  paddingBottom: 32,
  '@media(min-width: 768px)': {
    paddingBottom: 40,
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media(min-width: 1024px)': {
    paddingBottom: 60,
  },
};
export const menuWrap = {
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: 320,
  justifyContent: 'space-between',
  '@media(min-width: 768px)': {
    display: 'inline-flex',
  },
  '@media(min-width: 1260px)': {
    flexWrap: 'nowrap',
    maxWidth: 590,
    width: '100%',
  },
};

export const menuSection = {
  marginTop: 32,
  width: 129,
  '> div': {
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 3,
    marginTop: 16,
    textTransform: 'uppercase',
  },
  p: {
    color: '#a3a6a9',
    paddingBottom: 8,
    letterSpacing: 1.25,
  },
  '@media(min-width: 768px)': {
    marginTop: 0,
    marginBottom: 32,
  },
};

export const gray = {
  a: { color: '#a3a6a9' },
};

export const contactsWrap = {
  marginTop: 38,
  '> *': {
    // display: 'inline-block',
    width: '49%',
    verticalAlign: 'top',
    marginTop: 16,
    color: 'white',
    fontWeight: 'normal',
    letterSpacing: 1.25,
  },
  '@media(min-width: 768px)': {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 0,
    width:190,
    '> *': {
      display: 'block',
      width: '100%',
      marginTop: 0,
    },
  },
};
export const innerContacts = {
  display: 'inline-flex',
  alignItems: 'flex-end',
  width: 306,
  justifyContent: 'space-between',
  '@media(min-width: 768px)': {
    display: 'block',
    marginTop: 18,
  },
};
export const iconStyles = {
  display: 'flex',
  alignItems: 'center',
  width: 17,
  height: 17,
  marginLeft: 60,
  'img:not(:nth-of-type)': {
    marginLeft: 20,
  },
  '@media(min-width: 768px)': {
    marginTop: 23,
    marginLeft: 0,
  },
};
export const bottomHalf = {
  paddingTop: 8,
  '@media(min-width: 1024px)': {
    paddingTop: 26,
  },
};

export const logosWrap = {
  paddingBottom: 30,
  flexWrap: 'wrap',
  display: 'flex',
  justifyContent: 'space-between',
  '> *': {
    width: '48%',
    marginTop: 32,
    maxWidth: 150,
  },
  '@media(min-width: 768px)': {
    paddingBottom: 45,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      maxWidth: 120,
      width: '100%',
    },
    img: {
      maxWidth: 120,
      width: '100%',
    },
  },
  '@media(min-width: 1260px)': {
    svg: {
      maxWidth: 170,
    },
    img: {
      maxWidth: 170,
    },
  },
};
export const mobileMessage = {
  fontWeight: 'normal',
  width: 120,
  '&&': {
    '@media(min-width: 768px)': {
      display: 'none',
    },
  },
};

export const tabletMessage = {
  fontWeight: 'normal',
  fontSize: 18,
  lineHeight: 1.67,
  width: 444,
  marginTop: 24,
  '&&': {
    '@media(max-width: 767px)': {
      display: 'none',
    },
  },
};

export const finePrintWrap = {
  backgroundColor: '#3b3f43',
};

export const finePrintInner = {
  height: 105,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  '@media(min-width: 768px)': {
    padding: '0 26px',
    margin: 0,
    height: 47,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    maxWidth: 1172,
  },
  a: {
    color: 'white',
    textTransform: 'uppercase',
    fontSize: 12,
    fontWeight: 500,
    letterSpacing: 1.2,
    marginRight: 25,
    width: 115,
  },
  '@media(min-width: 1260px)': {
    height: 56,
    margin: '0 auto',
  },
};
export const copyright = {
  color: 'white',
  fontSize: 14,
  opacity: 0.5,
  paddingTop: 18,
  fontWeight: 500,
  '@media(min-width: 768px)': {
    paddingTop: 0,
  },
};

export const linkedInButton = {
  alignItems: 'center',
  backgroundColor: '#2877B5',
  borderRadius: 4,
  display: 'flex',
  height: 24,
  padding: '0 8px 0 6px',
  width: 103,
  span: {
    fontSize: 11,
    lineHeight: 1,
    marginTop: 2,
    marginLeft: 10,
  },
  '@media(min-width: 768px)': {
    marginTop: 18,
  },
};
