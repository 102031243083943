/** @jsx jsx */
import { jsx } from '@emotion/core';

import { root, followUp, realTime, outerWrap, imagesStyles, image, centerText } from './followUpTrackingStyles';
import Image from '../../components/ui/Image';

export default function FollowUpTracking() {
  return (
    <div css={root}>
      <div css={outerWrap}>
        <div css={centerText}>
          <div css={followUp}>
            Follow Up{' '}
            <span
              css={{
                '@media(min-width: 1260px)': {
                  fontWeight: 500,
                },
              }}
            >
              Tracking
            </span>
          </div>
          <div css={realTime}>
            Real time viewing of every call and email from our team and system as we diligently retrieve your documents
            during the well-oiled steps of our retrieval process.
          </div>
        </div>
        <div css={imagesStyles}>
          <Image image='secondary-pages/1_Follow_up_tracking_n55ewd.png' alt='' />
          <div css={image}>
            <Image image='secondary-pages/2_Follow_up_tracking_hbi8bo.png' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
