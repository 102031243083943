/** @jsx jsx */
import { jsx, keyframes } from "@emotion/core";
import Icon, { IconType } from "../icons/Icon";

const rotate = keyframes`
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
`;
const spinner = { animation: `${rotate} 2s infinite linear` };

const circleStyles = {
  width: 40,
  height: 40,
  borderRadius: "50%",
  backgroundColor: "#426d61",
  display: "flex",
  justifyContent: "center",
  alignItems: "center"
};
export default function IconCircle({ icon, styleOverrides }) {
  return (
    <div
      css={[
        circleStyles,
        styleOverrides,
        icon === IconType.ProcessingIcon && spinner
      ]}
    >
      <Icon icon={icon} />
    </div>
  );
}
