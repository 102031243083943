/** @jsx jsx */
import { jsx } from "@emotion/core";
import { Fragment } from "react";
import { createTheme } from "@material-ui/core";
import Header from "./Header";
import Footer from "./Footer";
import FloatingContact from "../../content/home/FloatingContact";
import ExitPopUp from "../../content/home/ExitPopUp";

// Need this spacing on top becasue of fixed header not in flow
const spacingTop = {
  marginTop: 75,
  "@media(min-width: 768px)": {
    marginTop: 85,
  },
  "@media(min-width: 1260px)": {
    marginTop: 120,
  },
};

export const theme = createTheme({
  palette: {
    primary: {
      main: "#63ad3e",
      light: "#89CE68",
      dark: "#479223",
    },
    secondary: {
      main: "#29a3aa",
    },
  },
  typography: {
    fontWeightMedium: 700,
    button: {
      fontWeight: 600,
      fontSize: 12,
      letterSpacing: "1",
    },
  },
});

export default function Layout({ children, ...props }) {
  const displayForm =
    props.location.pathname !== "/contact-us" &&
    props.location.pathname !== "/dinner-on-us" &&
    props.location.pathname !== "/dinner-on-us/";
  return (
    <Fragment>
      <Header {...props} />
      {displayForm && <ExitPopUp />}
      {displayForm && <FloatingContact />}
      <div css={spacingTop}>{children}</div>
      <Footer />
    </Fragment>
  );
}
