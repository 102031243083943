export const outerWrap = {
  backgroundColor: '#3b3f43',
  position: 'relative',
  maxHeight: 1235,
  marginBottom: 75,
  '&:before': {
    borderTop: '1px solid black',
    position: 'absolute',
    content: '""',
    right: 0,
    bottom: -75,
    left: 0,
    width: '100%',
    '@media(min-width: 768px)': {
      display: 'none',
    },
  },
  '@media(min-width: 768px)': {
    marginBottom: 50,
  },
  '@media(min-width: 1260px)': {
    marginTop: 167,
  },
};
export const contentWrap = {
  marginTop: 64,
  padding: '69px 19px 30px 19px',
  '@media(min-width: 768px)': {
    maxWidth: 768,
    margin: 'auto',
    padding: '60px 0 59px 68px',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1440,
    padding: '103px 0 100px 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
};
export const textBoxWrap = {
  color: 'white',
};

export const innerTextBox = {
  '@media(min-width: 768px)': {
    maxWidth: 648,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 894,
    marginTop: 53,
    paddingLeft: 150,
    paddingRight: 40,
  },
};
export const secured = {
  fontSize: 27,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
};
export const noMatter = {
  fontSize: 20,
  fontWeight: 500,
  color: '#7cc99e',
  width: '100%',
  marginTop: 10,
  lineHeight: 1.3,
  '@media(min-width: 1260px)': {
    fontSize: 22,
    marginTop: 26,
  },
};
export const paragraph = {
  lineHeight: 1.9,
  fontWeight: 300,
  marginTop: 12,
  '@media(min-width: 1260px)': {
    marginTop: 40,
    fontSize: 16,
  },
};
export const secondPar = {
  margin: '35px 0',
  '@media(min-width: 768px)': {
    marginBottom: 73,
  },
};

export const imageStyles = {
  display: 'block',
  maxWidth: 400,
  '@media(min-width: 768px)': {
    margin: '0 auto',
    maxWidth: 500,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 455,
  },
};
