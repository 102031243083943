/** @jsx jsx */
import { jsx } from '@emotion/core';

import { mostOuterWrap, outerWrap, innerWrap, contactUs, detailType, details } from './contactDetailsStyles';

export default function ContactDetails() {
  return (
    <div css={mostOuterWrap}>
      <div css={outerWrap}>
        <div css={contactUs}>Contact us</div>
        <div css={innerWrap}>
          <div>
            <div css={detailType}>Address</div>
            <div css={details}>
              DocProbe, LLC
              <br />
              1133 Ocean Avenue
              <br />
              Lakewood NJ 08701
            </div>
          </div>
          <div>
            <div css={detailType}>Phone</div>
            <div css={details}>866.486.0554</div>
          </div>
          <div>
            <div css={detailType}>Email</div>
            <div css={details}>info@docprobe.net</div>
          </div>
        </div>
      </div>
    </div>
  );
}
