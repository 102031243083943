/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';

import { root, mainWrap, textWrap, topBlackLine, horizontalLine, mainStuffs } from './stepStyles';
import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';
import { isIE, getUA } from 'react-device-detect';

const lineBreak = {
  display: 'block',
  fontWeight: 500,
  '@media(min-width: 768px)': { display: 'inline-block' },
  '@media(min-width: 1260px)': { display: 'block' },
};

const contentS = {
  '@media(min-width: 1260px)': {
    marginLeft: 190,
    maxWidth: 1050,
  },
  '@media(min-width: 1440px)': {
    marginLeft: 258,
  },
};

const textStyles = {
  '@media(min-width: 1260px)': {
    maxWidth: 358,
  },
};

export default function StepFive({ onSectionClick }) {
  return (
    <section css={root} className='relative step-five'>
      <div css={horizontalLine} data-line />
      <div className='container' css={mainWrap}>
        <div css={topBlackLine} />
        {/* <SectionCounter styles={{ ' div:nth-of-type(5)': { opacity: '1' } }} onSectionClick={onSectionClick} /> */}
        <div css={[mainStuffs, contentS]} data-content>
          <Text />
          <Images />
        </div>
      </div>
    </section>
  );
}

const imageStyles = {
  width: '100%',
  opacity: 0,
  '@media(min-width: 768px)': {
    maxWidth: 550,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 770,
  },
};

function Images() {
  return (
    <div className='relative' css={{ margin: '40px auto 10px' }}>
      <Image image='home/steps/processing-indexing-3_tqd7ih.png' styles={imageStyles} alt='place holder' />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ?(
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='home/steps/processing-indexing-1_utdarm.png' alt='Processing and Indexing images' />
            <Image image='home/steps/processing-indexing-3_tqd7ih.png' alt='Processing and Indexing images' />
            <Image image='home/steps/processing-indexing-2_zulyp7.png' alt='Processing and Indexing images' />
          </div>
        ) : (
          <Fragment>
            <Parallax y={[0, 0]}>
              <Image image='home/steps/processing-indexing-1_utdarm.png' alt='Processing and Indexing images' />
            </Parallax>
            <Parallax y={[10, -10]}>
              <Image image='home/steps/processing-indexing-3_tqd7ih.png' alt='Processing and Indexing images' />
            </Parallax>
            <Parallax y={[15, -15]}>
              <Image image='home/steps/processing-indexing-2_zulyp7.png' alt='Processing and Indexing images' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}

function Text() {
  return (
    <div css={[textWrap, textStyles]}>
      <h3>
        Processing & <span css={lineBreak}>Indexing</span>
      </h3>
      <p>
        Each document is barcoded and entered into our proprietary processing platform. Easily search and track any
        document as it moves towards fulfillment. Through our integration with your LOS, a digital copy is readily
        available to you at all times. Transparent and connected, you are always in the know and in control.
      </p>
      <NavLink to='/platform' className='processing-indexing'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}
