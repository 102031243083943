import React from 'react';
import { saveTrackingCookies } from './seo-tracking';
import { MuiThemeProvider } from '@material-ui/core';
import CookieConsent from 'react-cookie-consent';
import Cookies from 'js-cookie';

import { theme } from '../src/components/layout/Layout';

import Routes from './Routes';
import { useEffect } from 'react';

function App() {
  useEffect(() => {
    saveTrackingCookies();
  }, []);

  function deleteAllCookies() {
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      if (cookieName === 'consent-to-cookies') return;
      const neededAttributes = {
        domain: '.docprobe.net',
        path: '/',
      };
      Cookies.remove(cookieName, neededAttributes);
    });
  }

  return (
    <MuiThemeProvider theme={theme}>
      <Routes />
      <CookieConsent
        location='bottom'
        buttonText='Accept'
        cookieName='consent-to-cookies'
        style={{
          background: '#2B373B',
          padding: '0 64px 0 24px',
          borderTop: '2px solid #64ae3e',
        }}
        buttonStyle={{
          fontSize: '13px',
          backgroundColor: '#64ae3e',
          borderRadius: '4px',
          color: '#fff',
        }}
        expires={150}
        onDecline={deleteAllCookies}
        declineButtonText='Decline'
        enableDeclineButton={true}
        declineButtonStyle={{
          color: '#4e503b',
          fontSize: '13px',
          background: '#fff',
          borderRadius: '4px',
          marginRight: '5px',
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </MuiThemeProvider>
  );
}

export default App;
