/** @jsx jsx */
import { jsx } from '@emotion/core';

import Button from '../../components/ui/Button';

const mainWrap = {
  backgroundColor: '#f5f5f5',
  padding: '98px 0 79px',
  position: 'relative',
  '@media(min-width: 768px)': {
    marginTop: 55,
    paddingBottom: 88,
    height: '100%',
  },
  '@media(min-width: 1260px)': {
    marginTop: 0,
  },
};
const line = {
  borderLeft: '1px solid #3b3f43',
  position: 'absolute',
  left: '50%',
  top: -70,
  height: 125,
  '&:before': {
    content: '""',
    borderTop: '1px solid #3b3f43',
    position: 'absolute',
    left: -4,
    bottom: 0,
    width: 7,
  },
  '@media(min-width: 768px)': {
    display: 'none',
  },
};
const bottomHeader = {
  fontSize: 40,
  textAlign: 'center',
  maxWidth: 314,
  width: '100%',
  color: '#3b3f43',
  margin: '0 auto',
  textTransform: 'capitalize',
  '@media(min-width: 768px)': {
    maxWidth: 554,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    fontWeight: 300,
    maxWidth: 730,
  },
};

const buttonWrap = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '@media(min-width: 768px)': {
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
};

const buttonStyles = {
  backgroundColor: '#64ae3e',
  maxWidth: 291,
  width: '100%',
  marginTop: 33,
  color: '#fff',
  border: 'none',
  '@media(min-width: 768px)': {
    marginTop: 25,
  },
  '@media(min-width: 1260px)': {
    marginTop: 59,
  },
};

export default function BottomSection({ styles, title, linkOne, textOne, linkTwo, textTwo, linkThree, textThree }) {
  return (
    <div css={[mainWrap, styles]}>
      <div css={line} />
      <div css={bottomHeader}>{title || 'Explore other benefits'}</div>
      <div css={buttonWrap}>
        <Button styles={buttonStyles} text={textOne} to={linkOne} />
        <Button
          styles={[
            buttonStyles,
            {
              marginTop: 19,
              '@media(min-width: 768px)': {
                marginLeft: 18,
              },
            },
          ]}
          text={textTwo}
          to={linkTwo}
        />
        {textThree && linkThree && (
          <Button
            styles={[
              buttonStyles,
              {
                marginTop: 19,
                '@media(min-width: 768px)': {
                  marginLeft: 18,
                },
              },
            ]}
            text={textThree}
            to={linkThree}
          />
        )}
      </div>
    </div>
  );
}
