/** @jsx jsx */
import { jsx, Global } from '@emotion/core';
import { Component } from 'react';
import { NavLink } from 'react-router-dom';

import Button from '../ui/Button';
import { PlusIcon, MinusIcon } from '../ui/icons';
import {
  navWrap,
  opened,
  navItem,
  categoryHeader,
  sublistExpandedS,
  subListWrap,
  userWrap,
  contactButton,
  listWrap,
} from './mobileNavStyles';

export default class MobileNav extends Component {
  state = {
    menuExpandedStates: [false, false],
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.mobileMenuOpen !== this.props.mobileMenuOpen) {
      const { menuExpandedStates } = this.state;
      this.setState({ menuExpandedStates: menuExpandedStates.map((_) => false) });
    }
  };

  toggleMenuExpansion = (index) => {
    let { menuExpandedStates } = this.state;
    if (menuExpandedStates[index]) {
      //if one being clicked is already open, set all to false so that it gets closed
      menuExpandedStates = menuExpandedStates.map((_) => false);
    } else {
      //if a different one is being clicked, set only THAT one to be true so that all others close
      menuExpandedStates = this.state.menuExpandedStates.map((_, i) => i === index);
    }
    this.setState({ menuExpandedStates });
  };

  render() {
    const { mobileMenuOpen } = this.props;
    const [benefitsExpanded, meetUsExpanded] = this.state.menuExpandedStates;
    return (
      <nav css={[navWrap, mobileMenuOpen && opened]}>
        {mobileMenuOpen && <Global styles={{ ' body': { overflow: 'hidden' }, ' html': { overflow: 'hidden' } }} />}
        <div
          css={{
            paddingBottom: 112,
            position: 'relative',
            paddingTop: 75,
            minHeight: 'calc(100vh - 112px)',
            '@media(min-width: 768px)': {
              minHeight: '1px',
              paddingBottom: 0,
              paddingTop: 85,
            },
          }}
        >
          <ul css={listWrap}>
            <li css={navItem}>
              <div>
                <div css={categoryHeader} onClick={() => this.toggleMenuExpansion(0)}>
                  Benefits {benefitsExpanded ? <MinusIcon /> : <PlusIcon />}
                </div>
                <div css={[subListWrap, benefitsExpanded && sublistExpandedS]}>
                  <ul>
                    <li>
                      <NavLink to='/cost'>Cost</NavLink>
                    </li>
                    <li>
                      <NavLink to='/efficiency'>Efficiency</NavLink>
                    </li>
                    <li>
                      <NavLink to='/relationships'>Relationship</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li css={navItem}>
              <div>
                <div css={categoryHeader}>
                  <NavLink to='/features'>Features</NavLink>
                </div>
              </div>
            </li>
            <li css={navItem}>
              <div>
                <div css={categoryHeader} onClick={() => this.toggleMenuExpansion(1)}>
                  Meet Us {meetUsExpanded ? <MinusIcon /> : <PlusIcon />}
                </div>
                <div css={[subListWrap, meetUsExpanded && sublistExpandedS]}>
                  <ul>
                    <li>
                      <NavLink to='/about-us'>About</NavLink>
                    </li>
                    <li>
                      <NavLink to='/team'>Team</NavLink>
                    </li>
                    <li>
                      <NavLink to='/contact-us'>Contact</NavLink>
                    </li>
                    <li>
                      <NavLink to='/partners'>Partners</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <li css={navItem}>
              <div>
                <div css={categoryHeader}>
                  <NavLink to='/platform'>Platform</NavLink>
                </div>
              </div>
            </li>
          </ul>
          <div css={userWrap}>
            <Button styles={contactButton} secondary to='/contact-us' text='Contact Us' />
            <a href="https://app.docprobe.net" css={{ letterSpacing: 2, color: '#fff' }}>
              Log In
            </a>
          </div>
        </div>
      </nav>
    );
  }
}
