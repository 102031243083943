export const outerWrap = {
  backgroundColor: '#e5e5e5',
  padding: '45px 0 63px 0',
  '@media(min-width: 768px)': {
    paddingTop: 92,
  },
};
export const request = {
  fontSize: 27,
  fontWeight: 500,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
    fontSize: 37,
  },
};
export const innerWrap = {
  margin: '0 auto',
  '@media(min-width: 768px)': {
    display: 'flex',
    paddingTop: 66,
    paddingBottom: 64,
  },
};
export const emailButton = {
  '@media(min-width: 768px)': {
    margin: '0 auto',
    display: 'flex',
  },
};
export const email = {
  border: '1px solid #57616b',
  height: 52,
  width: 331,
  display: 'flex',
  paddingLeft: 21,
  fontSize: 16,
  margin: '45px auto 0',
  color: '#3b3f43',
  backgroundColor: '#e5e5e5',
  '@media(min-width: 768px)': {
    marginTop: 0,
    width: 529,
    marginRight: 12,
  },
};
export const buttonStyles = {
  backgroundColor: '#64ae3e',
  maxWidth: 331,
  width: '100%',
  margin: '26px auto 0',
  color: '#fff',
  borderRadius: 0,
  border: 'none',
  '@media(min-width: 768px)': {
    marginTop: 0,
    minWidth: 170,
    maxWidth: 170,
  },
};
