export const mostOuterWrap = {
  backgroundColor: '#efefef',
  '@media(min-width: 768px)': {
    '&:before': {
      height: 85,
      top: -85,
    },
  },
  '@media(min-width: 1260px)': {
    width: '40%',
    position: 'relative',
    verticalAlign: 'top',
    display: 'inline-flex',
    justifyContent: 'flex-end',
    '&:before': {
      backgroundColor: '#efefef',
      content: '""',
      display: 'block',
      left: 0,
      position: 'absolute',
      right: 0,
      width: '100%',
      height: 120,
      top: -120,
    },
  },
};

export const outerWrap = {
  padding: '48px 0 40px',
  maxWidth: 700,
  margin: '60px auto 0',
  '@media(min-width: 1260px)': {
    maxWidth: 200,
    margin: '124px 135px 0 0',
    padding: 0,
  },
};

export const contactUs = {
  fontSize: 27,
  fontWeight: 500,
  marginBottom: 21,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    textAlign: 'left',
  },
  '@media(min-width: 1260px)': {
    fontSize: 37,
    textAlign: 'left',
    marginBottom: 50,
  },
};
export const innerWrap = {
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'space-between',
  },
  '@media(min-width: 1260px)': {
    display: 'block',
  },
};
export const detailType = {
  textTransform: 'uppercase',
  color: '#64ae3e',
  fontWeight: 500,
  fontSize: 12,
  marginTop: 18,
  textAlign: 'center',
  letterSpacing: 3,
  '@media(min-width: 768px)': {
    textAlign: 'left',
  },
};
export const details = {
  fontSize: 18,
  textAlign: 'center',
  lineHeight: 1.67,
  marginTop: 18,
  '@media(min-width: 768px)': {
    textAlign: 'left',
  },
};
