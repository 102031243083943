import React from "react";
// hover color for tag is #000

export function TimeIcon({ stroke = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 64 64"
    >
      <g fill="none" fillRule="evenodd" stroke="#426D61" strokeLinecap="round">
        <path d="M16.79 31.497h-6.466M7.562 31.497H5.829M3.344 31.497H1.611M18.985 40h-7.872M8.35 40H6.618M19.054 22.994h-7.87M8.42 22.994H6.689M54.716 37.704a19.557 19.557 0 0 0 1.003-6.207c0-10.735-8.632-19.437-19.282-19.437-6.71 0-12.62 3.455-16.074 8.697a19.46 19.46 0 0 0-3.208 10.74c0 10.736 8.633 19.438 19.282 19.438 1.593 0 3.143-.194 4.623-.562" />
        <path
          strokeLinejoin="round"
          d="M53.008 31.497h-2.45M19.868 31.497h2.447M36.437 14.795v2.467M36.437 48.2V45.73M50.686 22.972l-2.106 1.26M22.188 40.023l2.107-1.261M27.98 17.134l1.25 2.123M44.746 17.047l-1.228 2.134M28.128 45.948l1.228-2.136M22.102 23.12l2.117 1.24M37.141 31.522c0 .503-.404.91-.902.91a.906.906 0 0 1-.902-.91c0-.502.404-.91.902-.91s.902.408.902.91zM50.157 31.522H37.14M33.267 7.975h-2.956V2.617h10.77v5.358h-2.958M38.123 7.975V9.78h-4.856V7.975M48.766 12.467l-1.157-.88 1.568-2.096 4.212 3.203-1.568 2.095-1.157-.878"
        />
        <path
          strokeLinejoin="round"
          d="M50.664 13.91l-.527.706-1.9-1.444.53-.705"
        />
        <g strokeLinejoin="round">
          <path d="M51.916 59.236c6 0 10.863-4.903 10.863-10.95 0-6.048-4.863-10.95-10.863-10.95-5.999 0-10.861 4.902-10.861 10.95a10.96 10.96 0 0 0 4.859 9.127 10.753 10.753 0 0 0 6.002 1.823zM56.735 45l-6.448 6.844M47.3 48.833l2.988 3.011" />
        </g>
      </g>
    </svg>
  );
}
export function BoxIcon({ stroke = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="96"
      height="96"
      viewBox="0 0 64 64"
    >
      <g fill="none" fillRule="evenodd" stroke="#426D61">
        <path
          strokeLinecap="round"
          strokeWidth=".75"
          d="M20.997 38.195h5.429M20.997 41.295h5.429M16.798 42.904H11.74a.2.2 0 0 1-.202-.197v-4.951a.2.2 0 0 1 .202-.197h5.058c.111 0 .2.089.2.197v4.951c0 .108-.089.197-.2.197zM20.997 29.492h6.119M20.997 32.593h5.429M16.798 34.2H11.74a.2.2 0 0 1-.202-.196v-4.95a.2.2 0 0 1 .202-.197h5.058c.111 0 .2.088.2.197v4.95c0 .108-.089.197-.2.197zM20.997 20.79h9.843M20.997 23.89h7.16M16.999 23.208V25.3c0 .109-.09.197-.201.197h-5.059a.2.2 0 0 1-.201-.197v-4.95a.2.2 0 0 1 .201-.197h3.269"
        />
        <path
          strokeLinejoin="round"
          d="M26.426 47.528l-18.664.085c-.935 0-1.695-.743-1.695-1.658V15.299c0-.262.107-.515.297-.7l4.086-4 4.04-3.952c.22-.215.518-.337.83-.337l18.606-.117c.936 0 1.695.742 1.695 1.658v17.647"
        />
        <path
          strokeLinejoin="round"
          d="M14.834 6.31v7.283c0 .715-.593 1.296-1.325 1.296H6.067"
        />
        <path strokeLinecap="round" d="M13.456 21.936l1.3 1.272 2.924-3.18" />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M26.427 57.45H57.63V30.57H26.427zM57.63 30.57H26.426l3.247-5.072h24.62z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth=".75"
          d="M47.897 42.552H36.159c-.75 0-1.363-.6-1.363-1.336v-.504c0-.735.613-1.335 1.363-1.335h11.738c.75 0 1.365.6 1.365 1.335v.504c0 .736-.615 1.336-1.365 1.336z"
        />
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M38.796 25.498l-1.02 5.073v5.266l2.04-1.452 2.212 1.816 1.868-1.634 2.041 1.405V30.57l-1.206-5.073"
        />
      </g>
    </svg>
  );
}

export function BackwardsDeeIcon({ ...props }) {
  return (
    <svg
      height="1824"
      viewBox="0 0 1807 1824"
      width="1807"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m3.04465038 521.567645v130.162545 1171.47566h130.36005362v-1171.47566h130.360054v1171.47566h130.360053v-1171.47566h130.468538v1171.47566h130.360054v-1171.47566h135.13337v-130.162545zm-.81577571-266.105942h-2.22887467v131.074038h890.193438c283.168972 0 514.850942 228.4313 514.850942 510.194899 0 286.97188-231.68197 520.58596-514.850942 520.58596h-107.317131v130.65967h152.130248c332.000435 0 599.497235-310.19559 599.497235-651.774745 0-336.064881-267.4968-640.739822-599.497235-640.739822zm1804.77112533 648.348272c0-474.015289-377.62438-903.809975-846.459489-903.809975h-957.49586062v130.322331l894.92713962-.101993c429.80854 0 781.3536 346.44041 781.3536 773.589637 0 434.983615-351.54506 789.175535-781.3536 789.175535h-113.471547v130.22034h176.040268c468.835109 0 846.459489-437.53345 846.459489-919.395875z"
        fill="#dedfdc"
        fillRule="evenodd"
        opacity=".15231756389"
        transform="matrix(-1 0 0 1 1807 0)"
      />
    </svg>
  );
}

export function ForwardDeeIcon({ ...props }) {
  return (
    <svg
      height="1824"
      viewBox="0 0 1807 1824"
      width="1807"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m3.04465038 521.567645v130.162545 1171.47566h130.36005362v-1171.47566h130.360054v1171.47566h130.360053v-1171.47566h130.468538v1171.47566h130.360054v-1171.47566h135.13337v-130.162545zm-.81577571-266.105942h-2.22887467v131.074038h890.193438c283.168972 0 514.850942 228.4313 514.850942 510.194899 0 286.97188-231.68197 520.58596-514.850942 520.58596h-107.317131v130.65967h152.130248c332.000435 0 599.497235-310.19559 599.497235-651.774745 0-336.064881-267.4968-640.739822-599.497235-640.739822zm1804.77112533 648.348272c0-474.015289-377.62438-903.809975-846.459489-903.809975h-957.49586062v130.322331l894.92713962-.101993c429.80854 0 781.3536 346.44041 781.3536 773.589637 0 434.983615-351.54506 789.175535-781.3536 789.175535h-113.471547v130.22034h176.040268c468.835109 0 846.459489-437.53345 846.459489-919.395875z"
        fill="#dedfdc"
        fillRule="evenodd"
        opacity=".15231756389"
        transform="matrix(1 0 0 -1 0 1824)"
      />
    </svg>
  );
}

export function RightCaretIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M6 4l4 4-4 4"
      />
    </svg>
  );
}

export function BurgerMenuIcon({ ...props }) {
  return (
    <svg
      height="18"
      viewBox="0 0 24 18"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="#64ae3e" fillRule="evenodd">
        <rect height="2" rx="1" width="24" />
        <rect height="2" rx="1" width="24" y="8" />
        <rect height="2" rx="1" width="24" y="16" />
      </g>
    </svg>
  );
}

export function XIcon({ ...props }) {
  return (
    <svg
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.3.org/2000/svg"
      {...props}
    >
      <g fill="currentColor" fillRule="evenodd" transform="translate(-1 -1)">
        <rect
          height="2"
          rx="1"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -4.142136 10)"
          width="24"
          x="-2"
          y="9"
        />
        <rect
          height="2"
          rx="1"
          transform="matrix(.70710678 .70710678 -.70710678 .70710678 10 -4.142136)"
          width="24"
          x="-2"
          y="9"
        />
      </g>
    </svg>
  );
}

export function SmallXIcon({ ...props }) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.3 0.709971C12.91 0.319971 12.28 0.319971 11.89 0.709971L6.99997 5.58997L2.10997 0.699971C1.71997 0.309971 1.08997 0.309971 0.699971 0.699971C0.309971 1.08997 0.309971 1.71997 0.699971 2.10997L5.58997 6.99997L0.699971 11.89C0.309971 12.28 0.309971 12.91 0.699971 13.3C1.08997 13.69 1.71997 13.69 2.10997 13.3L6.99997 8.40997L11.89 13.3C12.28 13.69 12.91 13.69 13.3 13.3C13.69 12.91 13.69 12.28 13.3 11.89L8.40997 6.99997L13.3 2.10997C13.68 1.72997 13.68 1.08997 13.3 0.709971Z"
        fill="#757575"
      />
    </svg>
  );
}

export function ErrorCircleIcon({ ...props }) {
  return (
    <svg
      width="61"
      height="61"
      viewBox="0 0 61 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M30.5 59.5C46.5163 59.5 59.5 46.5163 59.5 30.5C59.5 14.4837 46.5163 1.5 30.5 1.5C14.4837 1.5 1.5 14.4837 1.5 30.5C1.5 46.5163 14.4837 59.5 30.5 59.5Z"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5 12.5V37.5"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5 46.5C31.0523 46.5 31.5 46.0523 31.5 45.5C31.5 44.9477 31.0523 44.5 30.5 44.5C29.9477 44.5 29.5 44.9477 29.5 45.5C29.5 46.0523 29.9477 46.5 30.5 46.5Z"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function HourGlassIcon({ ...props }) {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 2H55"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 62H55"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 62V51.106C14.0002 49.0831 14.6139 47.1079 15.76 45.441L25 32L15.76 18.559C14.6139 16.8921 14.0002 14.9169 14 12.894V2"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M50 2V12.894C49.9998 14.9169 49.3861 16.8921 48.24 18.559L39 32L48.24 45.441C49.3861 47.1079 49.9998 49.0831 50 51.106V62"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 54V51.727C21.9989 50.9182 22.243 50.1282 22.7 49.461L32 35.939L41.3 49.461C41.7572 50.1284 42.0013 50.9189 42 51.728V54H22Z"
        stroke="#252729"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function CircleCheckIcon({ ...props }) {
  return (
    <svg
      width="30"
      height="22"
      viewBox="0 0 30 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 13L9 21L29 1"
        stroke="#64AE45"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function PlusIcon({ ...props }) {
  return (
    <svg
      height="18"
      viewBox="0 0 18 18"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g
        fill="#f5f5f5"
        fillRule="evenodd"
        transform="matrix(.70710678 .70710678 -.70710678 .70710678 9.899495 -1)"
      >
        <rect
          height="1.4"
          rx=".7"
          transform="matrix(.70710678 -.70710678 .70710678 .70710678 -3.302944 6.832886)"
          width="16.8"
          x="-1.803449"
          y="6.703449"
        />
        <rect
          height="1.4"
          rx=".7"
          transform="matrix(.70710678 .70710678 -.70710678 .70710678 7.167114 -2.496046)"
          width="16.8"
          x="-1.803449"
          y="6.703449"
        />
      </g>
    </svg>
  );
}

export function CellPhoneIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="64"
      height="64"
      viewBox="0 0 64 64"
    >
      <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round">
        <path d="M57.06 32.413l-2.335 1.021c-1.272.555-2.773-.023-3.334-1.286a2.51 2.51 0 0 1 1.294-3.309l2.337-1.022c1.274-.554 2.773.024 3.334 1.287a2.513 2.513 0 0 1-1.296 3.309zM55.085 38.672l-2.334 1.02c-1.273.557-2.773-.022-3.334-1.285a2.507 2.507 0 0 1 1.295-3.309l2.337-1.021a2.542 2.542 0 0 1 3.332 1.286 2.51 2.51 0 0 1-1.296 3.31zM53.103 44.956l-2.335 1.022c-1.272.555-2.773-.024-3.334-1.287a2.51 2.51 0 0 1 1.294-3.309l2.337-1.021a2.545 2.545 0 0 1 3.334 1.286 2.513 2.513 0 0 1-1.296 3.31zM51.128 51.214l-2.334 1.021a2.543 2.543 0 0 1-3.333-1.286 2.506 2.506 0 0 1 1.294-3.307l2.337-1.022a2.543 2.543 0 0 1 3.332 1.285 2.51 2.51 0 0 1-1.296 3.309zM11.759 56.043L6.672 46.06a.28.28 0 0 1 .129-.38l6.252-3.084a.285.285 0 0 1 .38.12l8.491 15.894c.072.136.242.19.382.12l3.094-1.552c2.192 1.678 5.58 2.055 10.202-.165l3.587-1.936" />
        <path d="M24.578 52.36l.35-.47a14.452 14.452 0 0 0 1.761-14.168l2.639-4.446a8.76 8.76 0 0 0 .54-7.887 1.565 1.565 0 0 0-2.69-.326l-5.356 7.134a12.985 12.985 0 0 0-2.158 4.428l-1.037 3.865a4.191 4.191 0 0 1-1.288 2.06l-2.725 2.378M12.513 47.866l-1.197.584" />
        <path d="M46.425 52.294l-.682 2.166a2.557 2.557 0 0 1-3.2 1.66l-16.92-5.26M29.588 24.725l4.53-14.363.932-2.95a2.555 2.555 0 0 1 3.197-1.66l18.386 5.716a2.527 2.527 0 0 1 1.67 3.174l-4.294 13.62M32.857 49.14l3.272 1.019M57.374 17.59L34.12 10.361M45.096 49.547L27.76 44.159" />
        <g strokeLinejoin="round">
          <path d="M45.431 27.28c.561-1.848-.608-3.25-2.474-3.808-1.863-.557-3.665-.046-4.226 1.804-.56 1.85.446 2.569 2.356 4.357 1.91 1.789 2.918 2.509 2.358 4.358-.561 1.848-2.358 2.339-4.222 1.78-1.863-.558-3.087-1.95-2.525-3.797M43.463 21.81l-.445 1.464M39.164 35.97l-.445 1.464" />
        </g>
      </g>
    </svg>
  );
}

export function MinusIcon({ ...props }) {
  return (
    <svg
      height="2"
      viewBox="0 0 18 2"
      width="18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        fill="#f5f5f5"
        fillRule="evenodd"
        height="1.3999999953"
        rx=".69999999765"
        width="16.79999994362"
        x=".92893189213"
        y=".29999989871"
      />
    </svg>
  );
}

export function QuoteGreyIcon({ ...props }) {
  return (
    <svg
      height="84"
      viewBox="0 0 87 84"
      width="87"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m82.9459459 0v12c-3.7838027 2.0540643-6.6216121 5.75673-8.5135135 11.1081081-1.8919013 5.3513781-2.8378378 12.4053616-2.8378378 21.1621622v3.7297297h15.4054054v36h-36v-26.9189189c0-16.324406 2.6486222-29.2431957 7.9459459-38.7567568 5.2973238-9.51356106 13.2972438-15.62160808 24-18.3243243zm-51 0v12c-3.7838027 2.0540643-6.6216121 5.75673-8.5135135 11.1081081-1.8919013 5.3513781-2.8378378 12.4053616-2.8378378 21.1621622v3.7297297h15.4054054v36h-36v-26.9189189c0-16.324406 2.64862216-29.2431957 7.94594595-38.7567568 5.29732375-9.51356106 13.29724375-15.62160808 23.99999995-18.3243243z"
        fill="#57616b"
      />
    </svg>
  );
}

export function QuoteGreenIcon({ ...props }) {
  return (
    <svg
      height="84"
      viewBox="0 0 87 84"
      width="87"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m82.9459459 0v12c-3.7838027 2.0540643-6.6216121 5.75673-8.5135135 11.1081081-1.8919013 5.3513781-2.8378378 12.4053616-2.8378378 21.1621622v3.7297297h15.4054054v36h-36v-26.9189189c0-16.324406 2.6486222-29.2431957 7.9459459-38.7567568 5.2973238-9.51356106 13.2972438-15.62160808 24-18.3243243zm-51 0v12c-3.7838027 2.0540643-6.6216121 5.75673-8.5135135 11.1081081-1.8919013 5.3513781-2.8378378 12.4053616-2.8378378 21.1621622v3.7297297h15.4054054v36h-36v-26.9189189c0-16.324406 2.64862216-29.2431957 7.94594595-38.7567568 5.29732375-9.51356106 13.29724375-15.62160808 23.99999995-18.3243243z"
        fill="#7cc99e"
      />
    </svg>
  );
}

export function SpotlightIcon({ ...props }) {
  return (
    <svg
      height="64"
      viewBox="0 0 64 64"
      width="64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round">
        <path d="m57.06 32.413-2.335 1.021c-1.272.555-2.773-.023-3.334-1.286a2.51 2.51 0 0 1 1.294-3.309l2.337-1.022c1.274-.554 2.773.024 3.334 1.287a2.513 2.513 0 0 1 -1.296 3.309zm-1.975 6.259-2.334 1.02c-1.273.557-2.773-.022-3.334-1.285a2.507 2.507 0 0 1 1.295-3.309l2.337-1.021a2.542 2.542 0 0 1 3.332 1.286 2.51 2.51 0 0 1 -1.296 3.31zm-1.982 6.284-2.335 1.022c-1.272.555-2.773-.024-3.334-1.287a2.51 2.51 0 0 1 1.294-3.309l2.337-1.021a2.545 2.545 0 0 1 3.334 1.286 2.513 2.513 0 0 1 -1.296 3.31zm-1.975 6.258-2.334 1.021a2.543 2.543 0 0 1 -3.333-1.286 2.506 2.506 0 0 1 1.294-3.307l2.337-1.022a2.543 2.543 0 0 1 3.332 1.285 2.51 2.51 0 0 1 -1.296 3.309zm-39.369 4.829-5.087-9.983a.28.28 0 0 1 .129-.38l6.252-3.084a.285.285 0 0 1 .38.12l8.491 15.894c.072.136.242.19.382.12l3.094-1.552c2.192 1.678 5.58 2.055 10.202-.165l3.587-1.936" />
        <path d="m24.578 52.36.35-.47a14.452 14.452 0 0 0 1.761-14.168l2.639-4.446a8.76 8.76 0 0 0 .54-7.887 1.565 1.565 0 0 0 -2.69-.326l-5.356 7.134a12.985 12.985 0 0 0 -2.158 4.428l-1.037 3.865a4.191 4.191 0 0 1 -1.288 2.06l-2.725 2.378m-2.101 2.938-1.197.584" />
        <path d="m46.425 52.294-.682 2.166a2.557 2.557 0 0 1 -3.2 1.66l-16.92-5.26m3.965-26.135 4.53-14.363.932-2.95a2.555 2.555 0 0 1 3.197-1.66l18.386 5.716a2.527 2.527 0 0 1 1.67 3.174l-4.294 13.62m-21.152 20.878 3.272 1.019m21.245-32.569-23.254-7.229m10.976 39.186-17.336-5.388" />
        <path
          d="m45.431 27.28c.561-1.848-.608-3.25-2.474-3.808-1.863-.557-3.665-.046-4.226 1.804-.56 1.85.446 2.569 2.356 4.357 1.91 1.789 2.918 2.509 2.358 4.358-.561 1.848-2.358 2.339-4.222 1.78-1.863-.558-3.087-1.95-2.525-3.797m6.765-10.164-.445 1.464m-3.854 12.696-.445 1.464"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export function EmailIcon({ ...props }) {
  return (
    <svg
      width="18px"
      height="13px"
      viewBox="0 0 18 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink='http://www.w3.org/1999/xlink'
    >
      <g
        id="Leadership"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Bio-–-Desktop" transform="translate(-728.000000, -747.000000)">
          <g id="Email" transform="translate(728.500000, 747.000000)">
            <path
              d="M15.921875,12.140625 L1.078125,12.140625 C0.750078125,12.140625 0.484375,11.8749219 0.484375,11.546875 L0.484375,1.453125 C0.484375,1.12507812 0.750078125,0.859375 1.078125,0.859375 L15.921875,0.859375 C16.2499219,0.859375 16.515625,1.12507812 16.515625,1.453125 L16.515625,11.546875 C16.515625,11.8749219 16.2499219,12.140625 15.921875,12.140625 L15.921875,12.140625 Z"
              id="Stroke-199"
              fill="#FFFFFF"
            ></path>
            <path
              d="M0.769939062,1.09595469 L7.85278281,8.17939219 C8.20072031,8.52703281 8.76448594,8.52703281 9.11242344,8.17939219 L16.2115953,1.08022031"
              id="Stroke-198"
              stroke="#3B3F43"
              strokeWidth="0.600000024"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export function PhoneIcon({ ...props }) {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink='http://www.w3.org/1999/xlink'
    >
      <g
        id="Leadership"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Bio-–-Desktop" transform="translate(-729.000000, -776.000000)">
          <g id="Phone" transform="translate(729.500000, 777.000000)">
            <path
              d="M12.0839196,9.23740179 C11.7758839,8.92936607 11.2765982,8.92936607 10.9685625,9.23740179 L9.66704464,10.5389196 C9.1535625,11.0521339 7.38597321,10.4888304 5.94838393,9.05124107 C4.5110625,7.61365179 3.94775893,5.84633036 4.46150893,5.33311607 L5.76249107,4.03133036 C6.07079464,3.72329464 6.07079464,3.22427679 5.76249107,2.91597321 L3.34561607,0.499098214 C3.03758036,0.1910625 2.53829464,0.1910625 2.23025893,0.499098214 L0.835526786,1.89329464 C-0.652955357,3.3823125 0.8866875,7.33615179 4.27534821,10.7248125 C7.66374107,14.1132054 11.6178482,15.6533839 13.1065982,14.1640982 L14.5007946,12.7699018 C14.8090982,12.4615982 14.8090982,11.9625804 14.5007946,11.6542768 L12.0839196,9.23740179"
              id="Stroke-217"
              fill="#FFFFFF"
            ></path>
            <path
              d="M13.7574911,13.5137143 L10.2247232,9.98121429"
              id="Stroke-218"
              stroke="#3B3F43"
              strokeWidth="0.550000012"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M5.0191875,4.774875 L1.48615179,1.242375"
              id="Stroke-219"
              stroke="#3B3F43"
              strokeWidth="0.550000012"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M7.76785714,4.55357143 C8.45357143,4.55357143 9.13875,4.815 9.66160714,5.33785714 C10.1847321,5.86098214 10.4466964,6.54642857 10.4464286,7.23214286"
              id="Stroke-220"
              stroke="#F8F8F8"
              strokeWidth="0.550000012"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M7.76785714,2.41071429 C9.00160714,2.41071429 10.235625,2.88107143 11.1771429,3.82258929 C12.1183929,4.76383929 12.5892857,5.998125 12.5892857,7.23214286"
              id="Stroke-221"
              stroke="#F8F8F8"
              strokeWidth="0.550000012"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M7.76785714,0.267857143 C9.55017857,0.267857143 11.3327679,0.947410714 12.6924107,2.30705357 C14.0520536,3.66669643 14.7321429,5.44982143 14.7321429,7.23214286"
              id="Stroke-222"
              stroke="#F8F8F8"
              strokeWidth="0.550000012"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #FFF
export function ProcessingIcon({ stroke = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="18"
      viewBox="0 0 17 18"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M1.25 9.082c0-4.05 3.313-7.364 7.363-7.364 2.872 0 5.376 1.62 6.554 4.05M15.977 9.082c0 4.05-3.313 7.364-7.364 7.364-2.872 0-5.376-1.62-6.554-4.05" />
        <path d="M15.83 1.497l-.589 4.345-4.418-.59M1.396 16.667l.59-4.345 4.418.59" />
      </g>
    </svg>
  );
}

// base color for tag is #E5E5EA
export function RightArrowIcon({ stroke = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
      >
        <path d="M.774 7.57h14.285M10.06 2.57l5 5-5 5" />
      </g>
    </svg>
  );
}

export function CheckIcon({ stroke = "currentColor", ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <path
        fill="none"
        fillRule="evenodd"
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.35"
        d="M3 7.75l3.5 3.5 6.5-6.5"
      />
    </svg>
  );
}
export function SelectedCircleIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <circle
        cx="43"
        cy="1381"
        r="5.5"
        fill="#3B3F43"
        fillRule="evenodd"
        stroke="#64AE3E"
        strokeWidth="3"
        transform="translate(-36 -1374)"
      />
    </svg>
  );
}
export function CircleIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      {...props}
    >
      <circle
        cx="199"
        cy="1381"
        r="5.5"
        fill="none"
        fillRule="evenodd"
        stroke="#64AE3E"
        strokeWidth="3"
        transform="translate(-192 -1374)"
      />
    </svg>
  );
}
export function SpeechBubble({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="200"
      height="166"
      viewBox="0 0 200 166"
      {...props}
    >
      <g fill="#3B3F43" fill-rule="evenodd" transform="translate(-.48)">
        <rect width="189" height="166" x="11.48" rx="10.24" />
        <path
          d="M4 71.68H20.64V88.32000000000001H4z"
          transform="rotate(-45 12.32 80)"
        />
      </g>
    </svg>
  );
}

// base color for tag is #
export function ErrorFaceIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="122"
      viewBox="0 0 122 122"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607H6.376v120.522h109.567V33.477"
        />
        <path fill="#E5E5EA" d="M83.073 33.476h32.87L83.073.607z" />
        <path
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.943 33.476L83.073.606M83.073.607v32.87h32.87M50.203 82.674h21.913M33.768 92.64h54.784M33.768 102.606h54.784"
        />
        <g
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M49.558 68.972l22.154-5.538M68.943 48.64l8.307-8.307M77.25 48.64l-8.307-8.307" />
          <g>
            <path d="M44.02 48.64l8.307-8.307M52.327 48.64l-8.307-8.307" />
          </g>
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function GotItFaceIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="122"
      viewBox="0 0 122 122"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607H6.376v120.522h109.567V33.477"
        />
        <path
          fill="#E5E5EA"
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.073.607v32.87h32.87"
        />
        <path
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.943 33.476L83.073.606M50.203 82.674h21.913M33.768 92.64h54.784M33.768 102.606h54.784"
        />
        <g transform="translate(45.66 37.24)">
          <circle
            cx="4.442"
            cy="4.944"
            r="4.167"
            fill="#64AE45"
            fillRule="nonzero"
          />
          <path
            stroke="#64AE45"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8.609 17.446a8.335 8.335 0 0 0 16.67 0M22.5 3.554h8.335"
          />
        </g>
      </g>
    </svg>
  );
}

// base color for tag is #
export function SuccessFaceIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // xmlns:xlink="http://www.w3.org/1999/xlink"
      width="122"
      height="122"
      viewBox="0 0 122 122"
      {...props}
    >
      <defs>
        <path
          id="a"
          d="M.678.266c0 9.554 7.746 17.3 17.3 17.3S35.279 9.82 35.279.266H.678z"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.053.49H6.356V121.01h109.567V33.36"
        />
        <path fill="#E5E5EA" d="M83.053 33.359h32.87L83.053.489z" />
        <path
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M115.924 33.359L83.054.489M83.053.49v32.87h32.87M50.183 82.557h21.913M33.748 92.522h54.784M33.748 102.488h54.784"
        />
        <g transform="translate(43 54.17)">
          <mask id="b" fill="#fff">
            <use xlinkHref="#a" />
          </mask>
          <path
            fill="#C7C7CC"
            d="M-4.322 22.565h44.601v-27.3H-4.322z"
            mask="url(#b)"
          />
        </g>
        <g
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M78.279 54.436c0 9.555-7.746 17.3-17.301 17.3-9.554 0-17.3-7.745-17.3-17.3h34.6zM78.279 42.902a5.767 5.767 0 0 0-11.534 0M55.212 42.902a5.767 5.767 0 0 0-11.533 0" />
        </g>
      </g>
    </svg>
  );
}
export function SpeechBubbleLarge({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="506"
      height="168"
      viewBox="0 0 506 168"
      {...props}
    >
      <g fill="#3B3F43" fill-rule="evenodd">
        <rect width="488" height="168" x="18" rx="16" />
        <path d="M6 112H32V138H6z" transform="rotate(-45 19 125)" />
      </g>
    </svg>
  );
}

export function NoFileFaceIcon({ ...props }) {
  return (
    <svg
      height="122"
      viewBox="0 0 122 122"
      width="122"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="m83.33.481h-77.041v120.522h110.061v-87.652"
          fill="#fff"
          stroke="#c7c7cc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <path d="m83.479 33.351h32.87l-32.87-32.869z" fill="#e5e5ea" />
        <path
          d="m116.35 33.351-32.87-32.87m0 .001v32.87h32.87m-65.741 49.198h21.913m-38.348 9.965h54.784m-54.784 9.965h54.784"
          stroke="#c7c7cc"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
        <g transform="translate(44 40)">
          <path
            d="m6.054 22.661 22.143-5.535"
            stroke="#c7c7cc"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <g fill="#c7c7cc" fillRule="nonzero">
            <circle cx="4.67" cy="4.67" r="4.152" />
            <circle cx="29.581" cy="4.67" r="4.152" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export function SpeechBubbleLarger({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="812"
      height="186"
      viewBox="0 0 812 186"
      {...props}
    >
      <g fill="#3B3F43" fill-rule="evenodd" transform="translate(.4)">
        <rect width="782" height="186" x="29.6" rx="19.2" />
        <path
          d="M8.718 115.718L44.986 121.118 50.387 157.386 14.118 151.986z"
          transform="rotate(-45 29.552 136.552)"
        />
      </g>
    </svg>
  );
}
export function HoverFaceIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="122"
      height="122"
      viewBox="0 0 122 122"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M83.097.981H6.053v120.522h110.062V33.851"
        />
        <path fill="#E5E5EA" d="M83.245 33.851h32.87L83.245.982z" />
        <path
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M116.115 33.851L83.245.981M83.245.982v32.87h32.87M50.375 83.05h21.913M33.94 93.015h54.784M33.94 102.98h54.784"
        />
        <g transform="translate(43.5 41)">
          <circle
            cx="17.404"
            cy="19.457"
            r="5.556"
            fill="#C7C7CC"
            stroke="#64AE45"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <circle cx="4.903" cy="4.178" r="4.167" fill="#64AE45" />
          <circle cx="29.905" cy="4.178" r="4.167" fill="#64AE45" />
        </g>
      </g>
    </svg>
  );
}
export function CalendarIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 15 15"
    >
      <g
        fill="none"
        fill-rule="evenodd"
        stroke="#FFF"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.12"
      >
        <path
          d="M10.92 1.4h1.68c.308 0 .56.252.56.56v10.08c0 .308-.252.56-.56.56H.84c-.308 0-.56-.252-.56-.56V1.96c0-.308.252-.56.56-.56h10.08zM3.64.28L3.64 2.52M9.8.28L9.8 2.52M.28 4.2L13.16 4.2"
          transform="translate(.8 1.2)"
        />
      </g>
    </svg>
  );
}

export function UploadFileIcon({ ...props }) {
  return (
    <svg
      fill="#354052"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
    >
      <path
        data-name="Icon material-file-upload"
        d="M11.5 17.5h6v-6h4l-7-7-7 7h4zm-4 2h14v2h-14z"
        transform="translate(-7.5 -4.5)"
      />
    </svg>
  );
}

export function DisabledUploadFileIcon({ ...props }) {
  return (
    <svg
      fill="#b1b2b0"
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="17"
    >
      <path
        data-name="Icon material-file-upload"
        d="M11.5 17.5h6v-6h4l-7-7-7 7h4zm-4 2h14v2h-14z"
        transform="translate(-7.5 -4.5)"
      />
    </svg>
  );
}

export function ModalUploadFileIcon({ ...props }) {
  return (
    <svg
      fill="rgba(112,112,112,.24)"
      xmlns="http://www.w3.org/2000/svg"
      width="24.754"
      height="30.059"
    >
      <path
        data-name="Icon material-file-upload"
        d="M14.573 27.486h10.609V16.877h7.073L19.877 4.5 7.5 16.877h7.073zM7.5 31.022h24.754v3.536H7.5z"
        transform="translate(-7.5 -4.5)"
      />
    </svg>
  );
}

export function ModalUploadFileIconBig({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34.393"
      height="41.763"
      viewBox="0 0 34.393 41.763"
    >
      <path
        data-name="Icon material-file-upload"
        d="M17.327 36.436h14.74V21.7h9.827L24.7 4.5 7.5 21.7h9.827zM7.5 41.35h34.393v4.913H7.5z"
        transform="translate(-7.5 -4.5)"
        fill="rgba(112,112,112,.24)"
      />
    </svg>
  );
}

export function submitSpinner({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      transform="translate(-8 -5.5)"
      width="129"
      height="53.785"
      viewBox="0 0 129 53.785"
    >
      <g data-name="Icon feather-loader">
        <path
          data-name="Path 179"
          d="M18 3v6"
          transform="translate(47 8)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 180"
          d="M18 27v6"
          transform="translate(47 4)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 181"
          d="m7.395 7.395 4.245 4.245"
          transform="translate(49.445 8.445)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 182"
          d="m24.36 24.36 4.24 4.24"
          transform="translate(44.555 3.555)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 183"
          d="M3 18h6"
          transform="translate(49 6)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 184"
          d="M27 18h6"
          transform="translate(45 6)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 185"
          d="m7.395 28.6 4.245-4.24"
          transform="translate(49.445 3.555)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
        <path
          data-name="Path 186"
          d="m24.36 11.64 4.24-4.245"
          transform="translate(44.555 8.445)"
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="3px"
        />
      </g>
    </svg>
  );
}

export function InfoIcon({ fill, stroke, ...props }) {
  return (
    <svg
      fill={fill || "#ef6611"}
      stroke={stroke || "#ef6611"}
      strokeWidth=".5px"
      xmlns="http://www.w3.org/2000/svg"
      width="16.5"
      height="16.5"
      {...props}
    >
      <path
        data-name="Icon material-info-outline"
        d="M10.2 15h1.6v-4.8h-1.6zM11 3a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm0 14.4a6.4 6.4 0 1 1 6.4-6.4 6.408 6.408 0 0 1-6.4 6.4zm-.8-8.8h1.6V7h-1.6z"
        transform="translate(-2.75 -2.75)"
      />
    </svg>
  );
}

export function ContentCopyIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="currentColor"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
    </svg>
  );
}

// base color for tag is #
export function NoResultsIcon({ ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="88"
      height="88"
      viewBox="0 0 88 88"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFF"
          fillRule="nonzero"
          d="M1.477 5.08H19.84v14.402H1.477zM21.253 5.08h65.79v14.402h-65.79z"
        />
        <g
          stroke="#64AE45"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        >
          <path d="M57.366 82.433H.99V4.625h85.59v48.594M.99 20.186h85.59M20.443 4.625v15.561M86.02 81.99l-8.927-8.926" />
          <path
            fill="#E5E5EA"
            d="M66.89 77.208c7.924 0 14.348-6.424 14.348-14.347 0-7.924-6.424-14.347-14.348-14.347S52.543 54.937 52.543 62.86c0 7.923 6.423 14.347 14.347 14.347z"
          />
        </g>
        <g transform="translate(31.894 35.529)">
          <circle
            cx="3.259"
            cy="3.283"
            r="2.92"
            fill="#64AE45"
            fillRule="nonzero"
          />
          <circle
            cx="20.776"
            cy="3.283"
            r="2.92"
            fill="#64AE45"
            fillRule="nonzero"
          />
          <path
            stroke="#64AE45"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4.232 13.988h15.57"
          />
        </g>
      </g>
    </svg>
  );
}
