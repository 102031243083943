/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  thinkingImageStyles,
  imageStyles,
  endToEnd,
  documentServices,
  innergreyContent,
  textBox,
  secondLineStyles,
  title,
  titleWrap,
  greyPar,
  threeSixty,
  outerGrey,
  outerContainer,
  pageWrap,
} from './streamlineSectionStyles';
import Image from '../../components/ui/Image';

export default function StreamlineSection() {
  return (
    <div css={pageWrap}>
      <div css={titleWrap}>
        <div css={title}>
          <div css={{ '@media(min-width: 768px)': { marginBottom: 47 } }}>
            <span css={{ fontWeight: 500 }}>How we streamline </span>
            your trailing docs
          </div>
        </div>
      </div>
      <div css={outerContainer}>
        <div css={{ position: 'relative', zIndex: 15 }}>
          <div className='md-flex' css={outerGrey}>
            <div css={secondLineStyles} />
            <div css={textBox}>
              <div css={documentServices}>
                <div css={innergreyContent}>
                  <div css={threeSixty}>
                    <span css={{ fontWeight: 500, marginRight: 6 }}>360°</span>
                    <span>Document Services</span>
                  </div>
                  <div css={endToEnd}>End-to-end customized fulfillment that makes your business easier.</div>
                  <div css={greyPar}>
                    Change the way your company handles post-closing operations with one complete fulfillment solution
                    for lenders. Our full-service process manages retrieving, processing, and shipping with faster
                    turnaround and lower costs. With our automated processes and LOS-integrated software, we get started
                    on your post-closing fulfillment as soon as you walk away from the closing table - which means you
                    can close with more confidence than ever.
                  </div>
                </div>
              </div>
            </div>
            <div css={imageStyles}>
              <Image image='bitmap_3x_c0uovr.jpg' styles={thinkingImageStyles} alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
