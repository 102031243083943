/** @jsx jsx */
import { jsx } from '@emotion/core';

import { accessOnDemand, everyDocument, outerWrap, innerWrap, imagesStyles, image } from './accessOnDemandStyles';
import Image from '../../components/ui/Image';

export default function AccessOnDemand() {
  return (
    <div css={{ maxWidth: 1440 }}>
      <div css={outerWrap}>
        <div css={innerWrap}>
          <div css={accessOnDemand}>
            Access On{' '}
            <span
              css={{
                '@media(min-width: 1260px)': {
                  fontWeight: 500,
                },
              }}
            >
              Demand
            </span>
          </div>
          <div css={everyDocument}>
            Every document is available to you when you need it, giving you the up-to-the-minute information you need to
            keep your business running smoothly.
          </div>
        </div>
        <div>
          <div css={imagesStyles}>
            <Image image='secondary-pages/1_Access_on_demand_vat1lr.png' alt='' styles={{ width: '100%' }} />
            <div css={image}>
              <Image image='secondary-pages/2_Access_on_demand_s3um98.png' alt='' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
