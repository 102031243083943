/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import {
  outerWrap,
  outer,
  titleOne,
  innerWrap,
  faster,
  processing,
  paragraph,
  imageStyles,
  wrapper,
  whiteLine,
  blackLine,
} from './improveRelationshipStyles';
import Image from '../../components/ui/Image';
export default function ImproveRelationship() {
  return (
    <Fragment>
      <div css={outerWrap}>
        <div css={outer}>
          <div css={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div css={titleOne}>
              <span css={{ fontWeight: 500 }}>How we improve</span> your secondary market relationships
            </div>
          </div>
          <div css={wrapper}>
            <div css={innerWrap}>
              <div css={faster}>
                <span css={{ fontWeight: 500 }}>Faster End-to-End</span> Fulfillment
              </div>
              <div css={processing}>Processing and delivery, on your schedule.</div>
              <div css={paragraph}>
                Meet your investors’ timeframes easily with our rapid document fulfillment service. Powered by our
                automated system and specialized follow-up services, your dedicated DocProbe team ensures every document
                is immediately retrieved, processed, and shipped to your investors when they need it.
              </div>
            </div>
            <Image image='bitmap_3x_b8vune.jpg' styles={imageStyles} alt='' />
          </div>
          <div css={whiteLine} />
        </div>
      </div>
      <div css={blackLine} />
    </Fragment>
  );
}
