/** @jsx jsx */
import { jsx } from '@emotion/core';

import kensieMaeImage from '../../images/partners/kensieMae.png';
import {
  title,
  outerWrap,
  imageStyles,
  paragraph,
  imageParStyles,
  lineStyles,
  horizontalLine,
} from './techPartnersStyles';

export default function TechPartners() {
  return (
    <div css={{ position: 'relative' }}>
      <div css={horizontalLine} />
      <div css={outerWrap} className='container'>
        <div css={lineStyles} />
        <div css={title}>Tech partners</div>
        <div css={imageParStyles}>
          <img src={kensieMaeImage} css={imageStyles} alt='' />
          <div css={paragraph}>
            KensieMae, LLC is a privately held consultation services company, specializing in Mortgage Banking & Lending
            operations. With over a decade of successful company start-up management, operational and underwriting
            management, workflow development, platform creation and expansion, and LOS design, support, training and
            implementation experience, KensieMae provides real-world, hands-on experience, guidance and expertise to
            today's challenging lending environment.
          </div>
        </div>
      </div>
    </div>
  );
}
