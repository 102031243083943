/** @jsx jsx */
import { jsx } from '@emotion/core';

import { outerWrap, imageStyles, innerWrap, informed, noMore, paragraph } from './informedReportingStyles';

import Image from '../../components/ui/Image';

export default function InformedReporting() {
  return (
    <div css={outerWrap}>
      <Image image='secondary-pages/relationship_Page_viwlr7.png' styles={imageStyles} alt='' />
      <div css={innerWrap}>
        <div css={informed}>
          Informed reporting <span css={{ fontWeight: 500 }}>and tracking</span>
        </div>
        <div css={noMore}>No more lost, forgotten, or overlooked documents.</div>
        <div css={paragraph}>
          Never lose sight of your documents with our retrieval processes, follow-up protocols, and proprietary tracking
          software. With our focused fulfillment team and easy-to-use reporting software, you can generate reports,
          track your pending documents, and ensure that your trailing document requirements are on-schedule for your,
          and your investors’, peace of mind.
        </div>
      </div>
    </div>
  );
}
