export const wrapper = {
  position: 'relative',
  marginTop: 130,
  '@media(min-width: 768px)': {
    marginTop: 180
  },
  '@media(min-width: 1260px)': {
    marginTop: 0
  }
};
export const horizontalLine = {
  borderTop: '1px solid black',
  position: 'absolute',
  content: '""',
  right: 0,
  top: -112,
  left: 0,
  width: '100%',
  marginTop: 65,
  '@media(min-width: 1260px)': {
    display: 'none'
  }
};
export const lineStyles = {
  borderLeft: '1px solid black',
  position: 'absolute',
  content: '""',
  right: '50%',
  top: -100,
  height: 110,
  '&:after': {
    borderTop: '1px solid black',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    top: 0
  },
  '@media(min-width: 768px)': {
    height: 165,
    top: -150
  },
  '@media(min-width: 1260px)': {
    height: 127,
    top: -90
  }
};
export const outerWrap = {
  paddingTop: 67,
  '@media(min-width: 1260px)': {
    display: 'inline-flex',
    borderTop: '1px solid black',
    borderRight: '1px solid black',
    width: '33.33%',
    paddingBottom: 47
  },
  ':last-child': {
    borderRight: 'none'
  }
};
export const sectionStyles = {
  textAlign: 'center',
  margin: '0 auto',
  '@media(min-width: 768px)': {
    display: 'flex',
    justifyContent: 'center'
  },
  '@media(min-width: 1260px)': {
    display: 'block'
  }
};
export const titleStyles = {
  fontWeight: 500,
  fontSize: 27,
  textAlign: 'center',
  lineHeight: 1.3,
  maxWidth: 230,
  margin: '38px auto 0',
  '@media(min-width: 768px)': {
    textAlign: 'left',
    margin: 0,
    paddingLeft: 15,
    width: '100%',
    maxWidth: 400
  },
  '@media(min-width: 1260px)': {
    fontSize: 37,
    maxWidth: 337,
    textAlign: 'center',
    marginTop: 20
  }
};
export const title1Styles = {
  '@media(min-width: 768px)': {
    fontWeight: 300
  }
};
export const paragraphStyles = {
  maxWidth: 306,
  lineHeight: 1.9,
  textAlign: 'center',
  margin: '11px auto 0',
  '@media(min-width: 768px)': {
    textAlign: 'left',
    maxWidth: 355,
    paddingLeft: 15
  },
  '@media(min-width: 1260px)': {
    fontSize: 16,
    lineHeight: 1.8,
    textAlign: 'center'
  }
};
export const linkStyles = {
  a:{
  textTransform: 'uppercase',
  letterSpacing: 3,
  fontWeight: 500,
  fontSize: 12,
  marginTop: 35,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  '@media(min-width: 768px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 15
  },
  '@media(min-width: 1260px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}
};
export const imageStyles = {
  width: 164,
  height: 164,
  margin: '0 auto',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    margin: 0,
    width: 115,
    height: 115
  },
  '@media(min-width: 1260px)': {
    margin: '0 auto'
  }
};
