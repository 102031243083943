export const greyBottom = {
  backgroundColor: '#f5f5f5',
  padding: '98px 0 52px 20px',
  '@media(min-width: 768px)': {
    marginTop: 70,
    paddingBottom: 88,
    height: '100%',
  },
  '@media(min-width: 1260px)': {
    padding: '174px 0 50px 20px',
  },
};
export const bottomHeader = {
  fontSize: 40,
  textAlign: 'center',
  maxWidth: 320,
  width: '100%',
  color: '#3b3f43',
  margin: '0 auto',
  '@media(min-width: 768px)': {
    maxWidth: 554,
    width: '100%',
  },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    fontWeight: 300,
    maxWidth: 730,
  },
};
export const bottomText = {
  fontSize: 25,
  textAlign: 'center',
  marginTop: 24,
};
export const buttonWrap = {
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '@media(min-width: 768px)': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
};
export const buttonStyles = {
  backgroundColor: '#64ae3e',
  border: 'none',
  color: '#fff',
  marginTop: 33,
  maxWidth: 291,
  width: '100%',
  '@media(min-width: 768px)': {
    marginTop: 46,
  },
  '@media(min-width: 1260px)': {
    marginTop: 59,
  },
};
