export const outerWrap = {
  borderLeft: '1px solid black',
  marginLeft: 20,
  marginBottom: 98,
  '@media(min-width: 1260px)': {
    maxWidth: 1200,
    margin: '0 auto',
  },
};

export const innerWrap = {
  padding: '43px 0 1px 20px',
  '@media(min-width: 768px)': {
    padding: '79px 0 1px 70px',
  },
  '@media(min-width: 1260px)': {
    padding: '121px 0 1px 112px',
  },
};

export const clients = {
  color: '#64ae3e',
  textTransform: 'uppercase',
  letterSpacing: 3,
  fontSize: 12,
  fontWeight: 500,
};

export const topLenders = {
  fontSize: 37,
  lineHeight: 1.22,
  maxWidth: 265,
  marginTop: 10,
  '@media(min-width: 768px)': {
    maxWidth: 681,
    marginTop: 16,
  },
};

export const imagesContainer = {
  margin: '57px 10px 50px 0',
  padding: '0 25px',
  '@media(min-width: 768px)': {
    margin: '68px 0px 60px',
    maxWidth: 616,
    padding: 0,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 700,
  },

  '@media(min-width: 1260px)': {
    margin: '86px 0px 121px',
    maxWidth: 1000,
  },
};

export const horizontalLine = {
  borderBottom: '1px solid black',
  position: 'absolute',
  content: '""',
  right: 0,
  bottom: 0,
  left: 0,
  width: '100%',
};

export const lineStyles = {
  borderLeft: '1px solid black',
  position: 'absolute',
  bottom: -79,
  left: '50%',
  height: 79,
  '&:after': {
    borderBottom: '1px solid black',
    width: 7,
    left: -4,
    content: '""',
    position: 'absolute',
    bottom: 0,
  },
  '@media(min-width: 1260px)': {
    display: 'none',
  },
  '@media(min-width: 768px)': {
    height: 56,
    bottom: -56,
  },
};
