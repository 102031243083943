import styled from "@emotion/styled";
import {makeStyles, Paper, Popper, Tooltip, withStyles} from "@material-ui/core";
import React from "react";

export const Tzvi = styled.div`
  :focus-within {
    .icon {
      path {
        fill: #29a3aa;
      }
    }
  }
`

export const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: '1px solid #e2e8f1', 
      borderRadius: 2,
    }, 
    "& .MuiInput-underline:hover:before, & .MuiInput-underline:before": {
      borderBottom: '1px solid #e2e8f1',
    },
    "& .MuiInput-underline:after": {
      borderBottom: '1px solid #29a3aa',
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#29a3aa",
      svg: {
        transition: 'all .2s',
      },
      ' svg': {
        transform: 'rotate(180deg)',
      },
    },
  },
  disabled: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "#b1b2b0"
    },
  },
  closeIcon: {
    position: 'absolute',
    opacity: 0.42,
    color: '#707070', 
    right: 24,
    top: 24,
    cursor: 'pointer',
    '& svg': {
      fontSize: 40,
    },
  },
  counties: {
    fontSize: 13,
    margin: '16px 24px',
    fontWeight: 700,
    color: '#686868',
    ":first-child": {margin: '0 24px 16px'},
    ":last-child": {margin: '16px 24px 0'},
    "& svg": { height: 16 },
  },
  tagText: {
    fontSize: 12,
    fontWeight: 700,
    fontFamily: 'system-ui',
    color: '#707070',
  }
}));

export const CustomTooltip = withStyles({
  tooltip: {
    width: 170,
    height: 42,
    padding: "14px 17px 19px 22px",
    fontSize: 12,
    fontWeight: "normal",
    backgroundColor: '#01666c',
    color: '#fff',
    left: 97,
    marginTop: -3,
  },
  arrow: {
    color: '#01666c',
  },
})(Tooltip);

export const wrapper = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  rowGap: "16px",
};

export const label = {
  fontSize: "14px",
  fontWeight: "bold",
  textAlign: "right",
  color: "#686868",
};

export const CustomPaper = (props) => {
  return <Paper
    {...props}
    style={{
      borderRadius: 2,
      boxShadow: '0 5px 15px 0 rgba(181, 182, 180, 0.16)',
      border: 'solid 1px #e2e8f1',
    }}
  />;
};

export const CustomPopper = function (props) {
  return (
    <Popper
      {...props}
      style={{ width: 232, position: "relative", zIndex: 10000 }}
      placement="bottom"
    />
  );
};