export const outerWrap = {
  padding: '57px 14px 0 14px',
  position: 'relative',
  '@media(min-width: 768px)': {
    padding: '55px 0 0 27px'
  },
  '@media(min-width: 1260px)': {
      maxWidth: 1033,
      paddingTop: 64,
      paddingBottom:41
    }
};
export const title = {
  fontWeight: 500,
  fontSize: 40,
  textAlign: 'center',
  paddingBottom: 88,
  '@media(min-width: 768px)': {
    textAlign: 'left',
    paddingBottom: 66
  },
  '@media(min-width: 1260px)': {
    fontSize: 50
  }
};
export const imageParSection = {
  '@media(min-width: 1260px)': {
    display: 'flex'
  }
};
export const lendersImageStyles = {
  paddingBottom: 20,
  height: 76,
  maxWidth: 237,
  '@media(min-width: 768px)': {
    paddingBottom: 29
  }
};
export const lendersPar = {
  lineHeight: 1.9,
  width: '100%',
  paddingBottom: 76,
  '@media(min-width: 768px)': {
    maxWidth: 621
  },
  '@media(min-width: 1260px)': {
    marginLeft: 52,
    maxWidth: 646,
    fontSize: 16,
    lineHeight: 1.8
  }
};
export const mortgageImageStyles = {
  paddingBottom: 20,
  height: 101,
  maxWidth: 237,
  '@media(min-width: 768px)': {
    paddingBottom: 29
  }
};
export const capitalImageStyles = {
  paddingBottom: 20,
  height: 123,
  '@media(min-width: 768px)': {
    paddingBottom: 29
  }
};
export const lineStyles = {
  '@media(max-width: 380px)': {
    display: 'none'
  },
  '@media(min-width: 768px)': {
    borderLeft: '1px solid #3b3f43',
    height: 1137,
    position: 'absolute',
    bottom: 0,
    right: 27
  },
  '@media(min-width: 1260px)': {
    right: -95,
    height: 874
  }
};
