export const outerWrap = {
  '@media(max-width: 1023px)': {
    display: 'none',
  },
  '@media(min-width: 1024px)': {
    backgroundColor: '#ffffff',
    borderRadius: 6,
    boxShadow: '-9px 11px 17px 0 rgba(0, 0, 0, 0.25)',
    left: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    top: -90,
    transform: 'scale(0.85)',
    width: 900,
    zIndex: 9000,
  },
  '@media(min-width: 1440px)': {
    width: 1122,
  },
};
export const topPart = {
  padding: '48px 60px',
  '@media(min-width: 1440px)': {
    padding: '48px 105px',
  },
};
export const triangleStyles = {
  height: 96,
  position: 'absolute',
  right: 0,
  top: 0,
  width: 96,
};
export const xStyles = {
  position: 'absolute',
  pointerEvents: 'none',
  right: 18,
  top: 18,
  svg: { color: '#fff' },
};
export const header = {
  color: '#3b3f43',
  fontSize: 50,
  letterSpacing: '-1.2px',
  lineHeight: 0.87,
  textAlign: 'center',
  '@media(min-width: 1440px)': {
    fontSize: 60,
  },
};
export const imageWrap = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: 76,
};
export const stepWrap = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  textAlign: 'center',
};
export const imageStyles = {
  '@media(min-width: 1024px)': {
    height: 130,
    width: 170,
  },
  '@media(min-width: 1440px)': {
    height: 207,
    width: 246,
  },
};
export const title = {
  color: '#3b3f43',
  fontSize: 38,
  fontWeight: 500,
  paddingTop: 29,
  '@media(min-width: 1440px)': {
    fontSize: 42,
  },
};
export const innerTitle = {
  color: '#3b3f43',
  fontSize: 22,
  fontWeight: 300,
  lineHeight: 1.5,
  paddingTop: 10,
  width: 200,
  margin: 'auto',
  '@media(min-width: 1440px)': {
    fontSize: 24,
    width: 224,
  },
};
export const bottomPart = {
  backgroundColor: '#dad9d9',
  height: 225,
  padding: '40px 98px',
  width: '100%',
};
export const showYou = {
  fontSize: 36,
  letterSpacing: '-0.72px',
  lineHeight: 1.44,
};
export const inputStyles = {
  backgroundColor: '#f8f8f8',
  border: '1px solid #ececec',
  borderRadius: 4,
  height: 52,
  marginRight: 36,
  width: 600,
  '@media(min-width: 1440px)': {
    width: 627,
  },
};
export const buttonStyles = {
  backgroundColor: '#64ae3e',
  border: 'none',
  color: '#fff',
  maxWidth: 170,
  width: '100%',
  '@media(min-width: 1440px)': {
    maxWidth: 262,
  },
};
