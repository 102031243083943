export const bar = `
   <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;">
      <tr>
         <td align="center" style="font-size:0;">
            <!--[if (gte mso 9)|(IE)]>
            <table align="center" border="0" cellspacing="0" cellpadding="0" width="800" style="width:800px;">
               <tr>
                  <td align="center" valign="top" width="800" style="width:800px;">
                     <![endif]-->
                     <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:800px;">
                        <tr>
                           <td class="chbg" bgcolor="#FFFFFF" valign="bottom" align="center"
                              style="font-size:0;background-image:url(https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/2ae24769-7b72-46e8-8521-3d4b50e20b79.jpg);background-repeat:no-repeat;background-position:center top;padding-top:70px;">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0" align="center">
                                 <tr>
                                    <td width="78" style="font-size:0;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/1aeb3179-b4ea-4928-94b2-a0b65a41cf6d.png" width="10" height="1"
                                       alt="10p.png">
                                    </td>
                                    <td valign="bottom" style="font-size:0;" align="center">
                                       <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;">
                                          <tr>
                                             <td valign="top" align="center">
                                                <div class="f32" style="font-family: 'aktiv-grotesk, Muli', sans-serif;  font-size: 46px;  line-height:50px;  font-weight: bold;   text-align: center;  color: #61ab3d;">
                                                   Here's why <br />
                                                   lenders <span style="white-space:nowrap">nationwide</span>
                                                </div>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td valign="top" align="center" style="padding:25px 0px 35px 0px;">
                                                <div class="f19"
                                                   style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:24px;font-weight:normal;font-style:normal;line-height:1.33;letter-spacing:normal;text-align:center;color:#000000;">
                                                   trusted DocProbe to track, correct and ship over <span style="color: #61ab3d; font-weight:bold">7,000,000</span> pages of Trailing Docs last year while they focused on
                                                   closing more loans.
                                                </div>
                                             </td>
                                          </tr>
                                          <tr>
                                             <td valign="top" align="center" style="font-size:0;">
                                                <!--[if (gte mso 9)|(IE)]>
                                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="644" style="width:644px;">
                                                   <tr>
                                                      <td align="center" valign="top" width="644" style="width:644px;">
                                                         <![endif]-->
                                                         <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;">
                                                            <tr>
                                                               <td valign="bottom" align="center"
                                                                  style="background-color:#6aab36;-webkit-border-top-left-radius:13px;-webkit-border-top-right-radius:13px;border-top-left-radius:13px;border-top-right-radius:13px;padding:22px 0px 0px 0px;"
                                                                  bgcolor="#6aab36">
                                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                     <tr class="displaymobile" style="display:none;">
                                                                        <td align="center" style="padding:0px 10px 5px 10px;">
                                                                           <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#ffffff;">Before
                                                                              DocProbe
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td align="center" style="padding:0px 10px 20px 10px;">
                                                                           <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:11px;font-weight:normal;line-height:13px;text-align:center;color:#ffffff;">
                                                                              Costly overhead and focus on non-revenue activities
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                     <tr class="hidemobile">
                                                                        <td align="center" style="padding-bottom:25px;">
                                                                           <div align="center"
                                                                              style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:35px;font-weight:bold;font-style:normal;line-height:1;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                              Before DocProbe
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                     <tr class="hidemobile">
                                                                        <td valign="bottom" style="font-size:0;">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;">
                                                                              <tr class="hidemobile">
                                                                                 <td width="33%" valign="top" style="border-right:#588e2d solid 1px;padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Fixed overhead during <br>
                                                                                                market fluctuations
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Costly errors and delays
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding-bottom:25px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Staff shifted to non-revenue<br>
                                                                                                Trailing Documents 
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                                 <td width="33%" valign="top" style="border-right:#588e2d solid 1px;padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Tedious <br>
                                                                                                follow-up
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Overworked staff
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding-bottom:25px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Disorganized<br>
                                                                                                fulfillment
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                                 <td width="33%" valign="top" style="padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Errors and missing<br>
                                                                                                documents
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Late deliveries and<br>
                                                                                                penalties 
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="padding:5px 0px;font-size:0;">
                                                                                             <div style="line-height:8px;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/b8a82524-941d-454d-b673-a634581b5e00.png"
                                                                                                width="7" height="8" style="display:block; width:7px; max-width:7px; height:auto; margin:auto;" alt="group-6.png">
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding-bottom:25px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:14px; line-height: 21px;font-weight:500;font-style:normal;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                                                                Upset investors
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                         <!--[if (gte mso 9)|(IE)]>
                                                      </td>
                                                   </tr>
                                                </table>
                                                <![endif]-->
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                    <td width="78" style="font-size:0;"><img src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/1aeb3179-b4ea-4928-94b2-a0b65a41cf6d.png" width="10" height="1"
                                       alt="10p.png">
                                    </td>
                                 </tr>
                                 <tr>
                                    <td width="78" class="no-radius" bgcolor="#203E34" style="font-size:0;background-color:#203E34; border-top-left-radius: 15px;"> </td>
                                    <td valign="top" style="font-size:0;">
                                       <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;">
                                          <tr>
                                             <td valign="top" align="center" style="font-size:0;background-color:#203E34;" bgcolor="#203E34">
                                                <!--[if (gte mso 9)|(IE)]>
                                                <table align="center" border="0" cellspacing="0" cellpadding="0" width="644" style="width:644px;">
                                                   <tr>
                                                      <td align="center" valign="top" width="644" style="width:644px;">
                                                         <![endif]-->
                                                         <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;">
                                                            <tr>
                                                               <td valign="bottom" align="center"
                                                                  style="background-color:#ffffff;-webkit-border-bottom-right-radius:13px;-webkit-border-bottom-left-radius:13px;border-bottom-right-radius:13px;border-bottom-left-radius:13px;padding:22px 0px 0px 0px;"
                                                                  bgcolor="#6aab36">
                                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                     <tr class="displaymobile" style="display:none;">
                                                                        <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                           <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#424245;">After
                                                                              DocProbe
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                     <tr class="displaymobile" style="display:none;">
                                                                        <td align="center" style="font-size:0;vertical-align:top;" height="34"><img
                                                                           src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/713044d0-f50f-4e15-8b81-3b570caa4807.png" width="23" height="24"
                                                                           style="display:block; width:23px; max-width:23px; height:auto; margin:auto;" alt="group-11.png">
                                                                        </td>
                                                                     </tr>
                                                                     <tr class="displaymobile" style="display:none;">
                                                                        <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                           <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:20px;font-weight:bold;line-height:21px;text-align:center;color:#61ab3d;">Lower
                                                                              Costs
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                     <tr class="displaymobile" style="display:none;">
                                                                        <td style="padding:0px 10px 22px 10px;font-size:0;" align="center">
                                                                           <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:13px;font-weight:normal;line-height:16px;text-align:center;color:#61ab3d;">File
                                                                              based fees 
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                                  <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                     <tr class="hidemobile">
                                                                        <td align="center" style="padding-bottom:25px;padding-left:10px;padding-right:10px;">
                                                                           <div align="center"
                                                                              style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:35px;font-weight:bold;font-style:normal;line-height:1;letter-spacing:normal;text-align:center;color:#424245;">
                                                                              After DocProbe
                                                                           </div>
                                                                        </td>
                                                                     </tr>
                                                                     <tr class="hidemobile">
                                                                        <td valign="bottom" style="font-size:0;">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                              <tr class="hidemobile">
                                                                                 <td width="33%" valign="top" style="border-right:#d5d5d5 solid 1px;padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td valign="middle" height="75">
                                                                                             <div style="font-size:10px;line-height:12px;"> </div>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:21px;font-weight:bold;line-height:25px;letter-spacing:normal;text-align:center;color:#61ab3d;">
                                                                                                Lower <br>
                                                                                                Costs
                                                                                             </div>
                                                                                             <div style="font-size:10px;line-height:12px;"> </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="font-size:0;" height="20">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/713044d0-f50f-4e15-8b81-3b570caa4807.png" width="23" height="24"
                                                                                             style="display:block; width:23px; max-width:23px; height:auto; margin:auto;" alt="group-11.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                File based fees<br>
                                                                                                and no long-term<br>
                                                                                                contract
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/7c52050c-909e-4495-9652-864f24fadf9f.png" width="25" height="19"
                                                                                             style="display:block; width:25px; max-width:25px; height:auto; margin:auto;" alt="group-11a.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;white-space:nowrap;">
                                                                                                Shipping included
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/f5a4b9ca-6811-4a85-8d52-07a0280d6ae9.png" width="20" height="22"
                                                                                             style="display:block; width:20px; max-width:20px; height:auto; margin:auto;" alt="group-13.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                Total focus on <br>
                                                                                                closing loans
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                                 <td width="33%" valign="top" style="border-right:#d5d5d5 solid 1px;padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td valign="middle" height="75">
                                                                                             <div style="font-size:10px;line-height:12px;"> </div>
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:21px;font-weight:bold;line-height:25px;letter-spacing:normal;text-align:center;color:#61ab3d;">
                                                                                                Increased <br>
                                                                                                Efficiency
                                                                                             </div>
                                                                                             <div style="font-size:10px;line-height:12px;"> </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="font-size:0;" height="20">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/7d8bc224-ee7f-4988-816e-1e3160c635ae.png" width="24" height="19"
                                                                                             style="display:block; width:24px; max-width:24px; height:auto; margin:auto;" alt="group-9.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                Technology driven<br>
                                                                                                end-to-end tracking<br>
                                                                                                process
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/135826f9-accf-41de-8031-08c83b5f7d0f.png" width="24" height="23"
                                                                                             style="display:block; width:24px; max-width:24px; height:auto; margin:auto;" alt="group-11b.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;white-space:nowrap;">
                                                                                                Superior follow-up
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/ef3059c2-6568-461d-bc05-e133ae667153.png" width="23" height="22"
                                                                                             style="display:block; width:23px; max-width:23px; height:auto; margin:auto;" alt="group-7.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                24/7 access to<br>
                                                                                                documents
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                                 <td width="33%" valign="top" style="padding:0px 10px;">
                                                                                    <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;" class="hidemobile">
                                                                                       <tr class="hidemobile">
                                                                                          <td valign="middle" height="75">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:21px;font-weight:bold;line-height:25px;letter-spacing:normal;text-align:center;color:#61ab3d;">
                                                                                                Improved<br>
                                                                                                Investor<br>
                                                                                                Relationships
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="font-size:0;" height="20">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/4315009d-b9ef-4190-886c-50b829861887.png" width="22" height="22"
                                                                                             style="display:block; width:22px; max-width:22px; height:auto; margin:auto;" alt="group-9a.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                Expert auditing<br>
                                                                                                and corrections<br>
                                                                                                included
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/9c058593-642c-40ce-b189-ff700ba7b0b0.png" width="25" height="22"
                                                                                             style="display:block; width:25px; max-width:25px; height:auto; margin:auto;" alt="group-15.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;white-space:nowrap;">
                                                                                                On time deliveries
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td align="center" style="font-size:0;vertical-align:top;" height="40"><img
                                                                                             src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/31a0bf4f-75ed-470f-872d-f437f765f5e5.png" width="18" height="23"
                                                                                             style="display:block; width:18px; max-width:18px; height:auto; margin:auto;" alt="group-5.png">
                                                                                          </td>
                                                                                       </tr>
                                                                                       <tr class="hidemobile">
                                                                                          <td style="padding:0px 0px 20px 0px;">
                                                                                             <div align="center"
                                                                                                style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#000000;">
                                                                                                No penalty <br>
                                                                                                guarantee
                                                                                             </div>
                                                                                          </td>
                                                                                       </tr>
                                                                                    </table>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                         <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;display:none;" class="displaymobile">
                                                            <tr style="display:none;" class="displaymobile">
                                                               <td valign="top" align="center" style="font-size:0;padding:33px 0px;">
                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;display:none;" class="displaymobile">
                                                                     <tr style="display:none;" class="displaymobile">
                                                                        <td valign="bottom" align="center"
                                                                           style="background-color:#6aab36;-webkit-border-top-left-radius:13px;-webkit-border-top-right-radius:13px;border-top-left-radius:13px;border-top-right-radius:13px;padding:22px 0px 0px 0px;font-size:0;"
                                                                           bgcolor="#6aab36">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td align="center" style="padding:0px 10px 5px 10px;font-size:0;">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#ffffff;">
                                                                                       Before DocProbe
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td align="center" style="padding:0px 10px 20px 10px;font-size:0;">
                                                                                    <div align="center"
                                                                                       style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:11px;font-weight:normal;line-height:13px;text-align:center;color:#ffffff;">Tedious &amp;
                                                                                       Disorganized fulfillment
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td valign="bottom" align="center"
                                                                           style="background-color:#ffffff;-webkit-border-bottom-right-radius:13px;-webkit-border-bottom-left-radius:13px;border-bottom-right-radius:13px;border-bottom-left-radius:13px;padding:22px 0px 0px 0px;font-size:0;"
                                                                           bgcolor="#6aab36">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#424245;">
                                                                                       After DocProbe
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td align="center" style="font-size:0;vertical-align:top;" height="34"><img
                                                                                    src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/135826f9-accf-41de-8031-08c83b5f7d0f.png" width="24" height="23"
                                                                                    style="display:block; width:24px; max-width:24px; height:auto; margin:auto;" alt="group-11b.png">
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:20px;font-weight:bold;line-height:21px;text-align:center;color:#61ab3d;">
                                                                                       Increased<br>
                                                                                       Efficiency
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 22px 10px;font-size:0;" align="center">
                                                                                    <div align="center"
                                                                                       style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:13px;font-weight:normal;line-height:16px;text-align:center;color:#61ab3d;">Superior
                                                                                       followup and<br>
                                                                                       tracking process
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                            <tr class="displaymobile" style="display:none;">
                                                               <td valign="top" align="center" style="font-size:0;padding:0px;">
                                                                  <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:644px;display:none;" class="displaymobile">
                                                                     <tr style="display:none;" class="displaymobile">
                                                                        <td valign="bottom" align="center"
                                                                           style="background-color:#6aab36;-webkit-border-top-left-radius:13px;-webkit-border-top-right-radius:13px;border-top-left-radius:13px;border-top-right-radius:13px;padding:22px 0px 0px 0px;"
                                                                           bgcolor="#6aab36">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td align="center" style="padding:0px 10px 5px 10px;">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#ffffff;">
                                                                                       Before DocProbe
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr>
                                                                                 <td align="center" style="padding:0px 10px 20px 10px;">
                                                                                    <div align="center"
                                                                                       style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:11px;font-weight:normal;line-height:13px;text-align:center;color:#ffffff;">Errors,
                                                                                       penalties &amp; upset investors
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                     <tr>
                                                                        <td valign="bottom" align="center"
                                                                           style="background-color:#ffffff;-webkit-border-bottom-right-radius:13px;-webkit-border-bottom-left-radius:13px;border-bottom-right-radius:13px;border-bottom-left-radius:13px;padding:22px 0px 0px 0px;"
                                                                           bgcolor="#6aab36">
                                                                           <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;display:none;" class="displaymobile">
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:bold;line-height:31px;text-align:center;color:#424245;">
                                                                                       After DocProbe
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td align="center" style="font-size:0;vertical-align:top;" height="34"><img
                                                                                    src="https://mcusercontent.com/cc149a4cdeef74df196937c8d/images/7d8bc224-ee7f-4988-816e-1e3160c635ae.png" width="24" height="19"
                                                                                    style="display:block; width:24px; max-width:24px; height:auto; margin:auto;" alt="group-9.png">
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 10px 10px;font-size:0;" align="center">
                                                                                    <div align="center" style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:20px;font-weight:bold;line-height:21px;text-align:center;color:#61ab3d;">
                                                                                       Improved Investor <br>
                                                                                       Relationships
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                              <tr class="displaymobile" style="display:none;">
                                                                                 <td style="padding:0px 10px 22px 10px;font-size:0;" align="center">
                                                                                    <div align="center"
                                                                                       style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:13px;font-weight:normal;line-height:16px;text-align:center;color:#61ab3d;">All Docs
                                                                                       corrected and<br>
                                                                                       delivered on time
                                                                                    </div>
                                                                                 </td>
                                                                              </tr>
                                                                           </table>
                                                                        </td>
                                                                     </tr>
                                                                  </table>
                                                               </td>
                                                            </tr>
                                                         </table>
                                                         <!--[if (gte mso 9)|(IE)]>
                                                      </td>
                                                   </tr>
                                                </table>
                                                <![endif]-->
                                             </td>
                                          </tr>
                                       </table>
                                    </td>
                                    <td width="78" bgcolor="#203E34" class="no-radius" style="font-size:0;background-color:#203E34; border-top-right-radius: 15px"> </td>
                                 </tr>
                              </table>
                           </td>
                        </tr>
                        <tr>
                           <td bgcolor="#203e34" valign="top" align="center" style="font-size:0;background-color:#203e34;padding:48px 20px 70px 20px; border-radius: 0 0 15px 15px" class="no-radius">
                              <table width="100%" border="0" cellspacing="0" cellpadding="0" style="min-width:100%;">
                                 <tr>
                                    <td align="center">
                                       <!--[if (gte mso 9)|(IE)]>
                                       <table align="center" border="0" cellspacing="0" cellpadding="0" width="560" style="width:560px;">
                                          <tr>
                                             <td align="center" valign="top" width="560" style="width:560px;">
                                                <![endif]-->
                                                <table border="0" cellpadding="0" cellspacing="0" width="100%" align="center" style="max-width:560px;">
                                                   <tr>
                                                      <td valign="top" align="center">
                                                         <div align="center"
                                                            style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:38px;font-weight:bold;line-height:1;letter-spacing:normal;text-align:center;color:#ffffff;">Get in Touch
                                                         </div>
                                                      </td>
                                                   </tr>
                                                   <tr>
                                                      <td valign="top" align="center" style="padding-bottom:15px;">
                                                         <div align="center"
                                                            style="font-family:aktiv-grotesk, Muli, sans-serif;font-size:16px;font-weight:normal;font-style:normal;line-height:1.25;letter-spacing:normal;text-align:center;color:#ffffff;">
                                                            for details and to find out about our seamless and quick setup. We'll have you up and running in hours. 
                                                         </div>
                                                      </td>
                                                   </tr>
                                                </table>
                                                <!--[if (gte mso 9)|(IE)]>
                                             </td>
                                          </tr>
                                       </table>
                                       <![endif]-->
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style="font-size:0;" align="center">
                                       <!--[if (gte mso 9)|(IE)]>
                                       <table align="center" border="0" cellspacing="0" cellpadding="0" width="430">
                                          <tr>
                                             <td align="left" valign="top" width="215">
                                                <![endif]-->
                                                <div style="display:inline-block;max-width:215px;vertical-align:top;width:100%;" class="deviceWidth">
                                                   <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:215px;" class="deviceWidth">
                                                      <tr>
                                                         <td valign="middle" align="center" style="font-size:0;padding:10px 5px;">
                                                            <table width="175" border="0" cellspacing="0" cellpadding="0" align="center">
                                                               <tr>
                                                                  <td style="padding:7px 0px;background-color:#FFF;-webkit-border-radius:25px;border-radius:25px; cursor: pointer;" align="center" valign="middle" bgcolor="#FFFFFF">
                                                                     <div align="center" style="font-family:Muli, sans-serif;font-size:15px;line-height:25px;color:#61ab3d;text-decoration:none;font-weight:normal;"><a
                                                                        href="http://www.docprobe.net" target="_blank" style="color:#61ab3d;text-decoration:none;font-weight:normal:">LEARN MORE</a>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </table>
                                                         </td>
                                                      </tr>
                                                   </table>
                                                </div>
                                                <!--[if (gte mso 9)|(IE)]>
                                             </td>
                                             <td align="left" valign="top" width="215">
                                                <![endif]-->
                                                <div style="display:inline-block;max-width:215px;vertical-align:top;width:100%;" class="deviceWidth">
                                                   <table align="center" border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width:215px;" class="deviceWidth">
                                                      <tr>
                                                         <td valign="middle" align="center" style="font-size:0;padding:10px 5px;">
                                                            <table width="175" border="0" cellspacing="0" cellpadding="0" align="center">
                                                               <tr>
                                                                  <td style="padding:7px 0px;background-color:#FFF;-webkit-border-radius:25px;border-radius:25px; cursor: pointer;" align="center" valign="middle" bgcolor="#FFFFFF">
                                                                     <div align="center" style="font-family:Muli, sans-serif;font-size:15px;line-height:25px;color:#61ab3d;text-decoration:none;font-weight:normal;"><a
                                                                        href="mailto:nerlanger@docprobe.net" style="color:#61ab3d;text-decoration:none;font-weight:normal;">EMAIL US</a>
                                                                     </div>
                                                                  </td>
                                                               </tr>
                                                            </table>
                                                         </td>
                                                      </tr>
                                                   </table>
                                                </div>
                                                <!--[if (gte mso 9)|(IE)]>
                                             </td>
                                          </tr>
                                       </table>
                                       <![endif]-->
                                    </td>
                                 </tr>
                              </table>
                           </td>
                        </tr>
                     </table>
                     <!--[if (gte mso 9)|(IE)]>
                  </td>
               </tr>
            </table>
            <![endif]-->
         </td>
      </tr>
   </table>`;
