export const inner = {
  maxWidth: 1314,
  margin: '0 auto 79px',
  width: '100%',
  '@media(min-width: 768px)': {
    position: 'relative',
  },
  '@media(min-width: 1260px)': {
    paddingBottom: 100,
    marginBottom: 129,
  },
};

export const textBox = {
  width: '100%',
  paddingTop: 44,
  paddingBottom: 40,
  '@media(min-width: 768px)': {
    width: 'calc(50% + 22px)',
    paddingLeft: 33,
    paddingRight: 45,
    paddingTop: 60,
    padding: '60px 45px 0px 33px',
  },
  '@media(min-width: 1260px)': {
    paddingLeft: 90,
    paddingTop: 153,
  },
};

export const imageWrap = {
  width: '100%',
  position: 'relative',
  img: { width: '100%' },
  '@media(min-width: 768px)': {
    left: '50%',
    position: 'absolute',
    top: -60,
    width: 344,
  },
  '@media(min-width: 1024px)': {
   width:400
  },
  '@media(min-width: 1260px)': {
    width: 554,
    top: -77,
    borderLeft: '1px solid #e5e5e5',
    paddingBottom:145
  },

};

export const textWrap = {
  padding: '0 20px',
  maxWidth: 456,
  '@media(min-width: 768px)': { paddingLeft: 0 },
};
export const audited = {
  color: 'white',
  fontSize: 27,
  lineHeight: 1.3,
  maxWidth: 296,
  '@media(min-width: 1260px)': {
    fontSize: 37,
    maxWidth: 437,
  },
};
export const streamlined = {
  color: '#7cc99e',
  fontSize: 20,
  fontWeight: 500,
  lineHeight: 1.3,
  paddingTop: 10,
  '@media(min-width: 1260px)': {
    fontSize: 22,
    maxWidth: 456,
  },
};
export const documentErrors = {
  color: 'white',
  lineHeight: 1.9,
  paddingTop: 17,
  '@media(min-width: 1260px)': {
    fontSize: 16,
    lineHeight: 1.8,
  },
};
