/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, Component, useState, useRef, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';

import { spinnerS } from '../components/ui/Spinner';
import doorDashCardImg from '../images/retention/Doordash-Large.png';
import amazonCardImg from '../images/retention/Amazon-Small.png';
import targetCardImg from '../images/retention/Target-Small.png';
import visaCardImg from '../images/retention/Visa-Small.png';
import signature from '../images/home/signature.png';
import nErlangerImage from '../images/home/nick-erlanger-large.png';
import nickImgTwo from '../images/home/n-erlanger.png';
import { getTrackingStringForEmail } from '../../src/seo-tracking';
import CalendlyEmbed from '../components/ui/CalendlyEmbed';

import {
  bannerStyles,
  bannerWrap,
  buttonStyles,
  buttonWrap,
  completeForm,
  detailsStyles,
  formWrap,
  giftCard,
  giftCardWrap,
  header,
  innerGiftCardWrap,
  otherOptions,
  outerWrap,
  pageWrap,
  plusIcon,
  optionsTextStyles,
  uberEatsStyles,
  wrapper,
  thankYouWrap,
  thankYou,
  signatureStyles,
  team,
  chatWrap,
  nErlangerImageStyles,
  speech,
  innerChatWrap,
  buttonStyles2,
  nickAnimation,
} from './clientRetentionStyles';
import Button from '../components/ui/Button';
import { PlusIcon, SelectedCircleIcon, CircleIcon, CalendarIcon, RightArrowIcon } from '../components/ui/icons';
import { validEmail } from '../Utils';

export default class ClientRetention extends Component {
  state = {
    selectedCardsState: [true, false, false, false],
    isExpanded: false,
    submitted: false,
    contactFormData: { firstName: '', lastName: '', company: '', email: '' },
    isLoading: false,
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.mobileMenuOpen !== this.props.mobileMenuOpen) {
      const { selectedCardsState } = this.state;
      this.setState({
        selectedCardsState: selectedCardsState.map((_) => false),
      });
    }
  };

  toggleMenuExpansion = (index) => {
    let { selectedCardsState } = this.state;
    if (selectedCardsState[index]) {
      //if one being clicked is already open, set all to false so that it gets closed
      selectedCardsState = selectedCardsState.map((_) => false);
    } else {
      //if a different one is being clicked, set only THAT one to be true so that all others close
      selectedCardsState = this.state.selectedCardsState.map((_, i) => i === index);
    }
    this.setState({ selectedCardsState });
  };

  onSubmit = async () => {
    const selectedIndex = this.state.selectedCardsState.findIndex((s) => s);
    let selectedCard = '';

    switch (selectedIndex) {
      case 0:
        selectedCard = 'DoorDash';
        break;
      case 1:
        selectedCard = 'Amazon';
        break;
      case 2:
        selectedCard = 'Target';
        break;
      case 3:
        selectedCard = 'Visa';
        break;
      default:
        selectedCard = "So... for some reason, no card was selected. You've got a bug";
    }

    this.setState({ isLoading: true });
    const formData = {
      ...this.state.contactFormData,
      tracking: getTrackingStringForEmail(),
      giftCard: selectedCard,
    };
    await axios.post(
      'https://prod-20.eastus.logic.azure.com:443/workflows/9559b8fcfbc44617b48f8ddbc3cdf051/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=Rq8qii98xg5EwL9usjXulANZIRSwQ9p3zpoYI17mN_0',
      formData,
    );

    this.setState({ submitted: true, isLoading: true });
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  render() {
    const { isExpanded, submitted, contactFormData, isLoading } = this.state;
    const [uberSelected, nordstromSelected, homeDepotSelected, bedBathSelected] = this.state.selectedCardsState;
    return (
      <Fragment>
        <Helmet>
          <title>Dinner On Us | DocProbe</title>
          <meta name='description' content='Dinner On Us' />
        </Helmet>
        {submitted ? (
          <ThankYou />
        ) : (
          <div css={pageWrap}>
            <div css={outerWrap}>
              <div css={bannerWrap}>
                <div css={bannerStyles}></div>
                <img src={doorDashCardImg} css={uberEatsStyles} alt='' />
              </div>
              <div css={header}>
                Have Dinner <span css={{ fontWeight: 'bold' }}>on Us!</span>
              </div>
            </div>
            <div css={completeForm}>
              Please complete the form below and we will email you your <span>DoorDash gift card</span> for your
              enjoyment.
            </div>
            <div css={wrapper}>
              <Form
                isLoading={isLoading}
                contactFormData={contactFormData}
                updateFormData={(field, text) =>
                  this.setState({
                    contactFormData: { ...contactFormData, [field]: text },
                  })
                }
              />
              <div css={optionsTextStyles}>
                <div css={plusIcon} onClick={() => this.setState({ isExpanded: !isExpanded })}>
                  <PlusIcon />
                </div>
                <div css={otherOptions}>
                  Alternatively, choose from one of these other options and let us thank you in the way that you will
                  enjoy best.
                </div>
              </div>
              {isExpanded && (
                <div css={giftCardWrap}>
                  <GiftCard
                    card={doorDashCardImg}
                    isSelected={uberSelected}
                    onClick={() => this.toggleMenuExpansion(0)}
                  />
                  <GiftCard
                    card={amazonCardImg}
                    isSelected={nordstromSelected}
                    onClick={() => this.toggleMenuExpansion(1)}
                  />
                  <GiftCard
                    card={targetCardImg}
                    isSelected={homeDepotSelected}
                    onClick={() => this.toggleMenuExpansion(2)}
                  />
                  <GiftCard
                    card={visaCardImg}
                    isSelected={bedBathSelected}
                    onClick={() => this.toggleMenuExpansion(3)}
                  />
                </div>
              )}
              <div css={buttonWrap}>
                <Button
                  text='Submit'
                  type='submit'
                  css={buttonStyles}
                  disabled={
                    !(
                      contactFormData.firstName &&
                      contactFormData.lastName &&
                      contactFormData.company &&
                      validEmail(contactFormData.email)
                    )
                  }
                  onClick={this.onSubmit}
                />
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

function Form({ contactFormData: { firstName, lastName, company, email }, updateFormData, isLoading }) {
  return (
    <form css={formWrap}>
      {isLoading && (
        <div css={spinnerS}>
          <svg xmlns='http://www.w3.org/2000/svg' width='56' height='55' viewBox='0 0 56 55'>
            <g fill='none' fillRule='evenodd'>
              <path
                fill='#fff'
                fillRule='nonzero'
                d='M22.638 54.287C7.816 51.312-1.822 36.831 1.153 22.01 4.13 7.186 18.61-2.452 33.432.524c14.822 2.975 24.46 17.455 21.485 32.278C51.94 47.624 37.46 57.263 22.638 54.287zM32.42 5.564C20.376 3.146 8.61 10.977 6.194 23.021 3.776 35.065 11.607 46.829 23.65 49.247c12.044 2.418 23.809-5.413 26.226-17.457C52.294 19.747 44.463 7.982 32.42 5.564z'
                opacity='.4'
              />
              <path
                fill='#64ae3e'
                d='M54.917 32.802l-5.04-1.012C52.293 19.747 44.462 7.982 32.42 5.564l1.012-5.04c14.822 2.975 24.46 17.455 21.485 32.278z'
              />
            </g>
          </svg>
        </div>
      )}
      <input
        type='text'
        name='firstName'
        css={detailsStyles}
        placeholder='First Name'
        className='name-input__wrap'
        value={firstName}
        onChange={(e) => updateFormData('firstName', e.target.value)}
      />
      <input
        type='text'
        name='lastName'
        css={detailsStyles}
        placeholder='Last Name'
        className='last-input__wrap'
        value={lastName}
        onChange={(e) => updateFormData('lastName', e.target.value)}
      />
      <input
        type='text'
        name='company'
        css={detailsStyles}
        placeholder='Company'
        className='company-input__wrap'
        value={company}
        onChange={(e) => updateFormData('company', e.target.value)}
      />
      <input
        type='email'
        name='email'
        css={detailsStyles}
        placeholder='Email'
        className='email-input__wrap'
        value={email}
        onChange={(e) => updateFormData('email', e.target.value)}
      />
    </form>
  );
}

function GiftCard({ card, onClick, isSelected }) {
  // const [isSelected, setSelected] = useState(false);
  return (
    <div css={innerGiftCardWrap} onClick={onClick}>
      {isSelected ? <SelectedCircleIcon /> : <CircleIcon />}
      <div css={[giftCard, isSelected && { '&:before': { borderWidth: 5 } }]}>
        <img src={card} alt='' />
      </div>
    </div>
  );
}

function ThankYou() {
  const [showCalendly, setShowCalendly] = useState(false);

  const handleCalendly = () => {
    setShowCalendly(true);
  };

  return (
    <div css={thankYouWrap}>
      {showCalendly ? (
        <div>
          <CalendlyEmbed
            account='nerlanger'
            eventName='catch-up-with-nick?hide_event_type_details=1&text_color=3b3f43&primary_color=64ae3e'
          />
        </div>
      ) : (
        <div>
          <div>
            <div css={thankYou}>
              Your Card is on it’s way! Thank you for your friendship & partnership with DocProbe. Enjoy!
            </div>
            {/* <div css={chatWrap}>
              <div className='relative hidden'>
                <img src={nErlangerImage} alt='' css={[nErlangerImageStyles, nickAnimation]} />
                <img
                  src={nickImgTwo}
                  alt=''
                  css={[
                    nErlangerImageStyles,
                    {
                      position: 'absolute',
                      top: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                    },
                  ]}
                />
              </div>
              <div css={innerChatWrap}>
                <div css={speech} className='aktiv-grotesk-ex'>
                  I look forward to catching up with you face to face. Until then, when is a good time to chat?
                </div>
                <div css={buttonStyles2} onClick={handleCalendly}>
                  <div className='df aic'>
                    <CalendarIcon />
                    Schedule a Call
                  </div>
                  <RightArrowIcon />
                </div>
              </div>
            </div> */}
            <div
              css={{
                '@media(min-width: 1024px)': {
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 60,
                },
              }}
            >
              <img src={signature} alt='' css={signatureStyles} />
              <div css={team}>Nick & the DocProbe Team</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
