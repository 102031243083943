/** @jsx jsx */
import { jsx } from '@emotion/core';

import { grayBoxTop, headerWrap, header, imageStyle } from './secondaryHeroStyles';

import Image from '../ui/Image';
import triangleImage from '../../images/scondary-pages/white-triangle.png';

const triangleStyles = {
  height: 95,
  width: '100%',
  '@media(min-width: 768px)': {
    height: 125,
  },
  '@media(min-width: 1260px)': {
    height: 150,
  },
};

export default function SecondaryHero({ image, children, imageStyles, headerStyles, wrapStyles }) {
  return (
    <div css={grayBoxTop}>
      <div className='container' css={[headerWrap, wrapStyles]}>
        <h2 css={[header, headerStyles]}>{children}</h2>
        <Image image={image} styles={[imageStyle, imageStyles]} alt='' />
      </div>
      <img src={triangleImage} css={triangleStyles} alt='' />
    </div>
  );
}
