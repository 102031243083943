/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

const textWrap = {
  fontSize: 15,
  lineHeight: 1.5,
  margin: '0 auto 30px',
  maxWidth: 824,
  padding: '0 20px',
  textAlign: 'justify',
  span: {
    display: 'block',
    fontSize: 16,
    fontWeight: 500,
    margin: '40px 0 20px',
  },
  '@media(min-width: 768px)': {
    padding: '0 32px',
    marginBottom: 100,
  },
};

export default function Security() {
  return (
    <Fragment>
      <Helmet>
        <title>Privacy | DocProbe</title>
        <meta name='description' content='Privacy Policy' />
      </Helmet>
      <div css={{ margin: '50px auto', fontSize: 50, textAlign: 'center' }}>DocProbe Privacy Policy</div>
      <div css={textWrap}>
        This website is created and maintained by DocProbe, LLC (together with its employees, advisors, affiliates and
        related companies, collectively hereinafter referred to as “DocProbe”). This notice provides DocProbe’s (“we”
        “us”) privacy policy regarding the nature, purpose, use, and sharing of any Personally Identifiable Information
        (PII) collected on this website. Our privacy policy explains our information practices when a visitor to the
        website (“you”) provides PII through this website. PII may include your name, email, phone numbers, or other
        informationthat identifies you personally. We do not require you to register or provide personal information to
        visit this website.
        <br />
        <span>Personally Identifiable Information</span>
        As a general rule, we do not collect PII about you when you visit this website, unless you chooseto provide such
        information to us. You may submit your name, email address and phone number for the limited purpose of providing
        us a method to reach out to you for services that you may wish. Submitting PII through the website is voluntary.
        By doing so, you are giving us your permission to use the information for the stated purpose.
        <br />
        If you choose to provide us with PII, through such methods as completing a web form, we will use that
        information solely for the stated purpose.
        <br />
        Categories of information we collect on this website are further described below.
        <span>Security</span>
        We take the security of all PII very seriously. We take precautions to maintain the security, confidentiality,
        and integrity of the information we collect on this site. We work to protect PII stored on our servers from
        unauthorized access using commercially available computer security products. We also employ various security
        technologies to protect the information stored on our systems.
        <span>Tracking Utilities</span>
        DocProbe has designed features on this website to allow users to anonymously use the site without revealing
        their identities. The only information we collect online is from cookies, pixel tags or similar devices, which
        provide limited data, such as the date, time and areas of the site that were visited, interactions on the site,
        and the Web path/domain from where the visitor came. DocProbe uses this information only to recognize the user
        as a unique visitor and hold information during the visit to the site. Tracking utilities identified are not
        tied to PII.
        <br />
        <br />
        This general information allows DocProbe to serve visitors better by continually improving this Web site and
        making it more convenient. In certain cases, users can choose not to provide us with the information by setting
        the browser to refuse to accept cookies, but if so, that may disable access to certain portions of the site.
        <span>Accurate and Updated Information</span> You have the option to change or modify information previously
        provided to us, or to have any information you have previously submitted, removed or modified, by emailing us at
        info@docprobe.net., or to opt out from receiving future communications from us by choosing the“unsubscribe” tab
        at the bottom of emails you receive from us.
        <span>Changes to the Privacy Policy</span>
        If we plan to make significant changes to this Privacy Policy, we will post those changes to the Privacy Policy
        page before they take effect.
      </div>
    </Fragment>
  );
}
