import { keyframes } from '@emotion/core';
const smallImage = 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_600/specs/takeout_kjlco8.png';
const largeImage = 'https://res.cloudinary.com/dyuuf6gor/image/upload/f_auto,q_auto,w_1045/specs/takeout_kjlco8.png';

export const pageWrap = {
  marginBottom: 67,
  marginTop: 75,
  '@media(min-width: 768px)': {
    marginTop: 85,
  },
  '@media(min-width: 1260px)': {
    marginTop: 120,
  },
};

export const outerWrap = {
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
  position: 'relative',
  '@media(min-width: 1024px)': {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'row-reverse',
  },
};

export const bannerWrap = {
  position: 'relative',
  '@media(min-width: 1024px)': {
    width: '33%',
  },
};

export const bannerStyles = {
  backgroundColor: '#fff',
  backgroundImage: `url(${smallImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  display: 'block',
  height: '150px',
  width: '100%',
  '@media(min-width: 768px)': {
    height: 262,
  },
  '@media(min-width: 1024px)': {
    height: 370,
    backgroundImage: `url(${largeImage})`,
  },
};

export const uberEatsStyles = {
  borderRadius: '8px',
  bottom: -90,
  boxShadow: '-10px 10px 24px 0 rgba(0, 0, 0, 0.2)',
  left: '50%',
  maxWidth: 250,
  position: 'absolute',
  transform: 'translateX(-50%)',
  width: '100%',
  zIndex: 100,
  '@media(min-width: 768px)': {
    bottom: -115,
    maxWidth: 362,
  },
  '@media(min-width: 1024px)': {
    bottom: 79,
    left: -140,
    maxWidth: 354,
    transform: 'translateX(0%)',
  },
};
export const header = {
  color: '#64ae3e',
  fontSize: 56,
  paddingBottom: 37,
  paddingTop: 100,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    fontSize: 73,
    height: 371,
    margin: '70px auto 0',
    maxWidth: 408,
    paddingBottom: 72,
  },
  '@media(min-width: 1024px)': {
    height: 370,
    margin: '0 auto',
    marginTop: 0,
    maxWidth: 495,
    textAlign: 'left',
  },
  '@media(min-width: 1440px)': {
    fontSize: 100,
    maxWidth: 626,
  },
};

export const formWrap = {
  paddingTop: 12,
  position: 'relative',
  '@media(min-width: 768px)': {
    marginTop: 63,
  },
  '@media(min-width: 1024px)': {
    marginTop: 33,
  },
  '@media(min-width: 1440px)': {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '63px auto 0',
    padding: 0,
    input: { height: 52, paddingLeft: 29 },
    '& .name-input__wrap': { width: 550 },
    '& .last-input__wrap': { width: 579 },
    '& .company-input__wrap': { width: 380 },
    '& .email-input__wrap': { width: 749 },
  },
};

export const completeForm = {
  fontSize: 28,
  margin: '0 auto',
  maxWidth: 274,
  paddingTop: 34,
  textAlign: 'center',
  span: {
    fontWeight: 'bold',
  },
  '@media(min-width: 768px)': {
    maxWidth: 473,
    paddingTop: 82,
  },
  '@media(min-width: 1024px)': {
    fontSize: 35,
    maxWidth: 849,
    paddingTop: 150,
    textAlign: 'left',
  },
  '@media(min-width: 1440px)': {
    fontSize: 44,
    maxWidth: 1157,
  },
};
export const wrapper = {
  margin: 'auto',
  maxWidth: 319,
  '@media(min-width: 768px)': {
    maxWidth: 467,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 849,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 1157,
  },
};
export const detailsStyles = {
  backgroundColor: '#f1f1f1',
  borderRadius: 2,
  height: 52,
  marginTop: 20,
  width: '100%',
  padding: '10px 19px',
  '@media(min-width: 768px)': {
    alignItems: 'center',
    display: 'flex',
    margin: '20px auto 0',
  },
  '@media(min-width: 1024px)': {
    height: 74,
    padding: '10px 32px',
  },
};
export const buttonWrap = {
  borderTop: '1px solid #3b3f43',
  paddingTop: 27,
  '@media(min-width: 1024px)': {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export const optionsTextStyles = {
  '@media(min-width: 1024px)': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 50,
  },
};

export const buttonStyles = {
  backgroundColor: '#64ae3e',
  border: '#64ae3e',
  borderRadius: 26,
  height: 52,
  margin: '0 auto',
  maxWidth: 196,
  minWidth: 196,
  ':disabled': {
    cursor: 'not-allowed',
    '&:hover': { backgroundColor: '#64ae3e' },
  },
  '@media(min-width: 1024px)': {
    margin: 0,
  },
};
export const plusIcon = {
  cursor: 'pointer',
  paddingTop: 41,
  textAlign: 'center',
  svg: { g: { fill: '#64ae3e' }, width: 32, height: 43 },
};
export const otherOptions = {
  color: '#64ae3e',
  fontSize: 27,
  margin: '0 auto',
  maxWidth: 310,
  paddingBottom: 52,
  paddingTop: 15,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    maxWidth: 474,
  },
  '@media(min-width: 1024px)': {
    fontSize: 29,
    margin: 0,
    maxWidth: 784,
    paddingLeft: 33,
    paddingTop: 40,
    textAlign: 'left',
  },
  '@media(min-width: 1440px)': {
    fontSize: 34,
    maxWidth: 1092,
  },
};
export const giftCardWrap = {
  alignItems: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginBottom: 54,
  svg: { cursor: 'pointer' },
  '@media(min-width: 768px)': {
    maxWidth: 450,
    margin: '0 auto 54px',
  },
  '@media(min-width: 1024px)': {
    maxWidth: 900,
  },
  '@media(min-width: 1440px)': {
    maxWidth: 1157,
  },
};
export const innerGiftCardWrap = {
  alignItems: 'center',
  display: 'flex',
  svg: {
    marginTop: 20,
    cursor: 'pointer',
    '@media(min-width: 768px)': {
      height: 21,
      width: 21,
    },
    '@media(min-width: 1024px)': {
      marginTop: 30,
    },
    '@media(min-width: 1440px)': {
      height: 28,
      width: 28,
    },
  },
  '@media(min-width: 1024px)': {
    alignItems: 'flex-start',
    paddingLeft: 24,
    ':first-of-type': { paddingLeft: 0 },
  },
};
export const giftCard = {
  borderRadius: 8,
  cursor: 'pointer',
  height: 71,
  margin: '20px 16px 0 13px',
  position: 'relative',
  width: 113,
  '&:before': {
    border: '0px solid #64ae3e',
    borderRadius: 8,
    bottom: 0,
    content: '""',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '100%',
    zIndex: 100,
    transition: 'all .1s',
  },
  '@media(min-width: 768px)': {
    height: 108,
    marginTop: 30,
    width: 170,
  },
  '@media(min-width: 1024px)': {
    height: 82,
    width: 129,
  },
  '@media(min-width: 1440px)': {
    height: 119,
    width: 189,
  },
};

// ---- THANK YOU, SUBMIT SUCCESS SECTION ---- //

export const thankYouWrap = {
  backgroundColor: 'rgba(0, 0, 0, 0.03)',
  margin: '0 auto',
  padding: '48px 32px',
  textAlign: 'center',
  '@media(min-width: 768px)': {
    padding: '90px 52px',
  },
  '@media(min-width: 1024px)': {
    padding: '80px 52px',
  },
  '@media(min-width: 1260px)': {
    padding: '90px 0px',
  },
};

export const thankYou = {
  fontSize: 25,
  lineHeight: 1.36,
  margin: '0 auto',
  maxWidth: 320,
  textAlign: 'center',
  fontWeight: 400,
  '@media(min-width: 768px)': {
    fontSize: 32,
    maxWidth: 420,
    lineHeight: '1.53',
  },
  '@media(min-width: 1024px)': {
    maxWidth: 570,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 781,
  },
};
export const signatureStyles = {
  margin: '40px auto 0px',
  maxWidth: 240,
  '@media(min-width: 768px)': {
    maxWidth: 370,
  },
  '@media(min-width: 1024px)': {
    margin: 0,
  },
};
export const chatWrap = {
  backgroundColor: '#ffffff',
  borderRadius: '19.2px',
  boxShadow: '13px 15px 73px -13px rgba(0, 0, 0, 0.08)',
  margin: '48px auto 0',
  maxWidth: 313,
  padding: '49px 0 46px 0',
  width: '100%',
  position: 'relative',
  '@media(min-width: 768px)': {
    alignItems: 'flex-start',
    display: 'flex',
    maxWidth: 662,
    padding: '72px 72px 96px',
  },
  '@media(min-width: 1024px)': {
    maxWidth: 871,
    padding: '78px 0 86px 86px',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 1000,
    padding: '72px 78px 72px',
  },
};
export const innerChatWrap = {
  '@media(min-width: 768px)': {
    marginLeft: 50,
  },
};

export const nErlangerImageStyles = {
  maxWidth: 209,
  margin: '0 auto',
  borderRadius: '19.2px',
  height: 209,
  objectFit: 'cover',
  '@media(min-width: 768px)': {
    height: 239,
    maxWidth: 173,
    margin: 0,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 209,
    height: 209,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 173,
    height: 223,
  },
};

const changer = keyframes`
    from { opacity: 1 }
    to  { opacity: 0 }
`;

export const nickAnimation = {
  position: 'relative',
  zIndex: 1,
  opacity: 1,
  animation: `${changer} .2s linear 4s`,
  animationFillMode: 'forwards',
};

export const speech = {
  fontSize: '27px',
  fontWeight: 'bold',
  paddingTop: 44,
  color: '#426d61',
  lineHeight: 1.46,
  margin: '0 auto',
  maxWidth: 250,
  '@media(min-width: 768px)': {
    textAlign: 'left',
    fontSize: 30,
    paddingTop: 0,
    maxWidth: 287,
    letterSpacing: '1px',
    lineHeight: '1.36',
    marginLeft: 0,
  },
  '@media(min-width: 1024px)': {
    maxWidth: 462,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 610,
  },
};

export const team = {
  color: '#64ae3e',
  fontSize: 22,
  fontWeight: 500,
  paddingTop: 10,
  textAlign: 'center',
  '@media(min-width: 768px)': {
    fontSize: 35,
  },
  '@media(min-width: 1024px)': {
    paddingLeft: 36,
    paddingTop: 0,
  },
};

export const buttonStyles2 = {
  alignItems: 'center',
  backgroundColor: '#64ae3e',
  border: '#64ae3e',
  borderRadius: 25,
  color: '#fff',
  display: 'flex',
  height: 50,
  justifyContent: 'space-between',
  lineHeight: 'normal',
  margin: '0 auto',
  maxWidth: 237,
  minWidth: 237,
  padding: '0 32px',
  marginTop: 30,
  fontSize: 14.7,
  fontWeight: 500,
  fontFamily: 'aktiv-grotesk-extended, sans-serif',
  cursor: 'pointer',
  '.df.aic svg': { marginRight: 12 },
  '@media(min-width: 768px)': {
    borderRadius: 29,
    fontSize: 17.5,
    height: 59,
    margin: 0,
    marginTop: 46,
    maxWidth: 281,
    minWidth: 281,
    svg: { width: 16, height: 16 },
  },
  '@media(min-width: 1260px)': {
    height: 62,
    maxWidth: 296,
    minWidth: 296,
    fontSize: 18,
  },
};
