export const imageStyles = {
  margin: '103px auto 0',
  height:96,
  width:96
};
export const innerWrap = {
  padding: '22px 18px 0 18px',
  textAlign: 'center'
};
export const transparent = {
  fontSize: 27,
  lineHeight: 1.3,
  maxWidth: 310,
  width: '100%',
  margin: '0 auto',
  '@media(min-width: 768px)': {
   maxWidth: 464
  },
  '@media(min-width: 1260px)': {
   fontSize: 37,
   maxWidth:638
  },
};
export const deserve = {
  color: '#7cc99e',
  fontSize: 20,
  fontWeight: 500,
  marginTop: 22,
  '@media(min-width: 768px)': {
   maxWidth: 372,
   margin:'14px auto 0'
  },
  '@media(min-width: 1260px)': {
   fontSize: 22,
   color:'#8ee5b4',
   maxWidth:710,
   marginTop: 25,
  },
};
export const paragraph = {
  lineHeight: 1.9,
  fontWeight: 300,
  marginTop: 10,
  '@media(min-width: 768px)': {
   margin: '31px auto 0',
   paddingBottom:40,
   maxWidth:594
  },
  '@media(min-width: 1260px)': {
   fontSize: 16,
   maxWidth:670
  },
};
