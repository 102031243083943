/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  circleWrap,
  tabs,
  rightTab,
  dataImp,
  docsRet,
  sortingS,
  auditCorrect,
  processesingIndex,
  shippingS,
  threeSixty,
  circleMessage,
} from './circleNavStyle';

const clickSections = {
  dataImport: 'step-one',
  documentRetrieval: 'step-two',
  sorting: 'step-three',
  auditAndCorrect: 'step-four',
  processesingIndexing: 'step-five',
  shipping: 'step-six',
};

export default function CircleNav({ onSectionClick }) {
  return (
    <div css={circleWrap}>
      <div className='green' css={threeSixty}>
        360° Fulfillment
      </div>
      <div css={circleMessage}>
        DocProbe retrieves, processes, and ships your post-closing trailing documents; seamlessly, efficiently and
        cost-effectively.
      </div>
      <div css={[rightTab, dataImp]} onClick={() => onSectionClick(clickSections.dataImport)}>
        <span>Data Import</span>
      </div>
      <div css={[rightTab, docsRet]} onClick={() => onSectionClick(clickSections.documentRetrieval)}>
        <span>
          Document
          <br />
          Retrieval
        </span>
      </div>
      <div css={[rightTab, sortingS]} onClick={() => onSectionClick(clickSections.sorting)}>
        <span>Sorting</span>
      </div>
      <div css={[tabs, auditCorrect]} onClick={() => onSectionClick(clickSections.auditAndCorrect)}>
        <span>Audit and Correct</span>
      </div>
      <div css={[tabs, processesingIndex]} onClick={() => onSectionClick(clickSections.processesingIndexing)}>
        <span>
          Processing and
          <br />
          Indexing
        </span>
      </div>
      <div css={[tabs, shippingS]} onClick={() => onSectionClick(clickSections.shipping)}>
        <span>Shipping</span>
      </div>
    </div>
  );
}
