/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import {
  whiteWrap,
  brownWrap,
  imageStyles,
  pillar,
  paragraph,
  innerWrap,
  outerWrap,
  show,
} from './foundingStoryStyles';
import Image from '../../components/ui/Image';

export default function FoundingStory() {
  return (
    <Fragment>
      <div css={whiteWrap}>
        <span css={{ fontWeight: 500 }}>Over a Decade of Committed Solutions:</span> Our Founding Story
      </div>
      <div css={outerWrap}>
        <div css={brownWrap}>
          <Image
            alt=''
            styles={imageStyles}
            image='madison-sign_3x_lzsdnd'
          />
        </div>
        <div css={innerWrap}>
          <div css={pillar}>
            Madison: <br css={show} /> <span css={{ fontWeight: 500 }}>Pillar of strength</span>
          </div>
          <div css={paragraph}>
            Post 2007-2008, as the real estate market lagged, residential lenders looked to reduce excessive overhead,
            while doubling their efforts to grow. Focused on originating and closing loans, one mid-sized residential
            mortgage lender in particular found themselves overwhelmed with tracking and handling their trailing
            documents.
            <div css={{ marginTop: 36 }}>
              Lacking the manpower and efficiencies they needed to keep up with their post-loan process operations,
              Madison's clients turned to them for assistance in tracking and processing their documents.
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
