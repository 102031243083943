/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  paperStyles,
  paperOneS,
  paperTwoS,
  paperThreeS,
  paperFourS,
  whiteOneS,
  whiteTwoS,
  whiteThreeS,
  whiteFourS,
} from './papersStyle';

export default function Papers() {
  return (
    <div>
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106520/home/papers/Paper_PNGs_Green_paper_4_edsflw.png'
        css={[paperStyles, paperOneS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106520/home/papers/Paper_PNGs_Green_paper_7_ofspzr.png'
        css={[paperStyles, paperTwoS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Green_paper_11_sd0imm.png'
        css={[paperStyles, paperThreeS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Green_paper_3_n3anjx.png'
        css={[paperStyles, paperFourS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Light_Paper_1_vig28d.png'
        css={[paperStyles, whiteOneS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Light_Paper_4_kgdazb.png'
        css={[paperStyles, whiteTwoS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Light_Paper_11_wjh2ck.png'
        css={[paperStyles, whiteThreeS]}
        alt=''
      />
      <img
        src='https://res.cloudinary.com/dyuuf6gor/image/upload/v1565106521/home/papers/Paper_PNGs_Light_Paper_10_lihaba.png'
        css={[paperStyles, whiteFourS]}
        alt=''
      />
    </div>
  );
}
