/** @jsx jsx */
import { jsx } from "@emotion/core";
import React, { Fragment } from "react";
import { TableRow, TableCell, Tooltip } from "@material-ui/core";
import Highlighter from "react-highlight-words";
import MissingDocs  from "./MissingDocs";
import RejectedWarning from "./RejectedWarning";
import { TitlePortalContext } from "./TitlePortalContext";
import Icon, { IconType } from "../../components/ui/icons/Icon";

const dataStyle = {
  fontSize: 14,
  lineHeight: 1.14,
  letterSpacing: 0.1,
  color: "#8a96a0",
};

const titleIdStyle = {
  fontWeight: "bold",
  color: "#354052",
  letterSpacing: "normal",
  paddingLeft: "0px",
};

export function DataTableRow({ data }) {
  const { searchValueState } = React.useContext(TitlePortalContext);
  const [searchValue, setSearchValue] = searchValueState;

  function HighlightedWords({ text }) {
    return <Highlighter searchWords={[searchValue]} textToHighlight={text} />;
  }

  function HighlightedTableCell({ children, styleOverRides }) {
    if (children === null) return <TableCell />;
    return (
      <TableCell style={{ ...dataStyle, ...styleOverRides }}>
        {typeof children === "object" ? ( // children in an array --> there are multiple children
          children
            .filter((c) => c !== null)
            .map((text, i) => (
              <Fragment key={text}>
                <HighlightedWords text={text} />
                {i !== children.length && <br />}
              </Fragment>
            ))
        ) : (
          <HighlightedWords text={children} />
        )}
      </TableCell>
    );
  }

  const { uploadModalState } = React.useContext(TitlePortalContext);
  const [uploadModal, setUploadModal] = uploadModalState;

  return (
    <TableRow
      css={uploadModal === data.loanId && { backgroundColor: "#f7f8f6" }}
    >
      <TableCell
        css={{
          paddingLeft: 0,
          paddingRight: "4px",
          width: "auto",
          textAlign: "center",
        }}
      >
        {data.loanLevelInfoTag === true && (
          <Tooltip
            arrow
            title={
              <span css={{ fontSize: 10 }}>
                Another party may be responsible for this loan
              </span>
            }
          >
            <span>
              <Icon fill="#29a3aa" stroke="#29a3aa" icon={IconType.InfoIcon} />
            </span>
          </Tooltip>
        )}
      </TableCell>
      <HighlightedTableCell styleOverRides={titleIdStyle}>
        {data.titleNumber}
      </HighlightedTableCell>
      <HighlightedTableCell>{data.loanNumber}</HighlightedTableCell>
      <HighlightedTableCell>{data.dateFunded}</HighlightedTableCell>
      <HighlightedTableCell>{data.borrower}</HighlightedTableCell>
      <HighlightedTableCell>
        {data.address}
        {data.addressLine2}
        {data.county}
      </HighlightedTableCell>
      <TableCell style={dataStyle}>{data.clientName}</TableCell>
      <MissingDocs
        key={data.loanId}
        data={data}
      />
      <RejectedWarning data={data}/>
    </TableRow>
  );
}
