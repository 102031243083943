/** @jsx jsx */
import { jsx } from '@emotion/core';
import { headerWrap, logoWrap, docprobe } from './PageHeaderStyles';

export default function PageHeader() {
  return (
    <header css={headerWrap}>
      <span css={logoWrap}>
        Welcome to <span css={docprobe}>DocProbe</span>
      </span>
    </header>
  );
}
