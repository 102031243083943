export const root = {
  maxWidth: 883,
  '@media(min-width: 1260px)': {
    marginRight: 0,
    marginLeft: 'auto'
  }
};
export const outerWrap = {
  backgroundColor: '#e5e5e5',
  maxHeight: 352,
  padding: '28px 35px',
  '@media(min-width: 768px)': {
    maxHeight: 445
  },
  '@media(min-width: 1260px)': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 850,
    maxHeight: 852,
    position: 'relative'
  }
};
export const centerText ={
 '@media(min-width: 1260px)': {
  marginLeft: 65,
 },
}
export const followUp = {
  fontSize: 27,
  fontWeight: 500,
  textAlign: 'center',
  '@media(min-width: 1260px)': {
   maxWidth:175,
   fontSize: 37,
   textAlign: 'left',
   fontWeight: 300,
  },
};
export const realTime = {
  fontWeight: 300,
  textAlign: 'center',
  lineHeight: 1.9,
  marginTop: 13,
  '@media(min-width: 768px)': {
    maxWidth: 550,
    margin: '13px auto 0'
  },
  '@media(min-width: 1260px)': {
   maxWidth:266,
   fontSize: 16,
   lineHeight:1.8,
   textAlign: 'left',
  },
};
export const imagesStyles = {
  position: 'relative',
  margin: '50px auto 0',
  maxWidth: 342,
  maxHeight: 422,
  '@media(min-width: 768px)': {
    maxWidth: 490,
    maxHeight: 450
  },
  '@media(min-width: 1260px)': {
    position: 'absolute',
    left: -375,
    top: '50%',
    transform: 'translateY(-50%)',
    maxWidth:636,
    maxHeight:580,
  }
};
export const image = {
  position: 'absolute',
  top: 0,
  left: 0
};
