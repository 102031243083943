export const outerWrap = {
  backgroundColor: 'white',
  position: 'relative',
  padding: '55px 0px 29px 0px',
  marginTop: 96,
  marginBottom: 40,
  '@media(min-width: 768px)': {
    marginTop: 82
  },
  '@media(min-width: 1260px)': {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '105px 101px',
    marginBottom: 70,
    marginTop: 92
  }
};
export const quote = {
  position: 'absolute',
  top: -27,
  left: 20,
  height: 57,
  width: 59,
  '@media(min-width: 768px)': {
    width: 87,
    height: 84,
    top: -40
  }
};
export const quoteStyles = {
  fontSize: 25,
  color: '#7cc99e',
  maxWidth: 262,
  fontWeight: 500,
  textAlign: 'center',
  margin: '0 auto',
  '@media(min-width: 768px)': {
    maxWidth: 424
  },
  '@media(min-width: 1260px)': {
    maxWidth: 415,
    fontSize: 25,
    textAlign: 'left'
  }
};
export const nameStyles = {
  fontSize: 18,
  textAlign: 'center',
  fontWeight: 500,
  marginTop: 20,
  '@media(min-width: 1260px)': {
    textAlign: 'left',
    maxWidth: 415,

  }
};
export const titleStyles = {
  textAlign: 'center',
  fontWeight: 300,
  marginTop: 10,
  '@media(min-width: 1260px)': {
    textAlign: 'left',
    maxWidth: 415,
  }
};
export const imageStyles = {
  margin: '46px auto 0',
  width: 289,
  height: 162,
  position: 'relative',
  '@media(min-width: 768px)': {
    width: 626,
    height: 352
  },
  '@media(min-width: 1260px)': {
    margin: 0
  }
};
export const imageOuterStyles = {
  position:'relative'
};
export const line = {
  borderLeft: '1px solid #426d61',
  position: 'absolute',
  left: '50%',
  bottom:-142,
  height: 142,
  '&:before': {
    content: '""',
    borderTop: '1px solid #426d61',
    position: 'absolute',
    left: -4,
    bottom: 0,
    width: 7
  },
  '@media(min-width: 768px)': {
    height:174,
    bottom:-175
   },
   '@media(min-width: 1260px)': {
      height: 245,
      bottom: -245,
      left:'96%',
      borderLeft: '1px solid #e5e5e5',
      '&:before': {
        content: '""',
        borderTop: '1px solid #e5e5e5',
        position: 'absolute',
        left: -4,
        bottom: 0,
        width: 7
      },
       },
 
};
