/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet';

import LenderInvestor from '../content/relationship/LenderInvestor';
import ImproveRelationship from '../content/relationship/ImproveRelationship';
import InformedReporting from '../content/relationship/InformedReporting';
import Audited from '../content/relationship/Audited';
import VideoSection from '../content/relationship/VideoSection';
import BottomSection from '../components/ui/BottomSection';

const blackSection = {
  backgroundColor: '#3b3f43',
  paddingBottom: 1,
};

export default function Relationship() {
  return (
    <Fragment>
      <Helmet>
        <title>Relationships | DocProbe</title>
        <meta
          name='description'
          content='With DocProbe, you can start building better lender-investor relationships by turning over the entire process to our efficient operations'
        />
      </Helmet>
      <LenderInvestor />
      <ImproveRelationship />
      <InformedReporting />
      <div css={blackSection}>
        <Audited />
        <VideoSection />
      </div>
      <BottomSection
        textOne='Reduced Cost'
        linkOne='/cost'
        linkTwo='/efficiency'
        textTwo='Streamlined Process'
        style={{
          marginTop: 0,
          '@media(min-width: 768px)': { marginTop: 0 },
          '@media(min-width: 1260px)': { marginTop: 0 },
        }}
      />
    </Fragment>
  );
}
