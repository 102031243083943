import {Tooltip, withStyles} from "@material-ui/core";

export const overlay = {
  position: 'absolute', 
  opacity: 0.53,
  backgroundImage: 'linear-gradient(to bottom, #012938, #01666c)',
  width: '100%',
  height: '100%',
  zIndex: 100,
};

export const CustomTooltip = withStyles({
  tooltip: {
    marginTop: 3,
    height: 40,
    minWidth: 330,
    textAlign: 'center',
    padding: "14px 22px 19px",
    fontSize: 12,
    fontWeight: "normal",
    backgroundColor: '#01666c',
    color: '#fff',
  },
  arrow: {
    color: '#01666c',
  },
})(Tooltip);

export const headerText = {
  fontWeight: 700,
  fontSize: 24,
  letterSpacing: 0.17,
  color: '#1a173b',
};

export const descriptionText = {
  fontWeight: 400,
  fontSize: 12,
  letterSpacing: 0.17,
  color: '#1a173b',
  marginTop: 4,
};

export const label = {
  fontSize: 12,
  fontWeight: 700,
  marginBottom: 8,
  color: '#023459',  
};

export const inputGroup = {
  display: 'grid',
  margin: '42px 0',
  gridTemplateColumns: 'repeat(3, 1fr)',
  rowGap: 24,
  columnGap: 50,
};

export const infoText = {
  fontSize: 11,
  lineHeight: 1.64,
  paddingBottom: 9,
};

export const buttonGroup = {
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: 7,
};

export const containerWrap = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%', 
  height: '100%',
  zIndex: 110,
};

export const container = {
  backgroundColor:'#fff',
  padding: 40,
  position: 'relative',
  borderRadius: 12,
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  color: '#686868',
};

export const fileReceivedWrapper = {
  backgroundColor: '#fcfcfb',
  border: "dashed 1px #b4bac6",
  borderRadius: "4px",
  height: '100%',
};

export const fileReceived = {
  color: "#3ea848",
  display: "flex",
  justifyContent: 'center',
  alignItems: "center",
  height: '100%',
  fontSize: 34,
  fontWeight: 600,
  lineHeight: 0.44,
  "& svg": { fontSize: 52, marginRight: 10 },
};

export const outerWrapper = {
  display: 'flex',
  flexDirection: 'column',
  height: 144,
  justifyContent: 'space-around',
  alignItems: 'center',
};

export const innerWrapper = {
  display: 'flex',
  flexDirection: 'column',
  height: 51,
  justifyContent: 'space-between',
  alignItems: 'center',
};

export const button = {
  width: 95,
  height: 35.8,
  fontWeight: 700, 
  fontSize: 12,
  borderRadius: 4,
};

export const disabledButton = {
  width: 111,
  border: 'solid 0.5px #b1b2b0',
  color: '#b1b2b0',
  backgroundColor: '#c6c7c4',
};

export const submitButton = {
  width: 111,
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.09)',
  backgroundColor: '#f7a01b',
  color: '#fff',
};

export const errorText = {
  fontSize: 14,
  fontWeight: 700,
  lineHeight: 1.07,
  marginRight: -11,
  color: '#e33e3a',
};