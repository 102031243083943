/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component, Fragment } from 'react';
import { getTrackingStringForEmail } from '../../seo-tracking';

import axios from 'axios';

import { outerWrap, contentWrap, title, detailsStyles, commentsStyles, buttonWrap } from './formSectionStyles';
import Button from '../../components/ui/Button';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validEmail } from '../../Utils';

const defaultForm = {
  name: '',
  email: '',
  phone: '',
  company: '',
  comments: '',
};

export default class FormSection extends Component {
  state = {
    formSubmitted: false,
    form: defaultForm,
  };

  onChange = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone } = this.state.form;

    if (name.trim() === '' || phone.trim() === '') {
      toast.error(`The ${name.trim() === '' ? 'name' : 'phone number'} field is required!`);
      return;
    }

    if (!validEmail(email)) {
      toast.error(email.trim() === '' ? 'The email field is required!' : 'The email address provided is invalid!');
      return;
    }

    const formData = {
      ...this.state.form,
      comments: `${this.state.form.comments}${getTrackingStringForEmail()}`,
    };
    await axios.post(
      'https://prod-34.eastus.logic.azure.com:443/workflows/f83d235c97354150b0d20dfcfccbd08e/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=uxOBJUfdqcKxb8S-4jZB7gT5Ao-5qAz6R2n3qsv33bI',
      formData,
    );
    this.setState({ formSubmitted: true, form: defaultForm });
    setTimeout(() => this.setState({ formSubmitted: false }), 5000);
  };

  render() {
    return (
      <div css={outerWrap}>
        <div css={contentWrap}>
          {this.state.formSubmitted ? (
            <h1>Thank you for your interest in DocProbe. A representative will contact you shortly.</h1>
          ) : (
            <Fragment>
              <div css={title}>How Can We Help?</div>
              <form>
                <input type='text' name='name' css={detailsStyles} placeholder='Name *' onChange={this.onChange} />
                <input type='text' name='company' css={detailsStyles} placeholder='Company' onChange={this.onChange} />
                <input
                  type='email'
                  name='email'
                  css={detailsStyles}
                  placeholder='Email Address *'
                  onChange={this.onChange}
                />
                <input type='tel' name='phone' css={detailsStyles} placeholder='Phone *' onChange={this.onChange} />
                <textarea name='comments' css={commentsStyles} placeholder='Comments' onChange={this.onChange} />
                <div css={buttonWrap}>
                  <Button onClick={this.handleSubmit} text='Submit' type='submit' />
                </div>
              </form>

              <ToastContainer
                position='top-right'
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable={false}
                pauseOnHover
              />
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}
