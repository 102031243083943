export const validEmail = (email) => {
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
};

export const validPoBox = (poBox) => {
  return poBox !== null && poBox !== '0' && poBox !== ''
};

export const formatAddressSecondLine = (city = "", state = "", zip = "") => {
  const getComma = (comma = false) => (comma ? "," : "");
  const getSpace = (space = false) => (space ? " " : "");

  const cityComma = getComma(city && (state || zip));
  const citySpace = getSpace(city && (state || zip));
  const stateComma = getComma(!cityComma && state && zip);
  const stateSpace = getSpace(state && zip);
  return `${city}${cityComma}${citySpace}${state}${stateComma}${stateSpace}${zip}`;
};
