/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Layout from './components/layout/Layout';

import Home from './pages/Home';
import AboutUs from './pages/AboutUs';
import ContactUs from './pages/ContactUs';
import Cost from './pages/Cost';
import Efficiency from './pages/Efficiency';
import Team from './pages/Team';
import PortalLogin from './pages/PortalLogin';
import Features from './pages/Features';
import Partners from './pages/Partners';
import Platform from './pages/Platform';
import Relationship from './pages/Relationship';
import Privacy from './pages/Privacy';
import Page404 from './pages/Page404';
import ScrollToTop from './components/ScrollToTop';
import ManageNotifications from './pages/ManageNotifications';
import DocumentUploads from './pages/DocumentUploads';
import ClientRetention from './pages/ClientRetention';
import BenefitsLandingPage from './pages/BenefitsLandingPage';
import TitleCompanyPortal from './pages/TitleCompanyPortal';
import TestPage from './pages/TestPage';

export default class Routes extends Component {
  render() {
    const routes = [
      { path: '/', exact: true, component: Home },
      { path: '/cost', exact: true, component: Cost },
      { path: '/contact-us', exact: true, component: ContactUs },
      { path: '/relationships', exact: true, component: Relationship },
      { path: '/partners', exact: true, component: Partners },
      { path: '/platform', exact: true, component: Platform },
      { path: '/about-us', exact: true, component: AboutUs },
      { path: '/features', exact: true, component: Features },
      { path: '/privacy', exact: true, component: Privacy },
      { path: '/efficiency', exact: true, component: Efficiency },
      { path: '/team', exact: true, component: Team },
      { path: '/benefits', exact: true, component: BenefitsLandingPage },
      { path: '/dinner-on-us', exact: true, component: ClientRetention },
      {
        path: '/manage-notifications',
        exact: true,
        component: ManageNotifications,
      },
      {
        path: '/document-uploads',
        exact: true,
        noLayout: true,
        component: DocumentUploads,
      },
      {
        path: '/title-company-portal',
        exact: true,
        component: TitleCompanyPortal,
        noLayout: true,
      },
      {
        path: '/test-page',
        exact: true,
        component: TestPage,
        noLayout: true,
      },

      {
        path: '/portal-login',
        exact: true,
        component: PortalLogin,
        noLayout: true,
      },
      { path: '*', exact: true, component: Page404 },
      { path: '/404', exact: true, component: Page404 },
    ];

    return (
      <Router>
        <ScrollToTop>
          <Switch>
            {routes.map(({ path, exact, component: Component, noLayout, layoutProps = {}, ...route }) => {
              const withLayout = ({ ...props }) => (
                <Layout {...props}>
                  <Component {...props} />
                </Layout>
              );
              return <Route key={path} path={path} exact={exact} component={noLayout ? Component : withLayout} />;
            })}
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}
