/** @jsx jsx */
import { jsx } from '@emotion/core';

import logo from './madison-logo-vertical.png';

import { mainWrap, banner, textWrap } from './trustSectionStyles';

export default function TrustSection() {
  return (
    <div css={mainWrap}>
      <div css={banner}>
        <img src={logo} alt='' />
      </div>
      <div css={textWrap}>
        <h3>
          A Legacy <span>of Trust</span>
        </h3>
        <p>
          DocProbe is proud to be part of the Madison family of companies, a national network of real estate companies
          providing effective solutions to a wide array of commercial and residential banking- related needs. Our
          partnership allows us to support our professional solutions with Madison’s infrastructure of industry
          expertise, extensive resources, and leadership.
        </p>
      </div>
    </div>
  );
}
