import React, { useState, useReducer, useEffect } from "react";
import Axios from "axios";

const ActionType = {
  postInit: "POST_INIT",
  postSuccess: "POST_SUCCESS",
  postFailure: "POST_FAILURE",
  refreshPostState: "REFRESH_POST_STATE"
};

function dataPostReducer(state, action) {
  switch (action.type) {
    case ActionType.postInit:
      return { ...state, isLoading: true, error: false, isSuccess: false };
    case ActionType.postSuccess:
      return {
        ...state,
        isLoading: false,
        error: false,
        isSuccess: true,
        data: action.payload
      };
    case ActionType.postFailure:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case ActionType.refreshPostState:
      return initialState(state.data);
    default:
      throw new Error();
  }
}

const initialState = data => {
  return {
    isLoading: false,
    error: false,
    isSuccess: false,
    data
  };
};

export default function usePostData(initialUrl, initialPostBody, initialData) {
  const [url, setUrl] = useState(initialUrl || "");
  const [postBody, setPostBody] = useState(initialPostBody || {});

  const [state, dispatch] = useReducer(
    dataPostReducer,
    initialState(initialData)
  );

  useEffect(() => {
    let didCancel = false;

    const postData = async () => {
      dispatch({ type: ActionType.postInit });

      try {
        const result = await Axios.post(url, postBody, {
          headers: { "Content-Type": "application/json" }
        });

        if (!didCancel) {
          dispatch({ type: ActionType.postSuccess, payload: result.data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: ActionType.postFailure, payload: error });
        }
      }
    };

    url && postData();

    return () => {
      didCancel = true;
    };
  }, [postBody, url]);

  const doPost = (newUrl, newPostBody) => {
    setUrl(newUrl);
    setPostBody(newPostBody);
  };

  const refreshState = () => {
    dispatch({ type: ActionType.refreshPostState });
  };

  return { ...state, doPost, refreshState };
}
