/** @jsx jsx */
import { jsx } from '@emotion/core';
import { NavLink } from 'react-router-dom';

import { MCRESLogo, MadisonTitle, TenThirtyLogo, LPRDLogo, SpecsLogo } from '../../images/home/mcres-logos';
import { LinkedInIcon } from '../../content/home/homeIcons';
import Image from '../ui/Image';

import {
  mainWrap,
  footerWrap,
  topHalf,
  bottomHalf,
  logosWrap,
  menuWrap,
  menuSection,
  contactsWrap,
  logoWrap,
  mobileMessage,
  tabletMessage,
  finePrintWrap,
  finePrintInner,
  copyright,
  innerContacts,
  gray,
  linkedInButton,
} from './footerStyles';

import specsImage from '../../images/specs-logo-white.png'

const currentYear = new Date().getFullYear();

export default function Footer() {
  return (
    <footer css={mainWrap}>
      <div css={footerWrap}>
        <TopHalf />
        <BottomHalf />
      </div>
      <div css={finePrintWrap}>
        <div css={finePrintInner} className='container'>
          <div>
            <NavLink to='/privacy' css={{ marginRight: 43 }}>
              Privacy Policy
            </NavLink>
          </div>
          <div css={copyright}>© {currentYear} DocProbe. All Rights Reserved.</div>
        </div>
      </div>
    </footer>
  );
}
function TopHalf() {
  return (
    <div css={topHalf}>
      <div css={logoWrap}>
        <Image image='logos-d_iaftkc.png' alt='' />
      </div>
      <div css={menuWrap}>
        <div css={menuSection}>
          <p>Benefits</p>
          <div>
            <NavLink to='/cost'>cost</NavLink>
          </div>
          <div>
            <NavLink to='/efficiency'>Efficiency</NavLink>
          </div>
          <div>
            <NavLink to='/relationships'>Relationships</NavLink>
          </div>
        </div>
        <div css={menuSection}>
          <p css={gray}>
            <NavLink to='/features'>Features</NavLink>
          </p>
        </div>
        <div css={menuSection}>
          <p>Meet Us</p>
          <div>
            <NavLink to='/about-us'>About</NavLink>
          </div>
          <div>
            <NavLink to='/team'>Team</NavLink>
          </div>
          <div>
            <NavLink to='/contact-us'>Contact</NavLink>
          </div>
        </div>
        <div css={menuSection}>
          <p css={gray}>
            <NavLink to='/partners' css={{ color: '#a3a6a9' }}>
              Partners
            </NavLink>
          </p>
          <p css={[gray, { marginTop: 15 }]}>
            <NavLink to='/platform'> Platform</NavLink>
          </p>
        </div>
      </div>
      <div css={contactsWrap}>
        <a className='db' href='tel:866-486-0554'>
          866-486-0554
        </a>
        <div css={innerContacts}>
          <a href='mailto:info@docprobe.net'>info@docprobe.net</a>
          <a
            css={linkedInButton}
            href='https://www.linkedin.com/company/docprobe/'
            target='_blank'
            rel='noopener noreferrer'
          >
            <LinkedInIcon fill='#3B3F43' />
            <span>Follow Us</span>
          </a>
        </div>
        {/* <div css={iconStyles}>
          <img src={facebook} alt='' />
          <img src={twitter} alt='' />
        </div> */}
      </div>
    </div>
  );
}

function BottomHalf() {
  return (
    <div css={bottomHalf}>
      <div>
        <p css={tabletMessage}>Part of the Madison Family</p>
        <div css={logosWrap}>
          <p css={mobileMessage}>Part of the Madison Family</p>
          <a href='http://www.madisoncres.com/' target='_blank' rel='noopener noreferrer'>
            <MCRESLogo />
          </a>
          <a href='https://www.madisontitle.com/' target='_blank' rel='noopener noreferrer'>
            <MadisonTitle />
          </a>
          <a href='https://www.madison1031.com/' target='_blank' rel='noopener noreferrer'>
            <TenThirtyLogo />
          </a>
          <a href='http://www.leaseprobe.com/' target='_blank' rel='noopener noreferrer'>
            <LPRDLogo />
          </a>
          <a href='http://www.madisonspecs.com/' target='_blank' rel='noopener noreferrer'>
            <img src={specsImage} alt='' />
          </a>
        </div>
      </div>
    </div>
  );
}
