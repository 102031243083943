/** @jsx jsx */
import { jsx } from '@emotion/core';

import { mainWrap, boxImageStyles, mobileBreak, smarterProcess, paragraph, retrieve } from './smarterProcessesStyles';
import Image from '../../components/ui/Image';

export default function SmarterProcesses() {
  return (
    <div css={mainWrap}>
      <Image image='secondary-pages/Process_Page_fpv4j3.png' styles={boxImageStyles} alt='' />
      <div css={retrieve}>
        Retrieve, Sort, Audit <br css={mobileBreak} />
        <span css={{ fontWeight: 500 }}>and Correct, Upload, Ship</span>
      </div>
      <div css={smarterProcess}>Smarter processes for corrected, accurate fulfillment and less backlog.</div>
      <div css={paragraph}>
        Your documents are our specialty, and we offer a smarter and full-service process to avoid missing documents or
        delayed records delivery. Working closely with your team, we implement an efficient, streamlined document ops
        process.
        <div css={{ marginTop: 40 }}>
          Starting with the timely import of your loan data report, we immediately notify relevant parties to retrieve
          all post-closing documents for review, audit and revision. With DocProbe, your system works smoothly and
          delay-free, with a personal account rep on-call to ensure every trailing document is retrieved, reviewed,
          approved, and sent to your investor.
        </div>
      </div>
    </div>
  );
}
