/** @jsx jsx */
import { jsx } from '@emotion/core';

import {
  textBoxWrap,
  contentWrap,
  imageStyles,
  innerTextBox,
  secured,
  noMatter,
  paragraph,
  secondPar,
  outerWrap,
} from './securedCompliantStyles';
import Image from '../../components/ui/Image';

export default function SecuredCompliant() {
  return (
    <div>
      <div css={outerWrap}>
        <div css={contentWrap}>
          <div css={textBoxWrap}>
            <div css={innerTextBox}>
              <div css={secured}>
                <span css={{ fontWeight: 500 }}>Secured, compliant, and</span> based in the USA
              </div>
              <div css={noMatter}>
                No matter how you process your post-closing documents, your confidential information needs to be
                protected at all times.
              </div>
              <div css={paragraph}>
                At DocProbe, we maintain rigorous standards of security, privacy, and compliance at every stage, with
                security measures in place for document retrieval and processing. From digital storage to our on-site
                file system, we built powerful firewalls to secure and protect your documents to prevent data breaches
                or loss, and we carefully monitor every document until delivery.
                <div css={secondPar}>
                  As a Madison company, DocProbe is backed by a powerful infrastructure of IT, security, and legal
                  support for your business protection, every step of the way. Our secure protocols and national
                  reputation ensure that you don’t have to compromise on our promise of trust, security, and policy held
                  to the industry’s highest standards. With our USA-based processes and compliance guarantee, you can
                  trust DocProbe with your documents, your data, and your reputation.
                </div>
              </div>
            </div>
          </div>
          <div css={{ paddingBottom: 1 }}>
            <Image image='bitmap_3x_abrjde.jpg' styles={imageStyles} alt='' />
          </div>
        </div>
      </div>
    </div>
  );
}
