export const heroWrap = {
  backgroundColor: '#f2f2f2',
  position: 'relative',
  '&:before': {
    backgroundColor: '#f2f2f2',
    content: '""',
    display: 'block',
    height: 75,
    left: 0,
    position: 'absolute',
    right: 0,
    top: -75,
    width: '100%',
  },
  '@media(min-width: 768px)': {
    '&:before': {
      height: 85,
      top: -85,
    },
  },
  '@media(min-width: 1260px)': {
    '&:before': {
      height: 120,
      top: -120,
    },
  },
};

export const imageWrap = {
  height: '100%',
  width: '100%',
  width: 'calc(100% + 150px)',
  position: 'absolute',
  overflow: 'hidden',
  '@media(min-width: 768px)': {
    left: -150,
    top: -240,
    width: 'calc(100% + 200px)',
  },
  '@media(min-width: 1260px)': {
    left: 100,
    top: -350,
    width: '100%',
  },
  '@media(min-width: 1440px)': {
    top: -400,
  },
};
