/** @jsx jsx */
import { jsx } from '@emotion/core';

import { SpotlightIcon } from '../../components/ui/icons';

const contentWrap = {
  color: '#fff',
  textAlign: 'center',
  padding: '0 20px 77px',
  maxWidth: 497,
  margin: 'auto',
  svg: {
    width: 96,
    height: 96,
  },
  p: {
    fontSize: 14,
    lineHeight: '1.93',
  },
  '@media(min-width: 768px)': {
    padding: '66px 0px 110px',
  },
  '@media(min-width: 1260px)': {
    maxWidth: 670,
    padding: '103px 0 159px',
    p: { fontSize: 18 },
  },
};
const imageStyles = {
  width: 96,
  height: 96,
  margin: '0 auto',
};

const header = {
  fontSize: 27,
  lineHeight: '1.3',
  marginBottom: 16,
  marginTop: 25,
  span: { fontWeight: 500 },
  '@media(min-width: 1260px)': {
    fontSize: 50,
    lineHeight: 1.04,
    marginBottom: 32,
    marginTop: 37,
  },
};

export default function Spotlight() {
  return (
    <div css={{ backgroundColor: '#3b3f43' }}>
      <div css={contentWrap}>
        <SpotlightIcon css={imageStyles} />
        <div css={header}>
          Spotlight On: <span>E-Mortgages</span>
        </div>
        <p>
          DocProbe is fully prepared to handle your new e-mortgages documentation. We offer our clients digital intake,
          storage, and shipping to your investors with the efficient process that moves as fast as the modern market.
        </p>
      </div>
    </div>
  );
}
