/** @jsx jsx */
import { jsx } from '@emotion/core';

import { audited, streamlined, documentErrors, textBox, textWrap, inner, imageWrap } from './auditedStyles';
import Image from '../../components/ui/Image';

export default function Audited() {
  return (
    <div className='container'>
      <div css={inner}>
        <div css={textBox}>
          <div css={textWrap}>
            <div css={audited}>
              <span css={{ fontWeight: 500 }}>Audited and corrected</span> deliverables
            </div>
            <div css={streamlined}>Streamlined for efficiency and reviewed for accuracy.</div>
            <div css={documentErrors}>
              Document errors, missing documents or incorrect information, can cause missed deadlines, investor
              frustration, and penalties that add up. DocProbe ensures every document is accepted and marked as
              completed on delivery with our comprehensive auditing, revision, review, and follow-up fulfillment
              process. Investor exceptions are managed until resolved. Our document system ensures that your trailing
              documents are processed faster, smarter, and accurately—building stronger relationships with the people
              who matter most.
            </div>
          </div>
        </div>
        <div css={imageWrap}>
          <Image image='bitmap_3x_l54rci.jpg' alt='' />
        </div>
      </div>
    </div>
  );
}
