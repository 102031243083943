/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Parallax } from 'react-scroll-parallax';
import { NavLink } from 'react-router-dom';
import { Fragment } from 'react';

import { root, mainWrap, textWrap, topBlackLine, horizontalRightLine, mainStuffs } from './stepStyles';
import Image from '../../components/ui/Image';
import { RightCaretIcon } from '../../components/ui/icons';
import { isIE, getUA } from 'react-device-detect';

const backgroundD = {
  display: 'none',
  '@media(min-width: 768px)': {
    position: 'absolute',
    display: 'block',
    left: -300,
    height: 800,
    top: 270,
  },
  '@media(min-width: 1260px)': {
    height: 1800,
    top: 190,
    left: -600,
  },
};

export default function StepFour({ onSectionClick }) {
  return (
    <section css={root} className='relative step-four'>
      <div css={horizontalRightLine} data-line />
      <div className='container' css={mainWrap}>
        <Image styles={backgroundD} image='home/background-d_giemij' alt='' />
        <div css={topBlackLine} />
        <div css={mainStuffs} data-content>
          <Text />
          <Images />
        </div>
      </div>
    </section>
  );
}

const imageStyles = {
  width: '100%',
  opacity: 0,
  '@media(min-width: 768px)': {
    maxWidth: 550,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 770,
  },
};

function Images() {
  return (
    <div
      className='relative'
      css={{ margin: '50px auto 10px', '@media(min-width: 1260px)': { margin: '-150px 10px 0 -175px' } }}
    >
      <Image image='home/steps/audit-correct-4_jdzjjk.png' alt='' styles={imageStyles} />
      <div css={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, width: '100%', height: '100%' }}>
        {isIE || getUA === 'ReactSnap' ? (
          <div css={{ img: { position: 'absolute', top: 0, right: 0 } }}>
            <Image image='home/steps/audit-correct-1_rnzdzu.png' alt='Audit and correct images' />
            <Image image='home/steps/audit-correct-2_i2kdn7.png' alt='Audit and correct images' />
            <Image image='home/steps/audit-correct-3_h0tizj.png' alt='Audit and correct images' />
            <Image image='home/steps/audit-correct-4_jdzjjk.png' alt='Audit and correct images' />
          </div>
        ) : (
          <Fragment>
            <Parallax y={[0, 0]}>
              <Image image='home/steps/audit-correct-1_rnzdzu.png' alt='Audit and correct images' />
            </Parallax>
            <Parallax y={[10, -10]}>
              <Image image='home/steps/audit-correct-2_i2kdn7.png' alt='Audit and correct images' />
            </Parallax>
            <Parallax y={[15, -15]}>
              <Image image='home/steps/audit-correct-3_h0tizj.png' alt='Audit and correct images' />
            </Parallax>
            <Parallax y={[20, -20]}>
              <Image image='home/steps/audit-correct-4_jdzjjk.png' alt='Audit and correct images' />
            </Parallax>
          </Fragment>
        )}
      </div>
    </div>
  );
}

const textStyles = {
  '@media(min-width: 1260px)': {
    marginTop: -170,
  },
};

function Text() {
  return (
    <div css={[textWrap, textStyles]}>
      <h3>
        Auditing &{' '}
        <span
          css={{
            '@media(max-width: 767px)': { display: 'block' },
          }}
          className='semi-bold'
        >
          Correcting
        </span>
      </h3>
      <p>
        Every document is audited for accuracy through our rigorous audit checklist. We manage and take responsibility
        for all necessary corrections and investor exceptions.
      </p>
      <NavLink to='/features' className='audit-correct'>
        Learn More <RightCaretIcon />
      </NavLink>
    </div>
  );
}
