/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';

import SecondaryHero from '../../components/layout/SecondaryHero';
import SecondayHeader from '../../components/layout/SecondaryHeader';

const headerOverrides = {
  maxWidth: 320,
  paddingTop: 31,
  '@media(min-width: 768px)': {
    maxWidth: 415,
    paddingTop: 136,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 480,
    paddingTop: 100,
  },
  '@media(min-width: 1440px)': {
    paddingLeft: 50,
  },
};

const imageOverrides = {
  bottom: -170,
  right: 20,
  maxWidth: 330,
  '@media(min-width: 768px)': {
    bottom: -155,
    right: -75,
    maxWidth: 500,
  },
  '@media(min-width: 1260px)': {
    maxWidth: 520,
    bottom: -180,
    right: 50,
  },
};
const headerWrapS = {
  '> div': {
    padding: '94px 0 56px',
    '@media(min-width: 768px)': {
      padding: '87px 0 51px',
    },
    '@media(min-width: 1260px)': {
      padding: '87px 0 134px',
    },
  },
};

export default function TopPart() {
  return (
    <Fragment>
      <SecondaryHero
        image='secondary-pages/process_rvtibo.png'
        headerStyles={headerOverrides}
        imageStyles={imageOverrides}
      >
        Simply Powerful. <br />
        <span css={{ fontWeight: 500 }}>Powerfully Simple.</span>
      </SecondaryHero>
      <SecondayHeader
        wrapStyles={headerWrapS}
        subText='DocProbe is your professional, specialized team with a reliable, trackable system that gets your docs from here to there—avoiding errors, oversights, and costly delays.'
      >
        <span>
          Trailing Docs mean tedious followup, overworked staff and a hard to manage, disorganized fulfillment
          processes.
        </span>{' '}
        But it doesn't have to be your headache.
      </SecondayHeader>
    </Fragment>
  );
}
