/** @jsx jsx */
import { jsx } from '@emotion/core';

import lendersImage from '../../images/partners/lendersImage.png';
import mortgageImage from '../../images/partners/mortgageCollab.png';
import capitalImage from '../../images/partners/capitalMarkets.png';
import {
  title,
  outerWrap,
  lendersImageStyles,
  lendersPar,
  lineStyles,
  mortgageImageStyles,
  capitalImageStyles,
  imageParSection,
} from './coopsStyles';

export default function Coop() {
  return (
    <div css={outerWrap} className='container'>
      <div css={lineStyles} />
      <div css={title}>CO-OPs</div>
      <div css={imageParSection}>
        <img src={lendersImage} css={lendersImageStyles} alt='' />
        <div css={lendersPar}>
          Lenders One is a mortgage cooperative that helps independent mortgage bankers operate competitively within the
          industry, and efficiently within their community. They are a one-stop resource for independent mortgage
          bankers, providing the resources and expertise to help each shop operate at maximum efficiency regardless of
          market cycle. Lenders One anticipates, understands, negotiates and delivers the services, partnerships and
          opportunities that help drive its member's businesses forward.
        </div>
      </div>
      <div css={imageParSection}>
        <img src={mortgageImage} css={mortgageImageStyles} alt='' />
        <div css={lendersPar}>
          Based in San Diego, California, The Mortgage Collaborative was founded in 2013 to empower mortgage lenders
          across the country with better financial execution, reduced costs, enhanced expertise and improved compliance,
          as well as helping our members access the dynamic and changing consumer base in America.
        </div>
      </div>
      <div css={imageParSection}>
        <img src={capitalImage} css={capitalImageStyles} alt='' />
        <div css={lendersPar}>
          Capital Markets Cooperative (CMC), part of the Computershare Group, helps Patron members across the country
          compete, succeed, and grow in the highly competitive mortgage banking industry. We can maximize growth for
          each of our members by leveraging the power of our Patron group of financial institutions, credit unions and
          independent mortgage lenders.
        </div>
      </div>
    </div>
  );
}
