/** @jsx jsx */
import { jsx } from '@emotion/core';

import { mainWrap, imageStyle, textWrap } from './cultuerStyles';
import Image from '../../components/ui/Image';

export default function CultureSection() {
  return (
    <div css={mainWrap}>
      <div css={textWrap}>
        <h3>
          <span>A Culture</span> of Solutions
        </h3>
        <p>
          At DocProbe we don’t think out of the box. We destroyed the box. Recognizing the inherent inefficiencies of
          the post-closing experience, we went back to the drawing board and rebuilt the process from the bottom up.
          Using technology as a platform, and personal dedication and innovation as the driver, we are committed to
          making trailing doc retrieval bring a smile to your face.
        </p>
      </div>
      <Image styles={imageStyle} image='bitmap_3x_bx8y42' alt='' />
    </div>
  );
}
