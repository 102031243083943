/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment } from 'react';
import { NavLink } from 'react-router-dom';

import { RightCaretIcon } from '../../components/ui/icons';
import Image from '../../components/ui/Image';
import {
  wrapper,
  outerWrap,
  sectionStyles,
  titleStyles,
  paragraphStyles,
  linkStyles,
  imageStyles,
  horizontalLine,
  lineStyles,
  title1Styles,
} from './threeSectionStyles';

export default function ThreeSection() {
  return (
    <Fragment>
      <div css={wrapper}>
        <div css={horizontalLine} />
        <div css={lineStyles} />
      </div>
      <div>
        <Section
          image='home/Landing_Page_Icons_Streamlined_Process_icon_s3fhq5.svg'
          title1='Streamlined'
          title2='Process'
          paragraph='Easily ensure your documents are retrieved, audited, indexed and shipped securely to your investors and agencies with our complete document solution.'
          link='Take a tour'
          to='/efficiency'
        />
        <Section
          image='home/Landing_Page_Icons_Costs_Icon_lsgske.svg'
          title1='Reduced'
          title2='Operational Costs'
          paragraph='Lower your fixed overhead cost and improve your bottom line with variable fees, process efficiencies, no long term contracts, and our no-penalty guarantee.'
          link='Explore your savings'
          to='/cost'
        />
        <Section
          image='home/Landing_Page_Icons_Relationships_Icon_zhf5n0.svg'
          title1='Improved'
          title2='Relationships'
          paragraph='Rank higher on the secondary market by delivering accurate and complete post-closing documents to your investors and agencies - on time, every time.'
          link='Learn How'
          to='/relationships'
        />
      </div>
    </Fragment>
  );
}

function Section({ image, title1, title2, paragraph, link, to }) {
  return (
    <div css={outerWrap}>
      <div css={sectionStyles}>
        <Image image={image} styles={imageStyles} alt='' />
        <div>
          <div css={titleStyles}>
            <span css={title1Styles}>{title1}</span> {title2}
          </div>
          <div css={paragraphStyles}>{paragraph}</div>
          <div css={linkStyles}>
            <NavLink to={to}>
              {link} <RightCaretIcon />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
